import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MobilePageComponent } from './mobile-page/mobile-page.component';
import { MobileSettingsComponent } from './mobile-settings/mobile-settings.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'install',
        pathMatch: 'full'
    },
    {
        path: 'settings',
        component: MobileSettingsComponent,
        canActivate: []
    },
    {
        path: 'install',
        component: MobilePageComponent,
        canActivate: []
    }
];

export const MobileRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(routes);
