export type IComune = {
        codguid: string,
        codreg: string,
        codprov: string,
        prov: string,
        cap: string,
        codcomune: string,
        //codistat_comune_high: string,
        //codistat_comune_low: string,
        //filler1: string,
        //filler2: string,
        //comune_itadeutch: string,
        comune: string,
        //comune_deutch: string,
        //prefisso: string,
        //capoluogo: string,
        //soppresso: string,
        //altimetrica: string,
        //metri: string,
        //litoranea: string,
        montano: string,
        codlavoro: string,
        descrlavoro: string,
        /*superficie: number,
        poplegale: number,
        pop2008: number,
        pop2009: number,
        codcatasto: string,
        url: string */
}

export const COMUNI: IComune[] = require("../../../assets/anagrafiche/comuni.json")
