<ng-template [ngIf]="!(displayService.isSmall | async)">
  <div class="row row-cat" style=" text-align: center;">
    <a href="javascript:void(0)" [routerLink]="['/', 'homepage']" class="cell-cat">
      <img *ngIf="funzionalitaAttiva == 'homepage';else homepageGrigia " #homepage
        src="./assets/portal/images/icone72dpi/icone72dpi-home.png" title="Home"
        style="border-radius: 50%; width: 40px; height: 40px" />
      <ng-template #homepageGrigia>
        <img #homepage src="./assets/portal/images/icone72dpi/icone72dpi-home-2.png" title="Home"
          style="border-radius: 50%; width: 40px; height: 40px" />
      </ng-template>
    </a>

    <a href="javascript:void(0)" [routerLink]="['/riassunto']" class="cell-cat">
      <img *ngIf="funzionalitaAttiva == 'riassunto';else riassuntoGrigia" #riassunto
        src="./assets/portal/images/icone72dpi/icone72dpi-19.png" title="Riassunto" />
      <ng-template #riassuntoGrigia><img src="./assets/portal/images/icone72dpi/icone72dpi-01.png"
          title="Riassunto" /></ng-template>
    </a>

    <a href="javascript:void(0)" [routerLink]="['/comunicazioni/comunicazioni']" class="cell-cat cat-comunicazioni" *ngIf="fun.isComunicazioniAbil()">
      <img *ngIf="funzionalitaAttiva == 'comunicazioni';else comunicazioniGrigia" #comunicazioni
        src="./assets/portal/images/icone72dpi/icone72dpi-20.png" title="Comunicazioni" />
      <ng-template #comunicazioniGrigia><img src="./assets/portal/images/icone72dpi/icone72dpi-02.png"
          title="Comunicazioni" /></ng-template>
    </a>

    <a href="javascript:void(0)" [routerLink]="['/ricetteDematerializzate']" class="cell-cat" *ngIf="fun.isFarmaciAbil()">
      <img *ngIf="funzionalitaAttiva == 'ricetteDematerializzate';else ricetteDematerializzateGrigia "
        #ricetteDematerializzate src="./assets/portal/images/icone72dpi/icone72dpi-21.png" title="Ricette" />
      <ng-template #ricetteDematerializzateGrigia><img src="./assets/portal/images/icone72dpi/icone72dpi-03.png"
          title="Ricette" /></ng-template>
    </a>

    <a *ngIf="fun.isParametriAbil()" href="javascript:void(0)"
      href="javascript:void(0)" [routerLink]="['/parametri']" class="cell-cat">
      <img *ngIf="funzionalitaAttiva == 'parametri';else parametriGrigia " #parametri
        src="./assets/portal/images/icone72dpi/iconaparceleste.png" title="Parametri" />
      <ng-template #parametriGrigia><img src="./assets/portal/images/icone72dpi/iconapargrigia.png"
          title="Parametri" /></ng-template>
    </a>

    <a href="javascript:void(0)" [routerLink]="['/terapiaContinuativa']" class="cell-cat" *ngIf="fun.isFarmaciAbil()">
      <img *ngIf="funzionalitaAttiva == 'terapiaContinuativa';else terapiaContinuativaGrigia" #terapiaContinuativa
        src="./assets/portal/images/icone72dpi/icone72dpi-22.png" title="Terapia continuativa" />
      <ng-template #terapiaContinuativaGrigia><img src="./assets/portal/images/icone72dpi/icone72dpi-04.png"
          title="Terapia continuativa" /></ng-template>
    </a>

    <a href="javascript:void(0)" [routerLink]="['/esenzioni']" class="cell-cat" *ngIf="fun.isEsenzioniAbil()">
      <img *ngIf="funzionalitaAttiva == 'esenzioni';else esenzioniGrigia" #esenzioni
        src="./assets/portal/images/icone72dpi/icone72dpi-23.png" title="Esenzioni" />
      <ng-template #esenzioniGrigia><img src="./assets/portal/images/icone72dpi/icone72dpi-05.png"
          title="Esenzioni" /></ng-template>
    </a>

    <a href="javascript:void(0)" [routerLink]="['/allergie']" class="cell-cat" *ngIf="fun.isAllergieAbil()">
      <img *ngIf="funzionalitaAttiva == 'allergie';else allergieGrigia" #allergie
        src="./assets/portal/images/icone72dpi/icone72dpi-24.png" title="Allergie" />
      <ng-template #allergieGrigia><img src="./assets/portal/images/icone72dpi/icone72dpi-06.png"
          title="Allergie" /></ng-template>
    </a>

    <a href="javascript:void(0)" [routerLink]="['/scadenze']" class="cell-cat" *ngIf="fun.isScadenzeAbil()">
      <img *ngIf="funzionalitaAttiva == 'scadenze';else scadenzeGrigia" #scadenze
        src="./assets/portal/images/icone72dpi/icone72dpi-25.png" title="Scadenze" />
      <ng-template #scadenzeGrigia><img src="./assets/portal/images/icone72dpi/icone72dpi-07.png"
          title="Scadenze" /></ng-template>
    </a>

    <a href="javascript:void(0)" [routerLink]="['/certificatiInps']" class="cell-cat" *ngIf="fun.isCertInpsAbil()">
      <img *ngIf="funzionalitaAttiva == 'certificati';else certificatiGrigia" #certificati
        src="./assets/portal/images/icone72dpi/icone72dpi-26.png" title="Certificati Inps" />
      <ng-template #certificatiGrigia><img src="./assets/portal/images/icone72dpi/icone72dpi-08.png"
          title="Certificati Inps" /></ng-template>
    </a>

    <a href="javascript:void(0)" [routerLink]="['/pianiTerapeutici']" class="cell-cat" *ngIf="fun.isPiaTerAbil()">
      <img *ngIf="funzionalitaAttiva == 'pianiTerapeutici';else pianiGrigia" #pianiTerapeutici
        src="./assets/portal/images/icone72dpi/icone72dpi-27.png" title="Piani terapeutici" />
      <ng-template #pianiGrigia><img src="./assets/portal/images/icone72dpi/icone72dpi-09.png"
          title="Piani terapeutici" /></ng-template>
    </a>

    <!-- presidi -->
    <a href="javascript:void(0)" [routerLink]="['/presidi']" class="cell-cat" *ngIf="fun.isPresidiAbil()">
      <img *ngIf="funzionalitaAttiva == 'presidi';else presidiGrigia" #certificati
        src="./assets/portal/images/icone72dpi/icone72dpi-28.png" title="Presidi" />
      <ng-template #presidiGrigia><img src="./assets/portal/images/icone72dpi/icone72dpi-10.png"
          title="Presidi" /></ng-template>
    </a>

    <!-- esamiDiLaboratorio -->
    <a href="javascript:void(0)" [routerLink]="['/esamiDiLaboratorio']" class="cell-cat" *ngIf="fun.isEsamiAbil()">
      <img #esamiDiLaboratorio *ngIf="funzionalitaAttiva == 'esamiDiLaboratorio';else esamiDiLaboratorioGrigia"
        src="./assets/portal/images/icone72dpi/icone72dpi-29.png" title="Esami di laboratorio" />
      <ng-template #esamiDiLaboratorioGrigia><img src="./assets/portal/images/icone72dpi/icone72dpi-11.png"
          title="Esami di laboratorio" /></ng-template>
    </a>

    <!-- procedureDiagnostiche -->
    <a href="javascript:void(0)" [routerLink]="['/procedureDiagnostiche']" class="cell-cat" *ngIf="fun.isProcDiaAbil()">
      <img #procedureDiagnostiche *ngIf="funzionalitaAttiva == 'procedureDiagnostiche';else procedureDiagnosticheGrigia"
        src="./assets/portal/images/icone72dpi/icone72dpi-30.png" title="Esami strumentali e visite" />
      <ng-template #procedureDiagnosticheGrigia><img src="./assets/portal/images/icone72dpi/icone72dpi-12.png"
          title="Esami strumentali e visite" /></ng-template>
    </a>

    <!-- procedureTerapeutiche -->
    <a href="javascript:void(0)" [routerLink]="['/procedureTerapeutiche']" class="cell-cat" *ngIf="fun.isProcTerAbil()">
      <img #procedureTerapeutiche *ngIf="funzionalitaAttiva == 'procedureTerapeutiche';else procedureTerapeuticheGrigia"
        src="./assets/portal/images/icone72dpi/icone72dpi-31.png" title="Prestazioni" />
      <ng-template #procedureTerapeuticheGrigia><img src="./assets/portal/images/icone72dpi/icone72dpi-13.png"
          title="Prestazioni" /></ng-template>
    </a>

    <!-- tao -->
    <a href="javascript:void(0)" [routerLink]="['/TAO']" class="cell-cat" *ngIf="fun.isTAOAbil()">
      <img #TAO *ngIf="funzionalitaAttiva == 'TAO';else taoGrigia"
        src="./assets/portal/images/icone72dpi/icone72dpi-32.png" title="TAO" />
      <ng-template #taoGrigia><img src="./assets/portal/images/icone72dpi/icone72dpi-14.png"
          title="TAO" /></ng-template>
    </a>

    <!-- cartella personale -->
    <a href="javascript:void(0)" [routerLink]="['/personale/cartellaPersonale']" class="cell-cat">
      <img #cartellaPersonale *ngIf="funzionalitaAttiva == 'cartellaPersonale';else cartellaPersonaleGrigia"
        src="./assets/portal/images/icone72dpi/icone72dpi-33.png" title="Cartella Personale" />
      <ng-template #cartellaPersonaleGrigia><img #cartellaPersonale
          src="./assets/portal/images/icone72dpi/icone72dpi-15.png" title="Cartella personale" /></ng-template>
    </a>

    <!-- calendario -->
    <a *ngIf="fun.isAppuntamentiAbil()" href="javascript:void(0)" [routerLink]="['/calendario']" class="cell-cat">
      <img #appuntamenti *ngIf="funzionalitaAttiva == 'Appuntamenti';else appuntamentiGrigia"
        src="./assets/portal/images/icone72dpi/icone72dpi-34.png" title="Appuntamenti" />
      <ng-template #appuntamentiGrigia><img #appuntamenti src="./assets/portal/images/icone72dpi/icone72dpi-16.png"
          title="Appuntamenti" /></ng-template>
    </a>

    <!-- spese -->
    <a href="javascript:void(0)" [routerLink]="['/spese']" class="cell-cat">
      <img #spese *ngIf="funzionalitaAttiva == 'spese';else speseGrigia"
        src="./assets/portal/images/icone72dpi/icone72dpi-spese-2.png" title="Spese Sanitarie" />
      <ng-template #speseGrigia><img src="./assets/portal/images/icone72dpi/icone72dpi-spese.png"
          title="Spese Sanitarie" /></ng-template>
    </a>

    <!-- teleconsulto -->
    <!--<a href="/tele>consulti"  class="cell-cat" *ngIf="fun.isFunzionalitaAbilitata('Sincronizzazione esami di laboratorio')">
    <img #teleconsulti
      src="./assets/portal/images/icone72dpi/icone72dpi-teleconsulti.png"
      title="Teleconsulti"
    />
  </a>-->

    <!-- anagrafica -->
    <a href="javascript:void(0)" [routerLink]="['/anagrafica']" class="cell-cat">
      <img #anagrafica *ngIf="funzionalitaAttiva == 'anagrafica' ;else anagraficaGrigia"
        src="./assets/portal/images/icone72dpi/icone72dpi-35.png" title="Profilo" />
      <ng-template #anagraficaGrigia><img src="./assets/portal/images/icone72dpi/icone72dpi-17.png"
          title="Profilo" /></ng-template>
    </a>
  </div>
</ng-template>