import { Injectable, OnDestroy, inject } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";
import { Subscription } from "rxjs";
import { SW_CHECK_UPDATE_TIME } from "../constants";
import { DialogMaterialService } from "../services/dialog-material.service";
import { dateToStringWithHours } from "../utils/Utility";

@Injectable({
    providedIn: 'root'
})
export class SwUpdateService implements OnDestroy {

    public debugLines = []
    updSub: Subscription

    private updates

    constructor(private dialogMaterialService: DialogMaterialService) {

        try {
            this.updates = inject(SwUpdate)

            this.updates.unrecoverable.subscribe(event => {
                this.debugLines.push(`<span class="error-text">Unrecoverable state: ${event.reason}</span>`)
                console.error(`Unrecoverable state: ${event.reason}`)
                location.reload()
                
                /* lastValueFrom(
                    this.dialogMaterialService
                        .openClose('Attenzione', "Impossibile applicare gli aggiornamenti automatici dell'applicazione. Verrà ricaricata la pagina.")
                        .afterClosed()
                ).then(() => location.reload()) */
            });
        } catch (err) {
            this.debugLines.push(`<span class="error-text">Error, the service worker library does not work</span>`)
            console.error("Error, the service worker library does not work")
            return
        }

        if (!this.enabled()) {
            this.debugLines.push(`<span class="error-text">Error, the service worker is not enabled</span>`)
            return
        }

        this.debugLines.push(`Service worker enabled`)

        this.schedule()
    }

    enabled() {
        return this.updates.isEnabled
    }

    addLog(log: string) {
        const time = dateToStringWithHours(new Date())
        const line = `${time} => ${log}`
        this.debugLines.push(line)
        console.log(`SW LOG: ${log}`)
    }

    async checkAndUpdate() {

        // se il sw non è abilitato non posso proseguire
        if (!this.enabled()) {
            return
        }

        // se ho updSub valorizzato significa che sono già registrato al servizio di check,
        // ergo controllo solo se c'è una nuova versione
        // altrimenti mi registro
        if (this.updSub) {
            await this.updates.checkForUpdate()
        } else {
            // questa chiamata controlla la prima volta, ma non quelle successive, per questo è
            // necessario chiamare periodicamente la checkForUpdate()
            this.updSub = this.updates.versionUpdates.subscribe(event => {
                const time = dateToStringWithHours(new Date())
                switch (event.type) {
                    case 'NO_NEW_VERSION_DETECTED':
                        this.addLog(`No new version detected. Current version: <b>${event.version.hash}</b>`)
                        break
                    case 'VERSION_DETECTED':
                        this.addLog(`Found new version: <b>${event.version.hash}</b>`)
                        break
                    case 'VERSION_INSTALLATION_FAILED':
                        this.addLog(`<span class="error-text">Error installing new version. Version: ${event.version.hash}, error: ${event.error}</span>`)
                        break
                    case 'VERSION_READY':
                        this.addLog(`New version installed. Current version: <b>${event.currentVersion.hash}</b>, new version: <b>${event.latestVersion.hash}</b>`)

                        const dialog = this.dialogMaterialService.openYesNo(
                            "Aggiornamento",
                            "Nuova versione dell'applicazione disponibile, aggiornare?"
                        )

                        dialog.afterClosed().subscribe(async result => {
                            if (result) {
                                console.log('[WS] Installing updates')
                                if (await this.updates.activateUpdate()) {
                                    location.reload()
                                }
                            }
                        })

                        break
                }
            })
        }
    }

    async schedule(runFirstTime = true) {
        if (runFirstTime) {
            await this.checkAndUpdate()
        }

        setInterval(async () => await this.checkAndUpdate(), SW_CHECK_UPDATE_TIME);
    }

    ngOnDestroy(): void {
        this.updSub?.unsubscribe()
    }
}