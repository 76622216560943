import { Component } from "@angular/core";
import { PwaInstallationService } from "../../lib/sw/pwa-installation.service";

@Component({
    selector: 'app-mobile-page',
    templateUrl: './mobile-page.component.html'
})
export class MobilePageComponent {

    constructor(private pwaService: PwaInstallationService) {

    }

    install() {
        this.pwaService.askToInstall()
    }

}
