import { Component } from '@angular/core';

@Component({
  selector: 'app-scopri',
  templateUrl: './scopri.component.html',
  styleUrls: ['../../../lib/themes/portale.theme.scss']
})
export class ScopriComponent {

}
