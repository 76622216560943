import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";

export interface EvButtonToggle {
    text: string
    icon: string
    url: string
    value: string
    click: () => void
}

@Component({
    selector: 'ev-button-toggle',
    styles: [
        `
        mat-button-toggle-group {
            width: 100%;
        }
        `
    ],
    host: {
        '[style.position]': "'fixed'",
        '[style.bottom]': "0",
        '[style.z-index]': "1",
        '[style.width]': "'100%'"
    },
    template: `
<mat-button-toggle-group>
    <mat-button-toggle 
        *ngFor="let button of buttons" 
        [value]="button.value" 
        (click)="handleClick(button)" 
        [ngStyle]="{'width': (100 / buttons.length)+'%'}"
    >
        <span *ngIf="button.text">{{button.text}}</span>
        <mat-icon *ngIf="button.icon">{{button.icon}}</mat-icon>
    </mat-button-toggle>
</mat-button-toggle-group>
    `
})
export class EvButtonToggleComponent {

    @Input() buttons: Partial<EvButtonToggle>[]

    constructor(private router: Router) {

    }

    handleClick(button: Partial<EvButtonToggle>) {
        if (button.click) {
            button.click()
        }
        if (button.url) {
            this.router.navigateByUrl(button.url)
        }
    }
}
