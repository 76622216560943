import { Directive, ElementRef, Input, OnDestroy, OnInit } from "@angular/core";
import { DisplayService } from "../services/display.service";
import { isEmpty } from "../utils/Utility";


@Directive({
    selector: '[classForScreen]'
})
export class ClassForScreenDirective implements OnInit, OnDestroy {
    @Input() classSmallScreen: string
    @Input() classForScreen: string

    private sub

    constructor(private elementRef: ElementRef, private displayService: DisplayService) {

    }

    ngOnInit(): void {
        this.sub = this.displayService.isSmall.subscribe(isSmall => {
            const element = this.getElement()

            this.removeClasses(element)
            if (isSmall && !isEmpty(this.classSmallScreen)) {
                element.classList.add(this.classSmallScreen)
            } else {
                if (!isEmpty(this.classForScreen))
                    element.classList.add(this.classForScreen)
            }
        })
    }

    getElement() {
        return this.elementRef.nativeElement as HTMLElement
    }

    removeClasses(element: HTMLElement) {
        if (this.classSmallScreen) {
            element.classList.remove(this.classSmallScreen)
        }

        if (this.classForScreen) {
            element.classList.remove(this.classForScreen)
        }
    }

    ngOnDestroy(): void {
        this.sub?.unsubscribe()
    }
}
