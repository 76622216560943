<div class="external-page-container">
    <div class="page-container">
        <ev-categorie [funzionalitaAttiva]="funzionalita" *ngIf="!hideCategorie"></ev-categorie>
        <div class="page-title" *ngIf="pageTitle">{{pageTitle}}</div>

        <ng-template [ngIf]="showInfo">
            <div class="page-info-container">
                <ev-page-info [title]="infoTitle" [text]="infoText" [expanded]="infoExpanded">
                    <ng-content select="[infoText]"></ng-content>
                </ev-page-info>
            </div>
        </ng-template>

        <ng-content></ng-content>
    </div>

</div>