import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FullCalendarModule } from "@fullcalendar/angular";
import {
    EvAttivazioneUtenteComponent,
    EvAttivazioneUtenteModalComponent
} from "./components/attivazione-utente/attivazione-utente.component";
import { EvCaptchaComponent } from "./components/captcha/captcha.component";
import { EvCategorieComponent } from "./components/categorie/categorie.component";
import { EvAutocompleteComponent } from "./components/ev-autocomplete.component";
import { EvButtonToggleComponent } from "./components/ev-button-toggle.component";
import { EvCodeInputComponent } from "./components/ev-code-input.component";
import { EvCodiceFiscaleComponent } from "./components/ev-codice-fiscale.component";
import { EvComuneComponent } from "./components/ev-comune.component";
import { EvCookieBannerComponent } from "./components/ev-cookie-banner.component";
import { EvDebugInfoComponent } from "./components/ev-debug-info.component";
import { EvDialogMaterialComponent } from "./components/ev-dialog-material.component";
import { EvFileUploadMaterialComponent } from "./components/ev-file-upload-material/ev-file-upload-material.component";
import { EvInfoArrowComponent } from "./components/ev-info-arrow.component";
import { EvListComponent } from "./components/ev-list/ev-list.component";
import { EvLogoComponent } from "./components/ev-logo.component";
import { EvManualeComponent } from "./components/ev-manuale.component";
import { EvNazioneComponent } from "./components/ev-nazione.component";
import { EvPageInfoComponent } from "./components/ev-page-info.component";
import { EvPageThemeComponent } from "./components/ev-page-theme.component";
import { EvPageComponent } from "./components/ev-page/ev-page.component";
import { EvPaginatorComponent } from "./components/ev-paginator.component";
import { EvPdfViewerComponent } from "./components/ev-pdf-viewer.component";
import { EvProgressComponent } from "./components/ev-progress.component";
import { EvProvinciaComponent } from "./components/ev-provincia.component";
import { EvSchedulerComponent } from "./components/ev-scheduler/ev-scheduler.component";
import { EvSesso } from "./components/ev-sesso.component";
import { EvSpinnerComponent } from "./components/ev-spinner.component";
import { EvTableMobileComponent } from "./components/ev-table-mobile/ev-table-mobile.component";
import { EvTable } from "./components/ev-table/ev-table.component";
import { EvTelephoneComponent } from "./components/ev-telephone.component";
import { EvUserProfileComponent } from "./components/ev-user-profile/ev-user-profile.component";
import HomeMessageComponent from "./components/home-message.component";
import { BackgroundImgFallbackDirective } from "./directives/background-img-fallback.directive";
import { ClassForScreenDirective } from "./directives/class-for-screen.directive";
import { HideOnMobileDirective } from "./directives/hide-on-mobile.directive";
import { ImgFallbackDirective } from "./directives/img-fallback.directive";
import { OverlayModule } from "@angular/cdk/overlay";
import { PortalModule } from "@angular/cdk/portal";
import { MatInputModule } from "@angular/material/input";
import { MatDialogModule } from "@angular/material/dialog";
import { MatStepperModule } from "@angular/material/stepper";
import { MatSortModule } from "@angular/material/sort";
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatExpansionModule } from "@angular/material/expansion";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatCardModule } from "@angular/material/card";
import { MatSelectModule } from "@angular/material/select";
import { MatButtonModule } from "@angular/material/button";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatListModule } from "@angular/material/list";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { MatTableModule } from '@angular/material/table';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { RouterModule } from "@angular/router";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
    declarations: [EvCodiceFiscaleComponent,
        EvTelephoneComponent,
        EvCodeInputComponent,
        EvDialogMaterialComponent,
        EvProgressComponent,
        EvCookieBannerComponent,
        EvAutocompleteComponent,
        EvCaptchaComponent,
        EvFileUploadMaterialComponent,
        EvPdfViewerComponent,
        EvLogoComponent,
        EvManualeComponent,
        EvTable,
        EvAttivazioneUtenteComponent,
        EvAttivazioneUtenteModalComponent,
        ImgFallbackDirective,
        ClassForScreenDirective,
        BackgroundImgFallbackDirective,
        EvListComponent,
        EvButtonToggleComponent,
        EvUserProfileComponent,
        EvPaginatorComponent,
        EvTableMobileComponent,
        EvPageComponent,
        EvPageThemeComponent,
        EvCategorieComponent,
        EvSpinnerComponent,
        EvPageInfoComponent,
        HideOnMobileDirective,
        HomeMessageComponent,
        EvDebugInfoComponent,
        EvInfoArrowComponent,
        EvSchedulerComponent,
        EvSesso,
        EvProvinciaComponent,
        EvComuneComponent,
        EvNazioneComponent
    ],
    imports: [
        FullCalendarModule,
        MatStepperModule,
        MatDialogModule,
        MatProgressBarModule,
        MatInputModule,
        ReactiveFormsModule,
        FormsModule,
        CommonModule,
        MatTableModule,
        MatPaginatorModule,
        MatCheckboxModule,
        MatIconModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatListModule,
        PortalModule,
        MatButtonToggleModule,
        MatSortModule,
        RouterModule,
        MatProgressSpinnerModule,
        OverlayModule,
        MatExpansionModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatCardModule,
        MatMenuModule,
        MatSelectModule
    ],
    exports: [
        EvComuneComponent,
        EvButtonToggleComponent,
        ImgFallbackDirective,
        EvCodiceFiscaleComponent,
        EvTelephoneComponent,
        EvCodeInputComponent,
        MatStepperModule,
        EvDialogMaterialComponent,
        EvProgressComponent,
        EvCookieBannerComponent,
        EvAutocompleteComponent,
        EvCaptchaComponent,
        EvFileUploadMaterialComponent,
        EvPdfViewerComponent,
        EvLogoComponent,
        EvManualeComponent,
        EvTable,
        EvAttivazioneUtenteComponent,
        EvAttivazioneUtenteModalComponent,
        MatStepperModule,
        MatDialogModule,
        MatProgressBarModule,
        MatInputModule,
        ReactiveFormsModule,
        FormsModule,
        CommonModule,
        MatTableModule,
        MatPaginatorModule,
        MatCheckboxModule,
        MatIconModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        MatButtonModule,
        ClassForScreenDirective,
        BackgroundImgFallbackDirective,
        EvListComponent,
        EvUserProfileComponent,
        EvPaginatorComponent,
        EvTableMobileComponent,
        EvPageComponent,
        EvPageThemeComponent,
        EvCategorieComponent,
        EvSpinnerComponent,
        EvPageInfoComponent,
        HideOnMobileDirective,
        MatDatepickerModule,
        MatMomentDateModule,
        HomeMessageComponent,
        MatExpansionModule,
        MatCardModule,
        MatSidenavModule,
        MatListModule,
        MatMenuModule,
        EvDebugInfoComponent,
        EvInfoArrowComponent,
        EvSchedulerComponent,
        EvSesso,
        EvProvinciaComponent,
        EvNazioneComponent
    ],

    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'it-IT' },
    ]
})
export class LibModule {

}
