import { Component, HostBinding, Input } from "@angular/core";

@Component({
    selector: 'ev-info-arrow',
    template: `
<div class="info-arrow-container" *ngIf="_show">
    <div>
        {{message}}
    </div>
    <mat-icon>{{icon}}</mat-icon>
</div>
    `,
    styles: [
        `:host {
            position: absolute;
        }`
    ]
})
export class EvInfoArrowComponent {

    @HostBinding('style.top.px')
    @Input({ required: true }) top: number

    @HostBinding('style.left.px')
    @Input({ required: true }) left: number

    @Input({ required: true }) message: string
    @Input() icon: string = 'arrow_dawnward'

    _show = false

    toggle() {
        this._show = !this._show
    }
}