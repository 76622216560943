import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UtenteService } from '../../../../lib/services/utente.service';

declare var $: any;

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.sass'],
})
export class LogoutComponent implements OnInit {

  constructor(
    private router: Router,
    private utenteService: UtenteService
  ) { }

  ngOnInit(): void {
    this.utenteService.logout().then(() => {
      this.router.navigate(['/'], {})
    })
  }

}
