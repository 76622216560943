<div style="padding: 20px">
    <ev-progress [hidden]="hiddenProgress"></ev-progress>

    <h1 mat-dialog-title>Richiesta di attivazione utente</h1>

    <div mat-dialog-content style="padding: 0 !important;">
        <mat-vertical-stepper linear #stepper>
            <mat-step label="Inserisci qui i tuoi dati" [stepControl]="this.attivazioneUtenteForm">
                <ev-attivazione-utente [userId]="userId"></ev-attivazione-utente>

                <button mat-raised-button color="primary" (click)="next()">Prosegui</button>
            </mat-step>

            <mat-step label="Conferma con email">
                <div style="margin-bottom: 20px;" *ngIf="this.attivazioneUtenteForm">
                    <mat-hint>
                        Ti abbiamo inviato un codice di sicurezza all'indirizzo <b>{{this.attivazioneUtenteForm.controls.email.value}}</b>
                    </mat-hint>
                </div>
                <div style="display: flex;justify-content: center; flex-direction: row-reverse;flex-wrap: wrap;gap: 10px">
                    <!-- <button mat-raised-button (click)="sendCode()" color="primary" style="margin: 20px 0 0 15px; flex: none">
                        Richiedi nuovo codice</button> -->

                    <ev-code #codiceUtente></ev-code>
                </div>
            </mat-step>
        </mat-vertical-stepper>
    </div>

    <div mat-dialog-actions align="end">
        <button mat-raised-button (click)="close()" style="margin-right: 10px;">Annulla</button>
        <button mat-raised-button (click)="send()" color="primary" [disabled]="!valid()">Invia richiesta</button>
    </div>

</div>

<ng-template #dialogConfermaInvio>
    <ev-dialog-material title="Conferma">
        <div main>
            Confermare l'invio della richiesta?
        </div>
        <div buttons>
            <button mat-button mat-raised-button mat-dialog-close>Annulla</button>
            <button mat-button cdkFocusInitial mat-raised-button color="primary" (click)="confirm()">Conferma</button>
        </div>
    </ev-dialog-material>
</ng-template>