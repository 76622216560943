<form [formGroup]="form" class="form">
    <div style="margin-top: 15px;">
        <div style="display: flex; flex-wrap: wrap; gap: 10px">
            <mat-form-field>
                <mat-label>Nome</mat-label>
                <input matInput formControlName="nome">
            </mat-form-field>

            <mat-form-field>
                <mat-label>Cognome</mat-label>
                <input matInput formControlName="cognome">
            </mat-form-field>

            <ev-sesso formControlName="sesso"></ev-sesso>

            <mat-form-field class="date-control">
                <mat-label>Data di nascita</mat-label>
                <input matInput [matDatepicker]="data" formControlName="data_nascita" [required]="false">
                <mat-datepicker-toggle matIconSuffix [for]="data"></mat-datepicker-toggle>
                <mat-datepicker #data></mat-datepicker>
            </mat-form-field>
        </div>

        <div style="display: flex; flex-wrap: wrap; gap: 10px; align-items: center;">

            <ev-nazione label="Nazione di nascita" formControlName="nazione_nascita"></ev-nazione>

            <!-- <ev-citta label="Provincia di Nascita" formControlName="provincia_nascita" #cittaCtrl></ev-citta> -->
            <ev-comune label="Luogo di Nascita" formControlName="comune_nascita"></ev-comune>

            <mat-form-field style="width: 100px;">
                <mat-label>Provincia</mat-label>
                <input matInput type="text" formControlName="provincia_nascita">
            </mat-form-field>

        </div>

        <div style="display: flex; gap: 10px; align-items: center;">
            <ev-codice-fiscale [initAsTouched]="true" formControlName="codice_fiscale"></ev-codice-fiscale>

            <div>
                <button mat-raised-button color="primary" (click)="calcolaAndApplicaCodiceFiscale()"
                    [disabled]="isCalcoloCodiceFiscaleDisabilitato()">
                    Calcola codice fiscale
                </button>
            </div>
        </div>

        <div style="display: flex; flex-wrap: wrap; gap: 10px;">
            <div>
                <ev-telephone [required]="false" formControlName="cellulare"></ev-telephone>
            </div>

            <div>
                <mat-form-field style="display: flex;">
                    <mat-label>E-mail</mat-label>
                    <input matInput formControlName="email" type="email" class="input-field-invisible-style">
                </mat-form-field>
                <div style="margin: -15px auto 22px auto;font-size: 12px;">
                    <mat-hint>L'email deve coincidere con quella comunicata al medico.</mat-hint>
                </div>
            </div>
        </div>

        <div>
            <ev-autocomplete label="Medico curante" [options]="mediciFiltrati" formControlName="codice_fiscale_medico"
                [required]="true" [initAsTouched]="true"></ev-autocomplete>
        </div>

        <div>
            <ev-captcha #captcha></ev-captcha>
        </div>
    </div>
</form>