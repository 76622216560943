import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpRequestResponse } from '../../../lib/models/http-request-response.model';
import { HttpRequestService } from '../../../lib/services/http-request.service';
import { LogService } from '../../../lib/services/log.service';
import { newError } from '../../../lib/utils/Utility';

@Component({
  selector: 'ev-contact-form',
  templateUrl: './contattaci.component.html',
  styleUrls: ['./contattaci.component.scss',],
})
export class ContattaciComponent {
  title = 'Contattaci';

  form = new FormGroup({
    subject: new FormControl('', [Validators.required]),
    from: new FormControl('', [Validators.required, Validators.email]),
    message: new FormControl('', [Validators.required, Validators.maxLength(350)]),
    accepted: new FormControl(false, [Validators.requiredTrue])
  })

  constructor(
    private logService: LogService,
    private httpRequestService: HttpRequestService,
    private router: Router
  ) { }


  invia() {

    const data = this.form.getRawValue()

    this.httpRequestService.post('/send-mail/', data)
      .subscribe((response) => {
        if (HttpRequestResponse.isSuccessResponse(response)) {
          this.router.navigate(['/'], {});
        } else {
          this.logService.messageError(
            'Errore',
            response.message,
            newError(response.message)
          );
        }
      });
  }
}
