import { Component, Input, OnInit } from "@angular/core";
import { NC_LOGO_BIG, NC_LOGO_SMALL, SCT_LOGO } from "../constants";
import { ApplicationService } from "../services/application.service";
import { CommonObject } from "../utils/Utility";

@Component({
    selector: 'ev-logo',
    styles: [`

    .logo-container {
        text-align: center;
        padding: 20px 20px 40px 20px;
    }
    .logo-container > img {
        height: 100px;
    }

    `],
    template: `
    <div class="logo-container" [ngStyle]="imgContainerStyle">
        <img [src]="imgPath" [ngStyle]="imgStyle"/>
    </div>
    `
})
export class EvLogoComponent implements OnInit {

    imgPath: string

    @Input() forceNC: boolean
    @Input() big: boolean = true
    @Input() imgContainerStyle: CommonObject
    @Input() imgStyle: CommonObject

    constructor(private applicationService: ApplicationService) {
    }

    ngOnInit() {
        if (this.forceNC || this.applicationService.isNextComunica()) {
            this.imgPath = this.big ? NC_LOGO_BIG : NC_LOGO_SMALL
        } else {
            this.imgPath = SCT_LOGO
        }
    }
}
