import { Directive, ElementRef, Input, OnDestroy, OnInit } from "@angular/core";
import { DisplayService } from "../services/display.service";

@Directive({
    selector: '[bgImgFallback]'
})
export class BackgroundImgFallbackDirective implements OnInit, OnDestroy {
    @Input() bgImgFallback: string
    @Input() noBackgroundOnMobile: boolean = false

    private sub

    constructor(private elementRef: ElementRef, private displayService: DisplayService) {
    }

    ngOnInit(): void {
        if (this.noBackgroundOnMobile) {
            this.sub = this.displayService.isSmall.subscribe(isSmall => {
                if (isSmall) {
                    this.elementRef.nativeElement.style.backgroundImage = null
                } else {
                    this.updateBackground()
                }
            })
        } else {
            this.updateBackground()
        }


    }

    updateBackground() {
        const bgImage = this.elementRef.nativeElement.style.backgroundImage

        if (!bgImage) {
            this.elementRef.nativeElement.style.backgroundImage = `url(${this.bgImgFallback})`
            this.elementRef.nativeElement.style.backgroundRepeat = `no-repeat`
            this.elementRef.nativeElement.style.backgroundSize = `cover`
        }
    }

    ngOnDestroy(): void {
        this.sub?.unsubscribe()
    }
}
