export class HttpRequestResponse {
  status: string;
  message: string;
  data?: { [key: string]: any };
  items?: any[];
  rows?: any[];
  total?: number

  static extractResponseMessage(response: HttpRequestResponse): string {
    return response.message;
  }

  static isSuccessResponse(response: HttpRequestResponse): boolean {
    if (response.status) {
      return response.status == 'success';
    } else {
      return response.total >= 0;
    }
  }


  isResponseOk(): boolean {
    return HttpRequestResponse.isSuccessResponse(this);
  }
}
