<div class="page" id="page">
  <div class="pixfort_corporate_2" id="section_corporate_4" style="/*width: 60% !important;*/ margin: 1px auto !important;">
      <div class="container">
          <div>
                  <h1>{{applicationName}}</h1><br><br>

                  <h3 style="text-align: center">Informativa cookie</h3>

                  Provvedimento Generale del Garante per la protezione dei dati personali – n. 229, 8 maggio 2014.<br><br>
                  <h4>COSA SONO I COOKIE</h4>
                  I cookie
                  sono brevi frammenti di testo (lettere e/o numeri) che permettono al server web di memorizzare sul client
                  (il browser) informazioni da riutilizzare nel corso della medesima visita al sito (cookie di sessione)
                  o in seguito, anche a distanza di giorni (cookie persistenti). I cookie vengono memorizzati, in base
                  alle preferenze dell’utente, dal singolo browser sullo specifico dispositivo utilizzato (computer, tablet,
                  smartphone). <br>
                  In base alle caratteristiche e all’utilizzo possiamo distinguere i cookie in 3 diverse categorie:<br><br>

                  <b>Cookie Tecnici.</b><br>
                  Si tratta di cookie indispensabili per il corretto funzionamento del nostro sito e sono
                  utilizzati per gestire funzionalità di base quali ad es.: il login, le lingue dell’interfaccia e le ricerche.
                  La durata dei cookie è limitata. La loro disattivazione potrebbe compromette l’utilizzo del sito.<br><br>
                  <b>Cookie Analitici.</b><br>
                  Sono cookie utilizzati per raccogliere e analizzare il traffico e l’utilizzo del sito. Questi
                  cookie, pur senza identificare l’utente, consentono, per esempio, di rilevare se il medesimo utente torna
                  a collegarsi in momenti diversi. Permettono inoltre di monitorare il sistema e migliorarne le prestazioni
                  e l’usabilità. La disattivazione di tali cookie può essere eseguita senza alcuna perdita di funzionalità.<br><br>
                  <b>Cookie Profilazione.</b><br>
                  Si tratta di cookie permanenti utilizzati per identificare (in modo anonimo e non)
                  le preferenze dell’utente e per l’invio di messaggi pubblicitari personalizzati. Il nostro sito non utilizza
                  cookie di questo tipo. <br><br>

                  <h4>I COOKIE DI TERZE PARTI</h4>
                  Visitando un sito web si possono ricevere cookie sia dal
                  sito visitato sia da siti gestiti da altre organizzazioni ("terze parti"). Un esempio notevole è rappresentato
                  dalla presenza dei "social plugin" per Facebook, Twitter, Google e LinkedIn. Si tratta di parti della
                  pagina visitata generate direttamente dai suddetti siti ed integrati nella pagina del sito ospitante.
                  L’utilizzo più comune dei social plugin è finalizzato alla condivisione dei contenuti sui social network
                  (ad es. il pulsante "Mi Piace" di Facebook).<br>
                  La presenza di questi plugins comporta la trasmissione di
                  cookie da e verso tutti i siti gestiti da terze parti. La gestione delle informazioni raccolte da "terze
                  parti" è disciplinata dalle relative informative cui si prega di fare riferimento. Per garantire una
                  maggiore trasparenza e comodità, si riportano qui di seguito gli indirizzi web delle diverse informative
                  e delle modalità per la gestione dei cookie. <br><br>

                  Facebook informativa: <a href="https://www.facebook.com/help/cookies/">https://www.facebook.com/help/cookies/</a><br>
                  Facebook configurazione: accedere alla sezione privacy del proprio account.<br>
                  Twitter informativa: <a href="https://support.twitter.com/articles/20170514">https://support.twitter.com/articles/20170514</a><br>
                  Twitter configurazione: <a href="https://twitter.com/settings/security">https://twitter.com/settings/security</a><br>
                  Linkedin informativa: <a href="https://www.linkedin.com/legal/cookie-policy">https://www.linkedin.com/legal/cookie-policy</a><br>
                  Linkedin configurazione: <a href="https://www.linkedin.com/settings/">https://www.linkedin.com/settings/</a><br>
                  Google informativa: <a href="http://www.google.it/intl/it/policies/technologies/cookies/">http://www.google.it/intl/it/policies/technologies/cookies/</a>
                  Google configurazione: <a href="http://www.google.it/intl/it/policies/technologies/managing/">http://www.google.it/intl/it/policies/technologies/managing/</a><br><br>

                  <h4>COOKIE GOOGLE ANALYTICS</h4>
                  Il nostro sito include anche talune componenti trasmesse da Google Analytics. Google Analytics utilizza
                  i "cookie" per raccogliere e analizzare in forma anonima le informazioni sui comportamenti di utilizzo
                  del nostro sito (compreso talvolta l’indirizzo IP dell’utente). Tali informazioni vengono raccolte da
                  Google Analytics, che le elabora allo scopo di redigere report statistici utilizzati dal nostro staff
                  riguardanti l’utilizzo del nostro sito web. Questo sito non utilizza (e non consente a terzi di utilizzare)
                  lo strumento di analisi di Google per monitorare o per raccogliere informazioni personali di identificazione.
                  Google non associa l’indirizzo IP a nessun altro dato posseduto da Google né cerca di collegare un indirizzo
                  IP con l’identità di un utente. Google può anche comunicare queste informazioni a terzi ove ciò sia imposto
                  dalla legge o laddove tali terzi trattino le suddette informazioni per conto di Google. Per ulteriori
                  informazioni, si rinvia al link di seguito indicato: <a href="https://www.google.it/policies/privacy/partners/">https://www.google.it/policies/privacy/partners/</a><br>

                  L’utente può disabilitare in modo selettivo l’azione di Google Analytics installando sul proprio browser
                  la componente di opt-out fornito da Google.<br>
                  Per disabilitare l’azione di Google Analytics, si rinvia
                  al link di seguito indicato: <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a><br><br>

                  <h4>GESTIONE, RIMOZIONE E DISATTIVAZIONE DEI COOKIES </h4>
                  L’utente può decidere se accettare o meno i cookie utilizzando le impostazioni del proprio browser.<br>
                  L’impostazione può essere definita in modo specifico per i diversi siti e applicazioni web. Inoltre i migliori browser consentono di definire impostazioni diverse per i cookie "proprietari" e per
                  quelli di "terze parti".<br><br>
                  <b>Guide alle impostazioni dei vari browser:</b><br>
                  Chrome: <a href="https://support.google.com/chrome/answer/95647?hl=it">https://support.google.com/chrome/answer/95647?hl=it</a><br>
                  Firefox: <a href="https://support.mozilla.org/it/kb/Gestione%20dei%20cookie">https://support.mozilla.org/it/kb/Gestione%20dei%20cookie</a><br>
                  Internet Explorer: <a href="http://windows.microsoft.com/it-it/windows7/how-to-manage-cookies-in-internetexplorer-9">http://windows.microsoft.com/it-it/windows7/how-to-manage-cookies-in-internetexplorer-9</a><br>
                  Opera: <a href="http://help.opera.com/Windows/10.00/it/cookies.html">http://help.opera.com/Windows/10.00/it/cookies.html</a><br>
                  Safari: <a href="http://support.apple.com/kb/HT1677?viewlocale=it_IT">http://support.apple.com/kb/HT1677?viewlocale=it_IT</a><br><br>

                  Servizi come Digital Advertising Alliance Consumer Choice e Your Online Choices inoltre consentono di bloccare l’utilizzo di cookie di profilazione da parte di varie società attive nel settore dell’advertising
                  online. Ulteriori informazioni sono ottenibili sui seguenti siti:<br>
                  Digital Advertising Alliance: <a href="http://www.aboutads.info/choices/">http://www.aboutads.info/choices/</a><br>
                  Your Online Choices: <a href="http://www.youronlinechoices.com/it/le-tue-scelte">http://www.youronlinechoices.com/it/le-tue-scelte</a>
          </div>
      </div>
  </div>
  <div class="pixfort_footer_1" id="section_footer_1">
      <div class="new_footer_1 pix_footers pix_builder_bg">
          <div class="container ">
              <div class="sixteen columns bg_foot">
                  <div class="twelve columns alpha desk_left">
                      <div class="footer_1_text">
                          <span class="editContent">
                              <label style="display: inline">© 2023 {{applicationName}}</label>
                              <label style="display: inline"> - </label>
                              <label style="display: inline">
                                  <a href="" target="_blank">Informativa sul trattamento dei dati personali</a>
                              </label>
                              <label style="display: inline"> - </label>
                              <label style="display: inline">
                                  <a href="" target="_blank">Termini e condizioni</a>
                              </label>
                              <label style="display: inline"> - </label>
                              <label style="display: inline">
                                  <a href="#" >Informativa cookie</a>
                              </label>
                          </span>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
