<table mat-table [dataSource]="data" *ngIf="columns">
    <ng-container matColumnDef="select" *ngIf="selection">
        <th mat-header-cell *matHeaderCellDef>
        </th>

        <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selectionModel.toggle(row) : null"
                [checked]="selectionModel.isSelected(row)">
            </mat-checkbox>
        </td>
    </ng-container>

    <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
        <th mat-header-cell *matHeaderCellDef>
            {{column.header}}
        </th>
        <td mat-cell *matCellDef="let row" (click)="cellClick(column, row)">
            <ng-template [ngIf]="!cellIsHidden(column, row)">
                {{column.cell(row)}}
                <mat-icon style="cursor: pointer;" *ngIf="column.icon">{{column.icon}}</mat-icon>
            </ng-template>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
<div class="paginator-container" *ngIf="pagination">
    <mat-paginator #paginator [length]="paginationTotal" [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions">
    </mat-paginator>
</div>