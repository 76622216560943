import { Component } from '@angular/core';

@Component({
  selector: 'app-dicono-di-noi',
  templateUrl: './dicono-di-noi.component.html',
  styleUrls: ['../../../lib/themes/portale.theme.scss']
})
export class DiconoDiNoiComponent {

}
