import { Component, ElementRef, Injector, Input, ViewChild, forwardRef } from "@angular/core";
import { NG_VALUE_ACCESSOR, NgControl, Validators } from "@angular/forms";
import { MatSelect } from "@angular/material/select";
import { Subject, debounceTime } from "rxjs";
import { COMUNI, IComune } from "../models/comuni.class";
import { isString, stringContainsAll } from "../utils/Utility";

@Component({
    selector: 'ev-comune',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => EvComuneComponent),
        }
    ],
    template: `
<mat-form-field [style.width]="width">
    <mat-label>{{label}}</mat-label>
    <!-- attenzione: se si usa value al posto di ngModel non aggiorna la classe di validazione -->
    <mat-select [(ngModel)]="value" [disabled]="disabled" (selectionChange)="changed($event.value)" (openedChange)="doFocus($event)" [required]="isRequired()">
        <input #filterInput type="text" (keyup)="filter()" placeholder="Filtra" style="padding: 10px; width: 100%" [(ngModel)]="filterValue">
        <mat-option *ngFor="let comune of comuni" [value]="comune">
            {{comune.comune}}
        </mat-option>
    </mat-select>
</mat-form-field>
    `
})
export class EvComuneComponent {
    // numero di righe da mostrare
    @Input() rowsToDisplay = 20
    @Input() label = 'Comune'
    @Input() disabled = false
    @Input() required = false
    @Input() width = '100%'
    @Input() set provincia(p: string) {
        if (p) {
            this._provincia = p

            this.filter()
        }
    }
    get provincia() {
        return this._provincia
    }
    _provincia: string

    @Input() set value(val: IComune) {
        this._value = val

        if (val) {
            this.filterFun(val, true)
            this.changed(val)
        } else {
            this.filterValue = ''
            this.comuni = COMUNI.slice(0, this.rowsToDisplay)
        }
    }
    get value() {
        return this._value
    }
    _value: IComune
    filterValue = ''

    @ViewChild('filterInput') filterInput: ElementRef<HTMLInputElement>
    @ViewChild(MatSelect) select: MatSelect

    comuni = COMUNI.slice(0, this.rowsToDisplay)
    filterSubject = new Subject<string>()

    onChange: any
    onTouched: any

    constructor(private injector: Injector) {
        this.filterSubject.pipe(debounceTime(500)).subscribe(value => this.filterFun(value))
    }

    /**
     * Necessario per sapere se il controllo è 'required'
     * @returns 
     */
    isRequired() {
        const control = this.injector.get(NgControl)
        if (control) {
            return control.control.hasValidator(Validators.required)
        }
        return false
    }

    doFocus(open) {
        if (open) {
            this.filterInput.nativeElement.focus()
        }
    }

    filterFun(value: string | IComune, exactMatch = false) {
        const strValue = isString(value) ? (value as string) : (value as IComune).comune

        this.comuni = COMUNI.filter(comune => {
            if (this.provincia) {
                if (comune.prov.toLowerCase() === this.provincia.toLowerCase()) {
                    return comune
                }
            } else {
                if (exactMatch) {
                    return comune.comune.toLowerCase() === strValue.toLowerCase()
                } else {
                    // verifica togliendo gli spazi
                    return stringContainsAll(comune.comune, strValue)
                }
            }
        })
            .sort((a, b) => a.comune.length - b.comune.length)
            .slice(0, this.rowsToDisplay)

        // se ho una sola selezione auto-seleziono
        if (exactMatch && this.comuni.length === 1) {
            this._value = this.comuni[0]
        }
    }

    filter() {
        this.filterSubject.next(this.filterValue)
    }

    changed(value) {
        if (this.onTouched) {
            this.onTouched(value)
        }
        if (this.onChange/*  && this.value !== value */) {
            this.onChange(value)
        }
    }

    writeValue(value: any): void {
        this.value = value
    }
    registerOnChange(fn: any): void {
        this.onChange = fn
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled
    }
}