import { AfterViewInit, Component, Input, OnDestroy, OnInit, forwardRef } from "@angular/core";
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, Validators } from "@angular/forms";
import { Subscription, pairwise, startWith } from "rxjs";

@Component({
    selector: 'ev-telephone',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => EvTelephoneComponent),
        }
    ],
    template: `
    <mat-form-field>
        <mat-label>{{label}}</mat-label>
        <input matInput [formControl]="telephone" (blur)="onTouched(telephone.value)">
    </mat-form-field>
    `
})
export class EvTelephoneComponent implements OnInit, OnDestroy, AfterViewInit, ControlValueAccessor {
    // label
    @Input() label = 'Cellulare'
    // se true è obbligatorio
    @Input() required = true

    onChange: any = () => { }
    onTouched: any = () => { }

    telephone = new FormControl('')
    separator = ' - '

    telephoneChangeSub: Subscription

    get value() {
        return `${this.telephone.value}`
    }


    ngOnInit() {
        if (this.required) {
            this.telephone.setValidators([Validators.required])
        }
    }

    ngAfterViewInit(): void {
        this.telephoneChangeSub = this.telephone.valueChanges
            .pipe(
                // emette subito '', così non si per il primo change
                startWith(''),
                // invia sempre la coppia valore prev, valore next
                pairwise()
            ).subscribe(([prev, next]) => {

                // serve per permettere la cancellazione
                if (next.length === 3 && prev.length !== 4) {
                    this.telephone.setValue(next + this.separator, { emitEvent: false })
                }

                this.onChange(this.telephone.value)
            })
    }

    writeValue(value: string): void {
        this.telephone.setValue(value)
    }
    registerOnChange(fn: any): void {
        this.onChange = fn
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn
    }
    setDisabledState?(isDisabled: boolean): void {
        if (isDisabled) {
            this.telephone.disable()
        } else {
            this.telephone.enable()
        }
    }

    ngOnDestroy(): void {
        this.telephoneChangeSub?.unsubscribe()
    }
}