import { Injectable } from "@angular/core";
import { SwPush } from "@angular/service-worker";
import { BehaviorSubject, lastValueFrom } from "rxjs";
import { environment } from "../../../environments/environment";
import { getDeviceId, isMobile } from "../../mobile/utility-mobile";
import { MOBILE_ASK_NOTIFICATIONS_ONLY_ON_MOBILE } from "../constants";
import { HttpRequestService } from "../services/http-request.service";
import { toJson } from "../utils/Utility";
import { SwUpdateService } from "./sw-update.service";


/**
 * LIMITAZIONI CONOSCIUTE
 * - Windows
 *      -   sia chrome che firefox funzionano
 * - iOS
 *      -   si possono aggiungere app alla home solo da Safari, e solo una volta aggiunta come app
 *          funzionano le notifiche, ergo chrome non è installabile come app e non riceve notifiche
 * - Android
 *      - chrome
 *          - funziona tutto
 *      - firefox
 *          - le notifiche vengono ricevute MA viene aperto il browser e non l'app
 *          - ergo i link da aprire devono essere assoluti e non relativi
 */
@Injectable({
    providedIn: 'root'
})
export class NotificationsService {

    subscriptionProcessReady = new BehaviorSubject<boolean>(false)
    subscription: PushSubscription = null

    constructor(
        private swPush: SwPush,
        private httpRequestService: HttpRequestService,
        private swUpdate: SwUpdateService
    ) {
    }

    isPermissionRejected() {
        return Notification.permission === "denied"
    }

    isNotificationsEnabled() {
        return this.swPush.isEnabled
    }

    /**
     * Verifica la sub. Se già presente la salva, altrimenti chiede di crearla
     */
    checkSubscription() {
        try {
            if (this.isPermissionRejected()) {
                this.swUpdate.addLog('<span class="error-text">Notifications permission rejected</span>')
                return
            }

            if (this.isNotificationsEnabled()) {
                // pulisco la sottoscrizione attuale
                // necessario altrimenti se cambia la vapidKey non funzionano più le notifiche
                try {
                    this.clearSubscription()
                } catch (err) {
                    console.log(err)
                }

                // verifico se ho una sottoscrizione attiva
                // richiamato "subito" e anche al change della subscription
                this.swPush.subscription.subscribe(async subscription => {
                    // se non sono mai stati dati i permessi o se sono stati negati subscription è null
                    if (!subscription) {
                        try {
                            // la chiedo/richiedo. se l'utente non ha dato in precedenza l'autorizzazione
                            // non accadrà nulla e andra in catch
                            await this.requestSubscription()

                            this.swUpdate.addLog(`Accepted subscription`)

                            console.log('sottoscrizione accettata')
                            // notifico che il processo di sottoscrizione è terminato
                            this.subscriptionProcessReady.next(true)
                        } catch (err) {
                            this.clearSubscription()
                        }
                    } else {
                        // se non è null la salvo sul db
                        await this.sendSubscriptionToServer(subscription)

                        this.subscription = subscription

                        this.swUpdate.addLog(`Current subscription:<br> <pre>${toJson(this.subscription.toJSON())}</pre>`)

                        console.log('salvata sottoscrizione')
                    }
                })
            } else {
                console.error("Service worker non abilitato")
            }
        } catch (err) {
            // qua safari
            console.error("ERRORE RICHIESTA NOTIFICHE: ", err)
        }
    }

    /**
     * Richiede la sub
     */
    async requestSubscription() {

        let subscription: PushSubscription = null

        if (MOBILE_ASK_NOTIFICATIONS_ONLY_ON_MOBILE) {
            if (isMobile()) {
                subscription = await this._requestSubscription()
            }
        } else {
            subscription = await this._requestSubscription()
        }

        return subscription
    }
    private async _requestSubscription() {
        return await this.swPush.requestSubscription({
            serverPublicKey: environment.config.pwa.vapidPublicKey
        })
    }

    /**
     * Invia una nuova sub al server per essere registrata
     */
    async sendSubscriptionToServer(subscription: PushSubscription) {
        return lastValueFrom(
            this.httpRequestService.post(
                '/mobile/push-notifications/register',
                {
                    dispositivo: getDeviceId(),
                    subscription: toJson(subscription)
                },
                {},
                false
            ))
    }

    /**
     * Clear the current subscription
     */
    async clearSubscription() {

        const reg = await navigator.serviceWorker.ready
        const subscription = await reg.pushManager.getSubscription()

        try {
            await subscription?.unsubscribe()
            this.swUpdate.addLog(`Cleared subscription:<br> <pre>${toJson(subscription)}</pre>`)

        } catch (err) {
            this.swUpdate.addLog(`Error clearing subscription from browser:<span class="error-text">${err.message}</span>`)
        }

        console.log('rimuovo sottoscrizione dal server')

        return lastValueFrom(this.httpRequestService.delete(
            '/mobile/push-notifications/',
            {
                subscription: toJson(this.subscription)
            },
            {},
            false
        ))
    }
}
