import { AfterViewInit, Component, Input } from '@angular/core';
import { HttpRequestResponse } from '../../../lib/models/http-request-response.model';
import { HttpRequestService } from '../../../lib/services/http-request.service';
import { LogService } from '../../../lib/services/log.service';
import { newError } from '../../../lib/utils/Utility';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements AfterViewInit {

  @Input() onlyLast: boolean = false
  @Input() hideCategorie: boolean = false

  newsList = []

  constructor(private http: HttpRequestService, private log: LogService) { }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.http.get("/news", { last: this.onlyLast || false }).subscribe(response => {
        if (HttpRequestResponse.isSuccessResponse(response)) {
          this.newsList = response.data as []
        } else {
          this.log.messageError("Errore", response.message, newError(response.message))
        }
      })

    }, 0)
  }

}
