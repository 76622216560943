import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LibModule } from '../lib/lib.module';
import { MobileLoginComponent } from './mobile-login/mobile-login.component';
import { MobilePageComponent } from './mobile-page/mobile-page.component';
import { MobileRouting } from './mobile.routing';


@NgModule({
    declarations: [
        MobilePageComponent,
        MobileLoginComponent
    ],
    imports: [
        CommonModule, 
        LibModule,
        MobileRouting
    ],
    exports: [
        MobilePageComponent,
        MobileLoginComponent
    ]
})
export class MobileModule { }
