import { Injectable } from "@angular/core"
import { isPwaInstallationEnabled, isSafari } from "../../mobile/utility-mobile"
import { MOBILE_ASK_INSTALL_TIME } from "../constants"
import { ApplicationService } from "../services/application.service"
import { DialogMaterialService } from "../services/dialog-material.service"

@Injectable({
    providedIn: 'root'
})
export class PwaInstallationService {
    deferredPrompt = null
    askToInstallDialogRef = null

    constructor(private dialogService: DialogMaterialService, private applicationService: ApplicationService) {
        try {
            if (isPwaInstallationEnabled()) {
                window.addEventListener('beforeinstallprompt', event => {
                    event.preventDefault()
                    this.deferredPrompt = event
                })
            }

            if (MOBILE_ASK_INSTALL_TIME > 0) {
                console.log('Avviato timer per installazione app')
                setTimeout(() => {
                    this.askToInstall()
                }, MOBILE_ASK_INSTALL_TIME);
            }
        } catch (err) {

        }
    }

    openBrowserPromptAndroid() {
        if (isPwaInstallationEnabled()) {
            if (this.deferredPrompt) {
                this.deferredPrompt.prompt()

                this.deferredPrompt.userChoice.then(choice => {
                    if (choice.outcome === 'accepted') {
                        console.log('user accepted the prompt')
                    }
                    this.deferredPrompt = null
                })
            } else {
                console.warn("Attenzione, il prompt è null, l'app è probabilmente già installata")
            }
        } else {
            console.error("Attenzione, non è possibile aprire il prompt su iOS")
        }
    }

    askToInstall() {
        try {
            const noAsk = localStorage.getItem('saluteconte.no-ask-install-pwa')

            if (noAsk === 'S') {
                return
            }

            if (isSafari()) {
                const dialogRef = this.dialogService.openYesNo(
                    "Installa come App",
                    `Se vuoi installare ${this.applicationService.getApplicationName()} App apri il
                    menù di Safari e premi 'Aggiungi alla Home'.<br>Vuoi che ti riproponga questo messaggio?`
                )
                dialogRef.afterClosed().subscribe(yes => {
                    if (!yes) {
                        localStorage.setItem('saluteconte.no-ask-install-pwa', 'S')
                    }
                })
            } else {
                if (this.deferredPrompt) {
                    this.askToInstallDialogRef = this.dialogService.openYesNo(
                        "Installa come App",
                        "Vuoi installare " + this.applicationService.getApplicationName() + " App?"
                    )

                    this.askToInstallDialogRef.afterClosed().subscribe(async result => {
                        if (result && isPwaInstallationEnabled()) {
                            this.openBrowserPromptAndroid()
                        }
                    })
                }
            }
        } catch (err) {
            console.error("Error ask pwa installation: ", err)
        }
    }
}
