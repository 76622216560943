import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { CommonObject, property } from '../utils/Utility';

/**
 * Classe singleton contenente la configurazione dell'applicazione.
 */
// @dynamic
export class Config {
  private static instance: Config;
  public config: { [key: string]: any };
  private configSub: Subscription;

  private constructor() {
  }

  static getInstance(): Config {
    if (!Config.instance) {
      Config.instance = new Config();
    }
    return Config.instance;
  }

  static getConfig(configPath: string | (string | number)[]) {
    const getProperty = property(configPath);

    return getProperty(Config.getInstance().config);
  }

  /**
   * Carica la configurazione leggendo da file JSON negli assets.
   * @param httpClient - Istanza del service HttpClient
   * @param environment - Ambiente
   */
  loadConfig(httpClient: HttpClient, environment: { production: boolean, name: string, config: CommonObject }): Promise<{ [key: string]: any }> {
    console.log('env: ', environment.name)

    return new Promise((resolve, reject) => {
      this.config = environment.config;

      resolve(environment.config)
    });
  }
}
