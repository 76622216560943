import { Component, ElementRef, Inject, Input } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CommonObject } from "../utils/Utility";
import { EvPageThemeComponent } from "./ev-page-theme.component";

@Component({
    selector: 'ev-dialog-material',
    template: `
<div [ngClass]="className">
    <h2 mat-dialog-title>{{title}}</h2>
    <mat-dialog-content>
        <ng-content select="[main]"></ng-content>

        <ng-template [ngIf]="text">
            <p [innerHTML]="text"></p>
        </ng-template>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <ng-content select="[buttons]"></ng-content>

        <ng-template [ngIf]="type === 'CLOSE'">
            <button mat-raised-button color="primary" (click)="close(true)">Chiudi</button>
        </ng-template>
        <ng-template [ngIf]="type === 'YES_NO'">
            <button mat-raised-button (click)="close(false)">No</button>
            <button mat-raised-button color="primary" (click)="close(true)">Si</button>
        </ng-template>
    </mat-dialog-actions>
</div>
    `
})
export class EvDialogMaterialComponent extends EvPageThemeComponent {
    // titolo della modale
    @Input() title: string
    // in base alla tipologia mostra dei pulsanti specifici
    @Input() type: 'CLOSE' | 'YES_NO'
    // testo della modale
    @Input() text: string = null
    // classe di stile (es per errore)
    @Input() className: string | string[] = null

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: CommonObject,
        private dialogRef: MatDialogRef<EvDialogMaterialComponent>,
        protected elementRef: ElementRef
    ) {
        super(elementRef)

        if (data) {
            this.type = data['type'] || 'CLOSE'
            this.text = data['text']
            this.title = data['title'] || 'Messaggio'
            this.className = data['panelClass'] || []
        }
    }

    close(value) {
        this.dialogRef.close(value)
    }
}
