import { Component } from "@angular/core";
import { NC_MANUAL_PATH, SCT_MANUAL_PATH } from "../constants";
import { ApplicationService } from "../services/application.service";

@Component({
    selector: 'app-manuale',
    styles: [`
        .container {
            position: relative;
            height: 90vh;
        }
    `],
    template: `
    <div class="container">
        <h1>Manuale di {{applicationName}}</h1>
        <ev-pdf-viewer [src]="manualPath"></ev-pdf-viewer>
    </div>
`
})
export class EvManualeComponent {

    applicationName: string
    manualPath: string

    constructor(applicationService: ApplicationService) {
        this.applicationName = applicationService.getApplicationName()

        if (applicationService.isNextComunica()) {
            this.manualPath = NC_MANUAL_PATH
        } else {
            this.manualPath = SCT_MANUAL_PATH
        }
    }

}
