import { Injectable } from "@angular/core"
import { toDataURL as QrCodeToDataURL } from "qrcode"
import { NC_FAQ_PATH, NC_MANUAL_PATH, NC_WEBSITE, SCT_FAQ_PATH, SCT_MANUAL_PATH } from "../constants"
import { CommonObject, blobToBase64, dataURIToBlob } from "../utils/Utility"
import { ApplicationService } from "./application.service"
import { FunzionalitaService } from "./funzionalita.service"
import { HttpRequestService } from "./http-request.service"
import { UtenteService } from "./utente.service"

declare const html2pdf: any
declare const DocumentoPrivacy: any
declare const NREListPdfGenerator: any

@Injectable({
    providedIn: 'root'
})
export class DocumentsService {

    private nomeUtente: string
    private nomeMedico: string
    private funzionalitaList: string[]
    private funzionalitaString: string
    private luogoNascita: string
    private dataNascita: string
    private codiceFiscale: string

    constructor(
        private applicationService: ApplicationService,
        private utenteService: UtenteService,
        private funzionalitaService: FunzionalitaService,
        private http: HttpRequestService
    ) {

    }

    async downloadManualDoc() {
        const path = this.applicationService.isNextComunica() ? NC_MANUAL_PATH : SCT_MANUAL_PATH

        return await this.http.downloadFile('manuale-portale-paziente.pdf', path)
    }

    async downloadFAQDoc() {
        const path = this.applicationService.isNextComunica() ? NC_FAQ_PATH : SCT_FAQ_PATH

        return await this.http.downloadFile('faq.pdf', path)
    }

    async downloadPrivacyDoc() {
        this.initPrivacyFields()

        if (this.applicationService.isNextComunica()) {
            await this.downloadPrivacyDocNC()
        } else {
            await this.downloadPrivacyDocScT()
        }
    }

    async downloadNCBrochure(data) {
        // FIXME: correggere
        const url = `https://${NC_WEBSITE}/${data['url_studio']}`
        const qrCode = await this.generateBarcode(url)

        const request = await fetch("assets/static/nc-locandina.html")
        let text = await request.text()

        // @ts-ignore
        text = text.replaceAll('{{qrCode}}', qrCode)
            .replaceAll('{{nomeStudio}}', data['nome'])
            .replaceAll('{{via}}', data['via'])
            .replaceAll('{{civico}}', data['civico'])
            .replaceAll('{{cap}}', data['cap'])
            .replaceAll('{{citta}}', data['citta'])
            .replaceAll('{{provincia}}', data['provincia'])

        return await this.generatePdfFromHtml(text, 'brochure.pdf')
    }

    private initPrivacyFields() {
        this.nomeUtente = this.utenteService.getChiave(['paziente', 'nome'])
        this.nomeMedico = this.utenteService.getChiave(['paziente', 'medico'])
        this.funzionalitaList = this.funzionalitaService.funzionalita
        this.luogoNascita = this.utenteService.getChiave([
            'paziente',
            'luogo_di_nascita',
        ])
        this.dataNascita = this.utenteService.getChiave(['paziente', 'natoil'])
        this.codiceFiscale = this.utenteService.getChiave(['paziente', 'codfis'])

        this.funzionalitaString = this.funzionalitaList.map(f => `<b>${f}</b><br>`).join("")
    }

    private async downloadPrivacyDocScT() {
        let doc = new DocumentoPrivacy(
            this.nomeMedico,
            this.funzionalitaList,
            this.nomeUtente,
            this.luogoNascita,
            this.dataNascita,
            this.codiceFiscale
        )

        // rimossa gestione callback
        doc.stampa('informativa-privacy.pdf')

    }

    private async downloadPrivacyDocNC() {
        const request = await fetch("assets/static/informativa-privacy-utente.html")
        let response = await request.text()
        // @ts-ignore
        response = response.replaceAll("{{nomeMedico}}", this.nomeMedico)
            .replaceAll("{{funzionalita}}", this.funzionalitaString)
            .replaceAll("{{nomeUtente}}", this.nomeUtente)
            .replaceAll("{{luogoNascita}}", this.luogoNascita)
            .replaceAll("{{dataNascita}}", this.dataNascita)
            .replaceAll("{{codiceFiscale}}", this.codiceFiscale)

        return await this.generatePdfFromHtml(response, 'informativa-privacy.pdf')
    }

    private async generatePdfFromHtml(text: string, filename: string, options?: CommonObject) {

        if (!options) {
            options = {
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                margin: 5,
                useCORS: true,
                filename
            }
        }

        const pdf = html2pdf().from(text).set(options).outputPdf().get("pdf")
        await pdf.then((pdf) => {
            const blob = new Blob([pdf.output('blob')], { type: 'application/pdf' })
            // @ts-ignore
            window.saveAs(blob, filename)
        })
    }

    private async generateBarcode(text, options?: CommonObject, target?: HTMLImageElement) {
        if (!options) {
            options = {
                errorCorrectionLevel: 'H',
                quality: 0.3,
                margin: 1,
                width: 100
            }
        }
        if (!target) {
            target = document.getElementById('qrcodeTarget') as HTMLImageElement
        }

        return await QrCodeToDataURL(text, options)
    }

    public async generaDocumentoNRE(codiceFiscale, ricette) {
        return new Promise((resolve, reject) => {
            let ricettePerPdf = ricette.map((ricetta) => {
                return { nre: ricetta.nre }
            })

            // genero il pdf
            const pdf = new NREListPdfGenerator({
                codiceFiscale: codiceFiscale,
                farmaci: ricettePerPdf,
            })

            pdf.genera(async (pdf) => {
                let pdfBlob = dataURIToBlob(pdf)

                blobToBase64(pdfBlob, true, async pdfEncoded => {
                    resolve(pdfEncoded)
                })
            })
        })
    }
}
