
import { Directive, ElementRef, OnDestroy, OnInit } from "@angular/core";
import { DisplayService } from "../services/display.service";

@Directive({
    selector: '[hideOnMobile]'
})
export class HideOnMobileDirective implements OnInit, OnDestroy {

    private sub

    constructor(private elementRef: ElementRef, private displayService: DisplayService) {

    }

    ngOnInit(): void {
        this.sub = this.displayService.isSmall.subscribe(isSmall => {
            if (isSmall) {
                this.elementRef.nativeElement.style.display = 'none'
            }
        })
    }

    ngOnDestroy(): void {
        this.sub?.unsubscribe()
    }
}
