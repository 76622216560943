<div class='container'>
  <div class='row'
    style="text-align: center; padding-top: 20px !important; font-family: 'Roboto', sans-serif; font-weight: normal; font-size: 100%;">
    <div class='col-12-md form-container'>
      <div class="form-group">
        <label for="codiceFiscale">Codice Fiscale</label>
        <input type="text" [(ngModel)]="codiceFiscale" class="form-control" />
      </div>
      <div class="form-group">
        <label for="username">Username</label>
        <input type="text" [(ngModel)]="pin" class="form-control" />
      </div>
      <div class="form-group">
        <label for="password">Password</label>
        <input type="password" [(ngModel)]="password" class="form-control" />
      </div>
      <div class="form-group">
        <label for="passwordConferma">Conferma Password</label>
        <input type="password" [(ngModel)]="passwordConferma" class="form-control" />
      </div>
      <div class="form-group">
        <button class="btn btn-primary" (click)="onSubmitNuovaPassword()">Aggiorna
          Password</button>
      </div>
    </div>
  </div>
</div>