// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  name: 'test',
  config: {
    "httpRequest": {
      "urlServer": "https://api.testlab.saluteconte.it"
    },
    next: {
      urlServer: 'https://testlab.api.nextmmg.it'
    },
    "agenda": {
      "urlAgendaClient": "https://testlab.prenota.saluteconte.it/"
    },
    "satispay": {
      "satispayScriptUrl": "https://staging.online.satispay.com/web-button.js",
      "satispayImgUrl": "https://staging.online.satispay.com/images/it-pay-red.svg"
    },
    "nc": {
      "backofficeUrl": "https://testlab.saluteconte.it/nc",
      showcaseLoginUserUrl: "https://testlab.saluteconte.it/?nc=true"
    },
    pwa: {
      vapidPublicKey: 'BCdlJsOaRqNG8WG6PPrDavPCVtehyycd_HJOZH8rFo5_snhP5FADvmlBtgt_0JLvWd63p05LT5jsjhXB3Jwzsyc',
      firebaseConfig: {
        apiKey: "AIzaSyAeSzKU930W9fdPiUfsvQEM-JSXy6QHb1w",
        authDomain: "salute-con-te.firebaseapp.com",
        projectId: "salute-con-te",
        storageBucket: "salute-con-te.appspot.com",
        messagingSenderId: "220709521591",
        appId: "1:220709521591:web:77a3bc00d120ad9287a532",
        measurementId: "G-DYVFXPWV3J"
      }
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
