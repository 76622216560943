import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, lastValueFrom, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { Config } from '../config/Config';
import { HttpRequestResponse } from '../models/http-request-response.model';

import { fromJson, isEmpty } from '../utils/Utility';
import { LogService } from './log.service';
import { SpinnerService } from './spinner.service';

import * as download from 'downloadjs';

@Injectable({
  providedIn: 'root'
})
export class HttpRequestService {
  private fixedParams: { [key: string]: string };

  constructor(private httpClient: HttpClient, private logService: LogService, private spinnerService: SpinnerService) {
  }

  static resolveUrl(url: string): string {
    const urlServer = Config.getConfig(['httpRequest', 'urlServer']);
    const regexProtocol = new RegExp('^(https?://)');

    if (regexProtocol.test(url)) {
      return url;
    } else {
      return urlServer + url;
    }
  }

  setFixedParams(fixedParams: { [key: string]: any }): void {
    this.fixedParams = { ...fixedParams };
  }

  appendFixedParams(fixedParams: { [key: string]: any }): void {
    this.fixedParams = {
      ...this.fixedParams,
      ...fixedParams,
    };
  }

  getFixedParams(): { [key: string]: any } {
    return this.fixedParams;
  }

  get<T = undefined>(url: string, params: { [key: string]: any } = {},
    options?: { [key: string]: any }, urlComplete: boolean = false, showSpinner: boolean = true): Observable<T extends undefined ? HttpRequestResponse : T> {

    let requestParams = { ...params, ...this.fixedParams };

    const token = localStorage.getItem('saluteconte.token');
    let ncToken = localStorage.getItem('next-comunica.token');
    let ncDatiUtenteRaw = localStorage.getItem('next-comunica.datiUtente');

    if (!isEmpty(token)) {
      requestParams['token'] = token;
    }
    if (!isEmpty(ncToken)) {
      requestParams['ncToken'] = ncToken;
    }
    if (!isEmpty(ncDatiUtenteRaw)) {
      const ncDatiUtente = fromJson(ncDatiUtenteRaw)
      requestParams['ncUsername'] = ncDatiUtente['username']
    }

    const requestOptions = {
      ...options,
      params: requestParams
    };

    let _url;
    if (urlComplete) {
      _url = url;
    } else {
      _url = HttpRequestService.resolveUrl(url);
    }

    if (showSpinner) {
      this.spinnerService.show();
    }

    return this.httpClient.get<T extends undefined ? HttpRequestResponse : T>(_url, requestOptions)
      .pipe(
        finalize(() => {
          if (showSpinner) {
            this.spinnerService.hide()
          }
        }),
        catchError((err) => {
          this.logService.messageError('Errore http', err.message, err);

          return throwError(err);
        })
      )

  }

  post(url: string, body: {}, options?: { [key: string]: string }, showSpinner: boolean = true, urlComplete = false): Observable<HttpRequestResponse> {
    const requestBody = { ...body, ...this.fixedParams };
    let token = localStorage.getItem('saluteconte.token');
    let ncToken = localStorage.getItem('next-comunica.token');
    let ncDatiUtenteRaw = localStorage.getItem('next-comunica.datiUtente');

    if (!isEmpty(token)) {
      requestBody['token'] = token;
    }
    if (!isEmpty(ncToken)) {
      requestBody['ncToken'] = ncToken;
    }
    if (!isEmpty(ncDatiUtenteRaw)) {
      const ncDatiUtente = fromJson(ncDatiUtenteRaw)
      requestBody['ncUsername'] = ncDatiUtente['username']
    }

    if (options && options.params) {
      if (!options.params['token']) {
        options.params['token'] = token
      }
      if (!options.params['ncToken']) {
        options.params['ncToken'] = ncToken
      }
    }

    const requestOptions = {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      ...options
    };

    const _payload = new HttpParams({
      fromObject: requestBody
    });

    let _url;
    if (urlComplete) {
      _url = url;
    } else {
      _url = HttpRequestService.resolveUrl(url);
    }

    if (showSpinner) {
      this.spinnerService.show();
    }
    return this.httpClient.post<HttpRequestResponse>(_url, _payload, requestOptions)
      .pipe(
        finalize(() => {
          if (showSpinner) {
            this.spinnerService.hide()
          }
        }),
        catchError((err) => {
          this.logService.messageError('Errore', err.message, err);

          return throwError(err);
        })
      );
  }

  delete<T = undefined>(url: string, params: { [key: string]: any } = {},
    options?: { [key: string]: any }, showSpinner: boolean = true): Observable<T extends undefined ? HttpRequestResponse : T> {

    let requestParams = { ...params, ...this.fixedParams };

    const token = localStorage.getItem('saluteconte.token');
    let ncToken = localStorage.getItem('next-comunica.token');
    let ncDatiUtenteRaw = localStorage.getItem('next-comunica.datiUtente');

    if (!isEmpty(token)) {
      requestParams['token'] = token;
    }
    if (!isEmpty(ncToken)) {
      requestParams['ncToken'] = ncToken;
    }
    if (!isEmpty(ncDatiUtenteRaw)) {
      const ncDatiUtente = fromJson(ncDatiUtenteRaw)
      requestParams['ncUsername'] = ncDatiUtente['username']
    }

    const requestOptions = {
      ...options,
      params: requestParams
    };

    const _url = HttpRequestService.resolveUrl(url);

    if (showSpinner) {
      this.spinnerService.show();
    }
    return this.httpClient.delete<T extends undefined ? HttpRequestResponse : T>(_url, requestOptions)
      .pipe(
        finalize(() => {
          if (showSpinner) {
            this.spinnerService.hide()
          }
        }),
        catchError((err) => {
          this.logService.messageError('Errore', err.message, err);

          return throwError(err);
        })
      );
  }

  async sendFile(path: string, body: {}, showSpinner = true) {
    let url = HttpRequestService.resolveUrl(path);
    if (showSpinner) {
      this.spinnerService.show();
    }

    let request = await fetch(url, {
      method: 'post',
      body: JSON.stringify(body),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    });
    const response = await request.json();
    if (showSpinner) {
      this.spinnerService.hide();
    }

    return response;
  }

  async downloadFile(filename: string, path: string) {
    this.spinnerService.show();

    const token = localStorage.getItem('saluteconte.token');
    let ncToken = localStorage.getItem('next-comunica.token');
    let ncDatiUtenteRaw = localStorage.getItem('next-comunica.datiUtente');

    const params = {}

    if (!isEmpty(token)) {
      params['token'] = token;
    }
    if (!isEmpty(ncToken)) {
      params['ncToken'] = ncToken;
    }
    if (!isEmpty(ncDatiUtenteRaw)) {
      const ncDatiUtente = fromJson(ncDatiUtenteRaw)
      params['ncUsername'] = ncDatiUtente['username']
    }

    const blob = await lastValueFrom(this.httpClient.get(path, {
      responseType: 'blob'
    }))

    download(blob, filename, blob.type)

    // @ts-ignore
    // window.saveAs(blob, filename);

    this.spinnerService.hide()
  }

}
