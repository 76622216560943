import { Component } from '@angular/core';

@Component({
  selector: 'app-vantaggi',
  templateUrl: './vantaggi.component.html',
  styleUrls: ['../../../lib/themes/portale.theme.scss']
})
export class VantaggiComponent {

}
