import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { EvSpinnerComponent } from '../components/ev-spinner.component';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  overlayRef: OverlayRef

  constructor(private overlay: Overlay) {

  }

  private getSpinner() {
    return $('.loading');
  }

  show() {

    this.hide()

    this.overlayRef = this.overlay.create({
      hasBackdrop: true, positionStrategy:
        this.overlay.position().global().centerHorizontally().centerVertically()
    })
    const userProfilePortal = new ComponentPortal(EvSpinnerComponent)
    this.overlayRef.attach(userProfilePortal)
  }

  hide() {
    this.overlayRef?.dispose()
  }

}
