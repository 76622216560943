<img src="">
<!-- <div class="page" id="page" style="background: url(<?= $this->basePath(); ?>/img/bg-provvisorio.png)">-->
<div class="page" id="page">
  <div class="pixfort_corporate_2" id="section_corporate_4"
       style="/*width: 60% !important;*/ margin: 1px auto !important;">
      <div class="container">



          <div lang="IT" link="blue" vlink="#FFA94A">

              <div class="WordSection1">

                  <h1><span style="font-size:16.0pt;line-height:115%">Termini e condizioni d’uso di
Salute con te</span></h1>

                  <p style="margin-bottom:7.5pt;text-align:justify;line-height:19.2pt;background:
white"><span class="Titolo2Carattere"><span style="font-size:13.0pt">Definizioni</span></span></p>

                  <p class="EvoluS" style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:justify">“<i>Il
                          Produttore</i>”: Evolus S.r.l. (C.F. / P.IVA 10633690010) iscritta al Registro
                      delle imprese di Torino al n. 1150010, con sede in Corso Unione Sovietica,
                      612/21 10135 TORINO, è la persona giuridica proprietaria e gestore del portale
                      “Salute Con Te”, titolare del dominio internet www.saluteconte.it;</p>

                  <p class="EvoluS" style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:justify">&nbsp;</p>

                  <p class="EvoluS" style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:justify">“<i>Salute
                          Con Te</i>” o <i>“il Portale”</i>: è il portale web, accessibile all’indirizzo <a
                              href="http://www.saluteconte.it"><b>www.saluteconte.it</b></a>, dedicato alla
                      erogazione, in favore degli utenti come <i>infra </i>definiti, del servizio
                      consistente nella messa a disposizione di un canale di comunicazione
                      privilegiato tra l’Utente ed il suo Medico Curante per il tramite di un’apposita
                      Area Riservata.&nbsp; </p>

                  <p class="EvoluS" style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:justify">&nbsp;</p>

                  <p class="EvoluS" style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:justify">“Area
                      Riservata” o “account”: è la sezione del portale associata ad un determinato
                      Utente, contenente i suoi dati personali e sanitari ed accessibile
                      esclusivamente dal relativo Utente, per mezzo delle proprie credenziali, e dal
                      suo Medico Curante.</p>

                  <p class="EvoluS" style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:justify">&nbsp;</p>

                  <p class="EvoluS" style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:justify">“<i>Utente”</i>:&nbsp;la
                      persona fisica maggiore di 14 anni che accede per il tramite di apposite
                      “credenziali” a Salute Con Te ed utilizza i Servizi a pagamento offerti dal
                      Portale e forniti dal Produttore.</p>

                  <p class="EvoluS" style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:justify">&nbsp;</p>

                  <p class="EvoluS" style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:justify">
                      “<i>Credenziali</i>”:
                      sono le chiavi elettroniche associate a ciascun Utente ed utilizzate, all’esito
                      della relativa attivazione dell’account da parte del Medico Curante, per
                      l’accesso all’area riservata e per la fruizione dei relativi servizi.</p>

                  <p class="EvoluS" style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:justify">&nbsp;</p>

                  <p class="EvoluS" style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:justify">“<i>Medico
                          Curante</i>”: è il medico di medicina generale convenzionato con il SSN e
                      scelto dal cittadino, che utilizza il software di cartella clinica Next MMG e
                      che, in forza di espressa autorizzazione dell’Utente interessato, mette a sua
                      disposizione, tramite il Portale, i Servizi concordati con il medesimo; ha
                      accesso ai dati ed ai documenti presenti nell’area riservata.</p>

                  <p class="EvoluS" style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:justify">&nbsp;</p>

                  <p class="EvoluS" style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:justify">“Offerta”:
                      è il documento o, comunque, la comunicazione anche elettronica – pubblicata
                      online - contenente l’indicazione del corrispettivo e di ogni altra condizione
                      economica applicabile per l’accesso a Salute con te ed ai servizi forniti.</p>

                  <p class="EvoluS" style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:justify">&nbsp;</p>

                  <p class="EvoluS" style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:justify">“Servizi”:
                      sono le funzionalità del sito che, al momento dell’attivazione dell’account, il
                      Medico Curante sceglie di mettere a disposizione dell’Utente, a titolo
                      esemplificativo e non esaustivo:</p>

                  <p class="EvoluS" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:53.25pt;margin-bottom:.0001pt;text-align:justify;text-indent:-18.0pt">-<span
                              style="font:7.0pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Richiesta di prescrizione di farmaci cronici</p>

                  <p class="EvoluS" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:53.25pt;margin-bottom:.0001pt;text-align:justify;text-indent:-18.0pt">-<span
                              style="font:7.0pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Richiesta di prescrizione di prestazioni periodiche</p>

                  <p class="EvoluS" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:53.25pt;margin-bottom:.0001pt;text-align:justify;text-indent:-18.0pt">-<span
                              style="font:7.0pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Invio di referti </p>

                  <p class="EvoluS" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:53.25pt;margin-bottom:.0001pt;text-align:justify;text-indent:-18.0pt">-<span
                              style="font:7.0pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Richiesta di appuntamenti per visite ambulatoriali</p>

                  <p class="EvoluS" style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:justify">&nbsp;</p>

                  <p class="EvoluS" style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:justify">&nbsp;</p>

                  <p class="EvoluS" style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:justify">&nbsp;</p>

                  <p class="EvoluS" style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:justify">&nbsp;</p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
0cm;text-align:justify;text-indent:18.0pt;line-height:19.2pt;background:white"><span class="Titolo2Carattere"><span
                                  style="font-size:13.0pt;color:#01856E">1.</span></span><span
                              class="Titolo2Carattere"><span style="font-size:13.0pt">Oggetto</span></span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">1.1.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">I presenti termini e
condizioni hanno ad oggetto le modalità ed i termini con cui il Produttore
fornisce l’accesso al Portale ed all’Area Riservata, nonché la specifica
indicazione delle attività consentite agli Utenti e le informazioni di cui al
D.Lgs. 206/2005.</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
0cm;text-align:justify;text-indent:18.0pt;line-height:19.2pt;background:white"><span class="Titolo2Carattere"><span
                                  style="font-size:13.0pt;color:#01856E">2.</span></span><span
                              class="Titolo2Carattere"><span style="font-size:13.0pt">Accettazione di Termini e
Condizioni d’uso</span></span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">2.1.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">Accedendo a Salute
Con Te, utilizzando i relativi Servizi, o scaricando ed installando i Software
e le applicazioni ad esso correlati, l’Utente accetta i presenti Termini e
Condizioni d’uso.</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">2.2.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">Manifestando il
proprio consenso e la propria accettazione dei presenti Termini e Condizioni
d’Uso, selezionando la voce “Autorizzo” e cliccando sul tasto “Prosegui” al
momento del primo accesso, l’Utente conferma di aver letto e compreso il
contenuto dei medesimi ed accetta di esserne vincolato.</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">2.3.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">L’Utente è tenuto a
rispettare i termini e condizioni di seguito specificati. </span></p>

                  <p class="MsoNormal" style="text-align:justify">&nbsp;</p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
0cm;text-align:justify;text-indent:18.0pt;line-height:19.2pt;background:white"><span class="Titolo2Carattere"><span
                                  style="font-size:13.0pt;color:#01856E">3.</span></span><span
                              class="Titolo2Carattere"><span style="font-size:13.0pt">Registrazione ed accesso
al Portale</span></span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">3.1.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">L’Utente potrà
accedere al Portale ed utilizzare Salute Con Te solo dopo averne richiesto
l’attivazione al proprio medico curante. </span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">3.2.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">Al momento della
richiesta di attivazione da parte dell’Utente, il Medico Curante comunica al
medesimo quali dei Servizi astrattamente disponibili sul Portale intende
mettere a sua disposizione.</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">3.3.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">La determinazione
degli specifici servizi di cui l’Utente potrà usufruire accedendo alla propria
Area Riservata è effettuata discrezionalmente dal Medico Curante; il produttore
non è in alcun modo responsabile delle scelte operate dal Medico Curante, né
sarà tenuto alla restituzione del canone di abbonamento in caso di limitazione
della quantità dei Servizi operata direttamente dal Medico Curante. </span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">3.4.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">La procedura di
attivazione può essere effettuata solo dai medici che utilizzano il software di
cartella clinica Next MMG che ne abbiano fatto richiesta e siano stati abilitati
al servizio dal Produttore.</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">3.5.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">L’accesso a Salute
Con Te da parte dell’Utente avviene mediante inserimento, nell’apposita
schermata di log in, di nome utente, password e codice fiscale.</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">3.6.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">Le credenziali
necessarie per il primo accesso dell’Utente al Portale sono fornite
direttamente dal Medico Curante e dovranno essere necessariamente modificate
dall’Utente in occasione del primo accesso.</span></p>

                  <p style="margin-bottom:7.5pt;text-align:justify;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">&nbsp;</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
0cm;text-align:justify;text-indent:18.0pt;line-height:19.2pt;background:white"><b><span
                                  style="font-size:13.0pt;font-family:'Cambria',serif;color:#01856E">4.</span></b><span
                              class="Titolo2Carattere"><span style="font-size:13.0pt">Utilizzo dei servizi –
obblighi e responsabilità dell’Utente</span></span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">4.1.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">L’utente si obbliga a
non utilizzare Salute Con Te in modo improprio. A titolo esemplificativo e non
esaustivo, è vietato all’Utente di interferire con i Servizi o tentare di
accedervi utilizzando un metodo diverso dall’interfaccia e dalle istruzioni
fornite dal Produttore.</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">4.2.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">È possibile
utilizzare Salute Con Te solo nei modi consentiti dalla legge.</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">4.3.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">È vietato rimuovere,
oscurare o alterare eventuali note legali visualizzate nel Portale.</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">4.4.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">Solo il titolare
dell’account è autorizzato ad accedere alla propria area riservata; a tutela
della riservatezza dei dati personali inseriti sulla medesima area riservata l’utente
si obbliga a:</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">a.<span
                                  style="font:7.0pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">modificare la
Password indicata dal Medico Curante in occasione del primo accesso;</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">b.<span
                                  style="font:7.0pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">scegliere una
Password di complessità tale da garantire il necessario grado di sicurezza,
costituita da almeno 8 caratteri alfa-numerici, di cui almeno una lettera
maiuscola ed un numero;</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">c.<span
                                  style="font:7.0pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">conservare con la
massima diligenza e riservatezza le Credenziali, impegnandosi altresì a non
consentirne l’utilizzo a terzi. Fermo restando quanto precede, l’Utente si
assume ogni responsabilità per l’eventuale utilizzo delle Credenziali da parte
di terzi, manlevando e tenendo indenne il Produttore da qualsiasi
responsabilità a riguardo;</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">d.<span
                                  style="font:7.0pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">in caso di
smarrimento o sottrazione delle Credenziali, ovvero in caso di utilizzo non
autorizzato delle proprie credenziali, o sospetto di violazione del Profilo,
bloccare l’accesso a Salute con Te, immediatamente, mediante la procedura di
recupero password prevista dal portale che consente l’immediata modifica della
password medesima con conseguente, immediata, disattivazione di quella
precedente;</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">e.<span
                                  style="font:7.0pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">manlevare e tenere
indenne il Produttore da ogni perdita, danno, responsabilità, onere o spesa,
ivi comprese eventuali spese legali, che dovessero occorrere in conseguenza del
mancato rispetto delle normative vigenti, anche in materia di trattamento dei
dati personali, ovvero di qualsiasi inadempimento da parte dell’Utente medesimo
agli obblighi previsti alle presenti Condizioni e termini d’uso, anche in caso
di utilizzo improprio delle Credenziali.</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">4.5.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">Con l’adesione alle
presenti Condizioni e Termini d’uso, l’Utente dichiara di essere pienamente
informato del fatto che:</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
72.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">a.<span
                                  style="font:7.0pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">Salute Con Te,
nell’erogazione dei Servizi presenti nell’Area Riservata, utilizza esclusivamente
i dati (personali, sanitari ed economici) trasmessi dal Medico Curante o
dall’Utente stesso e pertanto non può essere ritenuta in nessun modo
responsabile per il contenuto di tali dati ovvero per le tempistiche con le
quali essi vengono trasmessi ai sistemi informatici del Portale;</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
72.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">b.<span
                                  style="font:7.0pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">ad eccezione delle
operazioni tecniche strettamente necessarie al funzionamento del Portale, il
Produttore non accede né controlla i dati di qualsiasi natura immessi nelle
aree riservate ai Servizi e tali dati sono gestiti in piena autonomia e
conseguente piena responsabilità da parte dell’Utente medesimo ovvero del
Medico Curante;</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
72.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">c.<span
                                  style="font:7.0pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">la conservazione e
l’archiviazione dei dati immessi dall’Utente o dal Medico Curante nelle aree
riservate ai Servizi è affidata al sistema di Cloud <i>“google cloud platform”,
</i>che garantisce la sicurezza dei medesimi e la tutela della Privacy secondo
gli standard e le condizioni pubblicate sul portale e richiamate nell’<i><u>informativa</u></i><u>
<i>sul trattamento dei dati personali</i></u> di Salute Con Te;</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
72.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">d.<span
                                  style="font:7.0pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">in caso di recesso o risoluzione
per qualsiasi causa del presente contratto, tutti i documenti sanitari ed i
dati dell’Utente presenti nel relativo account saranno <u>immediatamente ed
automaticamente cancellati;</u></span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
72.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">e.<span
                                  style="font:7.0pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">il Medico Curante
decide discrezionalmente quali Servizi possono essere forniti all’Utente per il
tramite del Portale; è possibile che il Medico Curante scelga di inibire
all’Utente alcuni dei servizi in astratto disponibili sul Portale. In tal caso
il Produttore non sarà responsabile delle modalità operative utilizzate dal
medico e l’Utente non avrà diritto al rimborso del canone già corrisposto e
relativo alla sola possibilità di effettuare l’accesso alla propria Area
Riservata. </span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">4.6.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif"> Alcuni dei Servizi
di Salute Con Te sono disponibili su dispositivi mobili. Tali Servizi non
devono essere utilizzati qualora possano distrarre e causare violazioni del
codice della strada o delle leggi sulla sicurezza.</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">4.7.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">Il produttore si
riserva il diritto di sospendere o inibire l'uso di Salute Con Te all’Utente,
qualora questi non rispettasse i termini o le condizioni d’uso o in caso di
comportamenti illeciti, sospetti o comunque contrari a buona fede.</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
0cm;text-align:justify;text-indent:18.0pt;line-height:19.2pt;background:white"><span class="Titolo2Carattere"><span
                                  style="font-size:13.0pt;color:#01856E">5.</span></span><span
                              class="Titolo2Carattere"><span style="font-size:13.0pt">Recesso</span></span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">5.1.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif"> Ai sensi dell’art.
59, co. 1, lettere <i>a) </i>ed<i> o), </i>D.Lgs. 206/2005, l’Utente dichiara
di essere consapevole ed espressamente richiede che il presente Contratto abbia
esecuzione immediata a seguito dell’accettazione dei presenti Termini e
Condizioni e della contestuale conferma del pagamento.</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">5.2.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">In conseguenza di
quanto previsto al punto 5.1. che precede, l’Utente dichiara di accettare
espressamente la perdita del diritto al recesso previsto per i contratti
conclusi a distanza dal Consumatore ai sensi degli artt. 52 ss. D.Lgs.
206/2005.&nbsp; </span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">5.3.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">Fatto salvo quanto previsto
alle clausole 5.1. e 5.2. che precedono, l’Utente può recedere dal medesimo e
dall’uso di Salute Con Te in qualsiasi momento, utilizzando la funzione <i>“cancella
account” </i>presente nella propria Area riservata.</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">5.4.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif"> A seguito del
recesso da parte dell’Utente con le modalità di cui al punto che precede, tutti
i dati ed i documenti presenti sull’Area Riservata dell’Utente stesso <u>saranno
automaticamente e definitivamente cancellati. </u></span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">5.5.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">A seguito del recesso
effettuato con le modalità e nei casi di cui alle clausole 5.3 e 5.4., l’Utente
non avrà diritto alla restituzione del canone di abbonamento già corrisposto
per la fruizione del Portale.</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;line-height:19.2pt;background:white"><u><span
                                  style="font-size:11.0pt;font-family:'Calibri',sans-serif"><span
                                      style="text-decoration:none">&nbsp;</span></span></u></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
0cm;text-align:justify;text-indent:18.0pt;line-height:19.2pt;background:white"><span class="Titolo2Carattere"><span
                                  style="font-size:13.0pt;color:#01856E">6.</span></span><span
                              class="Titolo2Carattere"><span style="font-size:13.0pt">Titolarità dei diritti di
proprietà intellettuale e del Software</span></span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">6.1.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">L’utilizzo di Salute
Con Te non conferisce all’Utente alcun diritto di proprietà intellettuale sui
Servizi del Produttore o sui contenuti a cui accede. </span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">6.2.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">I presenti termini
non concedono all’Utente il diritto di utilizzare alcun marchio o logo presente
su Salute Con Te.</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">6.3.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">Il Produttore
conserva la titolarità del Prodotto informatico originale e di tutte le copie,
indipendentemente dal supporto utilizzato o dalla forma.</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">6.4.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">Tutti i marchi,
registrati e non, come ogni e qualsiasi segno distintivo o denominazione
apposti sul programma e sulla relativa documentazione, sono e restano di
proprietà esclusiva del Produttore, senza che dalla stipulazione del contratto
derivi all’utente alcun diritto sui medesimi. </span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">6.5.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">L’Utente riconosce
che tutti gli applicativi eventualmente messi a disposizione sul Portale per
l’utilizzazione dei Servizi contengono informazioni riservate e sono protetti
dalle leggi in materia di proprietà intellettuale e/o industriale. Salvo il
caso di espressa autorizzazione da parte del Produttore, l’utente si obbliga a
non modificare o disporre in qualsiasi modo (sia a titolo oneroso che gratuito)
e a non distribuire, diffondere o creare lavori basati, integralmente o
parzialmente, sui Servizi o sui Software della piattaforma.</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">6.6.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">Resta fermo che
l’Utente non può in nessun caso sub-licenziare, noleggiare, concedere in uso a
qualunque titolo, vendere, distribuire, mettere in circolazione, decodificare,
de compilare, disassemblare, modificare, trasformare anche solo in parte il
Software, effettuare operazioni di reverse engineering e comunque riprodurre,
copiare, usare o disporre del Software, o di sue parti, in violazione di quanto
previsto dalle presenti Condizioni o dalla normativa vigente.</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">6.7.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">L’Utente prende atto
che il Produttore, ove venisse a conoscenza del carattere illecito di attività
effettuate dall’Utente, provvederà immediatamente, ai sensi della normativa
applicabile, ad inoltrare apposita segnalazione alle Autorità competenti,
fornendo, su richiesta di queste ultime, le informazioni in suo possesso che
consentano l’identificazione dell’Utente al fine di individuare e prevenire
attività illecite.</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;line-height:19.2pt;background:white"><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">&nbsp;</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
0cm;text-align:justify;text-indent:18.0pt;line-height:19.2pt;background:white"><span class="Titolo2Carattere"><span
                                  style="font-size:13.0pt;color:#01856E">7.</span></span><span
                              class="Titolo2Carattere"><span style="font-size:13.0pt">Protezione della privacy</span></span>
                  </p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">7.1.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">Il Produttore adotta
tutte le tecniche più moderne per garantire la privacy degli Utenti e la
sicurezza dei loro dati. Essi sono a disposizione delle sole persone che
l’Utente avrà autorizzato e del Medico Curante.</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">7.2.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">Le&nbsp;norme sulla
privacy&nbsp;di Salute con te illustrano le modalità di trattamento dei dati
personali e di protezione della privacy dell’utente quando questi utilizza i
Servizi del Produttore.</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">7.3.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">Per tutte le
informazioni relative al trattamento dei dati ed ai profili di sicurezza,
nonché alle modalità di utilizzo e memorizzazione dei contenuti sul Portale si
rinvia espressamente a quanto previsto all’interno dell’<i><u>informativa</u></i>
<i><u>sul trattamento dei dati personali</u></i> di Salute Con Te.</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">7.4.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">Il Produttore non è
in ogni caso responsabile della liceità, esattezza, completezza, pertinenza e
non eccedenza dei dati memorizzati dall'utente e dai Medici Curanti.&nbsp;</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">7.5.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">Qualora l’Utente
inviasse feedback o suggerimenti sui servizi di Salute con te, il Produttore potrà
utilizzare tali feedback o suggerimenti in forma anonima e senza che per
effetto dei medesimi sorga alcun obbligo nei confronti dell’Utente.</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;line-height:19.2pt;background:white"><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">&nbsp;</span></p>

                  <p style="margin-top:0cm;margin-right:0cm;margin-bottom:7.5pt;margin-left:0cm;
text-align:justify;text-indent:18.0pt;line-height:19.2pt;background:white"><span class="Titolo2Carattere"><span
                                  style="font-size:13.0pt;color:#01856E">8.</span></span><span
                              class="Titolo2Carattere"><span style="font-size:13.0pt">Informazioni sul software
compreso in Salute Con Te</span></span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">8.1.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">Quando un Servizio
richiede o include un software scaricabile, tale software potrebbe aggiornarsi
automaticamente sul dispositivo dell’Utente qualora sia disponibile una nuova
versione o funzione. Ove tale funzione sia presente sul dispositivo utilizzato
dall’Utente, le impostazioni di aggiornamento automatico del software potranno
essere modificate.</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">8.2.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">Non è consentito
copiare, modificare, distribuire, vendere o concedere in locazione parti di
Salute Con Te o del software incluso, né decodificare o tentare di estrarre il
codice sorgente da tale software, a meno che la legge vieti tali restrizioni o
nei casi in cui si disponga della autorizzazione scritta da parte del
Produttore.</span></p>

                  <p class="EvoluS" style="text-align:justify">&nbsp;</p>

                  <p class="EvoluS" style="text-align:justify">&nbsp;</p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
0cm;text-align:justify;text-indent:18.0pt;line-height:19.2pt;background:white"><span class="Titolo2Carattere"><span
                                  style="font-size:13.0pt;color:#01856E">9.</span></span><span
                              class="Titolo2Carattere"><span style="font-size:13.0pt">Modifica e miglioramento
dei Servizi</span></span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">9.1.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">Il Produttore si adopera
costantemente per migliorare i Servizi di Salute con te. Il Produttore si
riserva pertanto il diritto di aggiungere o implementare funzionalità o
caratteristiche di Salute Con Te in qualsiasi momento e senza preavviso.</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">9.2.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">Il produttore si
riserva altresì il diritto di rimuovere, modificare, sospendere o interrompere funzionalità
o caratteristiche di Salute Con Te, in qualsiasi momento e senza preavviso, ove
tanto fosse indispensabile per rendere i Servizi conformi a norme imperative,
nazionali o europee, ovvero a tutelare la sicurezza degli Utenti. </span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">9.3.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">Fermo restando quanto
previsto alla clausola 9.2, in caso di interruzione della fornitura di un
Servizio, ove possibile, il Produttore offrirà all’Utente un ragionevole
preavviso e la possibilità di rimuovere i dati ed i documenti contenuti nella
propria Area Personale. </span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;
margin-bottom:.0001pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;
background:white">9.4.<span style="font-size:11.0pt;font-family:'Calibri',sans-serif">Resta
fermo il diritto di recesso da parte dell’Utente in qualunque momento, ai sensi
dell’art. 5. </span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;
margin-bottom:.0001pt;text-align:justify;line-height:19.2pt;background:white">&nbsp;</p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
0cm;text-align:justify;text-indent:18.0pt;line-height:19.2pt;background:white"><b><span
                                  style="font-size:13.0pt;font-family:'Cambria',serif;color:#01856E">10.</span></b><span
                              class="Titolo2Carattere"><span style="font-size:13.0pt">Garanzie e limitazioni di
responsabilità del Produttore.</span></span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">10.1.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">Il Produttore, i suoi
fornitori ed i suoi distributori non sono responsabili per i contenuti presenti
in Salute Con Te.&nbsp;</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;
margin-bottom:.0001pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;
background:white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">10.2.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">Il Produttore non è
in alcun modo responsabile dell’inserimento, da parte dell’Utente, di dati
scorretti e/o di cui abbia conseguito illegittimamente il possesso. Rispetto a
tali ipotesi, l’utente che inserisce dati di terzi soggetti se ne assume tutti
gli obblighi e le responsabilità di legge, manlevando e tenendo indenne il
Produttore da ogni contestazione, pretesa o richiesta di risarcimento che
dovesse pervenire da parte dei terzi i cui dati personali siano stati
illegittimamente inseriti da parte dell’Utente stesso, nonché da qualsiasi
eventuale sanzione comminata dalle Autorità competenti nel caso in cui tali
fatti costituiscano illeciti civili, penali o amministrativi.</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;
margin-bottom:.0001pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;
background:white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">10.3.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">Il Produttore non è
in alcun modo responsabile della perdita o dell’irregolare utilizzo dei dati
resi visibili e scaricabili sul dispositivo dell’Utente attraverso
l’applicazione, né di tutto quanto avvenga a seguito del download e
dell’eventuale salvataggio dei file relativi nei dispositivi utilizzati dall’Utente
medesimo.</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;
margin-bottom:.0001pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;
background:white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">10.4.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">Fermo l’impegno nel
cercare di garantire la continuità dei servizi, il Produttore non presta alcuna
garanzia e non sarà in alcun modo responsabile per le ipotesi di ritardi,
malfunzionamenti, interruzioni e/o sospensioni dell’accesso ai dati o alle
funzionalità causati da: </span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:0cm;margin-left:72.0pt;
margin-bottom:.0001pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;
background:white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">a.<span
                                  style="font:7.0pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">errato utilizzo del
Portale e dei Servizi da parte dell’Utente;</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:0cm;margin-left:72.0pt;
margin-bottom:.0001pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;
background:white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">b.<span
                                  style="font:7.0pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">malfunzionamenti di
qualsiasi tipo del dispositivo utilizzato dall’utente;</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:0cm;margin-left:72.0pt;
margin-bottom:.0001pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;
background:white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">c.<span
                                  style="font:7.0pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">interruzioni totali o
parziali o comunque inefficienze dei servizi forniti dagli operatori di
telecomunicazioni o da qualunque altro soggetto terzo addetto alla trasmissione
dei dati; </span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:0cm;margin-left:72.0pt;
margin-bottom:.0001pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;
background:white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">d.<span
                                  style="font:7.0pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">operazioni di
manutenzione necessarie a garantire l’efficienza e la sicurezza
dell’applicazione o delle sue funzionalità; </span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:0cm;margin-left:72.0pt;
margin-bottom:.0001pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;
background:white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">e.<span
                                  style="font:7.0pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">virus che possano
infettare i dispositivi nella disponibilità dell’utente.</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:0cm;margin-left:72.0pt;
margin-bottom:.0001pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;
background:white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">f.<span
                                  style="font:7.0pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">qualsiasi altra causa
non imputabile al Produttore.</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;
margin-bottom:.0001pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;
background:white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">10.5.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">In ogni caso e nella
misura consentita dalla legge, la eventuale responsabilità totale del
Produttore, dei suoi fornitori e dei suoi distributori in merito a qualsiasi
reclamo presentato ai sensi dei presenti termini, inclusi i reclami per
qualsiasi garanzia implicita, si limita alla somma versata dall’Utente per
l’utilizzo dei Servizi (o, a discrezione insindacabile del Produttore, a una
nuova fornitura dei Servizi).</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;
margin-bottom:.0001pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;
background:white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">10.6.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">In ogni caso, il
Produttore, i suoi fornitori ed i suoi distributori non saranno responsabili di
eventuali perdite o danni non ragionevolmente prevedibili, ovvero derivati da
cause di forza maggiore o caso fortuito.</span></p>

                  <p class="MsoNormal">&nbsp;</p>

                  <h2 style="margin-top:10.0pt;margin-right:0cm;margin-bottom:12.0pt;margin-left:
0cm;text-align:justify;text-indent:18.0pt"><span style="color:#01856E">11.</span>Condizioni
                      economiche e corrispettivi </h2>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">11.1.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">Per l’accesso e
l’utilizzo dei servizi di Salute con te l’Utente si impegna a versare un canone
annuale nei termini previsti nell’Offerta pubblicata sulla Homepage del Portale
nella sezione “Prezzo”.</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">11.2.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">Il pagamento dovrà
essere effettuato esclusivamente con modalità telematiche (es. Carta di
credito, Paypal, etc).</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">11.3.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">Solo dopo la
ricezione del pagamento e su esplicita richiesta, il Produttore provvederà ad
inviare regolare fattura. </span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">11.4.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">L’abbonamento ai
servizi dura 12 mesi, a partire dalla data di sottoscrizione. </span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">11.5.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">Il contratto si
intenderà rinnovato di anno in anno, per periodo di 12 mesi e così di seguito,
salvo disdetta che è possibile effettuare direttamente all’interno di Salute
con te tramite apposita funzione, almeno 10 giorni prima della scadenza del
contratto.</span></p>

                  <p class="EvoluS" style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:justify">&nbsp;</p>

                  <h2 style="margin-top:10.0pt;margin-right:0cm;margin-bottom:12.0pt;margin-left:
0cm;text-indent:18.0pt"><span style="color:#01856E">12.</span>Contatti e
                      reclami</h2>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">12.1.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">Salvo quanto previsto
alla clausola 12.2. che segue, per tutte le comunicazioni relative al presente
contratto, l’Utente potrà rivolgersi ai seguenti recapiti del Produttore:</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;line-height:19.2pt;background:white"><u><span
                                  style="font-size:11.0pt;font-family:'Calibri',sans-serif">Sede legale</span></u>
                  </p>

                  <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;
margin-bottom:.0001pt;text-align:justify;background:white"><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">Evolus S.r.l. (C.F. /
P.IVA 10633690010)</span></p>

                  <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;
margin-bottom:.0001pt;text-align:justify;background:white"><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">Corso Unione
Sovietica, 612/21</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;line-height:19.2pt;background:white"><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">10135 TORINO<u> </u></span>
                  </p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;line-height:19.2pt;background:white"><u><span
                                  style="font-size:11.0pt;font-family:'Calibri',sans-serif">PEC</span></u>
                  </p>

                  <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;
margin-bottom:.0001pt;text-align:justify;background:white"><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">evolus&#64;pfpec.it</span>
                  </p>

                  <p style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;
margin-bottom:.0001pt;text-align:justify;background:white"><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">&nbsp;</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">12.2.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">Eventuali segnalazioni
relative al Servizio offerto dal Produttore e disciplinato dai presenti termini
e condizioni d’uso dovranno essere comunicate mediante il form compilabile
presente nella sezione dell’Area Riservata denominato <i>“Comunicazioni” </i>e
selezionando quale tipologia del messaggio la voce “<i>Servizio soddisfazione
clienti</i>”.</span></p>

                  <p class="EvoluS" style="text-align:justify">&nbsp;</p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
0cm;text-align:justify;text-indent:18.0pt;line-height:19.2pt;background:white"><span class="Titolo2Carattere"><span
                                  style="font-size:13.0pt;color:#01856E">13.</span></span><span
                              class="Titolo2Carattere"><span style="font-size:13.0pt">Informazioni sui presenti
Termini e condizioni d’uso</span></span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">13.1.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">Il produttore si
riserva il diritto di modificare i presenti termini o eventuali termini
aggiuntivi applicabili a un Servizio in ogni tempo. I termini devono essere
consultati regolarmente. Le notifiche relative ad eventuali modifiche apportate
ai termini aggiuntivi verranno pubblicate nello spazio relativo allo specifico
Servizio.</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">13.2.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">Le modifiche non
avranno valore retroattivo ed entreranno in vigore non prima di quattordici
giorni dopo la data di pubblicazione. Tuttavia, eventuali modifiche riferite
alle nuove funzioni di un Servizio o eventuali modifiche apportate per assicurare
il rispetto di norme imperative entreranno in vigore con effetto immediato.</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">13.3.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">I termini e le
condizioni come modificati verranno comunicati all’Utente al primo accesso
successivo alla loro entrata in vigore per il tramite di un’apposita finestra
che richiederà l’accettazione delle modifiche con modalità telematiche. L’Utente
è libero di non accettare le modifiche dei termini di un servizio. In tal caso,
tuttavia, non gli sarà consentita la fruizione del medesimo.</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">13.4.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">In caso di conflitto
tra i presenti termini e i termini aggiuntivi, prevarranno i termini
aggiuntivi.&nbsp;</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">13.5.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">In deroga a quanto
disposto dalla clausola 13.3., ove la modifica da parte del Produttore
riguardasse il prezzo dei Servizi, essa non potrà essere applicata in corso di
esecuzione del contratto e sarà applicata solo ed esclusivamente al momento del
rinnovo annuale dell’abbonamento e previa espressa accettazione da parte
dell’Utente. </span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;background:
white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">13.6.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">I presenti termini
regolano il rapporto tra il Produttore e l’utente, e non danno luogo ad alcun
diritto di terze parti beneficiarie.</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;page-break-after:
avoid;background:white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">13.7.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">Qualora l’Utente non
dovesse rispettare i presenti termini e condizioni, la mancata pronta adozione
di provvedimenti da parte del Produttore non potrà essere intesa quale rinuncia
a nessuno dei diritti del Produttore.</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;page-break-after:
avoid;background:white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">13.8.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">L’invalidità o
l’inefficacia di una o più clausole del Contratto non comporta l’invalidità o
l’inefficacia dell’intero Contratto.</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;page-break-after:
avoid;background:white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">13.9.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">I presenti termini e
condizioni sono pubblicati sul Portale, nella sezione "Termini e
condizioni"</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
36.0pt;text-align:justify;text-indent:-18.0pt;line-height:19.2pt;page-break-after:
avoid;background:white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">13.10.</span><span
                              style="font-size:11.0pt;font-family:'Calibri',sans-serif">Dopo l’accettazione,
da parte dell’Utente, dei presenti termini e condizioni e l’effettuazione del
pagamento del servizio, il presente contratto, il riepilogo delle condizioni
economiche applicate ai Servizi e la conferma della positiva conclusione delle operazioni
saranno resi disponibili all’Utente nell’apposita sezione della sua Area
Riservata e dovranno essere stampati e conservati a cura dell’Utente stesso.</span></p>

                  <p style="margin-bottom:7.5pt;text-align:justify;line-height:19.2pt;page-break-after:
avoid;background:white"><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">&nbsp;</span></p>

                  <p style="margin-top:5.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:
0cm;text-align:justify;text-indent:18.0pt;line-height:19.2pt;background:white"><span class="Titolo2Carattere"><span
                                  style="font-size:13.0pt;color:#01856E">14.</span></span><span
                              class="Titolo2Carattere"><span style="font-size:13.0pt">Legge applicabile</span></span>
                  </p>

                  <p class="EvoluS" style="margin-left:36.0pt;text-align:justify;text-indent:-18.0pt">14.1.<span
                              class="EvoluSCarattere">Il presente contratto ed il relativo rapporto sono
regolati dalle leggi della Repubblica Italiana. </span></p>

                  <p class="MsoNormal" style="text-align:justify">&nbsp;</p>

                  <p class="MsoNormal" style="text-align:justify">&nbsp;</p>

                  <p class="MsoNormal" style="text-align:justify">&nbsp;</p>

                  <p class="MsoNormal" style="text-align:justify">&nbsp;</p>

              </div>


          </div>
      </div>
  </div>
  <div class="pixfort_footer_1" id="section_footer_1">
      <div class="new_footer_1 pix_footers pix_builder_bg">
          <div class="container ">
              <div class="sixteen columns bg_foot">
                  <div class="twelve columns alpha desk_left">
                      <div class="footer_1_text">
                          <span class="editContent">
                              <label style="display: inline">© 2022 Salute Con Te</label>
                              <label style="display: inline"> - </label>
                              <label style="display: inline">
                                  <a href="" target="_blank">Informativa sul trattamento dei dati personali</a>
                              </label>
                              <label style="display: inline"> - </label>
                              <label style="display: inline">
                                  <a href="#">Termini e condizioni</a>
                              </label>
                              <label style="display: inline"> - </label>
                              <label style="display: inline">
                                  <a href=""
                                     target="_blank">Informativa cookie</a>
                              </label>
                          </span>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
<script>
$(document).ready(function () {
  $('body > div.container').removeClass('container');
})
</script>
