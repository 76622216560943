import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpRequestResponse } from '../../../../lib/models/http-request-response.model';
import { HttpRequestService } from '../../../../lib/services/http-request.service';
import { LogService } from '../../../../lib/services/log.service';

@Component({
  selector: 'app-recupero-password',
  templateUrl: './recupero-password.component.html',
  styleUrls: ['./recupero-password.component.scss'],
})
export class RecuperoPasswordComponent implements OnInit {
  password: string;
  pin: string;
  passwordConferma: string;
  codiceFiscale: string;
  bodyCfConfirmKey: string;

  constructor(
    private httpRequestService: HttpRequestService,
    private route: ActivatedRoute,
    private logService: LogService,
  ) {}

  ngOnInit(): void {
    this.bodyCfConfirmKey = this.route.snapshot?.queryParams?.id;
  }

  onSubmitNuovaPassword() {
    this.httpRequestService
      .post('/reimposta-password', {
        codiceFiscale: this.codiceFiscale.toUpperCase(),
        newPassword: this.password,
        pin: this.pin,
        newPasswordConferma: this.passwordConferma,
        bodyCfConfirmKey: this.bodyCfConfirmKey,
      })
      .subscribe((response) => {
        if (HttpRequestResponse.isSuccessResponse(response)) {
          this.logService.messageInfo("Successo","password reimpostata con successo")
        } else {
          this.logService.messageInfo("Attenzione",response?.message);
        }
      });
  }
}
