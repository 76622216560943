<div class="page" id="page">
  <div class="pixfort_corporate_2" id="section_corporate_4"
    style="/*width: 60% !important;*/ margin: 1px auto !important">
    <div class="container">
      <h1 style="text-align:center">{{applicationName}}</h1><br><br>

      <h3 style="text-align: center">Informativa sul trattamento dei dati personali ai sensi degli articoli 13 e 14 del
        GDPR 2016/679</h3>

      <br><strong>Definizioni</strong><br><br>

      “{{applicationName}}” o “il Portale”: è il portale web, accessibile all’indirizzo <a [href]="urlWebsite"
        target="_blank">{{urlWebsite}}</a>, dedicato
      alla erogazione, in favore degli utenti come infra definiti, del servizio consistente nella messa a
      disposizione di un canale di comunicazione privilegiato tra l’Utente ed il suo MMG per il tramite di
      un’apposita Area Riservata.<br><br>

      “Area Riservata” o “account”: è la sezione del portale associata ad un determinato Utente, contenente
      i suoi dati personali e sanitari ed accessibile esclusivamente dal relativo Utente, per mezzo delle
      proprie credenziali, e dal suo MMG.<br><br>

      “MMG”: è il medico di medicina generale convenzionato con il SSN e scelto dal cittadino, che
      utilizza il software di cartella clinica Next MMG e che, in forza di espressa autorizzazione dell’Utente
      interessato, e su richiesta del medesimo, mette a sua disposizione, tramite il Portale, i Servizi
      concordati con questi; ha accesso ai dati ed ai documenti presenti nell’area riservata.<br><br>

      “Utente”: è la persona fisica, con età pari o maggiore di 14 anni, che accede per il tramite di apposite
      “credenziali” a {{applicationName}} ed utilizza i Servizi a pagamento offerti dal Portale e forniti dal
      Produttore e che, salvo ove diversamente specificato, coincide con l’interessato.<br><br>

      “Interessato”: è la persona fisica cui si riferiscono i Dati Personali<br><br>

      “Dati personali”: costituisce dato personale qualunque informazione che, direttamente o
      indirettamente, anche in collegamento con qualsiasi altra informazione, ivi compreso un numero di
      identificazione personale, renda identificata o identificabile una persona fisica.<br><br>

      “Titolare del trattamento” o “titolare”: è la persona fisica o giuridica, l’autorità pubblica, il servizio o
      altro organismo che, singolarmente o insieme ad altri, determina le finalità e i mezzi del trattamento
      dei dati personali e gli strumenti adottati, ivi comprese le misure di sicurezza relative al
      funzionamento ed alla fruizione di “{{applicationName}}”.<br><br>

      <strong>Premesse</strong><br><br>
      Il sito “{{applicationName}}” che state visitando è gestito da EVOLUS s.r.l.<br>
      Il portale “{{applicationName}}” offre agli Utenti che lo utilizzano, mediante la messa a disposizione di uno
      specifico Account, la possibilità di interagire direttamente con il proprio MMG, ricevendo dal
      medesimo e fornendogli informazioni, documenti e dati con modalità on-line.<br>
      A titolo esemplificativo e non esaustivo, per il tramite del Portale, ove abilitato dal proprio MMG,
      l’Utente ha la possibilità di:<br><br>
      - richiedere la prescrizione di farmaci per patologie croniche;<br>
      - richiedere la prescrizione di prestazioni periodiche;<br>
      - inviare direttamente al MMG referti di esami o visite;<br>
      - inviare al MMG il valore INR per consentire la prescrizione per la TAO;<br>
      - inviare comunicazioni al MMG;<br>
      - fissare appuntamenti con il MMG.<br><br>
      All’interno dell’Area Riservata a ciascun Utente sono presenti esclusivamente i dati personali forniti
      dall’Utente al momento del primo accesso all’account, i contenuti della cartella clinica in possesso
      del MMG, che il medesimo mette a disposizione dell’Utente, nonché i dati personali che l’Utente
      medesimo inserisce nella propria area personale, che può utilizzare come promemoria dei suoi dati
      sanitari o per richieste da sottoporre successivamente al medico.<br><br>
      Il consenso espresso dell’Utente al trattamento dei propri dati presenti in “{{applicationName}}” è
      presupposto indefettibile per l’utilizzo del portale.<br><br>
      Il Portale può essere utilizzato esclusivamente dagli utenti che abbiano già rilasciato il consenso al
      trattamento dei propri dati personali e sanitari al proprio MMG e che, accedendo per la prima volta
      al Portale, hanno letto e compreso la presente informativa, autorizzato il trattamento dei dati ed
      accettato termini e condizioni d’uso del portale.<br><br>
      I dati forniti dagli utenti al momento della prima registrazione al Portale, o successivamente, durante
      l’utilizzo dello stesso, formeranno oggetto di trattamento nel rispetto delle vigenti disposizioni –
      nazionali ed europee – in materia di protezione dei dati personali.<br><br>
      EVOLUS s.r.l. garantisce che il trattamento dei dati personali che dovessero pervenire via telefonica,
      per posta elettronica o moduli elettronici è conforme al GDPR 2016/679 (Nuovo Regolamento
      Europeo sulla protezione dei dati personali).<br><br>
      <hr style="border-top: 1px dashed black">

      Al momento del primo accesso al Portale, selezionando la voce “autorizzo” e cliccando sul tasto
      “prosegui”, l’Utente dichiara di aver letto e compreso il contenuto del presente documento ed
      autorizza spontaneamente il trattamento dei suoi dati personali di qualsiasi natura, ivi compresi quelli
      sanitari e identificativi, in conformità a quanto indicato nel presente documento e più in generale
      secondo quanto previsto dal GDPR 679/2016.<br><br>

      <strong>1 Titolare ed oggetto del trattamento</strong><br><br>

      1.1 La <strong>EVOLUS s.r.l.</strong> è Co-Titolare del trattamento insieme al MMG. La Evolus s.r.l. ha sede in
      Corso Unione Sovietica 612/3D, 10135 Torino (TO); e-mail: <a
        href="mailto:privacy@saluteconte.it">privacy&#64;saluteconte.it</a><br><br>

      1.2 Responsabile del trattamento è il Sig. Davide Zanellato, al quale l’interessato si può rivolgere
      direttamente per richiedere informazioni relative esclusivamente ai propri dati personali,
      all’indirizzo e-mail: <a href="mailto:privacy@saluteconte.it">privacy&#64;saluteconte.it</a><br><br>

      1.3 Nel Portale “{{applicationName}}” sono presenti due categorie di dati personali:<br><br>
      A. Dati personali dell’Utente raccolti dal Titolare, previa autorizzazione dell’utente medesimo, al
      momento della registrazione e dati di utilizzo raccolti automaticamente dal sistema durante l’uso del
      Portale, fra i quali:<br><br>

      - Nome, cognome e codice fiscale;<br>
      - Indirizzo e-mail;<br>
      - File di log, comprendenti le seguenti informazioni: indirizzo IP; indirizzi URL delle risorse richieste;
      orario della richiesta; parametri relativi al sistema operativo ed all’ambiente dell’utente; nomi del
      dominio del computer; tipo di browser.<br><br>

      B. Dati personali, anche sanitari, dell’Utente, raccolti dal MMG nell’esercizio della sua attività di
      Medico curante, inseriti nel Portale dallo stesso MMG e dati personali, anche sanitari, inseriti sul
      Portale dall’Utente, fra i quali, a titolo esemplificativo e non esaustivo:<br><br>
      - Dati anagrafici (Nome, Cognome, Codice fiscale);<br>
      - Ricette;<br>
      - Terapie Terapie continuative (farmaci cronici)<br>
      - Esenzioni<br>
      - Referti<br>
      - Allergie<br>
      - Scadenze<br>
      - Certificati di malattia INPS<br>
      - Piani terapeutici<br>
      - Presidi medico chirurgici<br>
      - Esame di laboratorio<br>
      - Esami strumentali e visite<br>
      - Prestazioni<br>
      - TAO (Terapia anti coagulante orale)<br>
      - Le fatture inviate al MEF dal medico<br><br>

      1.4 La Evolus s.r.l. è responsabile per le attività di:<br>
      <div class="tab2">
        Registrazione, Conservazione, Aggiornamento e Manutenzione dei dati informatici gestiti dal
        portale “{{applicationName}}”. La Evolus s.r.l. non è in alcun modo responsabile del contenuto dei dati
        inseriti sul portale direttamente dal MMG o dall’Utente.
        Il MMG e l’Utente sono i soli responsabili delle attività di:
        Consultazione, Inserimento, Modifica, Creazione e Cancellazione dei dati presenti sul portale
        “{{applicationName}}”.
      </div><br>

      1.5 L’Utente si assume la piena responsabilità dei propri dati personali che inserisce direttamente ed
      autonomamente sul Portale.<br><br>
      1.6 L’Utente si assume la piena responsabilità dei dati personali di terzi ottenuti, pubblicati o
      condivisi mediante “{{applicationName}}” e garantisce di avere il diritto di comunicarli o diffonderli,
      liberando la Evolus s.r.l. da qualsiasi responsabilità verso terzi.<br><br>
      1.7 Questo sito web fa uso di Cookie. Tutte le informazioni relative sono disponibili per la
      consultazione nella Cookie policy pubblicata sul Portale nella omonima sezione.<br><br>

      <strong>2. Base giuridica del trattamento</strong><br><br>
      2.1 I Co-Titolari trattano i dati personali dell’Utente lecitamente, laddove il trattamento:
      a. sia necessario all’esecuzione di un contratto di cui l’Utente è parte o all’esecuzione di misure
      precontrattuali adottate su richiesta;<br>
      b. sia necessario per adempiere un obbligo legale incombente sui Co-Titolari;<br>
      c. sia basato sul consenso espresso dell’Utente per le specifiche finalità di cui all’art. 3 che segue.<br><br>

      <strong>3. Finalità del trattamento</strong><br><br>
      3.1 Il trattamento dei dati di cui all’art. 1 che precede è finalizzato a:<br>
      a. consentire l’erogazione dei servizi offerti dal Portale {{applicationName}}, previa autenticazione e
      creazione, da parte del MMG di un Account dedicato all’Utente. La raccolta, la conservazione e
      l’elaborazione dei dati forniti dall’utente al momento della registrazione e/o durante l’utilizzo dei
      servizi sono finalizzate alla creazione ed alla successiva gestione operativa, tecnica e amministrativa
      del rapporto con l’utente e dell’account da questi creato.<br>
      b. Mettere in comunicazione l’utente con il proprio MMG e consentirgli l’utilizzo dei servizi offerti
      da {{applicationName}}.<br>
      c. Fornire assistenza tecnica su richiesta dell’Utente e/o consentire la manutenzione del Portale e la
      risoluzione di eventuali problemi tecnici.<br>
      d. Consentire all’Utente il recupero delle credenziali di accesso in caso di smarrimento o sottrazione
      delle medesime.<br><br>

      3.2 Il trattamento dei dati di cui all’art. 1.3, lettera A, è altresì finalizzato al monitoraggio delle attività
      svolte sul portale ed alle comunicazioni relative al sito e ai suoi contenuti, nonché l’eventuale invio
      di informazioni su argomenti attinenti ai contenuti del sito.<br><br>

      <strong>4. Modalità del trattamento</strong><br><br>
      4.1 I dati forniti dall’Utente sono trattati prevalentemente con strumenti automatizzati, per il tempo
      strettamente necessario a conseguire gli scopi per cui sono stati raccolti.<br><br>
      4.2 Il Titolare adotta specifiche misure tecniche ed organizzative per garantire un livello di sicurezza
      adeguato, in conformità a quanto previsto dall’art. 32 GDPR 2016/679.<br><br>
      4.3 Nello specifico, il portale è protetto da apposite misure di sicurezza, in costante evoluzione, in
      linea con il progresso tecnologico. Vengono a tal fine utilizzati attualmente il protocollo HTTPS,
      firewall di protezione e tecniche di crittografia sui dati “particolari” (ex dati sensibili).<br><br>

      4.4 Oltre al Titolare, in alcuni casi, potrebbero avere accesso ai dati di cui all’art. 1 lettera A altri
      soggetti coinvolti nell’organizzazione e nella gestione del Portale (personale amministrativo,
      amministratori di sistema) ovvero soggetti esterni (fornitori di servizi tecnici terzi) nominati anche,
      se necessario, Responsabili del Trattamento da parte della Evolus s.r.l.<br><br>
      4.5 Oltre alla Evolus s.r.l., al MMG ed all’Utente stesso, è possibile che abbiano accesso ai dati di cui
      all’art. 1 lettera B, e solo a fini manutentivi e di risoluzione di problemi tecnici, altri soggetti coinvolti
      nella gestione tecnica del Portale (personale tecnico dipendente della Società Titolare, consulenti
      tecnici esterni).<br><br>
      4.6 Al momento della richiesta di attivazione dei servizi da parte dell’Utente al proprio MMG, lo
      stesso MMG utilizzerà i dati anagrafici dell’Utente già in suo possesso ai fini della creazione delle
      credenziali di accesso telematico al Portale.<br><br>
      4.7 Per le successive autenticazioni e l’accesso al Portale l’Utente dovrà inserire le proprie credenziali
      di accesso ed il proprio codice fiscale.<br><br>
      4.8 Al momento della registrazione in “{{applicationName}}”, ad ogni Utente è richiesto l’inserimento di un
      indirizzo e-mail.<br><br>
      4.9 L’inserimento di tale dato è obbligatorio al fine di consentire all’Utente il recupero delle
      credenziali di accesso in caso di sottrazione o smarrimento delle medesime. L’acquisizione di tale
      dato è presupposto indefettibile per l’accesso al Portale e la fruizione degli specifici servizi messi a
      disposizione dell’Utente. Il rifiuto a fornire l’indirizzo e-mail impedisce la registrazione al portale
      “{{applicationName}}” e comporta conseguentemente l’impossibilità da parte dell’utente di sfruttarne le
      funzionalità.<br><br>
      4.10 Durante l’utilizzo del Portale e dei relativi servizi, sul dispositivo dell’Utente sono visualizzabili
      documenti contenenti dati relativi allo stato di salute dell’Utente stesso. Ad eccezione delle finalità e
      dei casi di cui all’art. 4.5., tali dati sono visibili soltanto da parte del MMG e dell’Utente che li hanno
      inseriti.<br><br>
      4.11 La lettura e la consultazione dei dati sono subordinate all’accesso all’Area Riservata presente in
      “{{applicationName}}”. Al momento di ogni accesso, si chiede a ciascun utente di digitare il proprio username
      e la propria password. Successivamente, a fini di maggior sicurezza, all’utente è richiesto
      l’inserimento del proprio codice fiscale, che viene automaticamente abbinato dal Sistema ai dati
      anagrafici inseriti dal MMG al momento dell’attivazione dell’account.<br><br>
      4.12 Conferendo i dati al momento della prima autenticazione, ogni utente acconsente:<br><br>
      a. che cognome, nome, codice fiscale, dati delle proprie ricette farmacologiche ed in generale i
      documenti caricati sul Portale, siano accessibili all’utente stesso, titolare dell’account, attraverso il
      canale dell’applicazione ed al proprio MMG, nonché, ai fini di cui agli artt. 3 e 4, a dipendenti della
      Evolus s.r.l. e Consulenti esterni per attività di tipo tecnico e manutentivo del Portale;<br><br>
      b. che siano resi visibili a monitor e – a discrezione dell’utente – scaricabili sul dispositivo utilizzato
      dall’Utente, dati e file relativi alle prescrizioni farmacologiche e comunque idonei a fornire dati
      relativi allo stato di salute dell’Utente.<br><br>
      4.13 Si raccomanda a tutti gli utenti di adottare le necessarie cautele per assicurare la segretezza della
      password. Si consiglia, in particolare, di scegliere una password che non contenga riferimenti agevolmente
      riconducibili all'utente, in modo da garantire la sicurezza del suo accesso. Si consiglia,
      altresì, di aver cura di non lasciare incustodito il dispositivo su cui è installata l’applicazione, al fine
      di evitare abusivi accessi da parte di terzi.<br><br>
      4.14 Nei limiti e per le finalità indicate nel presente documento, i dati degli utenti inseriti in “{{applicationName}}” potranno essere comunicati a:<br><br>
      a. soggetti, enti o autorità a cui la comunicazione dei dati sia obbligatoria per legge;<br>
      b. soggetti delegati e/o incaricati dalla Evolus s.r.l. per lo svolgimento di attività correlate
      all'erogazione dei servizi e/o all’aggiornamento tecnico dell’applicazione.<br><br>
      4.15 I dati personali dell’Utente possono essere utilizzati da parte della Evolus s.r.l. in giudizio o nelle
      fasi preparatorie alla sua eventuale instaurazione per la difesa da abusi nell’utilizzo di “{{applicationName}}”
      o dei Servizi connessi da parte dell’Utente.<br><br>
      4.16 L’Utente dichiara di essere consapevole che la Evolus s.r.l. potrebbe essere obbligato a
      comunicare i Dati per ordine di autorità pubbliche.<br><br>

      <strong>5. Conservazione dei dati</strong><br><br>
      5.1 I dati raccolti dai Co-Titolari e quelli presenti sul Portale {{applicationName}}, sono conservati per il
      periodo di tempo necessario a perseguire le finalità di cui al precedente art. 2 e solo per la durata del
      rapporto di servizio tra l'utente ed i Co-Titolari del trattamento. Al termine del rapporto di servizio
      tra i co-titolari e l’utente, i dati verranno conservati per un periodo pari a 6 mesi, oltre il quale verranno
      cancellati dai data base del portale.<br><br>

      5.2 È diritto dell’utente revocare, in qualsiasi momento, il consenso prestato al trattamento dei suoi
      dati in “{{applicationName}}”, senza che ciò pregiudichi la liceità del trattamento effettuato sulla base del
      consenso prestato prima della revoca.<br><br>

      5.3 La suddetta revoca avviene attraverso la cancellazione dell’Account sul portale “{{applicationName}}”.<br><br>

      5.4 In caso di cancellazione dell’Account, tutti i dati dell’utente registrati in “{{applicationName}}” saranno
      rimossi dal dispositivo e dall’applicazione, eccezion fatta per i documenti scaricati e salvati sul
      dispositivo.<br><br>

      5.5 La conservazione e l’archiviazione dei dati immessi dall’Utente o dal MMG nelle aree riservate
      ai Servizi è affidata al sistema di Cloud “google cloud platform”, che garantisce la sicurezza dei
      medesimi e la tutela della Privacy secondo elevati standard qualitativi, sulla base delle condizioni
      consultabili ai seguenti link:
      <a href="https://cloud.google.com/security/whitepaper"
        target="_blank">https://cloud.google.com/security/whitepaper</a>
      <a href="https://cloud.google.com/security/compliance"
        target="_blank">https://cloud.google.com/security/compliance</a>
      <br><br>

      5.6 I dati sono trattati presso le sedi operative dei Co-Titolari ed in ogni altro luogo in cui le parti
      coinvolte nel trattamento siano localizzate. Per ulteriori informazioni è sempre possibile contattare la
      Evolus s.r.l. agli indirizzi indicati all’art. 1. Ove necessario, la Evolus s.r.l. ha facoltà di affidarsi a
      server esterni, gestiti da operatori specializzati, che potrebbero essere ubicati nell’Unione Europea
      ovvero in paesi Extra-UE. In tal caso, la Evolus s.r.l. assicura che il trasferimento dei dati avvenga in
      conformità alle disposizioni di legge applicabili.<br><br>

      <strong>6. Diritti dell’interessato</strong><br><br>

      6.1 In caso di autorizzazione al trattamento dei dati, all’Interessato saranno garantiti tutti i diritti così
      come meglio specificati dagli articoli da 15 a 21 del GDPR 2016/679.<br><br>

      6.2 Precisamente, l’Interessato dal trattamento dei dati può:<br>

      a. ottenere dai Co-Titolari del trattamento la conferma che sia o meno in corso un trattamento di dati
      personali che lo riguardano;<br><br>

      b. ottenere l'accesso ai dati personali e alle seguenti informazioni: 1) l’origine dei dati personali; 2)
      le categorie di dati personali in questione; 3) le finalità e le modalità del trattamento; estremi
      identificativi dei Co-titolari, dei responsabili e del rappresentante designato; 4) la logica applicata in
      caso di trattamento effettuato con l'ausilio di strumenti elettronici; 5) i soggetti o delle categorie di
      soggetti ai quali i dati personali possono essere comunicati o che possono venirne a conoscenza in
      qualità di rappresentante designato nel territorio dello Stato, di responsabili o incaricati; 6) quando
      possibile, il periodo di conservazione dei dati personali previsto oppure, se non è possibile, i criteri
      utilizzati per determinare tale periodo; 7) l'esistenza del diritto dell'Interessato di chiedere ai Co-
      Titolari del trattamento la rettifica o la cancellazione dei dati personali o la limitazione del trattamento
      dei dati personali che lo riguardano o di opporsi al loro trattamento; 8) il diritto di proporre reclamo
      a un'autorità di controllo; 9) qualora i dati non siano raccolti presso l'Interessato, tutte le informazioni
      disponibili sulla loro origine; 10) l'esistenza di un processo decisionale automatizzato, compresa la
      profilazione di cui all'articolo 22, paragrafo 1 del GDPR, e, almeno in tali casi, informazioni
      significative sulla logica utilizzata, nonché l'importanza e le conseguenze previste di tale trattamento
      per l'Interessato;<br><br>

      c. ottenere: 1) l'aggiornamento, la rettificazione ovvero, quando vi ha interesse, l'integrazione dei dati;
      2) la cancellazione, la trasformazione in forma anonima o il blocco dei dati trattati in violazione di
      legge, compresi quelli di cui non è necessaria la conservazione in relazione agli scopi per i quali i dati
      sono stati raccolti o successivamente trattati; 3) l'attestazione che le operazioni di cui ai numeri 1) e
      2) sono state portate a conoscenza, anche per quanto riguarda il loro contenuto, di coloro ai quali i
      dati sono stati comunicati o diffusi, eccettuato il caso in cui tale adempimento si rivela impossibile o
      comporta un impiego di mezzi manifestamente sproporzionato rispetto al diritto tutelato;<br><br>

      d. opporsi, in tutto o in parte: 1) per motivi legittimi al trattamento dei dati personali che lo riguardano,
      ancorché pertinenti allo scopo della raccolta; 2) al trattamento di dati personali che lo riguardano a
      fini di invio di materiale pubblicitario o di vendita diretta o per il compimento di ricerche di mercato
      o di comunicazione commerciale, mediante l’uso di sistemi automatizzati di chiamata senza
      l’intervento di un operatore mediante e-mail e/o mediante modalità di marketing tradizionali mediante
      telefono e/o posta cartacea. Si fa presente che il diritto di opposizione dell’Interessato, esposto al
      precedente punto 2), per finalità di marketing diretto mediante modalità automatizzate si estende a
      quelle tradizionali e che comunque resta salva la possibilità per l’Interessato di esercitare il diritto di
      opposizione anche solo in parte. Pertanto, l’Interessato può decidere di ricevere solo comunicazioni
      mediante modalità tradizionali ovvero solo comunicazioni automatizzate oppure nessuna delle due
      tipologie di comunicazione;<br><br>

      e. ottenere la limitazione del trattamento al ricorrere di una delle ipotesi di cui all’art. 18 GDPR
      2016/679;<br><br>

      f. ottenere i dati che lo riguardano al fine di trasmetterli ad un altro titolare del trattamento ovvero
      ottenere la trasmissione diretta di tali dati dal Titolare ad un altro titolare, se tecnicamente
      fattibile;<br><br>

      g. proporre reclamo all’Autorità Garante per la Protezione dei dati personali;<br><br>

      h. ottenere dalla Evolus s.r.l. una copia dei dati personali oggetto di trattamento. In caso di ulteriori
      copie richieste dall'Interessato, la Evolus s.r.l. può addebitare un contributo spese ragionevole basato
      sui costi amministrativi. Se l'Interessato presenta la richiesta mediante mezzi elettronici, e salvo
      indicazione diversa dell'Interessato, le informazioni sono fornite in un formato elettronico di uso
      comune. il diritto di ottenere una copia di cui al punto precedente non deve ledere i diritti e le libertà
      altrui.<br><br>

      6.3 Qualora i dati personali siano trasferiti a un paese terzo o ad un’organizzazione internazionale,
      l’interessato ha il diritto di essere informato dell’esistenza di garanzie adeguate ai sensi dell’art. 46
      GDPR 2016/679 relative al trasferimento.<br><br>

      6.4 Salvo quanto previsto all’art. 34, paragrafo 3 del GDPR 2016/679, la Evolus s.r.l. comunica senza
      ingiustificato ritardo all’interessato eventuali violazioni dei suoi dati personali suscettibili di
      presentare un rischio elevato per i diritti e le libertà delle persone fisiche.<br><br>

      <strong>7. Modifiche alla presente informativa</strong><br><br>
      7.1 La Evolus s.r.l. si riserva il diritto di apportare modifiche alla presente informativa in qualunque
      momento, dandone comunicazione agli Utenti sul Portale {{applicationName}}, nella sezione “informativa
      Privacy”, si prega dunque di consultare regolarmente tale sezione, facendo riferimento alla data di
      ultima modifica indicata in fondo.<br><br>
      7.2 Qualora le modifiche interessino trattamenti la cui base giuridica è il consenso, la Evolus s.r.l.
      provvederà a raccogliere nuovamente il consenso dell’Utente, ove necessario.
    </div>
  </div>
  <div class="pixfort_footer_1" id="section_footer_1">
    <div class="new_footer_1 pix_footers pix_builder_bg">
      <div class="container">
        <div class="sixteen columns bg_foot">
          <div class="twelve columns alpha desk_left">
            <div class="footer_1_text">
              <span class="editContent">
                <label style="display: inline">© 2022 {{applicationName}}</label>
                <label style="display: inline"> - </label>
                <label style="display: inline">
                  <a href="#">Informativa sul trattamento dei dati personali</a>
                </label>
                <label style="display: inline"> - </label>
                <label style="display: inline">
                  <a href="" target="_blank">Termini e condizioni</a>
                </label>
                <label style="display: inline"> - </label>
                <label style="display: inline">
                  <a href="" target="_blank">Informativa cookie</a>
                </label>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<script>
  $(document).ready(function () {
    $("body > div.container").removeClass("container");
  });
</script>