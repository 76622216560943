import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EvManualeComponent } from './lib/components/ev-manuale.component';
import { AccessoVideoCallComponent } from './portale/pages/accesso-video-call/accesso-video-call.component';
import { ContattaciComponent } from './portale/pages/contact-form/contattaci.component';
import { CookieComponent } from './portale/pages/cookie/cookie.component';
import { DiconoDiNoiComponent } from './portale/pages/dicono-di-noi/dicono-di-noi.component';
import { ForgotPasswordComponent } from './portale/pages/impostazioni/forgot-password/forgot-password.component';
import { ForgotPinComponent } from './portale/pages/impostazioni/forgot-pin/forgot-pin.component';
import { RecuperoPasswordComponent } from './portale/pages/impostazioni/recupero-password/recupero-password.component';
import { LoginPageComponent } from './portale/pages/login/login/login-page.component';
import { LogoutComponent } from './portale/pages/login/logout/logout.component';
import { NormativaPrivacyComponent } from './portale/pages/normativa-privacy/normativa-privacy.component';
import { PrezzoComponent } from './portale/pages/prezzo/prezzo.component';
import { PrivacyComponent } from './portale/pages/privacy/privacy.component';
import { ScopriComponent } from './portale/pages/scopri/scopri.component';
import { SicurezzaComponent } from './portale/pages/sicurezza/sicurezza.component';
import { VantaggiComponent } from './portale/pages/vantaggi/vantaggi.component';

const routes: Routes = [
  {
    path: '',
    component: LoginPageComponent,
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: 'accesso_videocall',
    component: AccessoVideoCallComponent,
  },
  {
    path: 'vantaggi',
    component: VantaggiComponent,
  },
  {
    path: 'scopri',
    component: ScopriComponent,
  },
  {
    path: 'prezzo',
    component: PrezzoComponent,
  },
  {
    path: 'diconoDiNoi',
    component: DiconoDiNoiComponent,
  },
  {
    path: 'assistenza/contact/1',
    component: ContattaciComponent,
  },
  {
    path: 'iprivacy',
    component: PrivacyComponent,
  },
  {
    path: 'normativaPrivacy',
    component: NormativaPrivacyComponent,
  },
  {
    path: 'isicurezza',
    component: SicurezzaComponent,
  },
  {
    path: 'icookie',
    component: CookieComponent,
  },
  {
    path: "manuale",
    component: EvManualeComponent
  },
  {
    path: 'impostazioni/forgot_pin',
    component: ForgotPinComponent,
  },
  {
    path: 'impostazioni/forgot_password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'impostazioni/rp',
    component: RecuperoPasswordComponent,
  },
  

  /**
   * Applicazione
   */
  {
    path: '',
    loadChildren: () => import('./applicazione/applicazione.module').then(m => m.ApplicazioneModule),
    canActivate: []
  },

  /**
   * Next COMUNICA
   */
  {
    path: 'nc',
    loadChildren: () => import('./next-comunica/next-comunica.module').then(m => m.NextComunicaModule),
    canActivate: []
  },


  /**
   * Mobile
   */
  {
    path: 'mobile',
    loadChildren: () => import('./mobile/mobile.module').then(m => m.MobileModule),
    canActivate: []
  },

  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', bindToComponentInputs: true })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
