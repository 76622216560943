import { AfterViewInit, Component, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { ApplicationService } from "../../services/application.service";
import { UtenteService } from "../../services/utente.service";
import { Router } from "@angular/router";

@Component({
    selector: 'ev-user-profile',
    styleUrls: ['./ev-user-profile.component.scss'],
    templateUrl: './ev-user-profile.component.html'
})
export class EvUserProfileComponent implements AfterViewInit, OnDestroy {

    immagineProfilo
    nomeUtente
    nomeMedico
    dataScadenzaAbbonamento
    codiceFiscale
    isPagamentiVisibile
    isPagamentiVisibileSub: Subscription
    applicationName


    constructor(public utenteService: UtenteService, private router: Router, private as:ApplicationService) {
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.nomeUtente = this.utenteService.getChiave(['paziente', 'nome']);
            this.nomeMedico = this.utenteService.getChiave(['paziente', 'medico']);
            this.dataScadenzaAbbonamento = this.utenteService.getChiave(['paziente', 'data_scadenza_abbonamento'])
            this.codiceFiscale = this.utenteService.getChiave(['paziente', 'codfis'])
            this.immagineProfilo = 'data:image/png;base64,' + this.utenteService.getChiave(['paziente', 'img'])
            this.applicationName = this.as.getApplicationName()

            this.isPagamentiVisibileSub = this.utenteService.datiUtentePresenti.subscribe(value => {
                if (value) {
                    this.isPagamentiVisibile = this.utenteService.isPagamentiVisibile()
                }
            })
        }, 0);
    }

    goToChangeImage() {
        this.router.navigate(['/impostazioni/cambiaImmagineProfilo'], {

        });
    }

    ngOnDestroy(): void {
        this.isPagamentiVisibileSub?.unsubscribe()
    }
}
