import { Injectable, OnDestroy } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Observable, Subject, Subscription } from 'rxjs';
import { DialogMaterialService } from './dialog-material.service';

export interface SnackBarPosition {
  horizontal: MatSnackBarHorizontalPosition
  vertical: MatSnackBarVerticalPosition
}

@Injectable({
  providedIn: 'root'
})
export class LogService implements OnDestroy {

  // info
  private infoSub: Subject<any[]> = new Subject();
  public readonly infoOb: Observable<any[]> = this.infoSub.asObservable();

  // warn
  private warnSub: Subject<any[]> = new Subject();
  public readonly warnOb: Observable<any[]> = this.warnSub.asObservable();

  // error
  private errorSub: Subject<any[]> = new Subject();
  public readonly errorOb: Observable<any[]> = this.errorSub.asObservable();

  // message error sub
  private msgErrorSub: Subscription;

  constructor(private snackBar: MatSnackBar, private dms: DialogMaterialService) {
  }

  info(...args: any[]) {
    this.infoSub.next(args);
  }

  warn(...args: any[]) {
    console.warn(args);
    this.warnSub.next(args);
  }

  error(...args: any[]) {
    console.error(args);
    this.errorSub.next(args);
  }

  messageError(titolo: string, messaggio: string, err: Error, callback = () => {
  }) {

    console.error(titolo, ' ', messaggio);
    console.error(err);
    this.errorSub.next([titolo, messaggio, err]);

    return this.dms.openClose(titolo, messaggio, {
      panelClass: 'message-error'
    })
  }

  messageInfo(titolo: string, messaggio: string) {
    this.infoSub.next([titolo, messaggio]);

    return this.dms.openClose(titolo, messaggio)

  }

  messageWarning(titolo: string, messaggio: string, callback = () => {
  }) {
    this.warnSub.next([titolo, messaggio]);

    return this.dms.openClose(titolo, messaggio, {
      panelClass: 'message-warning'
    })
  }

  confirm2(titolo: string, messaggio: string) {
    return this.dms.openYesNo(titolo, messaggio)
  }

  snack(message: string, action: string = 'Chiudi', durationInSec: number = 3, position: SnackBarPosition = {
    horizontal: 'right',
    vertical: 'bottom'
  }) {
    return this.snackBar.open(message, action, {
      duration: durationInSec * 1000,
      horizontalPosition: position.horizontal,
      verticalPosition: position.vertical,
    });
  }

  ngOnDestroy() {
    if (this.msgErrorSub) {
      this.msgErrorSub.unsubscribe();
    }
  }

}
