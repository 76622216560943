import { Component, Input, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ApplicationService } from "../../services/application.service";

@Component({
    selector: 'ev-page',
    templateUrl: './ev-page.component.html',
    styleUrls: ['./ev-page.component.scss']
})
export class EvPageComponent implements OnInit {
    // titolo della pagina
    @Input({ required: true }) pageTitle: string
    // funzionalità
    @Input({ required: true }) funzionalita: string
    // info
    @Input() showInfo: boolean = false
    @Input() infoTitle: string
    @Input() infoText: string
    @Input() infoExpanded: boolean = false

    @Input() hideCategorie: boolean = false

    constructor(private titleService: Title, private applicationService: ApplicationService) {

    }

    ngOnInit(): void {
        this.titleService.setTitle(`${this.applicationService.getApplicationName()} - ${this.pageTitle}`)
    }

}
