import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, forwardRef } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator, Validators } from '@angular/forms';
import { ALLEGATI_DIMENSIONE_MASSIMA, ALLEGATI_ESTENSIONI_ACCETTATE } from "../../constants";
import { FileInfo, createFileInfo, createFileInfoSimple, extractFileContentWithoutDataUriPrefix } from '../../models/file-info.class';
import { LogService } from "../../services/log.service";
import { downloadFile, fromBase64, getFileExtension, humanFileSize, toBase64 } from "../../utils/Utility";
import { DomSanitizer } from '@angular/platform-browser';

/**
 * Piccolo componente per l'upload dei file.
 *
 * Supporta:
 *   - Upload di un file
 *   - Link azione per pulire/scaricare il file
 *   - Box di preview del file
 */
@Component({
  selector: 'ev-file-upload-material',
  templateUrl: './ev-file-upload-material.component.html',
  styleUrls: ['./ev-file-upload-material.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EvFileUploadMaterialComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: EvFileUploadMaterialComponent,
      multi: true
    }
  ]
})
export class EvFileUploadMaterialComponent implements Validator, OnInit {

  // label della filebox
  @Input() label: string
  // posizione della label
  @Input() labelPosition: string
  // lunghezza della label
  @Input() labelWidth: string
  // stile della filebox
  @Input() style: string
  // testo del pulsante
  @Input() buttonText: string = 'Scegli file'
  // dimensione massima file
  @Input() maxFileSize: number = ALLEGATI_DIMENSIONE_MASSIMA
  // estensioni valide
  @Input() validExtensions: string[] = ALLEGATI_ESTENSIONI_ACCETTATE
  // se true disabilita il campo
  @Input() disabled = false

  _required: boolean = false
  @Input() set required(_required: boolean) {
    this._required = _required
  }
  get required() {
    return this._required
  }

  // se true mostra i link azione
  @Input() showActionLinks: boolean = true
  // label download file
  @Input() downloadLinkLabel: string = 'Scarica file'
  // label pulisci file
  @Input() clearLinkLabel: string = 'Elimina allegati'

  // se true mostra la preview del file
  @Input() showPreview: boolean = true

  // se true mostra il nome del file
  @Input() showFileName: boolean = true

  // mostra le note
  @Input() showNotes: boolean = true

  // se true permette di uploadare più files #MULTI_UPLOAD
  @Input() multiUpload: boolean = false
  // in caso di errore upload (es. dimensione) se true butta via tutti i files uploadato
  @Input() stopUploadOnError: boolean = false
  // in modalità multi upload mostro i nomi dei files selezionati
  @Input() showFileNameMultiUpload:boolean = true

  // notifica il change del file
  @Output() onChange = new EventEmitter<any>()
  // notifica la pulizia del file
  @Output() onClear = new EventEmitter<string>()

  @ViewChild('form') form: ElementRef
  @ViewChild('fileInput') fileInput: ElementRef

  // dimenzione attuale file
  protected fileSize: number = 0
  // dimensione limite file (in stringa)
  fileSizeString: string = null
  // file selezionato
  selectedFiles: Partial<FileInfo>[] = []

  onChangeFun = (file) => {}
  onTouchedFun = () => {}

  constructor(private sanitizer: DomSanitizer, private logService: LogService) {
  }

  validate(control: AbstractControl) {
    return this.required ? Validators.required(control) : null
  }
  writeValue(value: []): void {
  }
  registerOnChange(fn: any): void {
    this.onChangeFun = fn
  }
  registerOnTouched(fn: any): void {
    // questa funzione che scatena il validate
    this.onTouchedFun = fn
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled
  }

  /**
   * Gestione di un file (sia per singolo che per multi) #MULTI_UPLOAD
   * @param file
   */
  async __manageFile(file: File) {
    // prima di mandare l'evento verifico l'estensione
    if (!this.checkValidExtension(file)) {
      return
    }

    // poi le dimensioni
    if (!this.checkFileSize(file)) {
      return
    }

    const fileInfo = await createFileInfo(file)

    return fileInfo
  }

  /**
   * Evento uno o più files selezionati
   * @param event
   */
  async onFileSelected(event: any) {
    this.clear(false, true)

    const selectedFiles: File[] = event.target.files ?? null

    if (selectedFiles?.length > 0) {
      for (let i = 0; i < selectedFiles.length; i++) {
        const file = await this.__manageFile(selectedFiles[i])

        if (file) {
          this.selectedFiles.push(file)
        } else {
          if (this.stopUploadOnError) {
            this.clear(false)
          }
        }
      }
    }

    if (this.selectedFiles.length > 0) {
      this.onChangeFun(this.selectedFiles)
      this.onChange.emit(this.selectedFiles)
      // this.onTouchedFun()
    }
  }

  ngOnInit(): void {
    this.fileSizeString = humanFileSize(this.maxFileSize)
  }

  /**
   * Restituisce il file come oggetto JS
   */
  getFile() {
    if (!this.selectedFiles.length) {
      throw new Error("Nessun file presente")
    }
    return this.selectedFiles[0].file

  }

  /**
   * Restituisce il nome del file
   */
  getFileName(file: Partial<FileInfo>) {
    if (!file) {
      return ''
    }
    return file.fileName
  }

  /**
   * Restituisce il numero dei files selezionati #MULTI_UPLOAD
   */
  getFileCount() {
    return this.selectedFiles?.length
  }


  /**
   * Restituisce il contenuto del file in formato "evoluto"
   * @param toAscii trasforma in ascii (utile per inviarlo al BE)
   * @param removeDataUriPrefix se true rimuove il prefisso data uri (es. data:image/jpeg;base64)
   */
  getFileContent(file: Partial<FileInfo>, toAscii: boolean = false, removeDataUriPrefix: boolean = false) {

    if (!file) {
      return
    }

    let fileContent = file.fileContent

    if (removeDataUriPrefix) {
      fileContent = extractFileContentWithoutDataUriPrefix(file)
    }

    if (toAscii) {
      return toBase64(fileContent)
    } else {
      return fileContent
    }
  }

  /**
   * Restituisce la dimensione del file
   */
  getFileSize() {
    return this.fileSize
  }

  addFile(fileName, fileContent, isAscii: boolean = true) {
    if (!this.selectedFiles) {
      this.selectedFiles = []
    }

    const content = isAscii ? fromBase64(fileContent) : fileContent

    const file = createFileInfoSimple(fileName, content)

    this.selectedFiles.push(file)
  }

  /**
   * ACTION LINKS
   */
  download() {

    this.selectedFiles.forEach(file => {
      const name = this.getFileName(file)
      const content = this.getFileContent(file)

      downloadFile(name, content);
    })
  }

  clear(emit: boolean = true, clearOnlyInternal = false) {
    this.selectedFiles = []

    if (!clearOnlyInternal) {
      (this.form.nativeElement as HTMLFormElement).reset()
    }

    this.onChangeFun(this.selectedFiles)

    if (emit) {
      this.onClear.emit()
    }
  }

  show() {
    return this.sanitizer.bypassSecurityTrustUrl(this.selectedFiles[0].fileContent)
  }

  /**
   * Check estensione file
   * @param file
   */
  checkValidExtension(file: File) {
    if (this.validExtensions.length > 0) {
      const ext = getFileExtension(file.name, true)
      if (!this.validExtensions.includes('.' + ext)) {
        this.logService.snack(`Estensioni consentite: ${this.validExtensions.join(" ")}`)
        return false
      }
    }

    return true
  }

  /**
   * Check dimensione massima file
   * @param file
   * @returns
   */
  checkFileSize(file: File) {
    const fileSize = file.size

    if (fileSize > this.maxFileSize) {
      this.logService.snack(`Il file ${file.name} supera la imensione massima consentita di ${this.fileSizeString}`)
      return false
    }
    return true
  }
}
