import { Portal, TemplatePortal } from "@angular/cdk/portal";
import { Component, Input, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";

/* export interface EvListItemLine {
    // dati per renderizzare la riga
    data: CommonObject
    // funzione di rendering
    render: () => void
    // eventuale classe della riga
    class?: string
} */

export interface EvListItem {
    // sezione principale (nome farmaco ad es.)
    title: string
    // linee aggiuntive
    // lines?: []
    // stile della riga
    style?: (item) => string
}

@Component({
    selector: 'ev-list',
    styleUrls: ['./ev-list.component.scss'],
    templateUrl: './ev-list.component.html'
})
export class EvListComponent implements OnInit {

    @Input() items: EvListItem[] = []
    @Input() multiple = true
    @Input() itemTemplate: TemplateRef<unknown>

    portal: Portal<any>

    constructor(private vcr: ViewContainerRef) {

    }

    ngOnInit(): void {
        console.log('itemTemplate', this.itemTemplate)
        this.portal = new TemplatePortal(this.itemTemplate, this.vcr)
    }

    applyStyle(item) {
        if (item.style) {
            return item.style(item)
        } else {
            return ''
        }
    }
}
