<div>
  <menu-portale></menu-portale>

  <section-title title="Vantaggi"></section-title>

  <div class="portale-section" style="background-color: #c0e0da;">
    <div class="block-container">
      <div>
        <img src="./assets/portal/images/3_app/hero_1.png" style="max-width: 100%;">
      </div>

      <div>
        <div>
          <h4 style="text-decoration: underline;">Eviti code in studio</h4>
          <p>
            Potrai evitare di andare in studio a ritirare ricette, portare esiti, effettuare richieste evitando anche
            costi di parcheggio, benzina, etc.
          </p>
        </div>

        <div>
          <h4 style="text-decoration: underline;">Saprai quando la tua ricetta è pronta</h4>
          <p>
            L'App installata sul tuo smartphone, con una semplice notifica, ti avvisa che il promemoria
            è pronto per essere stampato sulla stampante di casa.
          </p>
        </div>

        <div>
          <h4 style="text-decoration: underline;">La tua cartella clinica sempre disponibile</h4>
          <p>
            Anche quando sei in vacanza o in trasferta lavorativa. Oppure quando il tuo medico curante non
            è disponibile.
          </p>
        </div>

      </div>
    </div>
  </div>


  <div class="portale-section" style="background-color: #f0f1f5;">
    <div class="block-container">
      <div>
        <img src="./assets/portal/images/3_app/hero_2.jpg" style="max-width: 335px">
      </div>
      <div>
        <div>
          <h4 style="text-decoration: underline;">Memorizzazione dei tuoi referti</h4>
          <p>
            Radiografie e referti storici da condividere o meno con il tuo medico.
          </p>
        </div>

        <div>
          <h4 style="text-decoration: underline;">Monitoraggio più efficiente della TAO</h4>
          <p>
            I pazienti in TAO hanno la possibilità di visualizzare in qualsiasi momento la dose posologica del Coumadin
          </p>
        </div>

        <div>
          <h4 style="text-decoration: underline;">Aiuto alla burocrazia</h4>
          <p>
            Possibilità di avere in qualsiasi momento accesso ai tuoi certificati di malattia INPS con il relativo
            numero di protocollo e tutte le fatture inviate al MEF per il 730 precompilato al ministero per la
            dichiarazione dei redditi.
          </p>
        </div>

      </div>
    </div>
  </div>

  <app-scarica-app-section></app-scarica-app-section>

  <app-portale-layout-footer></app-portale-layout-footer>
</div>