import { AfterViewInit, Component, ElementRef, Input, OnDestroy, TemplateRef, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { DialogMaterialService } from "../services/dialog-material.service";
import { EvPageThemeComponent } from "./ev-page-theme.component";

@Component({
    selector: 'ev-home-message',
    providers: [
        { provide: MAT_DIALOG_DATA, useValue: {} },
        { provide: MatDialogRef, useValue: {} }
    ],
    styleUrls: ['../../lib/themes/generic.theme.scss'],
    template: `


<ng-template #message>
<div mat-dialog-title [innerHTML]="title"></div>
<div mat-dialog-content>
    <ng-content main>
    </ng-content>
</div>
<div mat-dialog-actions style="justify-content: end;">
    <button mat-raised-button mat-dialog-close color="primary">Chiudi</button>
</div>
</ng-template>
    `
})
export default class HomeMessageComponent extends EvPageThemeComponent implements AfterViewInit, OnDestroy {

    // id del messaggio - serve per non riproporlo all'utente
    @Input({ required: true }) id: string

    @Input() title: string = ''

    get key() {
        return `sct-message-${this.id}-shown`
    }

    @ViewChild('message') messageTpl: TemplateRef<any>

    dialogSub: Subscription

    dialogRef: MatDialogRef<any>

    constructor(private dms: DialogMaterialService, elementRef: ElementRef) {
        super(elementRef)
        this.color = '#62b1f6 !important'
    }

    ngAfterViewInit() {
        setTimeout(() => {

            if (this.mustShow()) {
                this.dialogRef = this.dms.open(this.messageTpl, {
                    panelClass: 'dialog-large'
                })

                this.dialogSub = this.dialogRef.afterClosed().subscribe(() => {
                    this.hideForFuture()
                })
            }

        }, 0)
    }

    mustShow() {
        const value = localStorage.getItem(this.key)
        if (value !== 'S') {
            return true
        }

        return false
    }

    hideForFuture() {
        localStorage.setItem(this.key, 'S')
    }

    close() {
        this.dialogRef?.close()
    }

    ngOnDestroy(): void {
        this.dialogSub?.unsubscribe()
    }
}
