<div>
  <menu-portale></menu-portale>

  <section-title title="Scopri di più"></section-title>

  <div class="portale-section">
    <div class="section-title">
      <h2>Richiedi le credenziali al tuo medico curante</h2>
    </div>

    <div class="block-container" style="flex-direction: column; align-items: unset; text-align: center;">
      <div style="margin-bottom: 20px;">
        Il tuo medico ti fornirà utente e password.
      </div>
      <div style="margin-bottom: 20px;">
        E' obbligatorio modificare la password al primo accesso.
      </div>
      <div style="margin-bottom: 20px;">
        Il medico ha facoltà di decidere se e quali dati condividere.
      </div>
      <div style="margin-bottom: 20px;">
        All'interno della tua area è presente un manuale on line in cui sono descritte tutte le funzioni in modo
        dettagliato. Ti consigliamo di leggerlo prima di iniziare a utilizzare il portale.
      </div>
      <div style="margin-bottom: 20px;">
        Puoi utilizzare un qualsiasi browser sul tuo computer ed è inoltre disponibile su Play Store un'app tramite la
        quale puoi visualizzare le notifiche sul tuo smartphone Android (l'app per iOS è in fase di sviluppo).
      </div>
    </div>
  </div>



  <div class="portale-section" style="background-color: #c0e0da;">
    <div class="section-title">
      <h2>Dopo l'accesso qui vedrai direttamente i dati clinici che il medico avrà deciso di condividere.</h2>
    </div>

    <div class="block-container">
      <img src="assets/portal/images/uploads/screenshotriassunto (1).jpg" style="width: 100%;" />
    </div>
  </div>


  <div class="portale-section">
    <div class="section-title">
      <h2>Qui potrai comunicare con il tuo medico.</h2>
    </div>

    <div class="block-container">
      <img src="assets/portal/images/uploads/com-por.png" style="width: 100%;" />
    </div>
  </div>


  <app-scarica-app-section></app-scarica-app-section>

  <app-portale-layout-footer></app-portale-layout-footer>
</div>