import { Component, Input } from "@angular/core";
import { ApplicationService } from "../../../lib/services/application.service";

@Component({
    selector: 'section-title',
    template: `
<mat-card style="margin: 0 auto; padding: 40px 20px 0 20px; max-width: 400px; background-color: #fff9; box-shadow: 5px 5px 15px 5px #666666;">
<mat-card-header>
    <mat-card-title style="color: #5eb8ac; text-align: center">{{applicationService.getApplicationName()}}</mat-card-title>
    <mat-card-subtitle style="color: #5eb8ac; text-align: center">Il portale per la comunicazione Medico - Paziente</mat-card-subtitle>
</mat-card-header>

<mat-card-content style="text-align: center; color: #5eb8ac">
    <h1>{{title}}</h1>

    <div>
        <ng-content></ng-content>
    </div>
</mat-card-content>

</mat-card>
    `
})
export class SectionTitleComponent {

    @Input() title: string

    constructor(protected applicationService: ApplicationService) {

    }

}
