import { AfterViewInit, Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpRequestResponse } from "../../../lib/models/http-request-response.model";
import { ApplicationService } from "../../../lib/services/application.service";
import { HttpRequestService } from "../../../lib/services/http-request.service";
import { LogService } from "../../../lib/services/log.service";
import { UtenteService } from "../../../lib/services/utente.service";
import { newError } from "../../../lib/utils/Utility";

@Component({
    selector: 'login-portale',
    template: `
<mat-card style="margin: 0 auto; padding: 20px; max-width: 400px; background-color: #fff9; box-shadow: 5px 5px 15px 5px #666666;">
<mat-card-header>
    <mat-card-title style="color: #5eb8ac; text-align: center">{{applicationService.getApplicationName()}} - Login</mat-card-title>
    <mat-card-subtitle style="color: #5eb8ac; text-align: center">Il portale per la comunicazione Medico - Paziente</mat-card-subtitle>
</mat-card-header>

<mat-card-content>

    <form [formGroup]="form">
    <ev-logo></ev-logo>

    <mat-form-field style="width: 100%;">
        <mat-label>Username</mat-label>
        <input matInput formControlName="username" style="text-transform: lowercase;">
    </mat-form-field>

    <mat-form-field style="width: 100%;">
        <mat-label>Password</mat-label>
        <input matInput type="password" formControlName="password" #pwd>
        <mat-icon matSuffix (click)="togglePassword(pwd)">visibility</mat-icon>
    </mat-form-field>
    </form>

</mat-card-content>
<mat-card-actions style="justify-content: center;">
    <button mat-raised-button color="primary" [disabled]="!form.valid" (click)="login()">ACCEDI</button>
</mat-card-actions>

<div style="margin-top: 20px; display: flex; flex-wrap: wrap; flex-direction: column; align-items: center">
    <a style="text-decoration: none; font-size: 12px !important" (click)="router.navigate(['/impostazioni/forgot_pin']);">Utente dimenticato?</a>

    <a style="text-decoration: none; font-size: 12px !important" (click)="router.navigate(['/impostazioni/forgot_password'])">Password dimenticata?</a>
</div>

<app-scarica-app-section [showHeader]="false"></app-scarica-app-section>

<div  style="margin-top: 40px; display: flex; justify-content: center">
<button mat-raised-button color="primary" (click)="router.navigate(['accesso_videocall'])">
    <mat-icon style="font-size: 15px">video_call</mat-icon>
    ACCEDI PER TELEVISITA
</button>

</div>
</mat-card>
    `,
    styles: [
        `
        app-scarica-app-section ::ng-deep div {
            background-color: transparent !important;
            padding: 0 !important;
        }
        `
    ]
})
export class LoginPortaleComponent implements AfterViewInit {

    form = new FormGroup({
        username: new FormControl('', [Validators.required]),
        password: new FormControl('', [Validators.required]),
    })

    get username() {
        return this.form.controls.username.value.toLowerCase()
    }
    get password() {
        return this.form.controls.password.value
    }

    isNc: boolean

    constructor(
        private utenteService: UtenteService,
        private httpRequestService: HttpRequestService,
        protected router: Router,
        protected applicationService: ApplicationService,
        private log: LogService,
        private activatedRoute: ActivatedRoute,
        private titleService: Title
    ) {
        // resetto la navigazione, altrimenti se l'utente non cambia pass al primo accesso non riesce più ad entrare
        localStorage.removeItem('saluteconte.no-nav')

        this.isNc = this.activatedRoute.snapshot.queryParams['nc'] === 'true'
        let appName

        if (this.isNc) {
            appName = 'NEXT Comunica'
        } else {
            appName = 'Salute con te'
        }

        localStorage.setItem('saluteconte.nome-applicazione', appName)

        this.titleService.setTitle(appName)
    }

    togglePassword(ctrl) {
        ctrl.type = ctrl.type === 'password' ? 'text' : 'password'
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            // prima di tutto verifico se ho un token valido nello storage del browser. in caso affermativo
            // vado al check della policy
            if (this.utenteService.isBypassLogin()) {
                this.utenteService.setBypassLogin(false)

                this.httpRequestService.get('/login/check-token').subscribe(response => {
                    if (HttpRequestResponse.isSuccessResponse(response)) {
                        this.utenteService.getDatiUtente().then(() => {
                            const username = this.utenteService.getChiave(['paziente', 'pin'])
                            if (username) {
                                this.isNormativaPrivacyAccettata(username)
                            }
                        })
                    }
                })
            }
        }, 100)


    }

    isNormativaPrivacyAccettata(username?: string) {
        this.httpRequestService.get(`/privacy-policy/user/${username || this.username}`, { isNc: this.applicationService.isNextComunica() }).subscribe((response) => {
            if (HttpRequestResponse.isSuccessResponse(response)) {

                this.utenteService.pulisciDatiUtente()
                this.utenteService.getDatiUtente().then(() => {
                    //Se la normativa privacy non è stata mai accettata reindirizzo L'utente alla pagina di accettazione
                    if (response?.data?.fattoPrimoAccesso !== "S") {
                        this.router.navigate(['/normativaPrivacy'], {})
                        return
                    }
                    // verifico se l'utente deve andare al form di pagamento
                    this.checkAbbonamentoScaduto();
                })
            } else {
                this.log.messageError("Errore", response.message, newError(response.message))
            }
        });
    }

    checkAbbonamentoScaduto() {
        if (this.utenteService.mustPay()) {
            this.router.navigate(['impostazioni', 'dati-fatturazione', 'abbonamento-scaduto'], {});
        } else {
            //Se non è scaduto l'abbonamento prima di loggarmi all'applicazione controllo che la password non sia scaduta, e quindi debba essere reimpostata
            this.checkPasswordScaduta()
        }
    }

    checkPasswordScaduta() {
        this.httpRequestService.get('/siev_fdatamod_password/password/check/expired/', {}).subscribe((response) => {
            if (HttpRequestResponse.isSuccessResponse(response)) {

                if (response.data?.isPasswordScaduta) {
                    this.router.navigate(['/cambioPassword'], { queryParams: { f: 1 }, });
                } else {
                    this.router.navigate(['/homepage'], {});
                }
            } else {
                this.log.messageError("Errore", response.message, newError(response.message))
            }
        });
    }

    login() {

        this.httpRequestService.post('/login', {
            pin: this.username,
            password: this.password,
            isNc: this.isNc
        }).subscribe((response) => {
            if (HttpRequestResponse.isSuccessResponse(response)) {

                debugger

                this.utenteService.setDatiUtente({
                    access_token: response.data.tokens.access_token,
                    refresh_token: response.data.tokens.refresh_token
                });

                // salvo il token nel localStorage
                localStorage.setItem('saluteconte.token', response.data.tokens.access_token);
                localStorage.setItem('saluteconte.refresh_token', response.data.tokens.refresh_token);

                this.utenteService.getDatiUtente().then(() => {
                    // con questa chiamata vengono progressivamente prese decisioni sul flusso d'accesso
                    this.isNormativaPrivacyAccettata();
                })

            } else {
                /* if (response?.data?.isPinEsistente && !response?.data?.isPasswordValida) {
                    //verifico se primo accesso
                    // this.verificaPrimoAccesso();
                } */

                this.log.messageWarning("Attenzione", response.message)
            }
        });
    }

    /* verificaPrimoAccesso() {
        const pUsername = this.username.toLowerCase();

        this.httpRequestService
            .post('/siev_fdatamod_password', {
                pin: pUsername
            })
            .subscribe((response) => {
                if (HttpRequestResponse.isSuccessResponse(response)) {
                    //Ottengo la data di modifica della password
                    this.dataModificaPassword = response.data?.dataModificaPassword

                    //se la password è stata modificata in passato setto le variabili sul primo accesso true sul primo accesso false se invece non è cosi faccio il contrario
                    if (this.dataModificaPassword) {
                        this.primoAccessoEseguito = true
                        this.primoAccessoNonEseguito = false
                    } else {
                        this.primoAccessoEseguito = false
                        this.primoAccessoNonEseguito = true
                    }
                } else {

                }
            });

    } */
}
