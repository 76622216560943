<!--<mat-selection-list [multiple]="multiple">
    <mat-list-option togglePosition="before" *ngFor="let item of items">
        <h3 style="text-wrap: wrap" matListItemTitle>{{terapia.farmaco}}</h3>
        <div matListItemLine style="display: flex; flex-wrap: wrap;">
            <b>Ultima presc.</b> <span>{{terapia.dataultprescr}}</span>
            <b>Data prossima presc.</b> <span>{{terapia.datanextprescr}}</span>
        </div>
        <div matListItemLine style="display: flex; flex-wrap: wrap; align-items: baseline;">
            <b>Qta:</b> <span>{{terapia.qta}}</span>
            <b>Qta richiesta:</b>
            <mat-form-field style="width: 50px;">
                <input matInput type="number">
            </mat-form-field>
        </div>
        <div matListItemLine>
            <b>Dose e posologia:</b> <span>{{terapia.posologia}}</span>
        </div>

    </mat-list-option>
</mat-selection-list>
-->

<mat-selection-list [multiple]="multiple">
    <mat-list-option togglePosition="before" *ngFor="let item of items" [style]="applyStyle(item)">
        <h3 class="wrap-text" matListItemTitle>{{item.title}}</h3>
        <!-- <div matListItemLine class="flex-item" *ngFor="let line of item.lines">
            <ng-container [ngTemplateOutlet]="itemTemplate"
                [ngTemplateOutletContext]="{item:item, lines:item.lines, line:line}">
            </ng-container>
        </div> -->
        <!-- <ng-container [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{item:item}">
        </ng-container> -->
        <ng-template [cdkPortalOutlet]="portal" let-item="item">

        </ng-template>
    </mat-list-option>
</mat-selection-list>