import { Component, Input, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
    selector: 'ev-sesso',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => EvSesso),
        }
    ],
    template: `
<mat-form-field style="width: 150px;">
    <mat-label>Sesso</mat-label>
    <mat-select [(value)]="value" (selectionChange)="changed($event.value)" [disabled]="disabled">
        <mat-option value="F">Femmina</mat-option>
        <mat-option value="M">Maschio</mat-option>
    </mat-select>
</mat-form-field>
    `
})
export class EvSesso implements ControlValueAccessor {
    @Input() disabled = false
    @Input() set value(value) {
        this._value = value
        this.changed(value)
    }
    get value() {
        return this._value
    }
    _value = 'F'

    onChange: (_: any) => void = () => { }
    onTouched: (_: any) => void = () => { }

    changed(value) {
        this.onTouched(value)
        this.onChange(value)
    }

    writeValue(value: string): void {
        this.value = value
    }
    registerOnChange(fn: any): void {
        this.onChange = fn
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled
    }

}