import { blobToBase64Promise } from "../utils/Utility"


export interface FileInfo {
    file: File
    fileName: string
    fileContent: string
    fileSize: number
}
/**
 * Crea un oggetto FileInfo
 * @param file 
 */
export async function createFileInfo(file: File): Promise<FileInfo> {
    return {
        file,
        fileName: file.name,
        fileContent: await extractFileContentAsDataURI(file),
        fileSize: file.size
    }
}

/**
 * Crea un file info "semplice" (chiamato quando il file viene caricato nel componente)
 * @param name 
 * @param content 
 * @returns 
 */
export function createFileInfoSimple(name: string, content: string): Partial<FileInfo> {
    return {
        fileName: name,
        fileContent: content
    }
}
export async function extractFileContentAsDataURI(file: File) {
    const result = await blobToBase64Promise(file, true) as string
    return result[0]
}
export function extractFileContentWithoutDataUriPrefix(file: Partial<FileInfo>) {
    return file.fileContent.split(",")[1]
}