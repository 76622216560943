import * as download from 'downloadjs';
import * as moment from 'moment';
import { ANNO_MASSIMO_PER_CALCOLO_CONFLITTI, APP_VERSION } from "../constants";
import { COMUNI } from '../models/comuni.class';
import { NAZIONI } from '../models/nazioni.class';

export interface CommonObject {
  [key: string]: any;
}

export interface ICodiceFiscaleObject {
  name: string;
  surname: string;
  gender: "F" | "M";
  day: number;
  month: number;
  year: number;
  birthplace: string;
  birthplaceProvincia: string;
  cf?: string;
}

export type MomentDate = moment.Moment

export const ISO8601_FORMAT = 'YYYY-MM-DDTHH:mmZ';

export function getAppVersion() {
  return APP_VERSION
}

export function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}

export function newError(message: string) {
  return new Error(message);
}

export function toJson(value): string {
  return JSON.stringify(value);
}
export function toJsonWithoutNull(value): string {
  return JSON.stringify(value, (k, v) => {
    if (v === null || v === undefined) {
      return ''
    }
    return v
  })
}
export function objectWithoutNull(value) {
  const o = toJsonWithoutNull(value)
  return fromJson(o)
}

export function fromJson(value: any): any {
  return JSON.parse(value);
}

export function toInt(value): Number {
  return parseInt(value);
}

export function toString(value): string {
  return String(value);
}

export function toNumber(value): number {
  let n = Number(value)

  if (isNaN(n)) {
    try {
      n = value.replace(',', '.')

      n = Number(n)
    } catch (err) {

    }
  }

  return n || 0
}

export function empty(o): boolean {
  if (o instanceof String || typeof o === 'string') {
    return o == null || o === undefined || o === '';
  } else {
    return o == null || o === undefined;
  }
}

export function isEmpty(value): boolean {
  return value === undefined ||
    value === null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().replace(' ', '').length === 0);
}

export function nvlString(str1: string, str2: string): string {
  return nvl(str1, str2);
}

export function nvl(val1: any, val2: any): any {
  return (val1) ? (val1) : (val2);
}

export function shallowProperty(key): (obj) => any {
  const result = (obj) => {
    return obj == null ? void 0 : obj[key];
  };

  return result;
}

export function deepGet(obj, path): any {
  const length = path.length;
  for (let i = 0; i < length; i++) {
    if (obj == null) {
      return void 0;
    }
    obj = obj[path[i]];
  }
  return length ? obj : void 0;
}

/**
 * Creates a function that, when passed an object, will traverse that object’s
 * properties down the given `path`, specified as an array of keys or indexes.
 * @param path - an array of keys or indexes
 */
export function property(path: string | (string | number)[]): any {
  if (!Array.isArray(path)) {
    return shallowProperty(path);
  }
  return (obj) => {
    return deepGet(obj, path);
  };
}

/**
 * Ritorna un boolean che indica se il parametro è una funzione.
 * @param value
 */
export function isFunction(value: any): boolean {
  return typeof value === 'function';
}

/**
 * Ritorna un boolean che indica se il parametro è un oggetto.
 * @param obj
 */
export function isObject(obj): boolean {
  const type = typeof obj;
  return type === 'function' || type === 'object' && !!obj;
}

/**
 * Ritorna un boolean che indica se il parametro è una stringa.
 * @param obj
 */
export function isString(obj): boolean {
  return typeof obj === 'string';
}

/**
 * Ritorna un boolean che indica se il parametro è un numero valido.
 * @param obj
 */
export function isNumber(obj): boolean {
  return Number.isFinite(obj);
}
/**
 * Restuisce true se obj è un array
 * @param obj 
 * @returns 
 */
export function isArray(obj): boolean {
  return Array.isArray(obj)
}

export function arrayBufferToBase64(buffer): string {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

export function currentMoment(giorni = 0) {
  return moment().add(giorni, 'days');
}

export function momentToString(data: moment.Moment, format: string = 'DD/MM/YYYY') {
  return data.format(format)
}

export function stringToMoment(date: string, format: string = 'DD/MM/YYYY') {
  return moment(date, format);
}

export function stringToDate(date: string, format: string = 'DD/MM/YYYY') {
  return moment(date, format).toDate();
}

export function stringWithTimeToDate(date: string, time: string, format: string = 'DD/MM/YYYY HH:mm') {
  return moment(`${date} ${time}`, format).toDate();
}

export function dayWithTimeToDate(day: string, time: string, maxYear = ANNO_MASSIMO_PER_CALCOLO_CONFLITTI) {
  return stringWithTimeToDate(`${day}/01/${maxYear}`, time)
}

export function getTimeFromDate(date: Date) {
  return moment(date).format('HH:mm')
}

export function dateToString(date: Date, format: string = 'DD/MM/YYYY') {
  return moment(date).format(format);
}

export function dateToStringWithHours(date: Date, format: string = 'DD/MM/YYYY HH:mm') {
  return moment(date).format(format);
}

export function dateToStringISO8601(date: Date) {
  const format = ISO8601_FORMAT;
  return dateToString(date, format);
}

export function stringToDateISO8601(date: string) {
  const format = ISO8601_FORMAT;
  return stringToDate(date, format);
}

/**
	 * Da una data in formato ISO emette una stringa in formato leggibile
	 * @param isoData data in formato ISO yyyy-mm-dd hh:mm:ss.xxx
	 * @returns data e ora in formato dd/mm/yyyy hh:mm
	 */
export function dataIsoToString(isoData: string): string {
		if (!isoData) return ''
		let yyyy = isoData.substring(0, 4)
		let mm = isoData.substring(5, 7)
		let dd = isoData.substring(8, 10)
		let time = isoData.substring(11, 16)
		return `${dd}/${mm}/${yyyy} ${time}`
	}

export function currentDate() {
  return moment().toDate();
}

export function dateAfter(date: Date, after: Date) {
  return moment(date).isAfter(after)
}

export function getFileExtension(filename, toLower = true) {
  const split = filename.split('.');
  if (split && split.length > 0) {
    const ext = split.pop();
    if (toLower) {
      return ext.toLowerCase()
    } else {
      return ext
    }
  }
  return null;
}

export function dataURIToBlob(dataUri) {
  let contentType = dataUri.split(',')[0].split(':')[1].split(';')[0];
  let sliceSize = 512;
  let b64Data = dataUri.split('base64,')[1];
  b64Data = b64Data.replace(/^[^,]+,/, '');
  b64Data = b64Data.replace(/\s/g, '');
  let byteCharacters = window.atob(b64Data);
  let byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    let slice = byteCharacters.slice(offset, offset + sliceSize);

    let byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    let byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
}

export function blobToBase64(blob, withHeader = true, cb) {
  let reader = new FileReader();
  reader.onloadend = function () {
    let dataUrl = reader.result;
    let base64;
    if (withHeader) {
      base64 = dataUrl;
    } else {
      // @ts-ignore
      base64 = dataUrl.split(',')[1];
    }
    cb(base64, reader);
  };
  reader.readAsDataURL(blob);
}

export function blobToBase64Promise(blob, withHeader = true) {
  return new Promise((resolve, reject) => {
    try {
      let reader = new FileReader();
      reader.onerror = (e) => {
        console.error(e)
      }
      reader.onloadend = function () {
        let dataUrl = reader.result;
        let base64;
        if (withHeader) {
          base64 = dataUrl;
        } else {
          // @ts-ignore
          base64 = dataUrl.split(',')[1];
        }
        resolve([base64, reader]);
      };
      reader.readAsDataURL(blob);
    } catch (err) {
      reject(err)
    }
  })
}

/**
 * Scarica un file creando un link HTML
 * @param filename 
 * @param data (questo può essere tanto un datauri che un percorso/url)
 *
export function downloadFileAsLink(filename, data, target = '_blank') {
  const className = 'utility_download_link'
  const a = document.createElement('a')

  a.style.display = 'none'
  a.className = className
  a.href = data
  a.download = filename
  if(target) {
    a.target = target
  }

  document.body.appendChild(a)

  a.click()

  //document.body.removeChild(a)
}*/

export function downloadFile(filename, base64Buff) {
  let contentType = base64Buff.split(',')[0].split(':')[1].split(';')[0];
  let sliceSize = 512;
  let b64Data = base64Buff.split('base64,')[1];
  b64Data = b64Data.replace(/^[^,]+,/, '');
  b64Data = b64Data.replace(/\s/g, '');
  let byteCharacters = window.atob(b64Data);
  let byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    let slice = byteCharacters.slice(offset, offset + sliceSize);

    let byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    let byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  let blob = new Blob(byteArrays, { type: contentType });

  debugger

  /**
   * DOWNLOAD MOBILE
   */

  try {
    // @ts-ignore
    if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
      try {
        // @ts-ignore
        window.ReactNativeWebView.postMessage(toJson({ blob: base64Buff, filename, contentType }), '*')
      } catch (err) {
        // risprovo senza il secondo parametro per compatibilità con alcuni dispositivi
        // @ts-ignore
        window.ReactNativeWebView.postMessage(toJson({ blob: base64Buff, filename, contentType }))
      }
    }
  } catch (err) {
    console.error("Errore invio ReactNativeWebView postMessage")
    console.error(err)
  }

  try {
    if (window.postMessage) {
      window.postMessage(toJson({ blob: base64Buff, filename, contentType }), '*')
    }
  } catch (err) {
    console.error("Errore invio postMessage")
    console.error(err)
  }

  try {
    if (window.parent && window.parent.postMessage) {
      window.parent.postMessage(toJson({ blob: base64Buff, filename, contentType }), '*')
    }
  } catch (err) {
    console.error("Errore invio parent postMessage")
    console.error(err)
  }

  download(blob, filename, contentType)

  // @ts-ignore
  // window.saveAs(blob, filename);
}

export function htmlEntities(text) {
  let desc = text ? text : '';

  desc = desc.replaceAll('&amp;amp;agrave;', 'à', desc);
  desc = desc.replaceAll('&amp;amp;egrave;', 'è', desc);
  desc = desc.replaceAll('&amp;amp;igrave;', 'ì', desc);
  desc = desc.replaceAll('&amp;amp;ograve;', 'ò', desc);
  desc = desc.replaceAll('&amp;amp;ugrave;', 'ù', desc);
  desc = desc.replaceAll('&agrave;', 'à', desc);
  desc = desc.replaceAll('&egrave;', 'è', desc);
  desc = desc.replaceAll('&igrave;', 'ì', desc);
  desc = desc.replaceAll('&ograve;', 'ò', desc);
  desc = desc.replaceAll('&ugrave;', 'ù', desc);
  desc = desc.replaceAll("&amp;", '&', desc);
  desc = desc.replaceAll("&gt;", ' >', desc);
  desc = desc.replaceAll("&lt;", ' <', desc);
  desc = desc.replaceAll("&quot;", ' "', desc);
  desc = desc.replaceAll('agrave;', 'à', desc);
  desc = desc.replaceAll('egrave;', 'è', desc);
  desc = desc.replaceAll('igrave;', 'ì', desc);
  desc = desc.replaceAll('ograve;', 'ò', desc);
  desc = desc.replaceAll('ugrave;', 'ù', desc);
  desc = desc.replaceAll("\r\n", "<br/>", desc);
  desc = desc.replaceAll("\\r", "<br/>", desc);
  desc = desc.replaceAll("\r", "<br/>", desc);
  desc = desc.replaceAll("\\n", "<br/>", desc);
  desc = desc.replaceAll("\n", "<br/>", desc);
  desc = desc.replaceAll("\t", "&nbsp;", desc);
  desc = desc.replaceAll("\\t", "&nbsp;", desc);

  return desc;
}

/**
 * Ritorna l'anno corrente
 * @return {string}
 */
export function getCurrentYear() {
  return toNumber(moment().format("YYYY"))
}

/**
 * Ritorna true se async/await è supportato
 * @returns
 */
export function checkAsyncAwait() {
  try {
    eval("(function() { async _ => _; })();");
  } catch (e) {
    return false;
  }

  return true;
}

/**
 * Restituisce true se è IE
 * @returns
 */
export function isIE() {
  let ua = navigator.userAgent;
  return ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
  // return $.browser.msie;
}

export function getUrlParameter(sParam) {
  let sPageURL = decodeURIComponent(window.location.search.substring(1)),
    sURLVariables = sPageURL.split('&'),
    sParameterName,
    i;

  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split('=');

    if (sParameterName[0] === sParam) {
      return sParameterName[1] === undefined ? true : sParameterName[1];
    }
  }
};

export function randomString(len = 10) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < len; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function randomNumer(limit: number) {
  return Math.floor(Math.random() * limit);
}

export function openWindow(url, name = "_blank") {
  window.open(url, name)
}


export function humanFileSize(bytes, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


  return bytes.toFixed(dp) + ' ' + units[u];
}

export function isLocalhost() {
  return ["localhost", "127.0.0.1", ""].includes(window.location.hostname)
}

export function qtaValida(qta: any) {
  if (!qta) {
    return false
  }
  if (toNumber(qta) <= 0) {
    return false
  }
  return true
}

export function toBase64(value: any) {
  return window.btoa(value)
}

export function fromBase64(value: any) {
  return window.atob(value)
}

export function formatNumber(n, fixed: number = 2) {
  return toNumber(n).toFixed(2).toLocaleString()
}

export interface NumberPatters {
  // ignorato
  decimalCharacter?: string
  // ignorato
  digitGroupSeparator?: string
  maximumValue?: number
  minimumValue?: number
  decimalPlaces?: number
  // ignorato
  decimalPlacesRawValue?: number
}
export function formatNumberPattern(n, pattern: NumberPatters) {

  const _hasCommaOrPoint = n => n.toString().indexOf('.') > -1 || n.toString().indexOf(',') > -1

  let n2 = Number(n.toString().replaceAll(',', '.'))

  if (isNaN(Number(n2))) {
    throw new Error(`Valore non valido ${n}`)
  }

  let num = toNumber(n2)

  if (pattern.maximumValue && num > toNumber(pattern.maximumValue)) {
    throw new Error(`Il valore inserito supera il valore massimo`)
  }
  if (pattern.minimumValue && num < toNumber(pattern.minimumValue)) {
    throw new Error(`Il valore inserito è inferiore al valore minimo`)
  }

  // ignoro decimalCharacter, digitGroupSeparator e decimalPlacesRawValue

  // nelle impostazioni tradizionali i decimalPlaces sono sempre specificati a 0
  if (pattern.decimalPlaces !== undefined && toNumber(pattern.decimalPlaces) === 0) {
    if (_hasCommaOrPoint(num)) {
      throw new Error(`Il valore inserito non può contenere decimali`)
    } else {
      return num.toFixed(0)
    }
  } else {

    // se non sono specificati allora sono sempre 2 i decimali
    return num.toFixed(2)
  }
}

export function deepClone(object) {
  return fromJson(toJson(object))
}

export function filtraMedici(m) {
  return m.tipo_medico === 'Medico di medicina generale' || m.tipo_medico === 'Medico specialista'
}

export function getRandomColors() {
  // Genera un valore casuale tra 0 e 255 per il rosso, verde e blu
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);

  // Imposta un valore fisso per l'opacità (ad esempio 0.8)
  const opacity = 0.8;

  // Restituisci una stringa nel formato 'rgba(r, g, b, opacity)' per il background
  const backgroundColor = `rgba(${r}, ${g}, ${b}, ${opacity})`;

  // Scegli un colore per il testo basato sulla luminosità
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  const textColor = luminance > 0.5 ? 'black' : 'white';

  return { backgroundColor, textColor };
}

export function dateInBetween(date: Date, start: Date, end: Date, includeExtremes = true) {
  const dateM = moment(date)

  return dateM.isBetween(start, end, null, includeExtremes ? "[]" : null)
}
export function datesInBetween(date1: Date, date2: Date, start: Date, end: Date) {
  return dateInBetween(date1, start, end) || dateInBetween(date2, start, end)
}
export function dayInBetween(day: number, start: Date, end: Date) {
  return start.getDay() <= day && end.getDay() >= day
}
export function timeInBetween(time: string, start: Date, end: Date, includeExtremes = true) {
  const timeStart = getTimeFromDate(start)
  const timeEnd = getTimeFromDate(end)

  return stringToMoment(time, 'HH:mm').isBetween(
    moment(timeStart, 'HH:mm'),
    moment(timeEnd, 'HH:mm'),
    null,
    includeExtremes ? "[]" : null
  )
}
export function timesInBetween(time1: string, time2: string, start: Date, end: Date, full = true) {
  let result = timeInBetween(time1, start, end) || timeInBetween(time2, start, end)
  if (result || !full) {
    return true
  }

  const time1Date = stringToMoment(time1, 'HH:mm').toDate()
  const time2Date = stringToMoment(time2, 'HH:mm').toDate()
  const timeStart = getTimeFromDate(start)
  const timeEnd = getTimeFromDate(end)

  return timeInBetween(timeStart, time1Date, time2Date) || timeInBetween(timeEnd, time1Date, time2Date)

}

export interface IDatiCodfis {
  cognome: string
  nome: string
  natoil: string
  genere: string
  cittanas: string
  provnas: string
  statonas: string
}
export class CodFis {

  static verificaEtaMinore14(codiceFiscale) {
    // Assumiamo che il codice fiscale sia in formato standard italiano
    // Estrazione della data di nascita dal codice fiscale
    const anno = parseInt(codiceFiscale.substring(6, 8), 10);
    const mese = codiceFiscale.charAt(8);
    const giorno = parseInt(codiceFiscale.substring(9, 11), 10) % 40; // gestione genere femminile

    // Conversione dell'anno e del mese
    const annoCompleto = anno < (new Date().getFullYear() % 100) ? 2000 + anno : 1900 + anno;
    const meseCompleto = "ABCDEHLMPRST".indexOf(mese) + 1; // mappatura mesi

    // Creazione dell'oggetto Date per la data di nascita
    const dataNascita = new Date(annoCompleto, meseCompleto - 1, giorno);

    // Calcolo dell'età
    const oggi = new Date();
    let eta = oggi.getFullYear() - dataNascita.getFullYear();
    const m = oggi.getMonth() - dataNascita.getMonth();

    if (m < 0 || (m === 0 && oggi.getDate() < dataNascita.getDate())) {
        eta--;
    }

    // Verifica se l'età è inferiore a 14 anni
    return eta < 14;
  }

  static comuneGetNomeAndProv(citta, prov) {

    return COMUNI.filter(com => {
      const { comune, prov } = com
      return comune.toUpperCase() === citta.toUpperCase() &&
        prov.toUpperCase() === prov.toUpperCase()
    })
  }

  static nazioneGetNome(stato) {

    return NAZIONI.filter(nazione => {
      return nazione.descrizione.toUpperCase().indexOf(stato.toUpperCase()) > -1
    })
  }

  static controlloCodfis = (strVal) => {
    let pesi: number = 0
    let arrPesi = [1, 0, 5, 7, 9, 13, 15, 17, 19, 21, 1, 0, 5, 7, 9, 13, 15, 17, 19, 21,
      2, 4, 18, 20, 11, 3, 6, 8, 12, 14, 16, 10, 22, 25, 24, 23]

    if (strVal && strVal.length >= 15) {
      for (let i = 0; i < 15; i++) {
        let code = strVal.charCodeAt(i)
        if (((i + 1) % 2) === 0) {
          if ((code >= 0x30) && (code <= 0x39))
            pesi += (code - 0x30)
          else if ((code >= 0x41) && (code <= 0x5A))
            pesi += (code - 0x41)
        }
        else {
          if ((code >= 0x30) && (code <= 0x39))
            pesi += arrPesi[code - 0x30];
          else if ((code >= 0x41) && (code <= 0x5A))
            pesi += arrPesi[code - 0x41 + 10]
        }
      }
      return String.fromCharCode(0x41 + (pesi % 26))
    }
    return false
  }

  static checkData(dataModel: IDatiCodfis) {

    // formare i caratteri da cognome e da nome
    let extractChars = (bCognome) => {
      let strVal = ''
      if (bCognome) {
        let errcog = 'Cognome non valido';
        if (dataModel.cognome) {
          strVal = dataModel.cognome.toUpperCase();
          if (strVal.length < 2) {
            throw errcog;
          }
        }
        else {
          throw errcog;
        }
      }
      else {
        let errnom = 'Nome non valido';
        if (dataModel.nome) {
          strVal = dataModel.nome.toUpperCase();
          if (strVal.length < 2) {
            throw errnom;
          }
        }
        else {
          throw errnom;
        }
      }

      let retValue = '';
      let strVoc = 'AEIOUÀÁÄÂĂÈÉËÌÍÏÎÒÓÖÙÚÜ'; // gestire le eventuali accentate, scartare apostrofo !!!
      let aAcc = 'ÀÁÄÂĂ';
      let eAcc = 'ÈÉË';
      let iAcc = 'ÌÍÏÎ';
      let oAcc = 'ÒÓÖ';
      let uAcc = 'ÙÚÜ';
      let ch = '';

      for (let i = 0; i <= strVal.length - 1; i++) {
        ch = strVal.charAt(i);
        if ((strVoc.indexOf(ch) < 0) && (ch !== " ") && (ch !== "'"))
          retValue += ch;
      }

      if (retValue.length >= 3) {
        if (!bCognome) {
          if (retValue.length > 3) { // se il nome ha più di 3 consonanti si prendono la 1,3 e 4
            retValue = retValue.charAt(0) + retValue.charAt(2) + retValue.charAt(3);
          }
        }
      }
      else {
        for (let i = 0; i < strVal.length; i++) {
          ch = strVal.charAt(i)
          if ((strVoc.indexOf(ch) >= 0) && (ch !== " ") && (ch !== "'")) {
            let voc = ch;
            // gestire le eventuali vocali accentate
            if (aAcc.indexOf(ch) >= 0)
              voc = 'A';
            if (eAcc.indexOf(ch) >= 0)
              voc = 'E';
            if (iAcc.indexOf(ch) >= 0)
              voc = 'I';
            if (oAcc.indexOf(ch) >= 0)
              voc = 'O';
            if (uAcc.indexOf(ch) >= 0)
              voc = 'U';

            retValue += voc;
          }
        }

        while (retValue.length < 3) {
          retValue += 'X';
        }
      }
      return retValue.substring(0, 3);
    }

    // formare la sequenza della data di nascita
    let getCharsFromDate = () => {
      let retVal = '';
      let errore = 'Data di nascita non valida';
      if (!dataModel.natoil) {
        throw errore;
      }
      if (dataModel.natoil.length < 10) {
        throw errore;
      }
      if (!dataModel.genere) {
        throw 'Genere non valorizzato'
      }
      let datanascita = new Date(dataModel.natoil)
      let isFemale = dataModel.genere === 'F'
      let year = datanascita.getFullYear().toString()
      let month = datanascita.getMonth() + 1
      retVal = year.substring(2, 4)
      switch (month) {
        case 1:
          retVal += 'A';
          break;
        case 2:
          retVal += 'B';
          break;
        case 3:
          retVal += 'C';
          break;
        case 4:
          retVal += 'D';
          break;
        case 5:
          retVal += 'E';
          break;
        case 6:
          retVal += 'H';
          break;
        case 7:
          retVal += 'L';
          break;
        case 8:
          retVal += 'M';
          break;
        case 9:
          retVal += 'P';
          break;
        case 10:
          retVal += 'R';
          break;
        case 11:
          retVal += 'S';
          break;
        case 12:
          retVal += 'T';
          break;
      }
      if (!isFemale) {
        var strTemp = datanascita.getDate().toString();
        if (strTemp.length <= 1) {
          retVal += "0";
          retVal += strTemp;
        }
        else
          retVal += strTemp;
      }
      else {
        retVal += (datanascita.getDate() + 40).toString();
      }
      return retVal;
    }

    // formare la sequenza finale in base al comune o stato estero di nascita
    let addCharsComune = (codfis) => {
      let errstat = 'Stato di nascita non valido'
      let statonas = ''
      if (dataModel.statonas) {
        statonas = dataModel.statonas.toUpperCase().trim()
      }
      let errcit = 'Luogo di nascita non valido'
      let cittanas = ''
      if (dataModel.cittanas) {
        cittanas = dataModel.cittanas.toUpperCase().trim()
      }
      let provnas = ''
      if (dataModel.provnas) {
        provnas = dataModel.provnas.toUpperCase().trim()
      }
      let codcatasto = ''
      if ((statonas === '') || (statonas === 'ITALIA')) {
        if (cittanas.length < 2) throw errcit
        // 
        let result = this.comuneGetNomeAndProv(cittanas, provnas)
        let rec: any = result[0];
        if (!rec) throw 'Comune ' + cittanas + ' non trovato'
        if (rec.comune.toUpperCase() === cittanas.toUpperCase()) {
          if (rec.codcatasto) {
            codcatasto = rec.codcatasto.toUpperCase().trim();
            codfis += codcatasto;
            codfis += this.controlloCodfis(codfis);
          }
        }
      }
      else {
        if (statonas.length < 2) throw errstat;
        //
        let result = this.nazioneGetNome(statonas)
        let rec = result[0];
        if (!rec) throw 'Stato ' + statonas + ' non trovato';
        if (rec.descrizione.toUpperCase() === statonas.toUpperCase()) {
          if (rec.codcatasto) {
            codcatasto = rec.codcatasto.toUpperCase().trim();
            codfis += codcatasto;
            codfis += this.controlloCodfis(codfis);
          }
        }
      }
      return codfis
    }
    // INIZIO MAIN 
    // console.log(extractChars(true));
    // console.log(extractChars(false));
    // console.log(getCharsComune());
    // console.log(getCharsFromDate());
    let codcog = extractChars(true);
    let codnom = extractChars(false);
    let codnas = getCharsFromDate();
    // per avere il codice catastale di comune o nazione necessita una chiamata al server
    // la chiamata è asincrona e quando ha successo emette il valore di codfis
    let result = addCharsComune(codcog + codnom + codnas)
    return result
  }
}

export function stringContainsAll(s1: string, s2: string) {
  return s1.trim().toLowerCase().indexOf(s2.trim().toLowerCase()) > -1
}
