import { Component } from '@angular/core';
import { APP_VERSION } from '../../../lib/constants';

@Component({
  selector: 'app-portale-layout-header',
  templateUrl: './portale-layout-header.component.html',
  styleUrls: ['./portale-layout-header.component.sass'],
})
export class PortaleLayoutHeaderComponent {

  APP_VERSION = APP_VERSION

  constructor() { }

}
