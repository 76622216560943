<ng-template [ngIf]="(this.utenteService.isLoggato | async)  && !(this.toolbarService.hideToolbar | async)"
    [ngIfElse]="nonLoggato">
    <mat-toolbar color="primary" class="sticky-toolbar">
        <button mat-icon-button (click)="snav.toggle()"><mat-icon style="color: black;">menu</mat-icon></button>
        <h1 *ngIf="showAppName">{{applicationService.getApplicationName(true)}}</h1>

        <ev-logo *ngIf="showAppLogo" [big]="false"></ev-logo>

        <div class="toolbar-spacer"></div>

        <ng-template [ngIf]="currentToolbarAction">
            <button *ngIf="currentToolbarAction.type === 'button'" (click)="toolbarActionClick(currentToolbarAction)"
                mat-raised-button>
                {{currentToolbarAction.text}}
            </button>
        </ng-template>

        <ng-template [ngIf]="toolbarService._showLogout | async">
            <a mat-raised-button color="primary" routerLink="/logout">
                <mat-icon>logout</mat-icon>
                Cambia utente
            </a>
        </ng-template>
    </mat-toolbar>

    <!-- margine necessario per lo sticky header -->
    <mat-sidenav-container class="sidenav">
        <mat-sidenav mode="over" #snav>
            <mat-nav-list (click)="snav.close()">

                <ev-user-profile></ev-user-profile>

                <mat-divider></mat-divider>
                <a mat-list-item routerLink="/homepage">
                    <div class="list-item-with-icon">
                        <mat-icon>home</mat-icon>
                        Home
                    </div>
                </a>
                <mat-divider></mat-divider>
                <a mat-list-item routerLink="/infoMedico">
                    <div class="list-item-with-icon">
                        <mat-icon>schedule</mat-icon>
                        Orari ambulatorio
                    </div>
                </a>

                <a mat-list-item routerLink="/bachecaMedico">
                    <div class="list-item-with-icon">
                        <mat-icon>content_paste</mat-icon>
                        Bacheca medico
                    </div>
                </a>

                <a mat-list-item routerLink="/news">
                    <div class="list-item-with-icon">
                        <mat-icon>priority_high</mat-icon>
                        Novita!
                    </div>
                </a>

                <a mat-list-item (click)="documentsService.downloadPrivacyDoc()">
                    <div class="list-item-with-icon">
                        <mat-icon>lock</mat-icon>
                        Documento privacy
                    </div>
                </a>

                <a mat-list-item routerLink="/farmacie">
                    <div class="list-item-with-icon">
                        <mat-icon>medication</mat-icon>
                        Email Farmacie
                    </div>
                </a>

                <a mat-list-item routerLink="/impostazioni/dati-fatturazione/abbonamento-in-scadenza"
                    *ngIf="isPagamentiVisibile">
                    <div class="list-item-with-icon">
                        <mat-icon>card_membership</mat-icon>
                        Pagamenti
                    </div>
                </a>

                <mat-divider></mat-divider>
                <a mat-list-item (click)="documentsService.downloadFAQDoc()">
                    <div class="list-item-with-icon">
                        <mat-icon>quiz</mat-icon>
                        FAQ
                    </div>
                </a>

                <a mat-list-item (click)="documentsService.downloadManualDoc()">
                    <div class="list-item-with-icon">
                        <mat-icon>description</mat-icon>
                        Manuale
                    </div>
                </a>

                <a mat-list-item [href]="URL_YT_NEXTCOMUNICA" target="_blank">
                    <div class="list-item-with-icon">
                        <mat-icon>videocam</mat-icon>
                        Canale YouTube
                    </div>
                </a>

                <a mat-list-item (click)="applicationService.richiediAssistenzaInterno()">
                    <div class="list-item-with-icon">
                        <mat-icon>contact_support</mat-icon>
                        Richiedi assistenza
                    </div>
                </a>

                <a mat-list-item routerLink="/cambioPassword">
                    <div class="list-item-with-icon">
                        <mat-icon>sync_lock</mat-icon>
                        Cambio password / email
                    </div>
                </a>

                <a mat-list-item routerLink="/impostazioni">
                    <div class="list-item-with-icon">
                        <mat-icon>settings</mat-icon>
                        Impostazioni
                    </div>
                </a>

                <mat-divider></mat-divider>
                <a mat-list-item routerLink="/logout">
                    <div class="list-item-with-icon">
                        <mat-icon>logout</mat-icon>
                        Esci
                    </div>
                </a>
                <mat-divider></mat-divider>
                <mat-list-item (click)="router.navigate(['debug'])">
                    <span style="font-size: 12px;">
                        © 2023 {{applicationService.getApplicationName()}}
                    </span>
                </mat-list-item>
                <a mat-list-item href="javascript:void(0)" target="_blank" [routerLink]="['/iprivacy']">
                    <span style="font-size: 12px;">
                        Trattamento dei dati <br /> personali
                    </span>
                </a>
                <a mat-list-item *ngIf="!applicationService.isNextComunica()" href="javascript:void(0)" target="_blank"
                    [routerLink]="['/isicurezza']">
                    <span style="font-size: 12px;">
                        Termini e condizioni
                    </span>
                </a>
                <a mat-list-item *ngIf="applicationService.isNextComunica()" href="assets/documents/termini-nc.pdf"
                    target="_blank">
                    <span style="font-size: 12px;">
                        Termini e condizioni
                    </span>
                </a>

                <a mat-list-item href="javascript:void(0)" target="_blank" [routerLink]="['/icookie']">
                    <span style="font-size: 12px;">
                        Informativa cookie
                    </span>
                </a>

            </mat-nav-list>
        </mat-sidenav>
        <mat-sidenav-content class="sidenav-content">
            <div class="main-container">
                <router-outlet></router-outlet>
            </div>
            <ev-button-toggle [buttons]="buttons" *ngIf="displayService.isSmall | async"></ev-button-toggle>
        </mat-sidenav-content>
    </mat-sidenav-container>
</ng-template>

<ng-template #nonLoggato>
    <router-outlet></router-outlet>
</ng-template>

<ev-home-message id="new-version-material" *ngIf="utenteService.isLoggato | async" title="Nuova versione!" #homeMessage>
    <!-- <app-news [hideCategorie]="true" [onlyLast]="true"></app-news> -->
    <div>
        Caro/a {{utenteService.getChiave(['paziente', 'nome'])}},<br><br>
        <!-- necessario per non far scrollare la pagina (bug di material?) -->
        <a href=""></a>

        ti informiamo che, raccogliendo i consigli di tanti utilizzatori come te, abbiamo realizzato una nuova versione
        del portale in cui abbiamo migliorato l'accesso, l'utilizzo dei tuoi dati sanitari e la comunicazione con il tuo
        medico di famiglia!<br><br>

        Abbiamo inoltre migliorato e ottimizzato la veste grafica e abbiamo realizzato un' app completa (sia per Android
        che per iOS) con le medesime funzionalità del portale. L'app è scaricabile dalla pagina di login<br><br>

        Se all'inizio avrai difficoltà ad eseguire le consuete operazioni non ti preoccupare! Siamo a tua completa
        disposizione per aiutarti! Basta far clic
        <a (click)="showManualAndCloseMessage(homeMessage)">QUI</a> o andare sulla voce “Contattaci” e potrai
        descriverci qual è il tuo problema.<br><br>

        Inoltre abbiamo creato il nostro canale <a href="https://www.youtube.com/@NextComunica"
            target="_blank">YOUTUBE</a> dove potrai trovare video esplicativi, che man mano stiamo aggiungendo, e il
        <a (click)="documentsService.downloadManualDoc()">manuale d'uso</a><br><br>

        Sperando di averti aiutato nella gestione della comunicazione con il tuo medico di famiglia ti salutiamo con la
        promessa che sarà sempre nostra premura il miglioramento e l'arricchimento dei servizi offerti<br><br>

        Puoi rileggere questo messaggio nella sezione Notivà che trovi nel menù laterale.<br><br>

        Staff Portale Paziente
    </div>
</ev-home-message>