import { Directive, ElementRef, HostListener, Input } from "@angular/core";

@Directive({
    selector: 'img[imgFallback]'
})
export class ImgFallbackDirective{
    @Input() imgFallback: string

    constructor(private elementRef: ElementRef) {

    }

    @HostListener('error')
    loadFallback() {
        const element = this.elementRef.nativeElement as HTMLImageElement

        element.src = this.imgFallback
    }
}
