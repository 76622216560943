import { Component, OnInit } from '@angular/core';
import { NC_WEBSITE, SCT_WEBSITE } from '../../../lib/constants';
import { ApplicationService } from '../../../lib/services/application.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {

  applicationName: string
  isNextComunica: boolean
  urlWebsite: string

  constructor(applicationService: ApplicationService) {
    this.applicationName = applicationService.getApplicationName()
    applicationService.isNextComunica() ? this.urlWebsite = NC_WEBSITE : SCT_WEBSITE
  }

  ngOnInit(): void {
  }

}
