


<ev-cookie-banner></ev-cookie-banner>

<div class="header_style">
  <div class="container">
    <div class="row" class="no-margin-bottom">
      <div class="col-xs-2"></div>
      <div class="col-xs-12 col-sm-8">
        <!--<nav role="navigation" class="navbar navbar-white navbar-embossed navbar-lg pix_nav_1">-->
        <nav role="navigation">
          <div class="navbar-header">
            <div style="width: 100%;" data-target="#navbar-collapse-02" data-toggle="collapse" class="navbar-toggle" type="button">
              <a style="float: left;font-weight: bold;color: #5eb8ac;font-size: 22px;"
              [routerLink]="['/assistenza/contact/1']"
              >Contattaci
            </a>
              <span class="sr-only">Toggle navigation</span>
            </div>

          </div>
          <div id="navbar-collapse-02" class="collapse navbar-collapse">
            <div style="display: flex; justify-content: center;">
              <ul class="nav navbar-nav" style="border-radius:10px; margin-top: 15px;">
                <li class="active propClone"
                  style="background-color: white; opacity: 0.6; border-radius:10px 0px 0px 10px;">
                  <a routerLink="/"  
                    style="color: rgba(94,184,172,255); font-weight: bold; text-transform: none; font-size: 15px;">Home</a>
                </li>
                <li class="propClone" style="background-color: white; opacity: 0.6;">
                  <a href="javascript:void(0)"   [routerLink]="['/vantaggi']"
                    style="color: rgba(94,184,172,255); font-weight: bold; text-transform: none; font-size: 15px;"
                    src="./assets/portal/images/uploads/logo-saluteconte.png">Vantaggi</a>
                </li>
                <li class="propClone" style="background-color: white; opacity: 0.6;">
                  <a href="javascript:void(0)"   [routerLink]="['/scopri']"
                    style="color: rgba(94,184,172,255); font-weight: bold; text-transform: none; font-size: 15px;"
                    src="./assets/portal/images/uploads/logo-saluteconte.png">Scopri di più</a>
                </li>
                <!--<li class="propClone" style="background-color: white; opacity: 0.6;">
                  <a href="javascript:void(0)" [routerLink]="['/prezzo']"
                    style="color: rgba(94,184,172,255); font-weight: bold; text-transform: none; font-size: 15px;"
                    src="./assets/portal/images/uploads/logo-saluteconte.png">Prezzo</a>
                </li>-->
                <li class="propClone" style="background-color: white; opacity: 0.6;">
                  <a href="javascript:void(0)"   [routerLink]="['/diconoDiNoi']"
                    style="color: rgba(94,184,172,255); font-weight: bold; text-transform: none; font-size: 15px;"
                    src="./assets/portal/images/uploads/logo-saluteconte.png">Dicono di noi</a>
                </li>
                <li class="propClone" style="background-color: white; opacity: 0.6;">
                  <a routerLink="/manuale" 
                    style="color: rgba(94,184,172,255); font-weight: bold; text-transform: none; font-size: 15px;">
                    Manuale
                  </a>
                </li>
                <!-- <li class="propClone" style="background-color: white; opacity: 0.6;">
                  <a href="javascript:void(0)"   [routerLink]="['/news']"
                    style="color: rgba(94,184,172,255); font-weight: bold; text-transform: none; font-size: 15px;"
                    src="./assets/portal/images/uploads/logo-saluteconte.png">News</a>
                </li> -->
                <li class="propClone"
                  style="background-color: white; opacity: 0.6; border-radius: 0 10px 10px 0 !important; ">
                  <a href="javascript:void(0)"   [routerLink]="['/assistenza/contact/1']"
                    style="color: rgba(94,184,172,255); font-weight: bold; text-transform: none; font-size: 15px;">
                    Contattaci</a>
                </li>
              </ul>
            </div>
          </div>
          <!-- /.navbar-collapse -->
          <!-- /.container -->
        </nav>
      </div>
    </div>
  </div>
  <!-- container -->
</div>
