<div style="padding: 20px;">

  <h1>Contattaci</h1>

  <div [formGroup]="form">

    <mat-form-field style="width: 100%;">
      <mat-label>Nome</mat-label>
      <input matInput formControlName="subject">
    </mat-form-field>

    <mat-form-field style="width: 100%;">
      <mat-label>E-mail</mat-label>
      <input matInput formControlName="from" type="email">
    </mat-form-field>

    <mat-form-field style="width: 100%;">
      <mat-label>Testo</mat-label>
      <textarea matInput formControlName="message"></textarea>
      <mat-hint>Lunghezza massima: 350 caratteri</mat-hint>
    </mat-form-field>

    <div>
      <mat-checkbox formControlName="accepted">
        Ho letto l'informativa ed acconsento all'utilizzo dei miei dati personali per i fini specificati nella stessa
      </mat-checkbox>
    </div>

    <div>
      <button mat-raised-button color="primary" [disabled]="!form.valid" (click)="invia()">Invia dati</button>
    </div>

  </div>

</div>
