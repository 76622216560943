import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FullCalendarComponent } from "@fullcalendar/angular";
import { CalendarOptions, DateSelectArg } from '@fullcalendar/core';
import { EventImpl } from "@fullcalendar/core/internal";
import itLocale from '@fullcalendar/core/locales/it';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import timegridPlugin from '@fullcalendar/timegrid';
import * as moment from 'moment';
import { GIORNI_KV } from "../../constants";
import { CommonObject, currentDate, dateAfter, dateToString, datesInBetween, dayInBetween, dayWithTimeToDate, isString, stringWithTimeToDate, timesInBetween, toInt } from "../../utils/Utility";
// sotto-interfaccia dell'evento non ricorrente di fullcalendar
export interface PeriodEvent {
    title: string
    start: Date
    end: Date
    extendedProps?: CommonObject
    backgroundColor?: string
    textColor?: string
}

// sotto-interfaccia dell'evento ricorrente di fullcalendar
export interface RecurrentEvent {
    title: string
    daysOfWeek: number[]
    startTime: string
    endTime: string
    extendedProps?: CommonObject
    backgroundColor?: string
    textColor?: string
    startRecur?: Date
}

export function createPeriodEvent(args: PeriodEvent) {
    return args
}
export function createRecurrentEvent(args: RecurrentEvent) {
    return args
}

export function generateDaysForRecurrency(startDay, endDay) {
    let sd = isString(startDay) ? toInt(startDay) : startDay
    let ed = isString(endDay) ? toInt(endDay) : endDay

    // se sd o ed sono 0 li sostituisco con 7 per poter fare i calcoli
    sd = sd === 0 ? 7 : sd;
    ed = ed === 0 ? 7 : ed;

    let days =
        Array.from({ length: ed - sd + 1 }, (_, i) => sd + i)
            // devo sostuire i 7 con i 0, perchè fullcalendar per la domenica ha 0
            .map(d => d === 7 ? 0 : d)

    return days
}


/**
 * Verifica dei conflitti
 * @param events 
 * @param newEvent 
 * @returns 
 */
export function checkConflicts(events, newEvent, filterOldPeriods = true) {
    const filteredEvents = filterOldPeriods ? events.filter(event => {
        // restituisco solo gli eventi che hanno una valenza con la data odierna
        if (event.start && event.end) {
            if (dateAfter(event.start, currentDate()) || dateAfter(event.end, currentDate())) {
                return event
            }
        }
        // i periodici li restituisco sempre
        else {
            return event
        }
    }) : events

    for (let e of filteredEvents) {
        // caso 1: e è un periodo, newEvent è un periodo
        // caso teoricamente impossibile, in quanto il calendario lo previene
        if (!e.daysOfWeek && !newEvent.periodico) {
            const newStart = stringWithTimeToDate(newEvent.data_inizio, newEvent.orario_inizio)
            const newEnd = stringWithTimeToDate(newEvent.data_fine, newEvent.orario_fine)
            // (e.start e e.end hanno l'orario nella data)
            if (datesInBetween(newStart, newEnd, e.start, e.end)) {
                return { conflict: 'Periodo', start: dateToString(e.start), end: dateToString(e.end) }
            }
        }
        // caso 2: e è un periodo, newEvent è un ricorrente
        if (!e.daysOfWeek && newEvent.periodico) {
            // devo avere il range di tutti i giorni selezionati
            const newDays = generateDaysForRecurrency(newEvent.giorno_inizio, newEvent.giorno_fine)
            for (let i = 0; i < newDays.length; i++) {
                // confronto il giorno
                if (dayInBetween(newDays[i], e.start, e.end)) {
                    // poi l'orario (e.start e e.end hanno l'orario nella data)
                    if (timesInBetween(newEvent.orario_inizio, newEvent.orario_fine, e.start, e.end)) {
                        return { conflict: 'Periodo', start: dateToString(e.start), end: dateToString(e.end) }
                    }
                }
            }
        }
        // caso 3: e è un ricorrente, newEvent è un periodo
        if (e.daysOfWeek && !newEvent.periodico) {
            const newStart = stringWithTimeToDate(newEvent.data_inizio, newEvent.orario_inizio)
            const newEnd = stringWithTimeToDate(newEvent.data_fine, newEvent.orario_fine)
            // devo avere il range di tutti i giorni selezionati
            const eDays = e.daysOfWeek
            for (let i = 0; i < eDays.length; i++) {
                // confronto il giorno
                if (dayInBetween(eDays[i], newStart, newEnd)) {
                    // poi l'orario
                    if (timesInBetween(e.startTime, e.endTime, newStart, newEnd)) {
                        return {
                            conflict: 'Ricorrenza',
                            dayOfWeek: GIORNI_KV[eDays[i]]
                        }
                    }
                }
            }
        }
        // caso 4: e è un ricorrente, newEvent è un ricorrente
        if (e.daysOfWeek && newEvent.periodico) {
            // devo avere il range di tutti i giorni selezionati
            const newDays = generateDaysForRecurrency(newEvent.giorno_inizio, newEvent.giorno_fine)
            const eDays = e.daysOfWeek

            for (let i = 0; i < newDays.length; i++) {
                const newStart = dayWithTimeToDate(newDays[i], newEvent.orario_inizio)
                const newEnd = dayWithTimeToDate(newDays[i], newEvent.orario_fine)
                // verifico il giorno
                if (eDays.indexOf(newDays[i]) > -1) {
                    // poi l'orario
                    if (timesInBetween(e.startTime, e.endTime, newStart, newEnd)) {
                        return {
                            conflict: 'Ricorrenza',
                            dayOfWeek: GIORNI_KV[eDays[i]]
                        }
                    }
                }
            }
        }
    }

    return null
}

@Component({
    selector: 'ev-scheduler',
    templateUrl: 'ev-scheduler.component.html',
    styleUrls: ['ev-scheduler.component.scss']
})
export class EvSchedulerComponent implements OnInit {

    _events = []
    @Input() set events(e) {
        this._events = e

        // ogli volta che ricarico gli eventi pulisco la selezione
        if (this.autoClearSelection) {
            this.unselect()
        }
    }
    get events() {
        return this._events
    }

    @Input() height = 0

    // quando si seleziona tutto il giorno lui arriva sempre fino alle 00:00 del giorno
    // successivo. se manageAllDay è true si ferma alle 23:59 del giorno precedente
    // @Input() manageAllDay = true

    // se true toglie un minuto dalla fine evento quando si prende un appuntamento. 
    // in questo modo si permette di prendere un appuntamento "esteticamente attaccato"
    @Input() removeOneMinuteFromEnd = false

    _allowOverlap = true
    @Input() set allowOverlap(o) {
        this._allowOverlap = o

        this.reloadConfig()
    }
    get allowOverlap() {
        return this._allowOverlap
    }

    // callback che se restituisce true disabilita un evento
    _disableEvent = event => true
    @Input() set disableEvent(de) {
        this._disableEvent = de

        this.reloadConfig()
    }
    get disableEvent() {
        return this._disableEvent
    }

    // configurazione custom per il calendario
    _customConfig: CalendarOptions = {}
    @Input() set customConfig(cc: CalendarOptions) {
        this._customConfig = cc

        this.reloadConfig()
    }
    get customConfig() {
        return this._customConfig
    }

    // blocca le date passate
    _blockPastDates = false
    @Input() set blockPastDates(b) {
        this._blockPastDates = b

        this.reloadConfig()
    }
    get blockPastDates() {
        return this._blockPastDates
    }

    // se true il componente si occupa di pulire l'area di selezione degli eventi
    @Input() autoClearSelection = true

    @Output() selectedEvent = new EventEmitter<DateSelectArg>()
    @Output() dateClickEvent = new EventEmitter<DateClickArg>()
    @Output() eventClickEvent = new EventEmitter<EventImpl>()

    @ViewChild('calendar') calendar: FullCalendarComponent

    calendarOptions: CalendarOptions

    ngOnInit() {
        this.reloadConfig()
    }

    reloadConfig() {
        this.calendarOptions =
        {
            ...{
                initialView: 'timeGridWeek',
                locale: itLocale,
                eventOverlap: this.allowOverlap,
                slotEventOverlap: this.allowOverlap,
                selectOverlap: this.allowOverlap,
                height: this.height > 0 ? this.height : 'auto',
                progressiveEventRendering: true,

                headerToolbar: {
                    left: 'prev,next today',
                    center: 'title',
                    right: 'timeGridWeek,dayGridMonth,listYear',
                },

                plugins: [dayGridPlugin, interactionPlugin, timegridPlugin, listPlugin],
                selectable: true,

                // chiamato quando si seleziona uno slot libero, sia col click che
                // selezionando un periodo
                select: args => {
                    if (this.removeOneMinuteFromEnd) {
                        args.end = moment(args.end).subtract(1, 'minute').toDate()
                    }

                    this.selectedEvent.emit(args)
                },

                selectConstraint: {
                    start: this._blockPastDates ? currentDate() : null
                },
                // viene gestita manualmente quando si aggiornano gli eventi
                unselectAuto: false,

                // chiamato quando si fa click su un evento
                eventClick: args => {
                    this.eventClickEvent.emit(args.event)
                },
                // chiamato quando si fa click su una data
                dateClick: args => {
                    this.dateClickEvent.emit(args)
                },
                eventClassNames: props => {
                    return this.disableEvent(props.event.extendedProps) ? 'event-disabled' : ''
                }
            },
            ...this.customConfig
        }
    }

    /**
     * Rimuove la selezione nel caso sia stata forzata
     */
    unselect() {
        if (this.calendar) {
            const api = this.calendar.getApi()
            api.unselect()
        }
    }
}