import { Component, Input } from "@angular/core";

@Component({
    selector: 'ev-progress',
    template: `
<mat-progress-bar [mode]="mode" [value]="value"></mat-progress-bar>
    `
})
export class EvProgressComponent {
    @Input() mode: "determinate" | "indeterminate" = "indeterminate"
    @Input() value = 0
}