<div>

    <button (click)="install()">Installa</button>

    <button (click)="enableNotifications()">Abilita notifiche</button>


    <button (click)="disableNotifications()">Disabilita notifiche</button>


</div>