import { Component, Input } from "@angular/core";
import { ApplicationService } from "../../../lib/services/application.service";
import { PwaInstallationService } from "../../../lib/sw/pwa-installation.service";

@Component({
  selector: 'app-scarica-app-section',
  styleUrls: ['../../../lib/themes/portale.theme.scss'],
  template: `
      <div class="portale-section" style="background-color: #c0e0da;">
        <div class="section-title" *ngIf="showHeader">
            <h2>Scarica l'app {{applicationService.getApplicationName()}}!</h2>
            <h4>Disponibile per iOS e Android.</h4>
        </div>
        <div class="block-container">
            <div class="block" (click)="pis.askToInstall()" style="cursor: pointer;">
              <i class="fa-brands fa-apple"></i>
            </div>
            <div class="block" (click)="pis.askToInstall()" style="cursor: pointer;">
              <i class="fa-brands fa-android"></i>
            </div>
        </div>
      </div>
    `,
    styles: [
      `
      i.fa-brands {
        font-family: "Font Awesome 6 Brands" !important;
        font-size: 40px;
      }
      `
    ]
})
export class ScaricaAppComponent {

  @Input() showHeader = true

  applicationName: string

  constructor(
    protected applicationService: ApplicationService,
    protected pis: PwaInstallationService
  ) {
  }

}
