import { SelectionModel } from "@angular/cdk/collections";
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";

export interface EvTableColumn {
    type: 'text' | 'action'
    columnDef: string
    header: string
    cell?: (element) => any
    icon?: string
    action?: (col, row, table) => any,
    hidden?: (row) => any
}

@Component({
    selector: 'ev-table',
    templateUrl: './ev-table.component.html'
})
export class EvTable implements OnInit {

    // table data
    @Input() data: any
    // colonne
    @Input() columns: EvTableColumn[] = []
    @Input() displayedColumns: string[] = []

    // selection
    @Input() selection: boolean = true
    @Input() multiSelection: boolean = true
    @Input() preSelectedRecords: [] = []
    selectionModel: SelectionModel<any>

    // pagination
    @Input() pagination: boolean = true
    @Input() pageSize: number = 10
    @Input() pageSizeOptions = [2, 5, 10, 25]
    @Input() paginationTotal = 0
    @ViewChild('paginator') paginator: MatPaginator

    ngOnInit(): void {
        if (this.displayedColumns.length === 0) {
            this.displayedColumns = this.columns.map(c => c.columnDef)
        }
        if (this.selection) {
            this.selectionModel = new SelectionModel(this.multiSelection, this.preSelectedRecords)
            if (this.displayedColumns.indexOf('select') === -1) {
                this.displayedColumns.unshift('select')
            }
        }
    }

    setData(data: any) {
        this.data = data
    }



    cellClick(column, row) {
        if (column.action) {
            column.action(column, row, this)
        }
    }

    cellIsHidden(column, row) {
        if (!column.hidden) {
            return false
        }
        return !column.hidden(row)
    }





    getSelectedRows() {
        if (!this.selection) {
            throw new Error("Selection is not enabled for this table")
        }
        return this.selectionModel.selected
    }





    pageChanged() {
        if (!this.pagination) {
            throw new Error("Pagination is not enabled for this table")
        }
        return this.paginator.page
    }
    goToFirstPage() {
        if (!this.pagination) {
            throw new Error("Pagination is not enabled for this table")
        }
        this.paginator.firstPage()
    }
    setPaginationTotal(total: number) {
        if (!this.pagination) {
            throw new Error("Pagination is not enabled for this table")
        }
        this.paginationTotal = total
    }
    getPageIndex() {
        if (!this.pagination) {
            throw new Error("Pagination is not enabled for this table")
        }
        return this.paginator.pageIndex + 1
    }
    getPageSize() {
        if (!this.pagination) {
            throw new Error("Pagination is not enabled for this table")
        }
        return this.paginator.pageSize
    }
}