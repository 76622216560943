import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { empty } from '../utils/Utility';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  applicationData: any;
  private applicationName

  constructor(private router: Router) {

  }

  /**
   * Restituisce un attributo dell'applicazione
   * @param key attributo utente
   */
  get(key: string) {
    // const userData = AppStorage.getStorage().get(LS_APP_DATA);
    if (!empty(this.applicationData)) {
      return this.applicationData[key];
    }
  }

  /**
   * Restituisce i dati applicazione
   */
  getApplicationData() {
    return this.applicationData;
  }

  getApplicationName(shortName = false) {
    if (!this.applicationName) {
      this.applicationName = localStorage.getItem('saluteconte.nome-applicazione')
    }

    if (shortName) {
      if (this.isNextComunica()) {
        return 'NC'
      } else {
        return 'ScT'
      }
    }

    return this.applicationName
  }

  isNextComunica() {
    return this.getApplicationName() === 'NEXT Comunica'
  }

  isSaluteConTe() {
    return this.getApplicationName() === 'Salute con te'
  }

  richiediAssistenzaInterno() {
    this.router.navigate(['impostazioni', 'contattaci'])
    // this.dialogMaterialService.open(ContattaciInternoComponent)
  }
}
