import { Component, ViewChild } from '@angular/core';
import { EvCaptchaComponent } from '../../../../lib/components/captcha/captcha.component';
import { EvForm } from '../../../../lib/models/ev-form.class';
import { HttpRequestResponse } from '../../../../lib/models/http-request-response.model';
import { HttpRequestService } from '../../../../lib/services/http-request.service';


@Component({
  selector: 'app-forgot-pin',
  templateUrl: './forgot-pin.component.html',
  styleUrls: ['./forgot-pin.component.scss']
})
export class ForgotPinComponent extends EvForm {

  @ViewChild(EvCaptchaComponent) captcha: EvCaptchaComponent
  codiceFiscale: string;
  errorMessage: string;
  successMessage: string;

  get valid() {
    if (this.codiceFiscale && this.captcha) {
      return this.codiceFiscale.length > 0 && this.captcha.valid
    } else {
      return false
    }
  }

  constructor(
    private httpRequestService: HttpRequestService
  ) {
    super()
  }

  onSubmitForgotPin() {
    this.httpRequestService
      .post('/recupera-username', {
        codiceFiscale: this.codiceFiscale.toUpperCase(),
      })
      .subscribe((response) => {
        if (HttpRequestResponse.isSuccessResponse(response)) {
          this.errorMessage = null;
          this.successMessage = response.message;
        } else {
          this.errorMessage = response.message;
          this.successMessage = null;
        }
      });
  }
}
