import { Injectable } from "@angular/core";
import { ReplaySubject, lastValueFrom } from "rxjs";
import { isEmpty } from "../utils/Utility";
import { HttpRequestService } from "./http-request.service";

@Injectable({
    providedIn: 'root'
})
export class FunzionalitaService {

    funzionalita: string[] = []

    funzionalitaLoaded$ = new ReplaySubject<boolean>(1)

    constructor(
        private http: HttpRequestService
    ) {

    }

    mustLoad() {
        return !this.funzionalita || this.funzionalita.length === 0
    }

    async loadFunzionalita() {
        const response = await lastValueFrom(this.getFunzionalita())

        this.funzionalita = response.data.funzionalita

        this.funzionalitaLoaded$.next(true)
    }

    getFunzionalita() {
        return this.http.get('/siev_fget_funzionalita', {}, {}, false, false)
    }

    pulisciFunzionalita() {
        this.funzionalita = null;
    }

    isFunzionalitaAbilitata(fun: string) {
        return !isEmpty(this.funzionalita.find(f => f.toLowerCase() === fun.toLowerCase()))
    }




    isComunicazioniAbil() {
        return this.isFunzionalitaAbilitata('Comunicazioni')
    }

    isFarmaciAbil() {
        return this.isFunzionalitaAbilitata('Sincronizzazione Farmaci')
    }

    isParametriAbil() {
        return this.isFunzionalitaAbilitata('Parametri')
    }

    isEsenzioniAbil() {
        return this.isFunzionalitaAbilitata('Sincronizzazione Esenzioni')
    }

    isAllergieAbil() {
        return this.isFunzionalitaAbilitata('Sincronizzazione Allergie')
    }

    isScadenzeAbil() {
        return this.isFunzionalitaAbilitata('Sincronizzazione Scadenze')
    }

    isEsamiAbil() {
        return this.isFunzionalitaAbilitata('Sincronizzazione esami di laboratorio')
    }

    isPresidiAbil() {
        return this.isFunzionalitaAbilitata('Sincronizzazione Presidi')
    }

    isProcDiaAbil() {
        return this.isFunzionalitaAbilitata('Sincronizzazione Procedure Diagnostiche')
    }

    isProcTerAbil() {
        return this.isFunzionalitaAbilitata('Sincronizzazione Procedure Terapeutiche')
    }

    isAppuntamentiAbil() {
        return this.isFunzionalitaAbilitata('Appuntamenti')
    }

    isCertInpsAbil() {
        return true
    }

    isPiaTerAbil() {
        return true
    }

    isTAOAbil() {
        return true
    }
}