import { Component, Input } from "@angular/core";

@Component({
    selector: 'ev-page-info',
    styleUrls: ['../../lib/themes/generic.theme.scss'],
    template: `
<mat-accordion>
    <mat-expansion-panel [expanded]="expanded">
        <mat-expansion-panel-header>
            <mat-panel-title>{{title}}</mat-panel-title>
        </mat-expansion-panel-header>
        <p [innerHTML]="text"></p>
        <ng-content></ng-content>
    </mat-expansion-panel>
</mat-accordion>
`
})
export class EvPageInfoComponent {
    @Input({ required: true }) title: string
    @Input({ required: true }) text: string
    @Input() expanded = false

}