import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ReplaySubject, lastValueFrom } from 'rxjs';
import { FirebaseNotificationsService } from '../sw/firebase-notifications.service';
import { currentMoment, empty, nvlString, property, stringToMoment } from '../utils/Utility';
import { FunzionalitaService } from './funzionalita.service';
import { HttpRequestService } from './http-request.service';
import { SpinnerService } from './spinner.service';



export interface DatiUtente {
  access_token: string,
  name: string,
  refresh_token: string,
  next: {
    schema: string
    db: string
  },
  paziente: {
    pin: string,
    codfis: string,
    nomemed: string,
    cognomemed: string,
    medico: string,
    nome: string,
    nomeSingolo: string,
    codfismed: string,
    medcf: string,
    cognome: string,
    mail: string,
    natoil: string,
    luogo_di_nascita: string,
    foto: string,
    email: string,
    residenza: string,
    domicilio: string,
    recapiti_telefonici: string,
    famiglia: string,
    cittadinanza: string,
    okCookie: string,
    attivo: number,
    apertura_form_pagamento: string,
    apertura_messaggio_famiglia: string,
    invioNotificaMail: string,
    indirizzoConsegnaRicette: null,
    data_scadenza_abbonamento: string,
    abbonamento_in_scadenza: string,
    costo_abbonamento: number,
    img: string,
    isPagante: boolean,
    abbonamento_attivo_medico: boolean
  },
  medico: {
    bacheca: string,
    comunicazione_urgente: string,
    info: string
  }
}

@Injectable({
  providedIn: 'root'
})
export class UtenteService {
  datiUtente: Partial<DatiUtente>;

  isLoggato = new ReplaySubject<boolean>(1)
  datiUtentePresenti = new ReplaySubject<boolean>(1)

  constructor(
    private httpRequestService: HttpRequestService,
    private router: Router,
    private notificationsService: FirebaseNotificationsService,
    private spinner: SpinnerService,
    private funzionalitaService: FunzionalitaService
  ) { }

  pulisciDatiUtente() {
    this.datiUtente.paziente = null;
  }

  pulisciDatiMedico() {
    this.datiUtente.medico = null;
  }

  setDatiUtente(datiUtente: Partial<DatiUtente>) {
    this.datiUtente = datiUtente;
  }

  async getDatiUtente() {
    let response;

    this.spinner.show()

    if (localStorage.getItem('saluteconte.token') == null) {
      this.router.navigate(['/'], {});
    }

    if (!this.datiUtente) {
      this.datiUtente = {
        access_token: localStorage.getItem('saluteconte.token'),
        refresh_token: localStorage.getItem('saluteconte.refresh_token')
      }
    }

    if (!this.datiUtente?.paziente) {
      response = await lastValueFrom(this.getDatiPaziente());

      if (response.status === 'error') {
        console.error(response)
        return
      }

      this.datiUtente.paziente = response.data.paziente;
      this.datiUtente.next = response.data.next;
      this.datiUtente.paziente.medico = nvlString(this.datiUtente.paziente.nomemed, '') + ' ' + nvlString(this.datiUtente.paziente.cognomemed, '');
      this.datiUtente.paziente.nomeSingolo = this.datiUtente.paziente.nome;
      this.datiUtente.paziente.nome = this.datiUtente.paziente.nome + ' ' + this.datiUtente.paziente.cognome;
    }
    if (!this.datiUtente?.medico) {
      // l'utente potrebbe non essere associato al medico
      if (!empty(response?.data?.paziente?.codfismed)) {
        response = await lastValueFrom(this.getDatiMedico(response.data?.paziente?.codfismed))
        //@ts-ignore
        this.datiUtente.medico = response.data;
      }

      this.verificaAutorizzazioni(true)
    }

    if (this.funzionalitaService.mustLoad()) {
      await this.funzionalitaService.loadFunzionalita()
    }

    this.spinner.hide()

    this.datiUtentePresenti.next(true)

    return this.datiUtente;

  }

  setChiave(chiave: string, valore: any) {
    this.datiUtente[chiave] = valore;
  }

  getChiave(chiave: string | string[]): any {
    const getProperty = property(chiave);

    return getProperty(this.datiUtente);
  }

  setToken(token: string) {
    this.datiUtente.access_token = token;
  }

  getToken() {
    return this?.datiUtente?.access_token;
  }

  aggiungiDatiUtente(newData) {
    this.datiUtente = Object.assign({}, this.datiUtente, newData);
  }

  setDatiPaziente(paziente) {
    this.datiUtente = Object.assign({ paziente }, this.datiUtente);
  }

  setDatiMedico(data) {
    this.datiUtente = Object.assign({ data }, this.datiUtente);
  }

  //ottengo i dati del paziente e li setto nella variabile datiUtente
  getDatiPaziente() {
    return this.httpRequestService.get('/siev_fgetdatipaz', {}, {}, false, false)
  }

  //ottengo i dati del medico
  getDatiMedico(codiceFiscaleMedico) {
    return this.httpRequestService.get('/medico/info/' + codiceFiscaleMedico, {}, {}, false, false);
  }

  isNextComunicaAttivo() {
    if (!this.datiUtente) {
      return false
    }
    return this.getChiave(['paziente', 'abbonamento_attivo_medico']) === 'S';
  }

  isAbbonamentoScaduto() {
    if (!this.datiUtente.paziente) {
      return
    }
    const dataScadenzaAbbonamento = stringToMoment(this.datiUtente.paziente.data_scadenza_abbonamento);
    const dataOggi = currentMoment();

    //Se la data di oggi è oltre la data di scadenza abbonamento reinoltro l'utente alla pagina di rinnovo abbonamento
    return dataOggi.diff(dataScadenzaAbbonamento) > 0
  }

  isAbbonamentoInScadenza() {
    return this.datiUtente.paziente.abbonamento_in_scadenza === 'S'
  }

  isAbbonamentoScadutoOInScadenza() {
    return this.isAbbonamentoScaduto() || this.isAbbonamentoInScadenza()
  }

  isUtentePagante() {
    if (!this.datiUtente) {
      return false
    }
    return this.datiUtente.paziente.isPagante
  }

  /**
   * Se true può accedere alla piattaforma
   */
  mustPay() {
    if (!this.datiUtente) {
      throw new Error("Dati utente non presenti")
    }

    // L'utente deve pagare se:
    // 1. ha il medico
    // 2. l'abbonamento è scaduto
    // 3. non ha next comunica attivo
    return this.datiUtente.paziente.codfismed &&
      this.isAbbonamentoScaduto() &&
      !this.isNextComunicaAttivo()
  }

  /**
   * Se true mostra la famiglia
   * @returns
   */
  isFamigliaVisibile() {
    return this.verificaAutorizzazioni()
  }
  /**
   * Se true mostra i pagamenti
   * @returns
   */
  isPagamentiVisibile() {
    return this.verificaAutorizzazioni()
  }

  verificaAutorizzazioni(printLog = false) {
    // ATTENZIONE: modifica temporanea. quando ci sarà sct pro il controllo si invertirà
    // con quello sotto isUtentePagante()
    if (!this.datiUtente.paziente.codfismed) {
      if (printLog) {
        console.log('> utente senza medico, non vede i pagamenti')
      }
      return false
    }

    if (this.isNextComunicaAttivo()) {
      if (printLog) {
        console.log('> utente next-comunica, il medico ha attivato next-comunica')
      }
      return false
    }

    if (this.isUtentePagante()) {
      if (printLog) {
        console.log('> utente pro, pagante')
      }
      return true
    }

    if (printLog) {
      console.log('> utente in prova, il medico NON ha attivato next comunica')
    }
    return true
  }

  isBypassLogin() {
    return localStorage.getItem('saluteconte.prova-login-silente') === '1'

  }
  setBypassLogin(bypass: boolean) {
    localStorage.setItem('saluteconte.prova-login-silente', bypass ? '1' : '0')
  }

  isWithoutDoctor() {
    return empty(this.datiUtente?.paziente?.codfismed)
  }

  isUtenteNext() {
    return !empty(this.datiUtente.next.db)
  }

  async logout() {

    const _logout = () => {
      this.datiUtente = {};

      localStorage.removeItem('saluteconte.token');
      localStorage.removeItem('saluteconte.refresh_token');

      this.funzionalitaService.pulisciFunzionalita()

      return this.isLoggato.next(false)
    }

    try {
      await this.notificationsService.clearSubscription()
    } catch (err) {
      console.error("ERROR LOGOUT: ", err)
    } finally {
      return await _logout()
    }
  }
}


