import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Injectable, OnDestroy } from "@angular/core";
import { ReplaySubject, Subscription } from "rxjs";

export const DISPLAY_NAME_MAP = new Map([
  [Breakpoints.XSmall, 'XSmall'],
  [Breakpoints.Small, 'Small'],
  [Breakpoints.Medium, 'Medium'],
  [Breakpoints.Large, 'Large'],
  [Breakpoints.XLarge, 'XLarge'],
]);

export const DISPLAYS = [
  Breakpoints.XSmall,
  Breakpoints.Small,
  Breakpoints.Medium,
  Breakpoints.Large,
  Breakpoints.XLarge,
]
export const SMALL_DISPLAY = ['XSmall', 'Small']

@Injectable({
  providedIn: 'root'
})
export class DisplayService implements OnDestroy {

  private currentScreenSize
  private boSub: Subscription
  // poichè il valore viene emesso nel costruttore nessuno arriva in tempo per prenderlo, perciò uso un
  // replay subject per ritornare l'ultimo valore emesso
  public isSmall = new ReplaySubject<boolean>(1)

  constructor(private bo: BreakpointObserver) {
    this.boSub =
      this.bo.observe(DISPLAYS)
        .subscribe(result => {
          for (const query of Object.keys(result.breakpoints)) {
            if (result.breakpoints[query]) {
              this.currentScreenSize = DISPLAY_NAME_MAP.get(query) ?? 'Unknown';
              this.isSmall.next(SMALL_DISPLAY.indexOf(this.currentScreenSize) > -1)
            }
          }
        })
  }

  ngOnDestroy(): void {
    this.boSub?.unsubscribe()
  }
}
