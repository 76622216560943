import { Component, Input } from "@angular/core";
import { URL_YT_NEXTCOMUNICA } from "../../../lib/constants";
import { DisplayService } from "../../../lib/services/display.service";

@Component({
    selector: 'menu-portale-items',
    template: `
<ng-template [ngIf]="isMatMenu" [ngIfElse]="simpleMenu">
    <a mat-menu-item routerLink="/">Home</a>
    <a mat-menu-item routerLink="/vantaggi">Vantaggi</a>
    <a mat-menu-item routerLink="/scopri">Scopri di più</a>
    <a mat-menu-item [href]="URL_YT_NEXTCOMUNICA" target="_blank">YouTube</a>
    <a mat-menu-item routerLink="/diconoDiNoi">Dicono di noi</a>
    <!-- <a mat-menu-item routerLink="manuale">Manuale</a> -->
    <a mat-menu-item [routerLink]="['/', 'assistenza','contact','1']">Contattaci</a>
</ng-template>
<ng-template #simpleMenu>
    <a routerLink="/">Home</a>
    <a routerLink="/vantaggi">Vantaggi</a>
    <a routerLink="/scopri">Scopri di più</a>
    <a [href]="URL_YT_NEXTCOMUNICA" target="_blank">YouTube</a>
    <a routerLink="/diconoDiNoi">Dicono di noi</a>
    <!-- <a routerLink="manuale">Manuale</a> -->
    <a [routerLink]="['/', 'assistenza','contact','1']">Contattaci</a>
</ng-template>
    `,
    styles: [
        `
        a {
            padding: 10px 20px;
            font-weight: bold;
            color: #5eb8ac !important;
            text-decoration: none;
        }`,
        `
        a span {
            color: #5eb8ac !important;
        }
        `,
        `
        a:hover {
            background-color: #eee;
        }
        `
    ]
})
export class MenuPortaleItemsComponent {
    @Input() isMatMenu: boolean = false

    URL_YT_NEXTCOMUNICA = URL_YT_NEXTCOMUNICA
}

@Component({
    selector: 'menu-portale',
    template: `
<div id="menu-big" *ngIf="!(displayService.isSmall | async)">
    <menu-portale-items></menu-portale-items>
</div>
<div id="menu-small" *ngIf="displayService.isSmall | async">
    <a mat-menu-item [routerLink]="['/', 'assistenza','contact','1']">Contattaci</a>

    <mat-icon [matMenuTriggerFor]="beforeMenu">menu</mat-icon>
    <mat-menu #beforeMenu="matMenu" xPosition="before">
        <menu-portale-items [isMatMenu]="true"></menu-portale-items>
    </mat-menu>
</div>
    `,
    styles: [
        `
        #menu-big {
            display: flex;
            flex-wrap: wrap;
            justify-content:center;
            padding: 10px 0;
            background-color: #e3e3e3;
        }`,
        `
        #menu-small {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        `
    ]
})
export class MenuPortaleComponent {

    constructor(protected displayService: DisplayService) {

    }

}
