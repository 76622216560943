<div>
  <menu-portale></menu-portale>

  <section-title title="Scopri di più">
    <div class="alert alert-danger" role="alert" *ngIf="this.errorMessage as value">
      {{ this.errorMessage }}
    </div>

    <form [formGroup]="form">
      <div>
        <ev-codice-fiscale formControlName="codiceFiscale"></ev-codice-fiscale>
      </div>

      <div>
        <mat-checkbox formControlName="accepted">
          Dichiaro di aver preso visione dell' <a target="_blank" style="color:blue" href="javascript:void(0)"
            [routerLink]="['/iprivacy']">"Informativa
            sul trattamento dei dati personali</a> e dei <a target="_blank" style="color:blue" href="javascript:void(0)"
            [routerLink]="['/isicurezza']">"Termini
            e condizioni</a> e, per poter effettuare l'accesso al portale
          www.saluteconte.it, autorizzo la titolare ‘EvoluS Srl' al trattamento dei
          miei dati personali
        </mat-checkbox>
      </div>

      <div>
        <button mat-raised-button color="primary" (click)="verificaVideoCall()"
          [disabled]="!form.valid">Verifica</button>
      </div>
    </form>
  </section-title>

  <div class="portale-section" style="flex-direction: row;">
    <div>
      <img src="assets/portal/images/uploads/facile-semplice.png" style="width: 300px; height: 250px" />
    </div>
    <div>
      <h2>Comunicare con il tuo medico non è mai stato così semplice, veloce e sicuro!</h2>

      <h3>Richiedi le credenziali tuo medico curante!</h3>
    </div>
  </div>

  <div class="portale-section" style="background-color: #c0e0da;">
    <div class="section-title">
      <h2>Attraverso il tuo account potrai:</h2>
    </div>
    <div class="block-container">
      <div class="block">
        <img src="assets/portal/images/uploads/drugs.png" />
        <p>Richiedere farmaci</p>
      </div>

      <div class="block">
        <img src="assets/portal/images/uploads/stethoscope.png" />
        <p>Richiedere prestazioni</p>
      </div>

      <div class="block">
        <img src="assets/portal/images/uploads/x-rays.png" />
        <p>Inviare immagini</p>
      </div>

      <div class="block">
        <img src="./assets/portal/images/uploads/stats.png" />
        <p>Prendere un appuntamento</p>
      </div>
    </div>
  </div>



  <div class="portale-section">
    <div class="section-title">
      <h2>Oltre all'interazione con il Medico potrai anche:</h2>
    </div>
    <div class="block-container">
      <div class="block">
        <img src="assets/portal/images/uploads/printer.png" />
        <p>Stampare il promemoria per le ricette</p>
      </div>

      <div class="block">
        <img src="assets/portal/images/uploads/report-2.png" />
        <p>Visualizzare e stampare la posologia della TAO</p>
      </div>

      <div class="block">
        <img src="assets/portal/images/uploads/report.png" />
        <p>Visualizzare e stampare i protocolli INPS relativi ai certificati di malattia</p>
      </div>

      <div class="block">
        <img src="./assets/portal/images/uploads/hospital.png" />
        <p>Avere sempre a disposizione i tuoi dati clinici, certificati del tuo medico, da consultare oppure da
          comunicare in caso di necessità</p>
      </div>
    </div>
  </div>

  <app-scarica-app-section></app-scarica-app-section>

  <app-portale-layout-footer></app-portale-layout-footer>
</div>