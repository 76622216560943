import { AfterViewInit, Component, Input, ViewChild } from "@angular/core";
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from "@angular/forms";
import { MatAutocomplete } from "@angular/material/autocomplete";
import { Subject } from "rxjs";
import { isNumber, isString } from "../utils/Utility";


@Component({
    selector: 'ev-autocomplete',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: EvAutocompleteComponent,
            multi: true
        }
    ],
    styles: [`

mat-form-field {
    width: 100%;
}

    `],
    template: `

    <mat-form-field appearance="fill">
        <mat-label>{{label}}</mat-label>
        <input matInput [formControl]="autcompleteControl" [matAutocomplete]="auto" [disabled]="disabled" (blur)="onTouched()"  [required]="required">
        <mat-autocomplete #auto="matAutocomplete" #autocompleteComponent>
        <mat-option *ngFor="let option of filteredOptions" [value]="option">
            {{displayOption(option)}}
        </mat-option>
        </mat-autocomplete>
    </mat-form-field>

    `
})
export class EvAutocompleteComponent implements AfterViewInit, ControlValueAccessor {

    @Input() label: string
    @Input() emitEmptyValue: boolean = false
    @Input() required = false
    @Input() initAsTouched = false


    @Input() set options(_options: EvAutocompleteOption[]) {
        this._options = _options

        this.optionsReady.next(_options)
    }
    get options() {
        return this._options
    }
    _options = []



    @Input() set value(_value) {
        this.optionsReady.subscribe(() => this.autcompleteControl.setValue(_value))
    }
    get value() {
        return this.autcompleteControl.value
    }
    autcompleteControl = new FormControl()





    @Input() disabled: boolean = false

    @ViewChild('autocompleteComponent') autocompleteComponent: MatAutocomplete

    filteredOptions: EvAutocompleteOption[]

    onChange = (value) => { }
    onTouched = () => { }

    // utilizzato per bloccare la selezione del record scelto nel caso le opzioni non siano pronte
    optionsReady = new Subject()

    ngAfterViewInit() {
        this.filter()
        this.display()

        if (this.initAsTouched) {
            setTimeout(() => {
                this.autcompleteControl.markAsTouched()
            }, 0)
        }
    }




    writeValue(_value: any) {
        this.value = _value
    }
    registerOnChange(fn: any): void {
        this.onChange = fn
    }
    registerOnTouched(fn: any): void {
        this.onTouched = () => {
            if (this.emitEmptyValue || this.value) {
                fn()
            }
        }
    }
    setDisabledState?(isDisabled: boolean) {
        this.disabled = isDisabled
    }





    filter() {
        this.autcompleteControl.valueChanges.subscribe(value => {
            let filteredOptions = []

            // se value è stringa allora ho un input dell'utente
            // se è un oggetto ho una selezione
            if (isString(value)) {
                // cerco prima per chiave e poi per valore
                filteredOptions = this.options.filter(m => m.value === value)
                if (filteredOptions.length === 0) {
                    filteredOptions = this.options.filter(m => m.text.toLowerCase().indexOf(value.toLowerCase()) > -1)
                }
            }

            this.filteredOptions = filteredOptions
        })
    }

    display() {
        this.autocompleteComponent.displayWith = option => {
            if (option && this.options) {
                let displayValue = ''

                if (option.text) {
                    displayValue = option.text
                    this.onChange(option.value)
                } else if (isNumber(option)) {
                    const options = this.options.filter(m => m.value === option)
                    displayValue = options[0].text
                    this.onChange(option.value)
                } else {
                    const options = this.options.filter(m => m.value === option)
                    displayValue = options[0].text
                    // qui non viene scatenato l'onChange, in quanto non si ha operato una scelta
                }

                return displayValue
            } else {
                return ''
            }
        }
    }

    displayOption(option) {
        return option.text
    }

}

export type EvAutocompleteOption = { text: string, value: any }
