import { ElementRef, Injectable } from "@angular/core";
import { THEME_COLOR_VAR, THEME_TEXT_COLOR_VAR } from "../constants";
import { CommonObject } from "../utils/Utility";

@Injectable({
    providedIn: 'root'
})
export class ThemeService {

    replacePropertyValues(property: string, color: string, textColor: string) {
        // @ts-ignore
        return property.replaceAll(THEME_COLOR_VAR, color)
            .replaceAll(THEME_TEXT_COLOR_VAR, textColor)
    }

    applyTheme(elementRef: ElementRef, properties: CommonObject, color: string, textColor: string) {
        Object.keys(properties).forEach(property => {
            const value = this.replacePropertyValues(properties[property], color, textColor)

            this.applyProperty(elementRef, property, value)
        })
    }

    applyProperty(elementRef: ElementRef, property: string, value: string) {
        elementRef.nativeElement.style.setProperty(property, value)
    }

}
