import { Injectable, OnDestroy } from "@angular/core";
import { ReplaySubject, Subscription } from "rxjs";
import { CommonObject } from "../utils/Utility";
import { DisplayService } from "./display.service";

export interface ToolbarAction {
    type: 'button'
    text: string,
    action: (...params) => void,
    data?: CommonObject
}

@Injectable({
    providedIn: 'root'
})
export class ToolbarService implements OnDestroy {

    isSmallSub: Subscription
    isSmall: boolean
    hideToolbar = new ReplaySubject<boolean>(1)
    showAction = new ReplaySubject<ToolbarAction>(1)
    _showLogout = new ReplaySubject<boolean>(1)

    constructor(private ds: DisplayService) {
        this.hideToolbar.next(false)
        this._showLogout.next(false)

        this.isSmallSub = this.ds.isSmall.subscribe(value => this.isSmall = value)
    }

    hide() {
        this.hideToolbar.next(true)
    }
    show() {
        this.hideToolbar.next(false)
    }

    hideLogout() {
        this._showLogout.next(false)
    }
    showLogout() {
        this._showLogout.next(true)
    }

    nextAction(action: ToolbarAction, onlyOnMobile: boolean = true) {
        if (onlyOnMobile) {
            if (this.isSmall) {
                this.showAction.next(action)
            }
        } else {
            this.showAction.next(action)
        }
    }
    clearAction() {
        this.showAction.next(null)
    }

    ngOnDestroy(): void {
        this.isSmallSub?.unsubscribe()
    }
}
