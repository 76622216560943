import { AfterViewInit, Component, Input, OnDestroy, OnInit, forwardRef } from "@angular/core";
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from "@angular/forms";

@Component({
    selector: 'ev-codice-fiscale',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => EvCodiceFiscaleComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: EvCodiceFiscaleComponent,
            multi: true
        }
    ],
    template: `
<mat-form-field>
    <mat-label>Codice fiscale</mat-label>
    <input matInput #cf [formControl]="control" [disabled]="disabled" (blur)="onTouched(cf.value)">
</mat-form-field>
    `
})
export class EvCodiceFiscaleComponent implements ControlValueAccessor, OnInit, AfterViewInit, OnDestroy {

    @Input() required = true
    @Input() initAsTouched = false

    disabled = false

    subscription = null

    get value() {
        return this.control.value
    }
    get valid() {
        return this.control.valid
    }

    control = new FormControl()

    // se non inizializzato non si può usare il componente fuori da un form
    onChange = (value) => { }
    // se non inizializzato non si può usare il componente fuori da un form
    onTouched = (value) => { }

    ngOnInit(): void {
        if (this.required) {
            this.control.setValidators([Validators.required, Validators.minLength(11), Validators.maxLength(16)])
        }

        // in questo modo si può impostare il campo con lo stile "in errore" fin da subito
        if (this.initAsTouched) {
            this.control.markAsTouched()
        }
    }

    /**
     * Metodo richiamato dal form per verificarne la validità
     * @returns 
     */
    validate({ value }: FormControl) {
        if (!this.control.valid) {
            return { invalid: true }
        }
    }

    ngAfterViewInit(): void {
        this.subscription = this.control.valueChanges.subscribe(value => {
            this.onChange(value)
        })
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe()
        }
    }

    writeValue(value: string): void {
        this.control.setValue(value)
    }
    registerOnChange(fn: any): void {
        this.onChange = fn
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled
    }



}