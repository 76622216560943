/**
 * Costanti
 */

import { CommonObject } from "./utils/Utility"

export const APP_VERSION = '2.1.1'

export const SCT_NOME_APP = 'Salute con te'
export const NC_NOME_APP = 'NEXT Comunica'

export const SCT_WEBSITE = "www.saluteconte.it"
export const NC_WEBSITE = 'www.nextcomunica.it'

export const SCT_MANUAL_PATH = './assets/documents/manual.pdf?version=' + APP_VERSION
export const NC_MANUAL_PATH = './assets/documents/manual.pdf?version=' + APP_VERSION

export const SCT_FAQ_PATH = './assets/documents/faq-sct.pdf?version=' + APP_VERSION
export const NC_FAQ_PATH = './assets/documents/faq-nc.pdf?version=' + APP_VERSION

export const NC_SUBSTITUTE_DOCTOR_INFO_PATH = './assets/documents/informativa_consenso_medico_sostituto.pdf?version=' + APP_VERSION

export const LISTA_CITTA_PATH = './assets/'

//
// HttpRequest
//
export const CFG_HTTP_REQUEST = 'httpRequest';

// stato terapia
export const TERAPIA_SCADUTA_CON_NOTIFICA_MEDICO = "-3"
export const TERAPIA_SCADUTA = "-2";
export const TERAPIA_IN_SCADENZA = "-1";
export const TERAPIA_ATTIVA = "1";
export const TERAPIA_SENZA_SCADENZA = "0";

// provenienza notifiche
export const NOTIFICA_PROVENIENZA_PAZIENTE = 'P';
export const NOTIFICA_PROVENIENZA_MEDICO = 'M';

// tipologia messaggio
export const NOTIFICA_TIPOLOGIA_MESSAGGIO = 'MESSAGGIO';
export const NOTIFICA_TIPOLOGIA_ANAGRAFICA = 'A';
export const NOTIFICA_TIPOLOGIA_ESAME = 'E';
export const NOTIFICA_TIPOLOGIA_PRESTAZIONI = 'R';
export const NOTIFICA_TIPOLOGIA_PRESIDIO = 'S';
export const NOTIFICA_TIPOLOGIA_TERAPIA_CONTINUATIVA = 'P';
export const NOTIFICA_TIPOLOGIA_PROCEDURE_DIAGNOSTICHE = 'D';
export const NOTIFICA_TIPOLOGIA_MESSAGGIO_REFERTO = 'MESSAGGIO_REFERTO';
export const NOTIFICA_TIPOLOGIA_MESSAGGIO_PITER = 'MESSAGGIO_PITER';

export const RICHIESTA_TIPOLOGIA_SEGNALAZIONE = 'SEGNALAZIONE';
export const RICHIESTA_TIPOLOGIA_INFORMAZIONI = 'INFORMAZIONI';
export const RICHIESTA_SERVIZIO_SODDISFAZIONE_CLIENTI = 'SERVIZIO_SODDISFAZIONE_CLIENTI';
export const RICHIESTA_ALTRO = 'ALTRO';

// Parametri
export const PARAMETRI_SPEDITO_SI = 'S';
export const PARAMETRI_SPEDITO_NO = 'N';



export const NOTIFICA_TESTO_RICHIESTA_TIPOLOGIA_MESSAGGIO = 'Richiesta modifica anagrafica';

// estensioni accettate allegati
export const ALLEGATI_ESTENSIONI_ACCETTATE = ['.pdf', '.xls', '.xlsx', '.png', '.jpg', '.jpeg', '.doc', '.docx', '.ppt', '.pptx', '.odt', '.txt', '.bpm', '.tiff', '.fiff', '.jfiff', '.jpe', '.rtf']
// estensioni accettate immagini
export const ALLEGATI_IMMAGINI_ACCETTATE = ['.png', '.jpg', '.jpeg', '.bpm', '.tiff', '.fiff', '.jfiff', '.jpe']
// dimensione massima allegati 5MB
export const ALLEGATI_DIMENSIONE_MASSIMA = 5 * 1024 * 1024;
// prefisso generico per gli allegati che non hanno prefisso
export const ALLEGATI_PREFISSO_GENERICO = 'data:application/octet-stream;charset=utf-16le;base64,'

// operazioni in export const SPESE
export const SPESE_OPERAZIONE_GRIGLIA = 1;
export const SPESE_OPERAZIONE_FILTRO_ANNO = 2;
export const SPESE_OPERAZIONE_TOTALE_ANNO = 3;

// Dimensione record da estrarre sulla pagina riassunto
export const RIASSUNTO_TOP_RECORDS = '5';

// numero massimo "standard righe"
export const MAX_ROWS = 9999;

// url "vuoto"
export const DEFAULT_EMPTY_URL = 'javascript:void(0)'

// immagine di background per lo showcase di NC
export const NC_DEFAULT_SHOWCASE_BACKGROUND = 'assets/next-comunica/showcase-default-background.jpg'
export const NC_DEFAULT_DOCTOR_FALLBACK_IMAGE = 'assets/next-comunica/stetoscopio.jpeg'

export const NC_LOGO_BIG = 'assets/next-comunica/logo-big.png'
export const NC_LOGO_SMALL = 'assets/next-comunica/logo-small.png'
export const SCT_LOGO = 'assets/portal/images/uploads/logo-saluteconte.png'
export const NC_ATTIVAZIONE_UTENTE_DATA = "nc-attivazione-utente-data"
export const URL_YT_NEXTCOMUNICA = "https://www.youtube.com/@NextComunica"

export const RECAPTCHA_SITEKEY = '6LcyijwUAAAAAGAUs_mVYflM5bi-ku816XVodZGJ'

export const SW_CHECK_UPDATE_TIME = 60 * 3000
export const MOBILE_ASK_INSTALL_TIME = 0
export const MOBILE_ASK_NOTIFICATIONS_ONLY_ON_MOBILE = false

export const TABLE_DEFAULT_TRUNCATE_TEXT = 40

export const THEME_COLOR_VAR = '{{color}}'
export const THEME_TEXT_COLOR_VAR = '{{text-color}}'
export const THEME_DEFAULT_PROPERTIES: CommonObject = {
    '--theme-color': '{{color}}',
    '--button-text-color': '{{text-color}}',
    // rimuovere sfondo da pagine
    //'--background-theme': 'linear-gradient({{color}}, {{color}}) top/100% 400px no-repeat'
}
export const COLOR_ARANCIONE = '#ffbb78';
export const COLOR_VERDE_SCURO = '#116954';
export const COLOR_AZZURRINO = '#06bbc4'
export const COLOR_DEBOLE_TRIFOGLIO = '#afeed8'

export const COLOR_BLACK = '#000'
export const COLOR_WHITE = '#fff'

export const GIORNI = ['Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato', 'Domenica']
export const GIORNI_KV = {
    1: 'Lunedì',
    2: 'Martedì',
    3: 'Mercoledì',
    4: 'Giovedì',
    5: 'Venerdì',
    6: 'Sabato',
    7: 'Domenica'
}
export const ANNO_MASSIMO_PER_CALCOLO_CONFLITTI = 2100

export const PROVINCE = {
    AG: "Agrigento",
    AL: "Alessandria",
    AN: "Ancona",
    AO: "Aosta",
    AP: "Ascoli Piceno",
    AQ: "L'Aquila",
    AR: "Arezzo",
    AT: "Asti",
    AV: "Avellino",
    BA: "Bari",
    BG: "Bergamo",
    BI: "Biella",
    BL: "Belluno",
    BN: "Benevento",
    BO: "Bologna",
    BR: "Brindisi",
    BS: "Brescia",
    BT: "Barletta-Andria-Trani",
    BZ: "Bolzano",
    CA: "Cagliari",
    CB: "Campobasso",
    CE: "Caserta",
    CH: "Chieti",
    CL: "Caltanissetta",
    CN: "Cuneo",
    CO: "Como",
    CR: "Cremona",
    CS: "Cosenza",
    CT: "Catania",
    CZ: "Catanzaro",
    EN: "Enna",
    FC: "Forlì-Cesena",
    FE: "Ferrara",
    FG: "Foggia",
    FI: "Firenze",
    FM: "Fermo",
    FR: "Frosinone",
    GE: "Genova",
    GO: "Gorizia",
    GR: "Grosseto",
    IM: "Imperia",
    IS: "Isernia",
    KR: "Crotone",
    LC: "Lecco",
    LE: "Lecce",
    LI: "Livorno",
    LO: "Lodi",
    LT: "Latina",
    LU: "Lucca",
    MB: "Monza e della Brianza",
    MC: "Macerata",
    ME: "Messina",
    MI: "Milano",
    MN: "Mantova",
    MO: "Modena",
    MS: "Massa-Carrara",
    MT: "Matera",
    NA: "Napoli",
    NO: "Novara",
    NU: "Nuoro",
    OR: "Oristano",
    PA: "Palermo",
    PC: "Piacenza",
    PD: "Padova",
    PE: "Pescara",
    PG: "Perugia",
    PI: "Pisa",
    PN: "Pordenone",
    PO: "Prato",
    PR: "Parma",
    PT: "Pistoia",
    PU: "Pesaro e Urbino",
    PV: "Pavia",
    PZ: "Potenza",
    RA: "Ravenna",
    RC: "Reggio Calabria",
    RE: "Reggio Emilia",
    RG: "Ragusa",
    RI: "Rieti",
    RM: "Roma",
    RN: "Rimini",
    RO: "Rovigo",
    SA: "Salerno",
    SI: "Siena",
    SO: "Sondrio",
    SP: "La Spezia",
    SR: "Siracusa",
    SS: "Sassari",
    SU: "Sud Sardegna",
    SV: "Savona",
    TA: "Taranto",
    TE: "Teramo",
    TN: "Trento",
    TO: "Torino",
    TP: "Trapani",
    TR: "Terni",
    TS: "Trieste",
    TV: "Treviso",
    UD: "Udine",
    VA: "Varese",
    VB: "Verbano-Cusio-Ossola",
    VC: "Vercelli",
    VE: "Venezia",
    VI: "Vicenza",
    VR: "Verona",
    VS: "Medio Campidano",
    VT: "Viterbo",
    VV: "Vibo Valentia",
    EE: "Estero"
}
