import { AfterViewInit, Component, ElementRef, Input, ViewChild, forwardRef } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { RECAPTCHA_SITEKEY } from "../../constants";

declare var grecaptcha: any;

@Component({
    selector: 'ev-captcha',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => EvCaptchaComponent),
        }
    ],
    template: `<div #container></div>`
})
export class EvCaptchaComponent implements AfterViewInit {
    @Input() theme: 'dark' | 'light' = 'light'
    @Input() siteKey: string
    @Input() timeout: number = 100

    __valid = false


    get valid() {
        return this.__valid
    }


    @ViewChild('container', { static: true }) container: ElementRef


    statusChanged = new BehaviorSubject<boolean>(false)



    ngAfterViewInit(): void {
        this.render()
    }



    render() {
        setTimeout(() => {
            grecaptcha.render(this.container.nativeElement, {
                sitekey: this.siteKey || RECAPTCHA_SITEKEY,
                theme: this.theme,
                callback: () => {
                    this.__valid = true
                    this.statusChanged.next(this.__valid)
                }
            });
        }, this.timeout);
    }

}
