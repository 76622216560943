import { Component, Input } from "@angular/core";
import { DEFAULT_EMPTY_URL } from "../constants";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
    selector: 'ev-pdf-viewer',
    template: `<iframe [src]="getSrc()" [width]="width" [height]="height"></iframe>`
})
export class EvPdfViewerComponent {
    @Input() src: string
    @Input() width: string = '100%'
    @Input() height: string = '100%'

    constructor(private domSanitizer: DomSanitizer) {

    }

    getSrc() {
        return this.domSanitizer.bypassSecurityTrustResourceUrl(this.src || DEFAULT_EMPTY_URL)
    }
}
