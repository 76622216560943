import { Browser } from "@shopify/browser"

const browser = new Browser({ userAgent: navigator.userAgent })

export function getOS() {
    return browser.os
}
export function getBrowser() {
    return browser.name
}
export function getDeviceId() {
    return getOS() + ' - ' + getBrowser()
}

export function isAndroid() {
    return browser.isAndroid
}
export function isIOS() {
    return browser.isIOS
}
export function isSafari() {
    return browser.isSafari
}
export function isMobile() {
    return browser.isMobile
}
export function isFirefox() {
    return browser.isFirefox
}
export function isChrome() {
    return browser.isChrome
}
export function isMac() {
    return browser.isMac
}
export function isDesktop() {
    return browser.isDesktop
}

export function isPwaInstallationEnabled() {
    // non la rendiamo installabile su desktop
    if(isDesktop()) {
        return false
    }

    // su android la pwa è installabile solo su chrome (su ff fa schifo)
    if (isAndroid() && !isChrome()) {
        return false
    }

    // su mac è installabile solo da safari
    if (isMac() && !isSafari()) {
        return false
    }

    // su ios  è installabile solo su safari
    if (isIOS() && !isSafari()) {
        return false
    }

    return true
}