import { Component, OnInit } from '@angular/core';
import { ApplicationService } from '../../../lib/services/application.service';

@Component({
  selector: 'app-cookie',
  templateUrl: './cookie.component.html',
  styleUrls: ['./cookie.component.scss']
})
export class CookieComponent implements OnInit {

  applicationName: string

  constructor(applicationService: ApplicationService) {
    this.applicationName = applicationService.getApplicationName()
  }

  ngOnInit(): void {
  }

}
