import { animate, state, style, transition, trigger } from "@angular/animations"
import { SelectionModel } from "@angular/cdk/collections"
import { Component, ContentChild, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from "@angular/core"
import { Sort } from "@angular/material/sort"
import { Subscription } from "rxjs"
import { HttpRequestResponse } from "../../models/http-request-response.model"
import { DisplayService } from "../../services/display.service"
import { HttpRequestService } from "../../services/http-request.service"
import { LogService } from "../../services/log.service"
import { CommonObject, isEmpty, isFunction, newError, stringToMoment } from "../../utils/Utility"
import { EvPaginatorComponent, EvPaginatorConfig } from "../ev-paginator.component"

export interface EvTableMobileColumn {
    type: 'text' | 'date' | 'datetime' | 'action'
    title: string
    // necessario sempre, anche per le colonne azione
    field: string
    icon?: string | ((column: any, element: any) => string)
    // se true tronca il testo del valore indicato
    truncateText?: number
    fill?: boolean
    action?: (column, element) => void
    // stile della riga
    rowStyle?: (column, element) => CommonObject,
    // stile della colonna
    headerStyle?: (column) => CommonObject,
    // nasconde la cella se la funzione restituisce true
    hide?: (column, element) => boolean
    // renderizza il footer della colonna
    renderFooter?: (column, dataSource) => string
    // stile footer
    rowFooterStyle?: (column) => CommonObject
}

@Component({
    selector: 'ev-table-mobile',
    styleUrls: ['./ev-table-mobile.component.scss'],
    templateUrl: './ev-table-mobile.component.html',
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class EvTableMobileComponent implements OnInit, OnDestroy {

    @Input() tableTitle: string

    // url da dove estrarre i dati
    @Input({ required: true }) remoteDataUrl: string
    // verbo della richiesta
    @Input() remoteDataVerb: 'POST' | 'GET' = 'POST'
    // query params della richiesta
    @Input() remoteRequestQueryParams: CommonObject = {}
    // corpo della richiesta (solo post)
    @Input() remoteRequestBody: CommonObject = {}
    // opzioni della richiesta
    @Input() remoteRequestOptions: CommonObject = {}
    // se true non carica automaticamente i dati
    @Input() autoLoadData: boolean = true
    // notifica l'avvenuto caricamento dei dati
    @Output() dataLoaded = new EventEmitter<any>()

    // dati della tabella
    _dataSource: any[] = []
    @Input() set dataSource(_dataSource: any[]) {
        this._dataSource = _dataSource
        this.initSelection()
        this.updateRealColumns()
        this.dataLoaded.emit()
    }
    get dataSource() {
        return this._dataSource
    }

    // colonne della tabella
    _columnsToDisplay: EvTableMobileColumn[] = []
    @Input({ required: true }) set columnsToDisplay(_columnsToDisplay: EvTableMobileColumn[]) {
        this._columnsToDisplay = _columnsToDisplay
        this.updateRealColumns()
    }
    get columnsToDisplay(): EvTableMobileColumn[] {
        return this._columnsToDisplay
    }


    // se true abilita la selezione
    _singleSelection: boolean = false
    @Input() set singleSelection(_singleSelection: boolean) {
        this._singleSelection = _singleSelection
        this.initSelection()
        this.updateRealColumns()
    }
    get singleSelection() {
        return this._singleSelection
    }
    _multipleSelection: boolean = false
    @Input() set multipleSelection(_multipleSelection: boolean) {
        this._multipleSelection = _multipleSelection
        this.initSelection()
        this.updateRealColumns()
    }
    get multipleSelection() {
        return this._multipleSelection
    }

    @Output() changedSelection = new EventEmitter<any>()

    // permette di condizionare la visualizzazione del checkbox di selezione
    @Input() showCheck = (element) => true

    // se true abilita la paginazione
    @Input() pagination = true
    @ViewChild('paginator') paginator: EvPaginatorComponent

    // se true abilita il sorting
    @Input() sorting = true

    // se false nasconde la gestione del dettaglio
    @Input() hideDetailTemplate: boolean = false
    // se true nasconde la colonna azione per l'espanzione del collassabile
    @Input() hideDetailTemplateIcon: boolean = true
    // se false non tronca le colonne testo su mobile
    @Input() enableTruncateOnDesktop: boolean = false
    // se true mostra l'header della tabella
    @Input() showHeader: boolean = true
    // se true mostra il primo elemento
    @Input() openFirstElement: boolean = false

    // se true abilita il footer delle colonne
    @Input() footer = false

    // riferimento al template di dettaglio da renderizzare
    @ContentChild('detailTemplate') detailTemplate: TemplateRef<any>


    // colonne "reali"
    protected _realColumns: any[]
    // righe selezionate
    protected _selection: SelectionModel<any>
    // elemento espanso
    expandedElement = null
    // se true siamo su schermi piccoli
    isSmall: boolean

    private dataSub: Subscription
    private displaySub: Subscription

    constructor(private hrq: HttpRequestService, private log: LogService, private display: DisplayService) {

    }

    ngOnInit(): void {
        this.displaySub = this.display.isSmall.subscribe(isSmall => {
            this.isSmall = isSmall
        })

        if (this.autoLoadData) {
            this.loadTableData()
        }
    }

    ngOnDestroy(): void {
        this.dataSub?.unsubscribe()
        this.displaySub?.unsubscribe()
    }

    /**
     * Può venir sovrascritta per gestire i dati di ritorno dal server
     */
    adjustResponse(data: any[]): any[] {
        return data
    }

    /**
     * Chiamata al backend per i dati
     */
    loadData(paginatorConfig: EvPaginatorConfig = { page: 1, rows: 10 }) {
        if (this.remoteDataVerb.toUpperCase() === 'GET') {
            const params = Object.assign({}, paginatorConfig, this.remoteRequestQueryParams)
            return this.hrq.get(this.remoteDataUrl, params, this.remoteRequestOptions)
        }
        else if (this.remoteDataVerb.toUpperCase() === 'POST') {

            let options = Object.assign({}, this.remoteRequestOptions, { params: this.remoteRequestQueryParams })
            const body = Object.assign({}, paginatorConfig, this.remoteRequestBody)

            return this.hrq.post(this.remoteDataUrl, body, options)
        }
    }

    /**
     * Chiama loadData() ed esegue funzioni collaterali
     */
    loadTableData() {
        const paginatorConfig = this.paginator?.getPageConfig()

        if (!isEmpty(this.remoteDataUrl)) {

            this.dataSub = this.loadData(paginatorConfig).subscribe(response => {
                if (HttpRequestResponse.isSuccessResponse(response)) {

                    this.setTotal(response.total)

                    this.dataSource = this.adjustResponse(response.rows)

                    if (this.dataSource.length > 0 && this.openFirstElement) {
                        this.expandClick(null, this.dataSource[0])
                    }

                    this.dataLoaded.emit()

                } else {
                    this.log.messageError('Errore', response.message, newError(response.message))
                }
            })
        }
    }


    /**
     * Ricarica i dati
     * @returns
     */
    reload() {
        this.loadTableData()
    }


    /**
     * Aggiorna le "reali" colonne della tabella
     */
    updateRealColumns() {
        // aggiunto la colonna "expand" per il "tick" di apertura/chiusura dettaglio
        this._realColumns = this.columnsToDisplay.map(c => c.field)
        if (!this.hideDetailTemplate) {
            this._realColumns = [...this._realColumns, 'expand']
        }
        if (this._singleSelection || this._multipleSelection) {
            this._realColumns = ['select', ...this._realColumns]
        }
    }


    /**
     * Applica uno stile all'header
     * @param column
     * @returns
     */
    applyHeaderStyle(column: EvTableMobileColumn) {
        return column.headerStyle ? column.headerStyle(column) : null
    }

    /**
     * Applica uno stile ad una cella
     * @param column
     * @param element
     * @returns
     */
    applyCellStyle(column: EvTableMobileColumn, element) {
        return column.rowStyle ? column.rowStyle(column, element) : null
    }

    /**
     * Applica uno stile alla cella nel footer
     * @param column
     * @returns
     */
    applyFooterCellStyle(column: EvTableMobileColumn) {
        return column.rowFooterStyle ? column.rowFooterStyle(column) : null
    }

    /**
     * Renderizza una colonna icona
     * @param column
     * @param element
     * @returns
     */
    renderIcon(column, element) {
        if (isFunction(column.icon)) {
            return column.icon(column, element)
        } else {
            return column.icon
        }
    }

    /**
     * Evento click su una cella
     * @param $event
     * @param column
     * @param element
     */
    cellClick($event, column, element) {
        $event.preventDefault()
        if (column.action) {
            column.action(column, element)
        }
    }

    /**
     * Nasconde una cella
     * @param column
     * @param element
     * @returns
     */
    cellHide(column, element) {
        if (column.hide) {
            return column.hide(column, element)
        }
        return false
    }

    /**
     * Esegue il rendering di una colonna testuale
     * @param column
     * @param element
     * @returns
     */
    renderTextColumn(column, element) {
        const _renderTextColumn = () => {
            if (column.truncateText > 0) {
                return element[column.field].substr(0, column.truncateText) + '...'
            }
            return element[column.field]
        }

        // se sono su schermo piccolo tronco se richiesto
        if (this.isSmall) {
            return _renderTextColumn()
        }

        // se non sono su schermo piccolo tronco solo se richiesto
        if (this.enableTruncateOnDesktop) {
            return _renderTextColumn()
        }

        // se sono su schermo grande e non devo troncare allora restituisco tutto
        return element[column.field]

    }

    /**
     * Evento click dell'expand panel
     * @param $event
     * @param element
     */
    expandClick($event, element) {
        // se clicco su un'azione non faccio nulla
        if ($event?.target?.nodeName.toLowerCase() === 'mat-icon' || this.hideDetailTemplate) {
            $event?.preventDefault()
        } else {
            this.expandedElement = this.expandedElement === element ? null : element
        }
    }

    /*************************************************/
    /******************* SELECTION *******************/
    /*************************************************/
    /**
     * Seleziona programmaticamente una riga
     * @param element
     */
    selectRow(element) {
        this._selection.select(element)
    }
    /**
     * Estrae le righe selezionate
     * @returns
     */
    getSelectedData() {
        if (!this._selection) {
            throw new Error("Selezione non abilitata")
        } else {
            return this._selection.selected
        }
    }
    /**
     * Permette di condizionare il check di selezione
     * @param elem
     * @returns
     */
    showSelectionCheck(elem): boolean {
        return this.showCheck(elem)
    }
    /**
     * Pulisce gli elementi selezionati
     * @returns
     */
    clearSelection() {
        if (!this._selection) {
            throw new Error("Selezione non abilitata")
        } else {
            return this._selection.clear()
        }
    }
    /**
     * Inizializza la selezione
     */
    private initSelection() {
        if (this.singleSelection) {
            this._selection = new SelectionModel(false, [])
        } else if (this.multipleSelection) {
            this._selection = new SelectionModel(true, [])
        }

        this._selection?.changed?.subscribe(event => {
            this.changedSelection.emit({
                addedd: event.added,
                removed: event.removed
            })
        })
    }


    /****************************************************/
    /******************** PAGINATION ********************/
    /****************************************************/
    /**
     * Evento pagina cambiata
     * @param pageConfig
     */
    pageChanged(pageConfig) {
        this.loadTableData()
    }




    /*************************************************/
    /******************** SORTING ********************/
    /*************************************************/
    /**
     * Evento di ordinamento
     * @param sort
     * @returns
     */
    sortData(sort: Sort) {
        const column = this._columnsToDisplay.find(col => col.field === sort.active)

        const data = this.dataSource.slice()

        if (!sort.active || sort.direction === '') {
            this.dataSource = data
            return
        }

        this.dataSource = data.sort((a, b) => {
            const isAsc = sort.direction === 'asc'

            return this.sortFunction(column, a[sort.active], b[sort.active], isAsc)
        })
    }
    /**
     * Funzione di sorting
     * @param a
     * @param b
     * @param isAsc
     */
    sortFunction(column, a, b, isAsc) {
        if (column.type === 'date') {
            const dateA = stringToMoment(a)
            const dateB = stringToMoment(b)

            return (dateA.isBefore(dateB) ? -1 : 1) * (isAsc ? 1 : -1)
        } else {
            return (a < b ? -1 : 1) * (isAsc ? 1 : -1)
        }
    }


    /**
     * Imposta il totale per la paginazione.
     * Va richiamato dall'esterno quando cambiano i dati
     * @param total
     */
    setTotal(total: number) {
        if (this.pagination) {
            this.paginator.total = total
        }
    }


    /*****************************/
    /********** FOOTER ***********/
    /*****************************/
    /**
     * Rendering dinamico del footer
     * @param column
     * @returns
     */
    renderFooter(column) {
        if (column.renderFooter) {
            return column.renderFooter(column, this.dataSource)
        }
        return ''
    }
}
