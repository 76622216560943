import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: 'ev-cookie-banner',
    styles: [`
        #ok-cookie-alert {
            /*position: absolute; */
            bottom: 0%; 
            left: 0%; 
            right: 0%
        }
    
    `],
    template: `
    
    <div id="ok-cookie-alert" class="alert alert-info alert-dismissable" [hidden]="hide">
        <a href="" class="close" (click)="accept()" data-dismiss="alert" aria-label="close">&times;</a>
        Questo sito fa uso solo di cookie tecnici atti a garantire la fruizione del
        sito stesso. Continuando la navigazione o chiudendo questo banner si accetta
        l'utilizzo dei cookies.
    </div>
    `
})
export class EvCookieBannerComponent implements OnInit {

    @Input() localStorageKey = 'saluteconte.ok-cookie'

    hide: boolean = false

    ngOnInit() {
        if (localStorage && localStorage.getItem) {
            if (localStorage.getItem(this.localStorageKey) === '1') {
                this.hide = true
            }
        }
    }

    accept() {
        if (localStorage && localStorage.setItem) {
            localStorage.setItem(this.localStorageKey, '1')
        }
        this.hide = true
    }


}