import { ComponentType } from "@angular/cdk/overlay";
import { Injectable, TemplateRef } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { EvDialogMaterialComponent } from "../components/ev-dialog-material.component";

@Injectable({
    providedIn: 'root'
})
export class DialogMaterialService {

    constructor(private dialogService: MatDialog) {

    }

    open<T>(component: ComponentType<T> | TemplateRef<any>, config?: MatDialogConfig) {
        return this.dialogService.open(component, config)
    }

    openYesNo(title: string, text: string, configDialog?: MatDialogConfig) {

        const config = this.initConfig(configDialog)

        config.data.title = title
        config.data.text = text
        config.data.type = 'YES_NO'

        config.data = Object.assign({}, config.data, configDialog)

        return this.dialogService.open(EvDialogMaterialComponent, config)
    }

    openClose(title: string, text: string, configDialog?: MatDialogConfig) {
        const config = this.initConfig(configDialog)

        config.data.title = title
        config.data.text = text
        config.data.type = 'CLOSE'

        config.data = Object.assign({}, config.data, configDialog)

        return this.dialogService.open(EvDialogMaterialComponent, config)
    }

    private initConfig(config) {
        let newConfig

        if (!config) {
            newConfig = { data: {} }
        } else {
            newConfig = { ...config }
            if (!newConfig.data) {
                newConfig.data = {}
            }
        }

        return newConfig
    }
}
