import { AfterViewInit, Component, Inject, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatStepper } from "@angular/material/stepper";
import { BehaviorSubject, lastValueFrom } from "rxjs";
import { NC_ATTIVAZIONE_UTENTE_DATA } from "../../constants";
import { HttpRequestResponse } from "../../models/http-request-response.model";
import { DialogMaterialService } from "../../services/dialog-material.service";
import { HttpRequestService } from "../../services/http-request.service";
import { LogService } from "../../services/log.service";

import { IComune } from "../../models/comuni.class";
import { CodFis, CommonObject, MomentDate, currentMoment, fromJson, momentToString, newError, objectWithoutNull, stringToMoment, toJsonWithoutNull } from "../../utils/Utility";
import { EvCaptchaComponent } from "../captcha/captcha.component";
import { EvAutocompleteOption } from "../ev-autocomplete.component";
import { EvCodeInputComponent } from "../ev-code-input.component";
import { EvDialogMaterialComponent } from "../ev-dialog-material.component";




@Component({
    selector: 'ev-attivazione-utente',
    styleUrls: ['attivazione-utente.component.scss'],
    templateUrl: './attivazione-utente.component.html'
})
export class EvAttivazioneUtenteComponent implements OnInit, OnDestroy {
    @Input() userId: number

    loaded = new BehaviorSubject<boolean>(false)
    saved = new BehaviorSubject<boolean>(false)

    form = new FormGroup({
        id: new FormControl(0),
        nome: new FormControl('', [Validators.required]),
        cognome: new FormControl('', [Validators.required]),
        sesso: new FormControl('', []),
        data_nascita: new FormControl<MomentDate>(null, []),
        nazione_nascita: new FormControl('', []),
        comune_nascita: new FormControl<IComune>(null, []),
        provincia_nascita: new FormControl('', [Validators.maxLength(2)]),
        codice_fiscale: new FormControl('', [Validators.required, Validators.minLength(11), Validators.maxLength(16)]),
        email: new FormControl('', [Validators.required, Validators.email]),
        cellulare: new FormControl('', []),
        codice_fiscale_medico: new FormControl('', [Validators.required]),
        captcha: new FormControl(null, [Validators.required])
    }, {
        updateOn: 'blur'
    })

    @ViewChild(EvCaptchaComponent) captcha: EvCaptchaComponent

    medici = []
    mediciFiltrati: EvAutocompleteOption[]

    subArray = []

    get codice_fiscale_medico() {
        return this.form.controls.codice_fiscale_medico
    }

    constructor(
        private dms: DialogMaterialService,
        private httpRequestService: HttpRequestService,
        private logService: LogService
    ) {
    }





    load() {
        this.subArray.push(
            this.httpRequestService.get('/nc/showcase/medici', { userId: this.userId }).subscribe(response => {
                this.medici = response.data.medici.map(m => {
                    m['id'] = m['codice_fiscale']
                    return m
                })
                this.mediciFiltrati = this.medici.map(m => { return { value: m.codice_fiscale, text: `${m.nome} ${m.cognome}` } })

                this.initForm()
            })
        )

        if (localStorage) {
            let data = localStorage.getItem(NC_ATTIVAZIONE_UTENTE_DATA)
            if (data) {
                data = fromJson(data)

                this.form.controls.id.setValue(data['id'], { emitEvent: false })
                this.form.controls.nome.setValue(data['nome'], { emitEvent: false })
                this.form.controls.cognome.setValue(data['cognome'], { emitEvent: false })
                this.form.controls.codice_fiscale.setValue(data['codice_fiscale'], { emitEvent: false })
                this.form.controls.email.setValue(data['email'], { emitEvent: false })
                this.form.controls.cellulare.setValue(data['cellulare'], { emitEvent: false })
                this.form.controls.codice_fiscale_medico.setValue(data['codice_fiscale_medico'], { emitEvent: false })
                this.form.controls.sesso.setValue(data['sesso'], { emitEvent: false })
                if (data['data_nascita']) {
                    this.form.controls.data_nascita.setValue(stringToMoment(data['data_nascita']), { emitEvent: false })
                }
                this.form.controls.provincia_nascita.setValue(data['provincia_nascita'], { emitEvent: false })
                this.form.controls.comune_nascita.setValue(data['comune_nascita'])
                this.form.controls.nazione_nascita.setValue(data['nazione_nascita'], { emitEvent: false })

                // aggiorno "manualmente" il comune di nascita
                this.updateFormPerCodiceFiscaleItaliano(data['nazione_nascita'])
            }
        }
    }

    save(end = false, other = {}) {

        if (this.form.controls.codice_fiscale.value?.length > 16) {
            this.logService.messageError("Attenzione", "Lunghezza massima codice fiscale: 16 caratteri", newError("Lunghezza massima codice fiscale: 16 caratteri"))
            return
        }

        this.loaded.next(false)

        const data = Object.assign({}, this.form.getRawValue(), other)

        // rimuovo i campi non presenti su db ma utili per l'interfaccia utente
        delete data['captcha']
        delete data['sesso']
        delete data['data_nascita']
        delete data['provincia_nascita']
        delete data['comune_nascita']
        delete data['nazione_nascita']

        if (end) {
            data['richiesta_salvata'] = 'S'
        }

        this.subArray.push(
            this.httpRequestService.post('/nc/showcase/richiesta-attivazione', objectWithoutNull(data), {}, false).subscribe(response => {

                if (HttpRequestResponse.isSuccessResponse(response)) {
                    const data = response.data

                    if (data.id) {
                        this.form.controls.id.setValue(data.id, { emitEvent: false })

                        if (localStorage) {
                            const dataToSave = this.form.getRawValue() as any
                            if (dataToSave['data_nascita']) {
                                dataToSave['data_nascita'] = momentToString(dataToSave['data_nascita'])
                            }
                            localStorage.setItem(NC_ATTIVAZIONE_UTENTE_DATA, toJsonWithoutNull(dataToSave))
                        }
                    }

                    if (end) {
                        if (localStorage) {
                            localStorage.setItem(NC_ATTIVAZIONE_UTENTE_DATA, toJsonWithoutNull({}))
                        }

                        this.saved.next(true)
                    }
                } else {

                    // se ho un errore specifico resetto il campo non valido
                    if (response.data.error) {
                        const control: FormControl = this.form.controls[response.data.error]
                        control.setValue(control.defaultValue, { emitEvent: false })
                    }

                    this.logService.messageError('Attenzione', response.message, newError(response.message))
                }

                this.loaded.next(true)
            })
        )
    }

    calcolaAndApplicaCodiceFiscale() {
        const cf = this.calcolaCodiceFiscale()

        this.form.controls.codice_fiscale.setValue(cf)
    }

    isCalcoloCodiceFiscaleDisabilitato() {
        const { nome, cognome, sesso, data_nascita, provincia_nascita, comune_nascita, nazione_nascita } = this.form.getRawValue()

        if (nazione_nascita?.toUpperCase() !== 'ITALIA') {
            return !nome || !cognome || !nazione_nascita
        }

        return !nome || !cognome || !sesso || !data_nascita?.isValid() || !provincia_nascita || !comune_nascita || !nazione_nascita
    }

    calcolaCodiceFiscale(onlyCheck = false) {
        try {
            const { nome, cognome, sesso, data_nascita, provincia_nascita, comune_nascita, nazione_nascita } = this.form.getRawValue()

            const cf = CodFis.checkData({
                nome,
                cognome,
                genere: sesso as "F" | "M",
                cittanas: comune_nascita?.comune,
                natoil: data_nascita?.format("YYYY-MM-DD"),
                provnas: provincia_nascita || '',
                statonas: nazione_nascita?.toUpperCase()
            })

            return cf
        } catch (err) {
            if (!onlyCheck) {
                this.logService.messageError('Errore', err, err)
            }
        }
    }

    initForm() {

        this.subArray.push(
            this.form.valueChanges.subscribe(data => {

                this.form.controls.codice_fiscale.setValue(
                    this.form.controls.codice_fiscale.value?.toUpperCase(),
                    {
                        emitEvent: false
                    }
                )
                this.save()
            })
        )

        this.subArray.push(
            this.form.controls.comune_nascita.valueChanges.subscribe(comune => {
                if (comune) {
                    this.form.controls.provincia_nascita.setValue(comune.prov, { emitEvent: false })
                }
                /* else {
                    this.form.controls.comune_nascita.reset(null, { emitEvent: false })
                    this.form.controls.provincia_nascita.reset(null, { emitEvent: false })
                } */
            })
        )

        this.subArray.push(
            this.form.controls.nazione_nascita.valueChanges.subscribe(nazione => {
                this.updateFormPerCodiceFiscaleItaliano(nazione)
            })
        )

        this.subArray.push(
            this.captcha.statusChanged.subscribe(valid => {
                if (valid) {
                    this.form.controls['captcha'].setValue(true)
                }
            })
        )

        this.form.markAllAsTouched()

        this.loaded.next(true)
    }



    valid() {
        return this.form.valid && this.captcha.valid
    }


    updateFormPerCodiceFiscaleItaliano(nazione: string) {
        if (nazione?.toUpperCase() === 'ITALIA') {
            //this.form.controls.comune_nascita.setValidators(Validators.required)
            //this.form.controls.provincia_nascita.setValidators(Validators.required)
            this.form.controls.comune_nascita.enable()
            this.form.controls.provincia_nascita.enable()
            this.form.controls.comune_nascita.updateValueAndValidity()
            this.form.updateValueAndValidity()
        } else {
            //this.form.controls.comune_nascita.clearValidators()
            //this.form.controls.provincia_nascita.clearValidators()
            this.form.controls.comune_nascita.disable()
            this.form.controls.provincia_nascita.disable()
            this.form.controls.comune_nascita.setValue(null, { emitEvent: false })
            this.form.controls.provincia_nascita.setValue(null, { emitEvent: false })
        }
    }



    verificaAutocomplete() {
        // serve la setTimeout per aggiornare il valore
        setTimeout(() => {
            const value = this.codice_fiscale_medico.value

            const check = this.medici.find(v => v === value)

            if (!check) {
                this.codice_fiscale_medico.setValue(null)
            }
        }, 0);

    }


    async verificaCodiceFiscale() {
        const codiceFiscaleInserito = this.form.controls.codice_fiscale.value
        const dataNascita = this.form.controls.data_nascita.value
        const cf = this.calcolaCodiceFiscale(true)

        try {

            if(dataNascita) {
                const dataAttuale = currentMoment()
                const diff = dataAttuale.diff(dataNascita, 'years') < 14
                if(diff) {
                    await lastValueFrom(
                        this.dms.openClose(
                            "Attenzione",
                            "Non è possibile effettuare l'attivazione al portale NextComunica per un minore di 14 anni"
                        ).afterClosed()
                    )
    
                    return false
                }
            } 
            // verifico che il codice fiscale non sia di un minore di 14 anni
            else if (CodFis.verificaEtaMinore14(codiceFiscaleInserito)) {
                await lastValueFrom(
                    this.dms.openClose(
                        "Attenzione",
                        "Non è possibile effettuare l'attivazione al portale NextComunica per un minore di 14 anni"
                    ).afterClosed()
                )

                return false
            }
        } catch (err) { }


        if (cf !== codiceFiscaleInserito) {
            return await lastValueFrom(
                this.dms.openYesNo(
                    "Attenzione",
                    "Il codice fiscale inserito non sembra corrispondere al codice fiscale calcolato. Proseguire?"
                ).afterClosed())
        } else {
            return true
        }
    }


    ngOnInit() {
        this.load()
    }

    ngOnDestroy() {
        this.subArray.forEach(s => s.unsubscribe())
    }

}






@Component({
    selector: 'ev-attivazione-utente-modal',
    styles: [''],
    templateUrl: './attivazione-utente-modal.component.html'
})
export class EvAttivazioneUtenteModalComponent implements AfterViewInit, OnDestroy {

    userId: number

    @ViewChild('stepper') stepper: MatStepper
    @ViewChild('codiceUtente') codiceUtente: EvCodeInputComponent
    @ViewChild(EvAttivazioneUtenteComponent) attivazioneUtenteComponent: EvAttivazioneUtenteComponent
    @ViewChild('dialogConfermaInvio') dialogConfermaInvio: TemplateRef<any>

    hiddenProgress = false

    // necessario perchè lo stepper altrimenti non può basarsi sul form figlio
    attivazioneUtenteForm = null

    confirmDisabled = true

    confermaInvioDialogRef = null

    subArray = []

    constructor(
        private dialogRef: MatDialogRef<EvAttivazioneUtenteComponent>,
        private dms: DialogMaterialService,
        private logService: LogService,
        private httpRequestService: HttpRequestService,
        @Inject(MAT_DIALOG_DATA) private data: CommonObject
    ) {
        this.userId = data.userId
    }


    ngAfterViewInit(): void {
        setTimeout(() => {
            this.attivazioneUtenteComponent.loaded.subscribe(v => this.hiddenProgress = v)
            // necessario perchè lo stepper altrimenti non può basarsi sul form figlio
            this.attivazioneUtenteForm = this.attivazioneUtenteComponent.form as FormGroup<any>

            // chiudo la finestra al salvataggio
            this.subArray.push(
                this.attivazioneUtenteComponent.saved.subscribe(value => {
                    if (value) {
                        this.dms.open(EvDialogMaterialComponent, {
                            data: {
                                type: 'CLOSE',
                                text: "Richiesta di attivazione inviata con successo.<br>Il suo medico prenderà in carico appena possibile la sua richiesta e le invierà un'email con le credenziali e relative istruzioni per l'accesso."
                            }
                        })

                        this.dialogRef.close()
                    }
                })
            )


        }, 0);
    }

    async next() {
        // verifico il codice fiscale
        if (!await this.attivazioneUtenteComponent.verificaCodiceFiscale()) {
            return
        }

        this.stepper.next()

        if (this.attivazioneUtenteForm.valid) {
            this.sendCode()
        }
    }


    sendCode() {
        const email = this.attivazioneUtenteComponent.form.controls.email.value

        this.subArray.push(
            this.httpRequestService.post('/nc/showcase/richiesta-attivazione/invia-codice-sicurezza-email',
                {
                    id: this.attivazioneUtenteComponent.form.controls.id.value,
                    email
                }, {}, false
            ).subscribe(response => {
                this.logService.snack(response.message)
            })
        )
    }


    valid() {
        return this.attivazioneUtenteComponent?.valid() && this.codiceUtente.valid()
    }


    send() {
        if (!this.attivazioneUtenteComponent.valid()) {
            return
        }
        this.confermaInvioDialogRef = this.dms.open(this.dialogConfermaInvio)
    }

    confirm() {
        // invio anche il codice di sicurezza
        this.attivazioneUtenteComponent.save(true, { __codice_sicurezza: this.codiceUtente.value })

        this.confermaInvioDialogRef.close()
    }


    close() {
        this.dialogRef.close()
    }

    ngOnDestroy(): void {
        this.subArray.forEach(s => s.unsubscribe())
    }

}
