import { Component, ElementRef, Injector, Input, ViewChild, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl, Validators } from "@angular/forms";
import { MatSelect } from "@angular/material/select";
import { NAZIONI } from "../models/nazioni.class";

@Component({
    selector: 'ev-nazione',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => EvNazioneComponent),
        }
    ],
    template: `
<mat-form-field [style.width]="width">
    <mat-label>{{label}}</mat-label>
    <mat-select [(ngModel)]="value" [disabled]="disabled" (selectionChange)="changed($event.value)" (openedChange)="doFocus($event)" [required]="isRequired()">
        <input #filterInput type="text" (keyup)="filter()" placeholder="Filtra" style="padding: 10px; width: 100%" [(ngModel)]="filterValue">
        <mat-option *ngFor="let nazione of nazioni" [value]="nazione.descrizione">
            {{nazione.descrizione}}
        </mat-option>
    </mat-select>
</mat-form-field>
    `
})
export class EvNazioneComponent implements ControlValueAccessor {
    @Input() label = 'Nazione'
    @Input() disabled = false
    @Input() width = '100%'

    @ViewChild(MatSelect) select: MatSelect

    @Input() set value(val) {
        if (val) {
            this._value = val
            this.changed(val)
        }
    }
    get value() {
        return this._value
    }
    _value = ''
    filterValue = ''

    @ViewChild('filterInput') filterInput: ElementRef<HTMLInputElement>

    nazioni: any = NAZIONI

    onChange: any
    onTouched: any

    constructor(private injector: Injector) {

    }

    doFocus(open) {
        if (open) {
            this.filterInput.nativeElement.focus()
        }
    }

    /**
    * Necessario per sapere se il controllo è 'required'
    * @returns 
    */
    isRequired() {
        const control = this.injector.get(NgControl)
        if (control) {
            return control.control.hasValidator(Validators.required)
        }
        return false
    }

    filter() {
        this.nazioni = NAZIONI.filter(nazione => {
            return nazione.descrizione.toLowerCase().indexOf(this.filterValue.toLowerCase()) > -1
        })
    }

    changed(value) {
        if (this.onTouched) {
            this.onTouched(value)
        }
        if (this.onChange) {
            this.onChange(value)
        }
    }

    writeValue(value: any): void {
        this.value = value
    }
    registerOnChange(fn: any): void {
        this.onChange = fn
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled
    }
}