import { Component, Input, OnInit } from "@angular/core";
import { ControlValueAccessor, FormArray, FormControl, Validators } from "@angular/forms";

@Component({
    selector: 'ev-code',
    template: `
<form [formGroup]="form">
    <mat-form-field *ngFor="let control of form.controls" [disabled]="disabled" (paste)="onPaste($event)" style="width: 45px; margin-right: 10px">
        <input matInput [formControl]="control" maxLength="1" style="text-align:center; font-weight:bold">
    </mat-form-field>
</form>
    `
})
export class EvCodeInputComponent implements OnInit, ControlValueAccessor {
    // se true accetta solo numeri
    @Input() onlyNumbers = false
    // imposta quanti elementi visualizzare
    @Input() length = 6
    // se true i valori sono required
    @Input() required = true
    // se true disabilita il componente
    @Input() disabled = false
    // se true emette un change per ogni set del valore
    @Input() emitChangeOnEachField = true
    // se true abilita il copia/incolla
    @Input() enableCopyPaste = true

    get value() {
        let code = ''
        for (let i = 0; i < this.form.controls.length; i++) {
            code += this.form.controls[i].value
        }
        return code
    }

    form = new FormArray([])

    onChangeFn = null
    onTouchedFun = null

    ngOnInit(): void {
        this.render()
    }

    render() {
        for (let i = 0; i < this.length; i++) {
            const control = this.createSimpleControl()

            this.form.push(control)
        }
    }


    createSimpleControl() {
        const defaultValue = this.onlyNumbers ? 0 : ''
        const validators = [Validators.minLength(1), Validators.maxLength(1)]
        if (this.required) {
            validators.push(Validators.required)
        }

        const control = new FormControl(defaultValue, validators)

        return control
    }


    valid() {
        return this.form.valid
    }


    onPaste($event: ClipboardEvent) {
        if (this.enableCopyPaste) {
            let clipboardData = $event.clipboardData
            let code = clipboardData.getData('text')

            this.writeValue(code)
        }
    }


    writeValue(value: string): void {
        for (let i = 0; i < value.length; i++) {
            this.form.controls[i].setValue(value[i])
        }
        if (this.emitChangeOnEachField && this.onChangeFn) {
            this.onChangeFn()
        }
    }
    registerOnChange(fn: any): void {
        this.onChangeFn = fn
    }
    registerOnTouched(fn: any): void {
        this.onTouchedFun = fn
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled
    }

}