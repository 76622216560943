import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpRequestResponse } from '../../../lib/models/http-request-response.model';
import { HttpRequestService } from '../../../lib/services/http-request.service';

@Component({
  selector: 'app-accesso-video-call',
  templateUrl: './accesso-video-call.component.html',
  styleUrls: ['../../../lib/themes/portale.theme.scss']
})
export class AccessoVideoCallComponent {
  errorMessage: string;

  form = new FormGroup({
    codiceFiscale: new FormControl('', [Validators.required]),
    accepted: new FormControl(false, [Validators.requiredTrue])
  })

  constructor(private httpRequestService: HttpRequestService) {
  }

  verificaVideoCall() {
    this.httpRequestService.post('/auth/accesso-light/', {
      codiceFiscale: this.form.controls.codiceFiscale.value.toUpperCase(),
    }).subscribe((response) => {
      if (HttpRequestResponse.isSuccessResponse(response)) {
        if (response.data?.attivo == 'S') {
          location.href = response.data?.link_videochiamata;
        }
      } else {
        this.errorMessage = response.message;
      }
    });
  }
}
