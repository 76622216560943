import { Component, OnInit } from '@angular/core';
import { ApplicationService } from '../../../lib/services/application.service';

@Component({
  selector: 'app-portale-layout-footer',
  templateUrl: './portale-layout-footer.component.html',
  styleUrls: ['./portale-layout-footer.component.scss']
})
export class PortaleLayoutFooterComponent implements OnInit {

  applicationName: string
  isNextComunica: boolean

  constructor(protected applicationService: ApplicationService) {
    this.applicationName = applicationService.getApplicationName()
    this.isNextComunica = applicationService.isNextComunica()
  }

  ngOnInit(): void {
  }

}
