<div>
  <menu-portale></menu-portale>

  <section-title title="Dicono di noi"></section-title>

  <div class="portale-section" style="text-align: center;">

    <ul>
      <li>
        <a href="assets/portal/images/dicono1.jpg" target="_blank">Il Sole 24 Ore</a>
      </li>
    </ul>

  </div>

  <app-scarica-app-section></app-scarica-app-section>

  <app-portale-layout-footer></app-portale-layout-footer>
</div>