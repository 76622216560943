import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ComunicazioniService } from '../../../applicazione/services/comunicazioni.service';
import { HttpRequestResponse } from '../../models/http-request-response.model';
import { DisplayService } from '../../services/display.service';
import { FunzionalitaService } from '../../services/funzionalita.service';
import { HttpRequestService } from '../../services/http-request.service';
import { LogService } from '../../services/log.service';
import { newError, toNumber } from '../../utils/Utility';


declare var $: any;
@Component({
  selector: 'ev-categorie',
  templateUrl: './categorie.component.html',
  styleUrls: ['./categorie.component.scss'],
})
export class EvCategorieComponent
  implements OnInit, OnDestroy {

  @Input() funzionalitaAttiva: any;
  categorieSub: Subscription;

  constructor(
    protected fun: FunzionalitaService,
    private httpRequestService: HttpRequestService,
    private logService: LogService,
    public displayService: DisplayService,
    private comunicazioniService: ComunicazioniService
  ) { }

  ngOnInit(): void {

    this.httpRequestService.post('/notifica/paziente/get', {
      nuove: true,
      provenienza: 'M'
    }).subscribe(response => {
      if (HttpRequestResponse.isSuccessResponse(response)) {

        const count = toNumber(response.data.count);

        // notifico la get delle notifiche
        this.comunicazioniService.lastComunicationsCount.next(count)

        if (count > 0) {

          let notEl = $(".cat-comunicazioni");

          let notifyBadge = $("<span />", {
            css: {
              position: 'absolute',
              right: '-4px',
              top: '-10px',
              background: 'red',
              'text-align': 'center',
              'border-radius': '30px 30px 30px 30px',
              color: 'white',
              padding: '1px 5px',
              'font-size': '15px'
            },
            text: count,
          });

          let itemBadge = $("<span />", {
            css: {
              position: 'relative',
              'padding-top': '20px',
              display: 'inline-block'
            },
            html: notifyBadge,
          });

          notEl.append(itemBadge);
        }

      } else {
        this.logService.messageError('Errore', response.message, newError(response.message));
      }
    })
  }

  ngOnDestroy(): void {
    if (this.categorieSub) {
      this.categorieSub.unsubscribe();
    }
  }
}
