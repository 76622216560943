export type INazione = {
    descrizione: string
    codiceistat: string
    codiceauto: string
    codcatasto: string
    dataend: string
    altreinfo: string
}

export const NAZIONI: INazione[] = [
    {
        "descrizione": "Faer oer (isole)",
        "codiceistat": null,
        "codiceauto": "DK",
        "codcatasto": "Z108",
        "dataend": null,
        "altreinfo": null
    },
    {
        "descrizione": "Repubblica di polonia",
        "codiceistat": null,
        "codiceauto": null,
        "codcatasto": "Z127",
        "dataend": null,
        "altreinfo": null
    },
    {
        "descrizione": "Ceylon",
        "codiceistat": null,
        "codiceauto": null,
        "codcatasto": "Z209",
        "dataend": "1975-12-31",
        "altreinfo": null
    },
    {
        "descrizione": "Cocos (isole)",
        "codiceistat": null,
        "codiceauto": "AUS",
        "codcatasto": "Z212",
        "dataend": null,
        "altreinfo": null
    },
    {
        "descrizione": "Gaza (territorio di)",
        "codiceistat": null,
        "codiceauto": "ET",
        "codcatasto": "Z218",
        "dataend": null,
        "altreinfo": null
    },
    {
        "descrizione": "Ryukyu (isole)",
        "codiceistat": null,
        "codiceauto": "USA",
        "codcatasto": "Z238",
        "dataend": "1975-12-31",
        "altreinfo": null
    },
    {
        "descrizione": "Africa del sud-ovest",
        "codiceistat": null,
        "codiceauto": "ZA",
        "codcatasto": "Z300",
        "dataend": "1975-12-31",
        "altreinfo": null
    },
    {
        "descrizione": "Namibia=africa del sud ovest",
        "codiceistat": null,
        "codiceauto": "ZA",
        "codcatasto": "Z300",
        "dataend": "1978-12-31",
        "altreinfo": null
    },
    {
        "descrizione": "Ceca, Repubblica",
        "codiceistat": "257",
        "codiceauto": null,
        "codcatasto": "Z156",
        "dataend": null,
        "altreinfo": null
    },
    {
        "descrizione": "Costa dei pirati (Trucial states)",
        "codiceistat": null,
        "codiceauto": "GB",
        "codcatasto": "Z215",
        "dataend": "1975-12-31",
        "altreinfo": null
    },
    {
        "descrizione": "Irlanda",
        "codiceistat": "221",
        "codiceauto": null,
        "codcatasto": "Z116",
        "dataend": null,
        "altreinfo": null
    },
    {
        "descrizione": "Kosovo ",
        "codiceistat": "272",
        "codiceauto": null,
        "codcatasto": "Z160",
        "dataend": null,
        "altreinfo": null
    },
    {
        "descrizione": "Taiwan (ex Formosa)",
        "codiceistat": "363",
        "codiceauto": null,
        "codcatasto": "Z217",
        "dataend": null,
        "altreinfo": null
    },
    {
        "descrizione": "Formosa (taiwan)",
        "codiceistat": "363",
        "codiceauto": null,
        "codcatasto": "Z217",
        "dataend": "1975-12-31",
        "altreinfo": null
    },
    {
        "descrizione": "Russa, Federazione",
        "codiceistat": "245",
        "codiceauto": null,
        "codcatasto": "Z154",
        "dataend": null,
        "altreinfo": null
    },
    {
        "descrizione": "Stato della Città del Vaticano",
        "codiceistat": "246",
        "codiceauto": null,
        "codcatasto": "Z106",
        "dataend": null,
        "altreinfo": null
    },
    {
        "descrizione": "Territori dell'Autonomia Palestinese",
        "codiceistat": "324",
        "codiceauto": null,
        "codcatasto": "Z161",
        "dataend": null,
        "altreinfo": null
    },
    {
        "descrizione": "Germania repubblica democratica",
        "codiceistat": "770",
        "codiceauto": null,
        "codcatasto": "Z111",
        "dataend": "1990-10-03",
        "altreinfo": null
    },
    {
        "descrizione": "Germania repubblica federale",
        "codiceistat": "216",
        "codiceauto": null,
        "codcatasto": "Z112",
        "dataend": "1990-10-03",
        "altreinfo": null
    },
    {
        "descrizione": "Centroafricano impero",
        "codiceistat": null,
        "codiceauto": null,
        "codcatasto": "Z308",
        "dataend": "1980-12-31",
        "altreinfo": null
    },
    {
        "descrizione": "Congo brazzaville",
        "codiceistat": null,
        "codiceauto": null,
        "codcatasto": "Z311",
        "dataend": "1975-12-31",
        "altreinfo": null
    },
    {
        "descrizione": "Congo leopoldville",
        "codiceistat": null,
        "codiceauto": null,
        "codcatasto": "Z312",
        "dataend": "1975-12-31",
        "altreinfo": null
    },
    {
        "descrizione": "Guinea portoghese",
        "codiceistat": null,
        "codiceauto": "P",
        "codcatasto": "Z320",
        "dataend": "1975-12-31",
        "altreinfo": null
    },
    {
        "descrizione": "Guinea spagnola",
        "codiceistat": null,
        "codiceauto": "E",
        "codcatasto": "Z321",
        "dataend": "1975-12-31",
        "altreinfo": null
    },
    {
        "descrizione": "Albania",
        "codiceistat": "201",
        "codiceauto": null,
        "codcatasto": "Z100",
        "dataend": null,
        "altreinfo": "AL"
    },
    {
        "descrizione": "Algeria",
        "codiceistat": "401",
        "codiceauto": null,
        "codcatasto": "Z301",
        "dataend": null,
        "altreinfo": "DZ"
    },
    {
        "descrizione": "Andorra",
        "codiceistat": "202",
        "codiceauto": null,
        "codcatasto": "Z101",
        "dataend": null,
        "altreinfo": "AND"
    },
    {
        "descrizione": "Arabia meridionale protettorato",
        "codiceistat": null,
        "codiceauto": "GB",
        "codcatasto": "Z202",
        "dataend": "1975-12-31",
        "altreinfo": "201"
    },
    {
        "descrizione": "Arabia saudita",
        "codiceistat": "302",
        "codiceauto": null,
        "codcatasto": "Z203",
        "dataend": null,
        "altreinfo": "KSA"
    },
    {
        "descrizione": "Armenia",
        "codiceistat": "358",
        "codiceauto": null,
        "codcatasto": "Z137",
        "dataend": "1994-01-01",
        "altreinfo": "ARM"
    },
    {
        "descrizione": "Bangladesh",
        "codiceistat": "305",
        "codiceauto": null,
        "codcatasto": "Z249",
        "dataend": null,
        "altreinfo": "BD"
    },
    {
        "descrizione": "Belgio",
        "codiceistat": "206",
        "codiceauto": null,
        "codcatasto": "Z103",
        "dataend": null,
        "altreinfo": "B"
    },
    {
        "descrizione": "Bhutan",
        "codiceistat": "306",
        "codiceauto": null,
        "codcatasto": "Z205",
        "dataend": null,
        "altreinfo": "BHT"
    },
    {
        "descrizione": "Bielorussia",
        "codiceistat": "256",
        "codiceauto": null,
        "codcatasto": "Z139",
        "dataend": null,
        "altreinfo": "BY"
    },
    {
        "descrizione": "Bosnia-Erzegovina",
        "codiceistat": "252",
        "codiceauto": null,
        "codcatasto": "Z153",
        "dataend": null,
        "altreinfo": "BIH"
    },
    {
        "descrizione": "Brunei",
        "codiceistat": "309",
        "codiceauto": "GB",
        "codcatasto": "Z207",
        "dataend": "1984-12-31",
        "altreinfo": "BRU"
    },
    {
        "descrizione": "Cambogia",
        "codiceistat": "310",
        "codiceauto": null,
        "codcatasto": "Z208",
        "dataend": null,
        "altreinfo": "K"
    },
    {
        "descrizione": "Cecoslovacchia",
        "codiceistat": "750",
        "codiceauto": null,
        "codcatasto": "Z105",
        "dataend": "1993-01-01",
        "altreinfo": "CS"
    },
    {
        "descrizione": "Cipro",
        "codiceistat": "315",
        "codiceauto": null,
        "codcatasto": "Z211",
        "dataend": null,
        "altreinfo": "CY"
    },
    {
        "descrizione": "Danimarca",
        "codiceistat": "212",
        "codiceauto": null,
        "codcatasto": "Z107",
        "dataend": null,
        "altreinfo": "DK"
    },
    {
        "descrizione": "Emirati arabi uniti",
        "codiceistat": "322",
        "codiceauto": null,
        "codcatasto": "Z215",
        "dataend": null,
        "altreinfo": "UAE"
    },
    {
        "descrizione": "Estonia",
        "codiceistat": "247",
        "codiceauto": null,
        "codcatasto": "Z144",
        "dataend": null,
        "altreinfo": "EST"
    },
    {
        "descrizione": "Filippine",
        "codiceistat": "323",
        "codiceauto": null,
        "codcatasto": "Z216",
        "dataend": null,
        "altreinfo": "RP"
    },
    {
        "descrizione": "Finlandia",
        "codiceistat": "214",
        "codiceauto": null,
        "codcatasto": "Z109",
        "dataend": null,
        "altreinfo": "FIN"
    },
    {
        "descrizione": "Francia",
        "codiceistat": "215",
        "codiceauto": null,
        "codcatasto": "Z110",
        "dataend": null,
        "altreinfo": "F"
    },
    {
        "descrizione": "Georgia",
        "codiceistat": "360",
        "codiceauto": null,
        "codcatasto": "Z136",
        "dataend": "1994-01-01",
        "altreinfo": "GE"
    },
    {
        "descrizione": "Giappone",
        "codiceistat": "326",
        "codiceauto": null,
        "codcatasto": "Z219",
        "dataend": null,
        "altreinfo": "J"
    },
    {
        "descrizione": "Gibilterra",
        "codiceistat": "218",
        "codiceauto": "GB",
        "codcatasto": "Z113",
        "dataend": null,
        "altreinfo": "GBZ"
    },
    {
        "descrizione": "Giordania",
        "codiceistat": "327",
        "codiceauto": null,
        "codcatasto": "Z220",
        "dataend": null,
        "altreinfo": "HKJ"
    },
    {
        "descrizione": "Grecia",
        "codiceistat": "220",
        "codiceauto": null,
        "codcatasto": "Z115",
        "dataend": null,
        "altreinfo": "GR"
    },
    {
        "descrizione": "Guinea equatoriale",
        "codiceistat": null,
        "codiceauto": null,
        "codcatasto": "Z321",
        "dataend": null,
        "altreinfo": "GQ"
    },
    {
        "descrizione": "Guinea bissau",
        "codiceistat": null,
        "codiceauto": null,
        "codcatasto": "Z320",
        "dataend": null,
        "altreinfo": "GNB"
    },
    {
        "descrizione": "Indonesia",
        "codiceistat": "331",
        "codiceauto": null,
        "codcatasto": "Z223",
        "dataend": null,
        "altreinfo": "RI"
    },
    {
        "descrizione": "Iran, Repubblica Islamica del",
        "codiceistat": "332",
        "codiceauto": null,
        "codcatasto": "Z224",
        "dataend": null,
        "altreinfo": "IR"
    },
    {
        "descrizione": "Iraq",
        "codiceistat": "333",
        "codiceauto": null,
        "codcatasto": "Z225",
        "dataend": null,
        "altreinfo": "IRQ"
    },
    {
        "descrizione": "Islanda",
        "codiceistat": "223",
        "codiceauto": null,
        "codcatasto": "Z117",
        "dataend": null,
        "altreinfo": "IS"
    },
    {
        "descrizione": "Israele",
        "codiceistat": "334",
        "codiceauto": null,
        "codcatasto": "Z226",
        "dataend": null,
        "altreinfo": "IL"
    },
    {
        "descrizione": "Iugoslavia",
        "codiceistat": null,
        "codiceauto": null,
        "codcatasto": "Z118",
        "dataend": null,
        "altreinfo": "YU"
    },
    {
        "descrizione": "Kazakhstan ",
        "codiceistat": "356",
        "codiceauto": null,
        "codcatasto": "Z152",
        "dataend": "1994-01-01",
        "altreinfo": "KZ"
    },
    {
        "descrizione": "Kirghizistan",
        "codiceistat": "361",
        "codiceauto": null,
        "codcatasto": "Z142",
        "dataend": "1994-01-01",
        "altreinfo": "KS"
    },
    {
        "descrizione": "Kuwait",
        "codiceistat": "335",
        "codiceauto": null,
        "codcatasto": "Z227",
        "dataend": null,
        "altreinfo": "KWT"
    },
    {
        "descrizione": "Laos",
        "codiceistat": "336",
        "codiceauto": null,
        "codcatasto": "Z228",
        "dataend": null,
        "altreinfo": "LAO"
    },
    {
        "descrizione": "Libano",
        "codiceistat": "337",
        "codiceauto": null,
        "codcatasto": "Z229",
        "dataend": null,
        "altreinfo": "RL"
    },
    {
        "descrizione": "Liechtenstein",
        "codiceistat": "225",
        "codiceauto": null,
        "codcatasto": "Z119",
        "dataend": null,
        "altreinfo": "FL"
    },
    {
        "descrizione": "Lituania",
        "codiceistat": "249",
        "codiceauto": null,
        "codcatasto": "Z146",
        "dataend": null,
        "altreinfo": "LT"
    },
    {
        "descrizione": "Lussemburgo",
        "codiceistat": "226",
        "codiceauto": null,
        "codcatasto": "Z120",
        "dataend": null,
        "altreinfo": "L"
    },
    {
        "descrizione": "Macao",
        "codiceistat": null,
        "codiceauto": "P",
        "codcatasto": "Z231",
        "dataend": null,
        "altreinfo": "MAC"
    },
    {
        "descrizione": "Maldive",
        "codiceistat": "339",
        "codiceauto": null,
        "codcatasto": "Z232",
        "dataend": null,
        "altreinfo": "MV"
    },
    {
        "descrizione": "Malta",
        "codiceistat": "227",
        "codiceauto": null,
        "codcatasto": "Z121",
        "dataend": null,
        "altreinfo": "M"
    },
    {
        "descrizione": "Man (isola)",
        "codiceistat": null,
        "codiceauto": "GB",
        "codcatasto": "Z122",
        "dataend": null,
        "altreinfo": "122"
    },
    {
        "descrizione": "Monaco",
        "codiceistat": "229",
        "codiceauto": null,
        "codcatasto": "Z123",
        "dataend": null,
        "altreinfo": "MC"
    },
    {
        "descrizione": "Mongolia",
        "codiceistat": "341",
        "codiceauto": null,
        "codcatasto": "Z233",
        "dataend": null,
        "altreinfo": "MNG"
    },
    {
        "descrizione": "Montenegro",
        "codiceistat": "270",
        "codiceauto": "",
        "codcatasto": "Z159",
        "dataend": null,
        "altreinfo": "MNE"
    },
    {
        "descrizione": "Myanmar (ex Birmania)",
        "codiceistat": "307",
        "codiceauto": null,
        "codcatasto": "Z206",
        "dataend": null,
        "altreinfo": "BUR"
    },
    {
        "descrizione": "Namibia",
        "codiceistat": "441",
        "codiceauto": null,
        "codcatasto": "Z300",
        "dataend": null,
        "altreinfo": "NAM"
    },
    {
        "descrizione": "Nepal",
        "codiceistat": "342",
        "codiceauto": null,
        "codcatasto": "Z234",
        "dataend": null,
        "altreinfo": "NEP"
    },
    {
        "descrizione": "Norvegia",
        "codiceistat": "231",
        "codiceauto": null,
        "codcatasto": "Z125",
        "dataend": null,
        "altreinfo": "N"
    },
    {
        "descrizione": "Oman",
        "codiceistat": "343",
        "codiceauto": null,
        "codcatasto": "Z235",
        "dataend": null,
        "altreinfo": "OM"
    },
    {
        "descrizione": "Paesi bassi",
        "codiceistat": null,
        "codiceauto": null,
        "codcatasto": "Z126",
        "dataend": null,
        "altreinfo": "NL"
    },
    {
        "descrizione": "Pakistan",
        "codiceistat": "344",
        "codiceauto": null,
        "codcatasto": "Z236",
        "dataend": null,
        "altreinfo": "PK"
    },
    {
        "descrizione": "Polonia",
        "codiceistat": "233",
        "codiceauto": null,
        "codcatasto": "Z127",
        "dataend": "1989-12-31",
        "altreinfo": "PL"
    },
    {
        "descrizione": "Portogallo",
        "codiceistat": "234",
        "codiceauto": null,
        "codcatasto": "Z128",
        "dataend": null,
        "altreinfo": "P"
    },
    {
        "descrizione": "Qatar",
        "codiceistat": "345",
        "codiceauto": "GB",
        "codcatasto": "Z237",
        "dataend": "1975-12-31",
        "altreinfo": "Q"
    },
    {
        "descrizione": "Regno unito",
        "codiceistat": "219",
        "codiceauto": "GB",
        "codcatasto": "Z114",
        "dataend": null,
        "altreinfo": "GB"
    },
    {
        "descrizione": "Romania",
        "codiceistat": "235",
        "codiceauto": null,
        "codcatasto": "Z129",
        "dataend": null,
        "altreinfo": "RO"
    },
    {
        "descrizione": "San marino",
        "codiceistat": null,
        "codiceauto": null,
        "codcatasto": "Z130",
        "dataend": null,
        "altreinfo": "RSM"
    },
    {
        "descrizione": "Serbia e montenegro",
        "codiceistat": "271",
        "codiceauto": null,
        "codcatasto": "Z157",
        "dataend": "2006-06-02",
        "altreinfo": "SCG"
    },
    {
        "descrizione": "Serbia, Repubblica di",
        "codiceistat": "271",
        "codiceauto": null,
        "codcatasto": "Z158",
        "dataend": null,
        "altreinfo": "SRB"
    },
    {
        "descrizione": "Sikkim",
        "codiceistat": null,
        "codiceauto": null,
        "codcatasto": "Z239",
        "dataend": "1975-12-31",
        "altreinfo": "239"
    },
    {
        "descrizione": "Siria",
        "codiceistat": "348",
        "codiceauto": null,
        "codcatasto": "Z240",
        "dataend": null,
        "altreinfo": "SYR"
    },
    {
        "descrizione": "Slovenia",
        "codiceistat": "251",
        "codiceauto": null,
        "codcatasto": "Z150",
        "dataend": null,
        "altreinfo": "SLO"
    },
    {
        "descrizione": "Spagna",
        "codiceistat": "239",
        "codiceauto": null,
        "codcatasto": "Z131",
        "dataend": null,
        "altreinfo": "E"
    },
    {
        "descrizione": "Svezia",
        "codiceistat": "240",
        "codiceauto": null,
        "codcatasto": "Z132",
        "dataend": null,
        "altreinfo": "S"
    },
    {
        "descrizione": "Svizzera",
        "codiceistat": "241",
        "codiceauto": null,
        "codcatasto": "Z133",
        "dataend": null,
        "altreinfo": "CH"
    },
    {
        "descrizione": "Thailandia",
        "codiceistat": "349",
        "codiceauto": null,
        "codcatasto": "Z241",
        "dataend": null,
        "altreinfo": "T"
    },
    {
        "descrizione": "Timor orientale",
        "codiceistat": "338",
        "codiceauto": "P",
        "codcatasto": "Z242",
        "dataend": null,
        "altreinfo": "TL"
    },
    {
        "descrizione": "Ucraina",
        "codiceistat": "243",
        "codiceauto": null,
        "codcatasto": "Z138",
        "dataend": null,
        "altreinfo": "UA"
    },
    {
        "descrizione": "Ungheria",
        "codiceistat": "244",
        "codiceauto": null,
        "codcatasto": "Z134",
        "dataend": null,
        "altreinfo": "H"
    },
    {
        "descrizione": "Unione repubbliche socialiste sovietiche",
        "codiceistat": null,
        "codiceauto": null,
        "codcatasto": "Z135",
        "dataend": "1991-09-06",
        "altreinfo": "SU"
    },
    {
        "descrizione": "Uzbekistan",
        "codiceistat": "357",
        "codiceauto": null,
        "codcatasto": "Z143",
        "dataend": "1994-01-01",
        "altreinfo": "UZ"
    },
    {
        "descrizione": "Vietnam",
        "codiceistat": "353",
        "codiceauto": null,
        "codcatasto": "Z251",
        "dataend": null,
        "altreinfo": "VN"
    },
    {
        "descrizione": "Vietnam del nord",
        "codiceistat": null,
        "codiceauto": null,
        "codcatasto": "Z245",
        "dataend": "1977-12-31",
        "altreinfo": "VNN"
    },
    {
        "descrizione": "Vietnam del sud",
        "codiceistat": null,
        "codiceauto": null,
        "codcatasto": "Z244",
        "dataend": "1977-12-31",
        "altreinfo": "VNS"
    },
    {
        "descrizione": "Yemen",
        "codiceistat": "354",
        "codiceauto": null,
        "codcatasto": "Z246",
        "dataend": null,
        "altreinfo": "ADN"
    },
    {
        "descrizione": "La reunion (isola)",
        "codiceistat": null,
        "codiceauto": "F",
        "codcatasto": "Z324",
        "dataend": null,
        "altreinfo": null
    },
    {
        "descrizione": "Rhodesia",
        "codiceistat": null,
        "codiceauto": null,
        "codcatasto": "Z337",
        "dataend": "1980-12-31",
        "altreinfo": null
    },
    {
        "descrizione": "Territorio francese degli afar e degli issa",
        "codiceistat": null,
        "codiceauto": "F",
        "codcatasto": "Z346",
        "dataend": "1977-12-31",
        "altreinfo": null
    },
    {
        "descrizione": "Swaziland-sudafrica britannico",
        "codiceistat": null,
        "codiceauto": "GB",
        "codcatasto": "Z349",
        "dataend": "1975-12-31",
        "altreinfo": null
    },
    {
        "descrizione": "Nyasaland",
        "codiceistat": null,
        "codiceauto": "",
        "codcatasto": "Z369",
        "dataend": "1964-07-05",
        "altreinfo": null
    },
    {
        "descrizione": "Congo belga",
        "codiceistat": null,
        "codiceauto": null,
        "codcatasto": "Z370",
        "dataend": "1960-01-01",
        "altreinfo": null
    },
    {
        "descrizione": "Honduras britannico",
        "codiceistat": null,
        "codiceauto": "GB",
        "codcatasto": "Z512",
        "dataend": "1975-12-31",
        "altreinfo": null
    },
    {
        "descrizione": "Turks e caicos (isole)",
        "codiceistat": null,
        "codiceauto": "GB",
        "codcatasto": "Z519",
        "dataend": null,
        "altreinfo": null
    },
    {
        "descrizione": "Vergini americane (isole)",
        "codiceistat": null,
        "codiceauto": "USA",
        "codcatasto": "Z520",
        "dataend": null,
        "altreinfo": null
    },
    {
        "descrizione": "Vergini britanniche (isole)",
        "codiceistat": null,
        "codiceauto": "GB",
        "codcatasto": "Z525",
        "dataend": null,
        "altreinfo": null
    },
    {
        "descrizione": "Saint lucia",
        "codiceistat": null,
        "codiceauto": null,
        "codcatasto": "Z527",
        "dataend": null,
        "altreinfo": null
    },
    {
        "descrizione": "Dahomey (benin)",
        "codiceistat": "406",
        "codiceauto": null,
        "codcatasto": "Z314",
        "dataend": "1975-12-31",
        "altreinfo": null
    },
    {
        "descrizione": "Centrafricana, Repubblica",
        "codiceistat": "414",
        "codiceauto": null,
        "codcatasto": "Z308",
        "dataend": null,
        "altreinfo": null
    },
    {
        "descrizione": "Centroafricana repubblica",
        "codiceistat": "414",
        "codiceauto": null,
        "codcatasto": "Z308",
        "dataend": "1977-12-31",
        "altreinfo": null
    },
    {
        "descrizione": "Congo (Repubblica del)",
        "codiceistat": "418",
        "codiceauto": null,
        "codcatasto": "Z311",
        "dataend": null,
        "altreinfo": null
    },
    {
        "descrizione": "Zaire",
        "codiceistat": "463",
        "codiceauto": null,
        "codcatasto": "Z312",
        "dataend": "1997-05-17",
        "altreinfo": null
    },
    {
        "descrizione": "Dominicana, Repubblica",
        "codiceistat": "516",
        "codiceauto": null,
        "codcatasto": "Z505",
        "dataend": null,
        "altreinfo": null
    },
    {
        "descrizione": "Volta (Altovolta)",
        "codiceistat": "409",
        "codiceauto": null,
        "codcatasto": "Z354",
        "dataend": "1984-12-31",
        "altreinfo": null
    },
    {
        "descrizione": "Cayman (isole)",
        "codiceistat": null,
        "codiceauto": "GB",
        "codcatasto": "Z530",
        "dataend": null,
        "altreinfo": null
    },
    {
        "descrizione": "Guyana britannica",
        "codiceistat": null,
        "codiceauto": "GB",
        "codcatasto": "Z606",
        "dataend": "1975-12-31",
        "altreinfo": null
    },
    {
        "descrizione": "Malvine=falkland (isole)",
        "codiceistat": null,
        "codiceauto": "GB",
        "codcatasto": "Z609",
        "dataend": null,
        "altreinfo": null
    },
    {
        "descrizione": "Cook (isole)",
        "codiceistat": null,
        "codiceauto": "NZ",
        "codcatasto": "Z703",
        "dataend": null,
        "altreinfo": null
    },
    {
        "descrizione": "Marianne (isole)",
        "codiceistat": null,
        "codiceauto": "USA",
        "codcatasto": "Z710",
        "dataend": null,
        "altreinfo": null
    },
    {
        "descrizione": "Marshall (isole)",
        "codiceistat": null,
        "codiceauto": "USA",
        "codcatasto": "Z711",
        "dataend": "1990-12-22",
        "altreinfo": null
    },
    {
        "descrizione": "Midway (isole)",
        "codiceistat": null,
        "codiceauto": "USA",
        "codcatasto": "Z712",
        "dataend": null,
        "altreinfo": null
    },
    {
        "descrizione": "Bahamas",
        "codiceistat": "505",
        "codiceauto": null,
        "codcatasto": "Z502",
        "dataend": null,
        "altreinfo": "BS"
    },
    {
        "descrizione": "Bahamas (isole)",
        "codiceistat": "505",
        "codiceauto": "GB",
        "codcatasto": "Z502",
        "dataend": "1975-12-31",
        "altreinfo": "BS"
    },
    {
        "descrizione": "Barbados",
        "codiceistat": "506",
        "codiceauto": null,
        "codcatasto": "Z522",
        "dataend": null,
        "altreinfo": "BDS"
    },
    {
        "descrizione": "Beciuania sud africa britannico",
        "codiceistat": "744",
        "codiceauto": "GB",
        "codcatasto": "Z304",
        "dataend": "1966-10-01",
        "altreinfo": "304"
    },
    {
        "descrizione": "Belize",
        "codiceistat": "507",
        "codiceauto": null,
        "codcatasto": "Z512",
        "dataend": null,
        "altreinfo": "BH"
    },
    {
        "descrizione": "Benin (ex Dahomey)",
        "codiceistat": "406",
        "codiceauto": null,
        "codcatasto": "Z314",
        "dataend": null,
        "altreinfo": "BPB"
    },
    {
        "descrizione": "Bermuda",
        "codiceistat": "745",
        "codiceauto": "GB",
        "codcatasto": "Z400",
        "dataend": null,
        "altreinfo": "BMU"
    },
    {
        "descrizione": "Bophuthatswana",
        "codiceistat": null,
        "codiceauto": null,
        "codcatasto": "Z364",
        "dataend": "1994-01-01",
        "altreinfo": "364"
    },
    {
        "descrizione": "Burundi",
        "codiceistat": "410",
        "codiceauto": null,
        "codcatasto": "Z305",
        "dataend": null,
        "altreinfo": "BU"
    },
    {
        "descrizione": "Camerun",
        "codiceistat": "411",
        "codiceauto": null,
        "codcatasto": "Z306",
        "dataend": null,
        "altreinfo": "CAM"
    },
    {
        "descrizione": "Canada",
        "codiceistat": "509",
        "codiceauto": null,
        "codcatasto": "Z401",
        "dataend": null,
        "altreinfo": "CND"
    },
    {
        "descrizione": "Capo verde (isole)",
        "codiceistat": "413",
        "codiceauto": "P",
        "codcatasto": "Z307",
        "dataend": "1975-12-31",
        "altreinfo": "CV"
    },
    {
        "descrizione": "Ciad",
        "codiceistat": "415",
        "codiceauto": null,
        "codcatasto": "Z309",
        "dataend": null,
        "altreinfo": "TCH"
    },
    {
        "descrizione": "Ciskei",
        "codiceistat": "754",
        "codiceauto": null,
        "codcatasto": "Z367",
        "dataend": "1994-01-01",
        "altreinfo": "367"
    },
    {
        "descrizione": "Comore",
        "codiceistat": "417",
        "codiceauto": null,
        "codcatasto": "Z310",
        "dataend": null,
        "altreinfo": "COM"
    },
    {
        "descrizione": "Comore (isole)",
        "codiceistat": "417",
        "codiceauto": "F",
        "codcatasto": "Z310",
        "dataend": "1975-12-31",
        "altreinfo": "COM"
    },
    {
        "descrizione": "Costa d'avorio",
        "codiceistat": "404",
        "codiceauto": null,
        "codcatasto": "Z313",
        "dataend": null,
        "altreinfo": "CI"
    },
    {
        "descrizione": "Costa rica",
        "codiceistat": "513",
        "codiceauto": null,
        "codcatasto": "Z503",
        "dataend": null,
        "altreinfo": "CR"
    },
    {
        "descrizione": "Cuba",
        "codiceistat": "514",
        "codiceauto": null,
        "codcatasto": "Z504",
        "dataend": null,
        "altreinfo": "C"
    },
    {
        "descrizione": "Egitto",
        "codiceistat": "419",
        "codiceauto": null,
        "codcatasto": "Z336",
        "dataend": null,
        "altreinfo": "ET"
    },
    {
        "descrizione": "El salvador",
        "codiceistat": "517",
        "codiceauto": null,
        "codcatasto": "Z506",
        "dataend": null,
        "altreinfo": "ES"
    },
    {
        "descrizione": "Etiopia",
        "codiceistat": "420",
        "codiceauto": null,
        "codcatasto": "Z315",
        "dataend": "1993-05-24",
        "altreinfo": "ETH"
    },
    {
        "descrizione": "Gabon",
        "codiceistat": "421",
        "codiceauto": null,
        "codcatasto": "Z316",
        "dataend": null,
        "altreinfo": "G"
    },
    {
        "descrizione": "Gambia",
        "codiceistat": "422",
        "codiceauto": null,
        "codcatasto": "Z317",
        "dataend": null,
        "altreinfo": "WAG"
    },
    {
        "descrizione": "Ghana",
        "codiceistat": "423",
        "codiceauto": null,
        "codcatasto": "Z318",
        "dataend": null,
        "altreinfo": "GH"
    },
    {
        "descrizione": "Gibuti",
        "codiceistat": "424",
        "codiceauto": null,
        "codcatasto": "Z361",
        "dataend": null,
        "altreinfo": "DJI"
    },
    {
        "descrizione": "Groenlandia",
        "codiceistat": "520",
        "codiceauto": "DK",
        "codcatasto": "Z402",
        "dataend": null,
        "altreinfo": "GRL"
    },
    {
        "descrizione": "Guadalupa",
        "codiceistat": "771",
        "codiceauto": "F",
        "codcatasto": "Z508",
        "dataend": null,
        "altreinfo": "GP"
    },
    {
        "descrizione": "Guatemala",
        "codiceistat": "523",
        "codiceauto": null,
        "codcatasto": "Z509",
        "dataend": null,
        "altreinfo": "GCA"
    },
    {
        "descrizione": "Guinea",
        "codiceistat": "425",
        "codiceauto": null,
        "codcatasto": "Z319",
        "dataend": null,
        "altreinfo": "RG"
    },
    {
        "descrizione": "Haiti",
        "codiceistat": "524",
        "codiceauto": null,
        "codcatasto": "Z510",
        "dataend": null,
        "altreinfo": "RH"
    },
    {
        "descrizione": "Honduras",
        "codiceistat": "525",
        "codiceauto": null,
        "codcatasto": "Z511",
        "dataend": null,
        "altreinfo": "HN"
    },
    {
        "descrizione": "Irian occidentale",
        "codiceistat": null,
        "codiceauto": "RI",
        "codcatasto": "Z707",
        "dataend": null,
        "altreinfo": "707"
    },
    {
        "descrizione": "Kenya",
        "codiceistat": "428",
        "codiceauto": null,
        "codcatasto": "Z322",
        "dataend": null,
        "altreinfo": "EAK"
    },
    {
        "descrizione": "Lesotho",
        "codiceistat": "429",
        "codiceauto": null,
        "codcatasto": "Z359",
        "dataend": null,
        "altreinfo": "LS"
    },
    {
        "descrizione": "Liberia",
        "codiceistat": "430",
        "codiceauto": null,
        "codcatasto": "Z325",
        "dataend": null,
        "altreinfo": "LB"
    },
    {
        "descrizione": "Libia",
        "codiceistat": "431",
        "codiceauto": null,
        "codcatasto": "Z326",
        "dataend": null,
        "altreinfo": "LAR"
    },
    {
        "descrizione": "Macquarie (isole)",
        "codiceistat": null,
        "codiceauto": "AUS",
        "codcatasto": "Z708",
        "dataend": null,
        "altreinfo": "708"
    },
    {
        "descrizione": "Malawi",
        "codiceistat": "434",
        "codiceauto": null,
        "codcatasto": "Z328",
        "dataend": null,
        "altreinfo": "MW"
    },
    {
        "descrizione": "Mali",
        "codiceistat": "435",
        "codiceauto": null,
        "codcatasto": "Z329",
        "dataend": null,
        "altreinfo": "RMM"
    },
    {
        "descrizione": "Marocco",
        "codiceistat": "436",
        "codiceauto": null,
        "codcatasto": "Z330",
        "dataend": null,
        "altreinfo": "MA"
    },
    {
        "descrizione": "Martinica",
        "codiceistat": null,
        "codiceauto": "F",
        "codcatasto": "Z513",
        "dataend": null,
        "altreinfo": "MQ"
    },
    {
        "descrizione": "Mauritania",
        "codiceistat": "437",
        "codiceauto": null,
        "codcatasto": "Z331",
        "dataend": null,
        "altreinfo": "RIM"
    },
    {
        "descrizione": "Mauritius",
        "codiceistat": "438",
        "codiceauto": null,
        "codcatasto": "Z332",
        "dataend": null,
        "altreinfo": "MS"
    },
    {
        "descrizione": "Mauritius (isole)",
        "codiceistat": "438",
        "codiceauto": "GB",
        "codcatasto": "Z332",
        "dataend": "1975-12-31",
        "altreinfo": "MS"
    },
    {
        "descrizione": "Mayotte (isola)",
        "codiceistat": null,
        "codiceauto": "F",
        "codcatasto": "Z360",
        "dataend": null,
        "altreinfo": "MYT"
    },
    {
        "descrizione": "Messico",
        "codiceistat": "527",
        "codiceauto": null,
        "codcatasto": "Z514",
        "dataend": null,
        "altreinfo": "MEX"
    },
    {
        "descrizione": "Montserrat",
        "codiceistat": null,
        "codiceauto": "GB",
        "codcatasto": "Z531",
        "dataend": null,
        "altreinfo": "MSR"
    },
    {
        "descrizione": "Mozambico",
        "codiceistat": "440",
        "codiceauto": null,
        "codcatasto": "Z333",
        "dataend": null,
        "altreinfo": "MOC"
    },
    {
        "descrizione": "Nauru (isole)",
        "codiceistat": null,
        "codiceauto": "AUS",
        "codcatasto": "Z713",
        "dataend": "1975-12-31",
        "altreinfo": "NAU"
    },
    {
        "descrizione": "Nicaragua",
        "codiceistat": "529",
        "codiceauto": null,
        "codcatasto": "Z515",
        "dataend": null,
        "altreinfo": "NIC"
    },
    {
        "descrizione": "Niger",
        "codiceistat": "442",
        "codiceauto": null,
        "codcatasto": "Z334",
        "dataend": null,
        "altreinfo": "RN"
    },
    {
        "descrizione": "Niue=savage (isole)",
        "codiceistat": null,
        "codiceauto": "NZ",
        "codcatasto": "Z714",
        "dataend": null,
        "altreinfo": "NIU"
    },
    {
        "descrizione": "Panama",
        "codiceistat": "530",
        "codiceauto": null,
        "codcatasto": "Z516",
        "dataend": null,
        "altreinfo": "PA"
    },
    {
        "descrizione": "Panama zona del canale",
        "codiceistat": "530",
        "codiceauto": "USA",
        "codcatasto": "Z517",
        "dataend": "1980-12-31",
        "altreinfo": "517"
    },
    {
        "descrizione": "Puerto rico",
        "codiceistat": null,
        "codiceauto": "USA",
        "codcatasto": "Z518",
        "dataend": null,
        "altreinfo": "PRI"
    },
    {
        "descrizione": "Ruanda",
        "codiceistat": "446",
        "codiceauto": null,
        "codcatasto": "Z338",
        "dataend": null,
        "altreinfo": "RWA"
    },
    {
        "descrizione": "Sahara settentrionale",
        "codiceistat": null,
        "codiceauto": "MA",
        "codcatasto": "Z363",
        "dataend": "1984-12-31",
        "altreinfo": "363"
    },
    {
        "descrizione": "Sahara spagnolo",
        "codiceistat": null,
        "codiceauto": null,
        "codcatasto": "Z339",
        "dataend": null,
        "altreinfo": "339"
    },
    {
        "descrizione": "Saint pierre et miquelon (isole)",
        "codiceistat": null,
        "codiceauto": "F",
        "codcatasto": "Z403",
        "dataend": null,
        "altreinfo": "SPM"
    },
    {
        "descrizione": "Saint vincent e grenadine",
        "codiceistat": null,
        "codiceauto": null,
        "codcatasto": "Z528",
        "dataend": null,
        "altreinfo": "WV"
    },
    {
        "descrizione": "Sant'elena (isola)",
        "codiceistat": null,
        "codiceauto": "GB",
        "codcatasto": "Z340",
        "dataend": null,
        "altreinfo": "SHN"
    },
    {
        "descrizione": "São Tomé e Principe",
        "codiceistat": "448",
        "codiceauto": null,
        "codcatasto": "Z341",
        "dataend": null,
        "altreinfo": "STP"
    },
    {
        "descrizione": "Seychelles",
        "codiceistat": "449",
        "codiceauto": null,
        "codcatasto": "Z342",
        "dataend": null,
        "altreinfo": "SY"
    },
    {
        "descrizione": "Seychelles (isole)",
        "codiceistat": "449",
        "codiceauto": null,
        "codcatasto": "Z342",
        "dataend": null,
        "altreinfo": "SY"
    },
    {
        "descrizione": "Sierra leone",
        "codiceistat": null,
        "codiceauto": null,
        "codcatasto": "Z344",
        "dataend": null,
        "altreinfo": "WAL"
    },
    {
        "descrizione": "Somalia",
        "codiceistat": "453",
        "codiceauto": null,
        "codcatasto": "Z345",
        "dataend": null,
        "altreinfo": "SO"
    },
    {
        "descrizione": "Somalia francese",
        "codiceistat": null,
        "codiceauto": "F",
        "codcatasto": "Z346",
        "dataend": "1975-12-31",
        "altreinfo": "346"
    },
    {
        "descrizione": "Sud Africa",
        "codiceistat": "454",
        "codiceauto": null,
        "codcatasto": "Z347",
        "dataend": null,
        "altreinfo": "ZA"
    },
    {
        "descrizione": "Sudan",
        "codiceistat": "455",
        "codiceauto": null,
        "codcatasto": "Z348",
        "dataend": null,
        "altreinfo": "SUN"
    },
    {
        "descrizione": "Swaziland",
        "codiceistat": "456",
        "codiceauto": null,
        "codcatasto": "Z349",
        "dataend": null,
        "altreinfo": "SD"
    },
    {
        "descrizione": "Tanganica",
        "codiceistat": null,
        "codiceauto": null,
        "codcatasto": "Z350",
        "dataend": "1966-10-01",
        "altreinfo": "350"
    },
    {
        "descrizione": "Tanzania",
        "codiceistat": "457",
        "codiceauto": null,
        "codcatasto": "Z357",
        "dataend": null,
        "altreinfo": "EAT"
    },
    {
        "descrizione": "Togo",
        "codiceistat": "458",
        "codiceauto": null,
        "codcatasto": "Z351",
        "dataend": null,
        "altreinfo": "TG"
    },
    {
        "descrizione": "Transkei",
        "codiceistat": null,
        "codiceauto": null,
        "codcatasto": "Z365",
        "dataend": "1994-01-01",
        "altreinfo": "365"
    },
    {
        "descrizione": "Trinidad e tobago",
        "codiceistat": null,
        "codiceauto": null,
        "codcatasto": "Z612",
        "dataend": null,
        "altreinfo": "TT"
    },
    {
        "descrizione": "Tunisia",
        "codiceistat": "460",
        "codiceauto": null,
        "codcatasto": "Z352",
        "dataend": null,
        "altreinfo": "TN"
    },
    {
        "descrizione": "Venda",
        "codiceistat": null,
        "codiceauto": null,
        "codcatasto": "Z366",
        "dataend": "1994-01-01",
        "altreinfo": "366"
    },
    {
        "descrizione": "Zambia",
        "codiceistat": "464",
        "codiceauto": null,
        "codcatasto": "Z355",
        "dataend": null,
        "altreinfo": "Z"
    },
    {
        "descrizione": "Zimbabwe (ex Rhodesia) ",
        "codiceistat": "465",
        "codiceauto": null,
        "codcatasto": "Z337",
        "dataend": null,
        "altreinfo": "ZW"
    },
    {
        "descrizione": "Norfolk (isole e isole del mar dei coralli)",
        "codiceistat": null,
        "codiceauto": "AUS",
        "codcatasto": "Z715",
        "dataend": null,
        "altreinfo": null
    },
    {
        "descrizione": "Isole cilene (pasqua e sala y gomez)",
        "codiceistat": null,
        "codiceauto": "RCH",
        "codcatasto": "Z721",
        "dataend": null,
        "altreinfo": null
    },
    {
        "descrizione": "Malaysia",
        "codiceistat": "340",
        "codiceauto": null,
        "codcatasto": "Z230",
        "dataend": "1966-10-01",
        "altreinfo": null
    },
    {
        "descrizione": "Apolide",
        "codiceistat": "999",
        "codiceauto": null,
        "codcatasto": null,
        "dataend": null,
        "altreinfo": null
    },
    {
        "descrizione": "Gilbert e ellice (isole)",
        "codiceistat": null,
        "codiceauto": null,
        "codcatasto": "Z705",
        "dataend": null,
        "altreinfo": null
    },
    {
        "descrizione": "Congo, Repubblica democratica del (ex Zaire)",
        "codiceistat": "463",
        "codiceauto": null,
        "codcatasto": "Z312",
        "dataend": null,
        "altreinfo": null
    },
    {
        "descrizione": "Marshall, Isole",
        "codiceistat": "712",
        "codiceauto": null,
        "codcatasto": "Z711",
        "dataend": null,
        "altreinfo": null
    },
    {
        "descrizione": "Micronesia, Stati Federati",
        "codiceistat": "713",
        "codiceauto": null,
        "codcatasto": "Z735",
        "dataend": null,
        "altreinfo": null
    },
    {
        "descrizione": "Salomone, Isole",
        "codiceistat": "725",
        "codiceauto": null,
        "codcatasto": "Z724",
        "dataend": null,
        "altreinfo": null
    },
    {
        "descrizione": "Salomone (isole)",
        "codiceistat": "725",
        "codiceauto": "GB",
        "codcatasto": "Z724",
        "dataend": "1978-12-31",
        "altreinfo": null
    },
    {
        "descrizione": "Christmas (isola)",
        "codiceistat": "751",
        "codiceauto": "AUS",
        "codcatasto": "Z702",
        "dataend": null,
        "altreinfo": null
    },
    {
        "descrizione": "Dipendenze britanniche",
        "codiceistat": "758",
        "codiceauto": null,
        "codcatasto": "Z901",
        "dataend": null,
        "altreinfo": null
    },
    {
        "descrizione": "Dipendenze francesi",
        "codiceistat": "760",
        "codiceauto": null,
        "codcatasto": "Z902",
        "dataend": null,
        "altreinfo": null
    },
    {
        "descrizione": "Dipendenze canadesi",
        "codiceistat": "759",
        "codiceauto": null,
        "codcatasto": "Z800",
        "dataend": null,
        "altreinfo": null
    },
    {
        "descrizione": "Dipendenze neozelandesi",
        "codiceistat": "761",
        "codiceauto": null,
        "codcatasto": "Z903",
        "dataend": null,
        "altreinfo": null
    },
    {
        "descrizione": "Dipendenze sovietiche",
        "codiceistat": "763",
        "codiceauto": null,
        "codcatasto": "Z802",
        "dataend": "1992-03-31",
        "altreinfo": null
    },
    {
        "descrizione": "Dipendenze norvegesi antartiche",
        "codiceistat": "762",
        "codiceauto": null,
        "codcatasto": "Z904",
        "dataend": null,
        "altreinfo": null
    },
    {
        "descrizione": "Dipendenze russe",
        "codiceistat": null,
        "codiceauto": null,
        "codcatasto": "Z802",
        "dataend": null,
        "altreinfo": null
    },
    {
        "descrizione": "Dipendenze statunitensi",
        "codiceistat": "764",
        "codiceauto": null,
        "codcatasto": "Z905",
        "dataend": null,
        "altreinfo": null
    },
    {
        "descrizione": "Dipendenze sudafricane",
        "codiceistat": "765",
        "codiceauto": null,
        "codcatasto": "Z906",
        "dataend": null,
        "altreinfo": null
    },
    {
        "descrizione": "Dipendenze australiane",
        "codiceistat": "757",
        "codiceauto": null,
        "codcatasto": "Z900",
        "dataend": null,
        "altreinfo": null
    },
    {
        "descrizione": "Dipendenze norvegesi artiche",
        "codiceistat": "762",
        "codiceauto": null,
        "codcatasto": "Z801",
        "dataend": null,
        "altreinfo": null
    },
    {
        "descrizione": "Afghanistan",
        "codiceistat": "301",
        "codiceauto": null,
        "codcatasto": "Z200",
        "dataend": null,
        "altreinfo": "AFG"
    },
    {
        "descrizione": "Angola",
        "codiceistat": "402",
        "codiceauto": "P",
        "codcatasto": "Z302",
        "dataend": "1977-12-31",
        "altreinfo": "ANG"
    },
    {
        "descrizione": "Anguilla, isola di",
        "codiceistat": "502",
        "codiceauto": "GB",
        "codcatasto": "Z529",
        "dataend": null,
        "altreinfo": "AIA"
    },
    {
        "descrizione": "Antigua e barbuda",
        "codiceistat": "503",
        "codiceauto": null,
        "codcatasto": "Z532",
        "dataend": null,
        "altreinfo": "AG"
    },
    {
        "descrizione": "Antille britanniche",
        "codiceistat": "736",
        "codiceauto": null,
        "codcatasto": "Z500",
        "dataend": null,
        "altreinfo": "523"
    },
    {
        "descrizione": "Antille olandesi",
        "codiceistat": "737",
        "codiceauto": "NL",
        "codcatasto": "Z501",
        "dataend": null,
        "altreinfo": "ANT"
    },
    {
        "descrizione": "Arabia meridionale federazione",
        "codiceistat": "738",
        "codiceauto": null,
        "codcatasto": "Z201",
        "dataend": "1975-12-31",
        "altreinfo": "201"
    },
    {
        "descrizione": "Argentina",
        "codiceistat": "602",
        "codiceauto": null,
        "codcatasto": "Z600",
        "dataend": null,
        "altreinfo": "RA"
    },
    {
        "descrizione": "Australia",
        "codiceistat": "701",
        "codiceauto": null,
        "codcatasto": "Z700",
        "dataend": null,
        "altreinfo": "AUS"
    },
    {
        "descrizione": "Austria",
        "codiceistat": "203",
        "codiceauto": null,
        "codcatasto": "Z102",
        "dataend": null,
        "altreinfo": "A"
    },
    {
        "descrizione": "Azerbaigian",
        "codiceistat": "359",
        "codiceauto": null,
        "codcatasto": "Z141",
        "dataend": "1994-01-01",
        "altreinfo": "AE"
    },
    {
        "descrizione": "Bahrein",
        "codiceistat": "304",
        "codiceauto": "GB",
        "codcatasto": "Z204",
        "dataend": "1975-12-31",
        "altreinfo": "BRN"
    },
    {
        "descrizione": "Basutoland sud africa britannico",
        "codiceistat": "743",
        "codiceauto": "GB",
        "codcatasto": "Z303",
        "dataend": "1966-10-01",
        "altreinfo": "303"
    },
    {
        "descrizione": "Bolivia",
        "codiceistat": "604",
        "codiceauto": null,
        "codcatasto": "Z601",
        "dataend": null,
        "altreinfo": "BOL"
    },
    {
        "descrizione": "Botswana",
        "codiceistat": "408",
        "codiceauto": null,
        "codcatasto": "Z358",
        "dataend": null,
        "altreinfo": "BB"
    },
    {
        "descrizione": "Brasile",
        "codiceistat": "605",
        "codiceauto": null,
        "codcatasto": "Z602",
        "dataend": null,
        "altreinfo": "BR"
    },
    {
        "descrizione": "Bulgaria",
        "codiceistat": "209",
        "codiceauto": null,
        "codcatasto": "Z104",
        "dataend": null,
        "altreinfo": "BG"
    },
    {
        "descrizione": "Burkina Faso (ex Alto Volta)",
        "codiceistat": "409",
        "codiceauto": null,
        "codcatasto": "Z354",
        "dataend": null,
        "altreinfo": "RHV"
    },
    {
        "descrizione": "Capo verde",
        "codiceistat": "413",
        "codiceauto": null,
        "codcatasto": "Z307",
        "dataend": null,
        "altreinfo": "CV"
    },
    {
        "descrizione": "Caroline (isole)",
        "codiceistat": "749",
        "codiceauto": null,
        "codcatasto": "Z701",
        "dataend": null,
        "altreinfo": "701"
    },
    {
        "descrizione": "Caroline (isole)=stati federati di micronesia",
        "codiceistat": "749",
        "codiceauto": "USA",
        "codcatasto": "Z701",
        "dataend": "1990-12-22",
        "altreinfo": "701"
    },
    {
        "descrizione": "Cile",
        "codiceistat": "606",
        "codiceauto": null,
        "codcatasto": "Z603",
        "dataend": null,
        "altreinfo": "RCH"
    },
    {
        "descrizione": "Cinese, Repubblica Popolare",
        "codiceistat": "314",
        "codiceauto": null,
        "codcatasto": "Z210",
        "dataend": null,
        "altreinfo": "CP"
    },
    {
        "descrizione": "Colombia",
        "codiceistat": "608",
        "codiceauto": null,
        "codcatasto": "Z604",
        "dataend": null,
        "altreinfo": "CO"
    },
    {
        "descrizione": "Corea, Repubblica (Corea del Sud)",
        "codiceistat": "320",
        "codiceauto": null,
        "codcatasto": "Z213",
        "dataend": null,
        "altreinfo": "ROK"
    },
    {
        "descrizione": "Corea, Repubblica Popolare Democratica (Corea del Nord) ",
        "codiceistat": "319",
        "codiceauto": null,
        "codcatasto": "Z214",
        "dataend": null,
        "altreinfo": "DVR"
    },
    {
        "descrizione": "Croazia",
        "codiceistat": "250",
        "codiceauto": null,
        "codcatasto": "Z149",
        "dataend": null,
        "altreinfo": "HR"
    },
    {
        "descrizione": "Dominica",
        "codiceistat": "515",
        "codiceauto": null,
        "codcatasto": "Z526",
        "dataend": null,
        "altreinfo": "WD"
    },
    {
        "descrizione": "Ecuador",
        "codiceistat": "609",
        "codiceauto": null,
        "codcatasto": "Z605",
        "dataend": null,
        "altreinfo": "EC"
    },
    {
        "descrizione": "Eritrea",
        "codiceistat": "466",
        "codiceauto": null,
        "codcatasto": "Z368",
        "dataend": null,
        "altreinfo": "ER"
    },
    {
        "descrizione": "Figi",
        "codiceistat": "703",
        "codiceauto": null,
        "codcatasto": "Z704",
        "dataend": null,
        "altreinfo": "FJI"
    },
    {
        "descrizione": "Figi (isole)",
        "codiceistat": "703",
        "codiceauto": "GB",
        "codcatasto": "Z704",
        "dataend": "1975-12-31",
        "altreinfo": "FJI"
    },
    {
        "descrizione": "Germania",
        "codiceistat": "769",
        "codiceauto": "D",
        "codcatasto": "Z112",
        "dataend": null,
        "altreinfo": "D"
    },
    {
        "descrizione": "Giamaica",
        "codiceistat": "518",
        "codiceauto": null,
        "codcatasto": "Z507",
        "dataend": null,
        "altreinfo": "JA"
    },
    {
        "descrizione": "Grenada",
        "codiceistat": "519",
        "codiceauto": null,
        "codcatasto": "Z524",
        "dataend": null,
        "altreinfo": "WG"
    },
    {
        "descrizione": "Guam (isola)",
        "codiceistat": "772",
        "codiceauto": "USA",
        "codcatasto": "Z706",
        "dataend": null,
        "altreinfo": "GUM"
    },
    {
        "descrizione": "Guyana",
        "codiceistat": "612",
        "codiceauto": null,
        "codcatasto": "Z606",
        "dataend": null,
        "altreinfo": "GUY"
    },
    {
        "descrizione": "Guyana",
        "codiceistat": null,
        "codiceauto": "NL",
        "codcatasto": "Z608",
        "dataend": "1975-12-31",
        "altreinfo": "GUY"
    },
    {
        "descrizione": "Guyana francese",
        "codiceistat": "774",
        "codiceauto": "F",
        "codcatasto": "Z607",
        "dataend": null,
        "altreinfo": "GF"
    },
    {
        "descrizione": "Hong kong",
        "codiceistat": null,
        "codiceauto": "GB",
        "codcatasto": "Z221",
        "dataend": "1997-07-01",
        "altreinfo": "HK"
    },
    {
        "descrizione": "Ifni",
        "codiceistat": null,
        "codiceauto": "E",
        "codcatasto": "Z323",
        "dataend": "1975-12-31",
        "altreinfo": "323"
    },
    {
        "descrizione": "India",
        "codiceistat": "330",
        "codiceauto": null,
        "codcatasto": "Z222",
        "dataend": null,
        "altreinfo": "IND"
    },
    {
        "descrizione": "Italia",
        "codiceistat": "100",
        "codiceauto": "I",
        "codcatasto": null,
        "dataend": null,
        "altreinfo": "IT"
    },
    {
        "descrizione": "Kiribati",
        "codiceistat": "708",
        "codiceauto": null,
        "codcatasto": "Z731",
        "dataend": null,
        "altreinfo": "KIR"
    },
    {
        "descrizione": "Lettonia",
        "codiceistat": "248",
        "codiceauto": null,
        "codcatasto": "Z145",
        "dataend": null,
        "altreinfo": "LV"
    },
    {
        "descrizione": "Macedonia, Repubblica di ",
        "codiceistat": "253",
        "codiceauto": null,
        "codcatasto": "Z148",
        "dataend": null,
        "altreinfo": "MK"
    },
    {
        "descrizione": "Madagascar",
        "codiceistat": "432",
        "codiceauto": null,
        "codcatasto": "Z327",
        "dataend": null,
        "altreinfo": "RM"
    },
    {
        "descrizione": "Marcus (isole)",
        "codiceistat": null,
        "codiceauto": "USA",
        "codcatasto": "Z709",
        "dataend": "1975-12-31",
        "altreinfo": "709"
    },
    {
        "descrizione": "Moldova, Repubblica ",
        "codiceistat": "254",
        "codiceauto": null,
        "codcatasto": "Z140",
        "dataend": null,
        "altreinfo": "MD"
    },
    {
        "descrizione": "Nauru",
        "codiceistat": "715",
        "codiceauto": null,
        "codcatasto": "Z713",
        "dataend": null,
        "altreinfo": "NAU"
    },
    {
        "descrizione": "Nigeria",
        "codiceistat": "443",
        "codiceauto": null,
        "codcatasto": "Z335",
        "dataend": null,
        "altreinfo": "WAN"
    },
    {
        "descrizione": "Nuova guinea",
        "codiceistat": null,
        "codiceauto": "AUS",
        "codcatasto": "Z718",
        "dataend": "1975-12-31",
        "altreinfo": "PG"
    },
    {
        "descrizione": "Nuova zelanda",
        "codiceistat": null,
        "codiceauto": null,
        "codcatasto": "Z719",
        "dataend": null,
        "altreinfo": "NZ"
    },
    {
        "descrizione": "Nuove ebridi (isole condominio franco-inglese)",
        "codiceistat": null,
        "codiceauto": "GB-F",
        "codcatasto": "Z717",
        "dataend": "1980-12-31",
        "altreinfo": "NHE"
    },
    {
        "descrizione": "Palau",
        "codiceistat": "720",
        "codiceauto": null,
        "codcatasto": "Z734",
        "dataend": null,
        "altreinfo": "PAL"
    },
    {
        "descrizione": "Palau repubblica",
        "codiceistat": null,
        "codiceauto": "USA",
        "codcatasto": "Z734",
        "dataend": "1997-01-01",
        "altreinfo": "PAL"
    },
    {
        "descrizione": "Papua nuova guinea",
        "codiceistat": null,
        "codiceauto": null,
        "codcatasto": "Z730",
        "dataend": null,
        "altreinfo": "PNG"
    },
    {
        "descrizione": "Papuasia",
        "codiceistat": null,
        "codiceauto": "AUS",
        "codcatasto": "Z720",
        "dataend": "1975-12-31",
        "altreinfo": "PG"
    },
    {
        "descrizione": "Paraguay",
        "codiceistat": "614",
        "codiceauto": null,
        "codcatasto": "Z610",
        "dataend": null,
        "altreinfo": "PY"
    },
    {
        "descrizione": "Perù",
        "codiceistat": null,
        "codiceauto": null,
        "codcatasto": "Z611",
        "dataend": null,
        "altreinfo": "PE"
    },
    {
        "descrizione": "Pitcairn (e dipendenze)",
        "codiceistat": null,
        "codiceauto": "GB",
        "codcatasto": "Z722",
        "dataend": null,
        "altreinfo": "PCN"
    },
    {
        "descrizione": "Polinesia francese (isole)",
        "codiceistat": null,
        "codiceauto": "F",
        "codcatasto": "Z723",
        "dataend": null,
        "altreinfo": "PYF"
    },
    {
        "descrizione": "Saint Kitts e Nevis",
        "codiceistat": "534",
        "codiceauto": null,
        "codcatasto": "Z533",
        "dataend": null,
        "altreinfo": "KN"
    },
    {
        "descrizione": "Samoa",
        "codiceistat": "727",
        "codiceauto": null,
        "codcatasto": "Z726",
        "dataend": null,
        "altreinfo": "WS"
    },
    {
        "descrizione": "Samoa americane (isole)",
        "codiceistat": null,
        "codiceauto": "USA",
        "codcatasto": "Z725",
        "dataend": null,
        "altreinfo": "ASM"
    },
    {
        "descrizione": "São Tomé e Principe (isole)",
        "codiceistat": "448",
        "codiceauto": "P",
        "codcatasto": "Z341",
        "dataend": "1975-12-31",
        "altreinfo": "STP"
    },
    {
        "descrizione": "Senegal",
        "codiceistat": "450",
        "codiceauto": null,
        "codcatasto": "Z343",
        "dataend": null,
        "altreinfo": "SN"
    },
    {
        "descrizione": "Slovacchia",
        "codiceistat": "255",
        "codiceauto": null,
        "codcatasto": "Z155",
        "dataend": null,
        "altreinfo": "SK"
    },
    {
        "descrizione": "Suriname",
        "codiceistat": "616",
        "codiceauto": null,
        "codcatasto": "Z608",
        "dataend": null,
        "altreinfo": "SME"
    },
    {
        "descrizione": "Tagikistan",
        "codiceistat": "362",
        "codiceauto": null,
        "codcatasto": "Z147",
        "dataend": "1994-01-01",
        "altreinfo": "TJ"
    },
    {
        "descrizione": "Tokelau=isole dell'unione",
        "codiceistat": null,
        "codiceauto": "NZ",
        "codcatasto": "Z727",
        "dataend": null,
        "altreinfo": "TKL"
    },
    {
        "descrizione": "Tonga",
        "codiceistat": "730",
        "codiceauto": null,
        "codcatasto": "Z728",
        "dataend": null,
        "altreinfo": "TO"
    },
    {
        "descrizione": "Tonga (isole degli amici)",
        "codiceistat": "730",
        "codiceauto": "GB",
        "codcatasto": "Z728",
        "dataend": "1975-12-31",
        "altreinfo": "TO"
    },
    {
        "descrizione": "Turchia",
        "codiceistat": "351",
        "codiceauto": null,
        "codcatasto": "Z243",
        "dataend": null,
        "altreinfo": "TR"
    },
    {
        "descrizione": "Tuvalu",
        "codiceistat": "731",
        "codiceauto": null,
        "codcatasto": "Z732",
        "dataend": null,
        "altreinfo": "TUV"
    },
    {
        "descrizione": "Uganda",
        "codiceistat": "461",
        "codiceauto": null,
        "codcatasto": "Z353",
        "dataend": null,
        "altreinfo": "EAU"
    },
    {
        "descrizione": "Uruguay",
        "codiceistat": "618",
        "codiceauto": null,
        "codcatasto": "Z613",
        "dataend": null,
        "altreinfo": "ROU"
    },
    {
        "descrizione": "Vanuatu",
        "codiceistat": "732",
        "codiceauto": null,
        "codcatasto": "Z733",
        "dataend": null,
        "altreinfo": "VU"
    },
    {
        "descrizione": "Venezuela",
        "codiceistat": "619",
        "codiceauto": null,
        "codcatasto": "Z614",
        "dataend": null,
        "altreinfo": "YV"
    },
    {
        "descrizione": "Normanne (isole)=isole del canale",
        "codiceistat": null,
        "codiceauto": "GB",
        "codcatasto": "Z124",
        "dataend": null,
        "altreinfo": "124"
    },
    {
        "descrizione": "Nuova caledonia (isole e dipendenze)",
        "codiceistat": null,
        "codiceauto": "F",
        "codcatasto": "Z716",
        "dataend": null,
        "altreinfo": "NCL"
    },
    {
        "descrizione": "Pasqua (isola)",
        "codiceistat": null,
        "codiceauto": "RCH",
        "codcatasto": "Z721",
        "dataend": "1984-12-31",
        "altreinfo": "721"
    },
    {
        "descrizione": "Sahara meridionale",
        "codiceistat": null,
        "codiceauto": "RIM",
        "codcatasto": "Z362",
        "dataend": "1984-12-31",
        "altreinfo": "362"
    },
    {
        "descrizione": "Singapore",
        "codiceistat": "346",
        "codiceauto": null,
        "codcatasto": "Z248",
        "dataend": null,
        "altreinfo": "SGP"
    },
    {
        "descrizione": "Sri Lanka (ex Ceylon) ",
        "codiceistat": "311",
        "codiceauto": null,
        "codcatasto": "Z209",
        "dataend": null,
        "altreinfo": "CL"
    },
    {
        "descrizione": "Stati uniti d'america",
        "codiceistat": "536",
        "codiceauto": null,
        "codcatasto": "Z404",
        "dataend": null,
        "altreinfo": "USA"
    },
    {
        "descrizione": "Turkmenistan ",
        "codiceistat": "364",
        "codiceauto": null,
        "codcatasto": "Z151",
        "dataend": "1994-01-01",
        "altreinfo": "TM"
    },
    {
        "descrizione": "Wallis e futuna (isole)",
        "codiceistat": null,
        "codiceauto": "F",
        "codcatasto": "Z729",
        "dataend": null,
        "altreinfo": "WLF"
    },
    {
        "descrizione": "Yemen repubblica democratica popolare",
        "codiceistat": null,
        "codiceauto": null,
        "codcatasto": "Z250",
        "dataend": "1990-05-22",
        "altreinfo": "YS"
    },
    {
        "descrizione": "Zanzibar",
        "codiceistat": null,
        "codiceauto": null,
        "codcatasto": "Z356",
        "dataend": "1966-10-01",
        "altreinfo": "356"
    }
]