import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NC_WEBSITE, SCT_WEBSITE } from '../../../lib/constants';
import { HttpRequestResponse } from '../../../lib/models/http-request-response.model';
import { ApplicationService } from '../../../lib/services/application.service';
import { HttpRequestService } from '../../../lib/services/http-request.service';
import { LogService } from '../../../lib/services/log.service';
import { UtenteService } from '../../../lib/services/utente.service';
import { newError } from '../../../lib/utils/Utility';


@Component({
  selector: 'app-normativa-privacy',
  templateUrl: './normativa-privacy.component.html',
  styleUrls: ['../../../lib/themes/portale.theme.scss'],
})
export class NormativaPrivacyComponent {
  urlWebsite: string

  form = new FormGroup({
    accepted: new FormControl(false, [Validators.requiredTrue])
  })

  constructor(
    private httpRequestService: HttpRequestService,
    private utenteService: UtenteService,
    private router: Router,
    private logService: LogService,
    protected applicationService: ApplicationService
  ) {
    if (applicationService.isNextComunica()) {
      this.urlWebsite = NC_WEBSITE
    } else {
      this.urlWebsite = SCT_WEBSITE
    }
  }

  accettaNormativaPrivacy() {
    this.httpRequestService.post('/privacy-policy/accept', { isNc: this.applicationService.isNextComunica() })
      .subscribe((response) => {
        if (HttpRequestResponse.isSuccessResponse(response)) {
          this.checkAbbonamentoScaduto();
        } else {
          this.logService.messageError('Attenzione', response.message, newError(response.message))
        }
      });
  }

  //Controllo via i dati utente se l'abbonamento è scaduto, e reindirizzo l'utente al form di pagamento
  checkAbbonamentoScaduto() {
    if (this.utenteService.mustPay()) {
        this.router.navigate(['impostazioni', 'dati-fatturazione', 'abbonamento-scaduto'], {});
    } else {
        //Se non è scaduto l'abbonamento prima di loggarmi all'applicazione controllo che la password non sia scaduta, e quindi debba essere reimpostata
        this.checkPasswordScaduta()
    }
}

  checkPasswordScaduta() {
    this.httpRequestService
      .get('/siev_fdatamod_password/password/check/expired/', {})
      .subscribe((response) => {
        if (HttpRequestResponse.isSuccessResponse(response)) {
          debugger

          if (response.data?.isPasswordScaduta) {
            this.router.navigate(['/cambioPassword'], { queryParams: { f: 1 }, });
            return
          } else {
            this.router.navigate(['/homepage'], {});
          }
        } else {
          this.logService.messageError('Attenzione!', response.message, newError(response.message));
        }
      });
  }
}
