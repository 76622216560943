import { AfterViewInit, Component, EventEmitter, Injectable, Input, OnDestroy, Output, ViewChild } from "@angular/core";
import { MatPaginator, MatPaginatorIntl, PageEvent } from "@angular/material/paginator";
import { Subject, Subscription } from "rxjs";

/**
 * Traduzione del paginator in italiano
 */
@Injectable()
class EvPaginatorIntl implements MatPaginatorIntl {
    changes = new Subject<void>();

    // For internationalization, the `$localize` function from
    // the `@angular/localize` package can be used.
    firstPageLabel = $localize`Prima pagina`;
    itemsPerPageLabel = $localize`Elem. per pagina:`;
    lastPageLabel = $localize`Ultima pagina`;

    // You can set labels to an arbitrary string too, or dynamically compute
    // it through other third-party internationalization libraries.
    nextPageLabel = 'Prossima pagina';
    previousPageLabel = 'Pagina precedente';

    getRangeLabel(page: number, pageSize: number, length: number): string {
        if (length === 0) {
            return $localize`Pagina 1 di 1`;
        }
        const amountPages = Math.ceil(length / pageSize);
        return $localize`Pagina ${page + 1} di ${amountPages}`;
    }
}

export interface EvPaginatorConfig {
    page: number
    rows: number
}

/**
 * Component per gestire una generica paginazione
 * 
 * Per farlo funzionare (vedi terapia-continuativa.component.ts):
 *      - mettersi in ascolto dell'emitter changePage() per il cambio della pagina
 *      - aggiornare l'input total ogni volta che si ricaricano i dati
 */
@Component({
    selector: 'ev-paginator',
    providers: [{ provide: MatPaginatorIntl, useClass: EvPaginatorIntl }],
    styles: [`
    :host ::ng-deep mat-paginator .mat-mdc-paginator-outer-container .mat-mdc-paginator-container {
        justify-content: center;
    }`],
    template: `
<mat-paginator #paginator showFirstLastButtons [length]="total" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions">
</mat-paginator>
    `
})
export class EvPaginatorComponent implements AfterViewInit, OnDestroy {
    @Input() pageSize: number = 10
    @Input() pageSizeOptions = [2, 5, 10, 25]
    // totale elementi
    @Input() total = 0
    @Output() changePage = new EventEmitter<EvPaginatorConfig>()

    currentPage: number = 1
    rows: number = 10

    @ViewChild('paginator') paginator: MatPaginator

    sub: Subscription

    ngAfterViewInit(): void {
        // change della pagina
        this.sub = this.paginator.page.subscribe((ev: PageEvent) => {
            this.currentPage = ev.pageIndex + 1
            this.rows = ev.pageSize

            this.changePage.emit({ ...this.getPageConfig() })
        })
    }

    getPageConfig(): EvPaginatorConfig {
        return {
            page: this.currentPage,
            rows: this.rows
        }
    }

    resetPaginator() {
        this.currentPage = 1
        this.rows = this.pageSize
    }

    pageChanged() {
        return this.paginator.page
    }
    goToFirstPage() {
        this.paginator.firstPage()
    }
    settotal(total: number) {
        this.total = total
    }
    getPageIndex() {
        return this.paginator.pageIndex + 1
    }
    getPageSize() {
        return this.paginator.pageSize
    }

    ngOnDestroy(): void {
        this.sub?.unsubscribe()
    }
}
