import { Component } from "@angular/core";
import { NotificationsService } from "../../lib/sw/notifications.service";
import { PwaInstallationService } from "../../lib/sw/pwa-installation.service";

@Component({
    selector: 'mobile-settings',
    templateUrl: './mobile-settings.component.html',
    styleUrls: ['./mobile-settings.component.scss']
})
export class MobileSettingsComponent {

    constructor(
        private pwaInstallationService: PwaInstallationService,
        private notificationsService: NotificationsService
    ) {

    }

    install() {
        this.pwaInstallationService.askToInstall()
    }

    enableNotifications() {
        this.notificationsService.checkSubscription()
    }

    disableNotifications() {
        this.notificationsService.clearSubscription()
    }
}
