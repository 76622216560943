<div class="header_nav_1 dark inter_3_bg pix_builder_bg" id="section_intro_3" classForScreen="" classSmallScreen="no-background">
  <app-portale-layout-header></app-portale-layout-header>

  <div class="container">
    <div class="row margin_bottom_10 padding_top_10">
      <div class="col-xs-2"></div>
      <div class="col-xs-12 col-sm-8">
        <div class="center_text"
          style="-webkit-box-shadow: 0px 10px 30px -15px rgba(0,0,0,.3);
box-shadow: 0px 10px 30px -15px rgba(0,0,0,.3); background-color:rgb(255,255,255,.6); border-radius:25px; padding: 10px 0 !important">
          <p class="big_title bold_text editContent"
            style="color: rgba(94,184,172,255); font-size: 48px; background-color: rgba(0, 0, 0, 0); font-family: Aubrey, sans-serif;">
            <img src="assets/portal/images/uploads/logo-saluteconte.png" style="width: 150px; height: 150px;">
            <br> Salute con te
          </p>
          <p class="big_text normal_gray editContent"
            style="font-weight: bold;  color: rgba(94,184,172,255); font-size: 22px; background-color: rgba(0, 0, 0, 0);">
            Il portale per la comunicazione Medico - Paziente</p>
          <input type="text" style="visibility: hidden" />
          <input type="text" style="visibility: hidden" />
          <p class="big_title bold_text editContent"
            style="color: rgba(94,184,172,255); font-size: 48px; background-color: rgba(0, 0, 0, 0);">
            Prezzo
          </p>
          <input type="text" style="visibility: hidden" />
        </div>
      </div>
    </div>
  </div>
</div>

<div class="pixfort_corporate_2" id="section_corporate_4">
  <div class="amazing_style pix_builder_bg"
    style="outline-offset: -3px; background-image: none; background-color: rgb(238, 238, 238); padding-top: 0px; padding-bottom: 60px; box-shadow: none; border-color: rgb(68, 68, 68); background-size: cover; background-attachment: scroll; background-repeat: repeat repeat;"
    src="./assets/portal/images/uploads/com-por-png">
    <div class="container">
      <div class="row">
        <div class="col-xs-12">
          <span>
            <br><br><span style="font-weight: bold">Salute con te costa circa un caffè al mese<br><br>
              Il tempo che risparmierai varrà infinitamente di più</span><br><br>
            Totalmente <span style="font-weight: bold">GRATUITO</span> per 3 mesi.<br><br><br>
            <span style="font-weight: bold">Abbonamento individuale</span><br><br>
            <div class="col-sm-4"></div>
            <div class="col-sm-4"
              style="border-radius: 5px; padding: 70px 20px;background-color: rgba(94,184,172,255);color: #fff;">
              <p> € <span style="font-size: 50px">1</span> / mese</p><br>
              <span style="font-size: 20px;">Fatturati annualmente</span><br>
              <span>ovvero 12€ all'anno</span>
            </div>
            <div class="col-sm-4"></div>
          </span>


        </div>
      </div>
    </div>


    <!-- prezzo famiglia -->
    <div class="container">
      <div class="row">
        <div class="col-xs-12">
          <p style="margin-bottom: 0 !important;">
            <br><br>
            <span style="font-weight: bold">Per gli abbonamenti multipli costa ancora meno:</span><br><br>
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-5"
          style="border-radius: 5px; padding: 70px 20px;background-color: rgba(94,184,172,255);color: #fff;">
          <p>Abbonamento per la coppia</p>
          <p>€ <span style="font-size: 50px">1,75</span> / mese</p><br>
          <span style="font-size: 20px;">Fatturati annualmente</span><br>
          <span>ovvero 21€ all'anno</span>
        </div>

        <div class="col-xs-2"></div>

        <div class="col-xs-5"
          style="border-radius: 5px; padding: 70px 20px;background-color: rgba(94,184,172,255);color: #fff;">
          <p>Abbonamento per la famiglia <span style="font-size: 12px">(massimo 5 persone)</span></p>
          <p>€ <span style="font-size: 50px">2,42</span> / mese</p><br>
          <span style="font-size: 20px;">Fatturati annualmente</span><br>
          <span>ovvero 29€ all'anno</span>
        </div>
      </div>
    </div>

  </div>
</div>

<app-scarica-app-section></app-scarica-app-section>

<app-portale-layout-footer></app-portale-layout-footer>
