import { Component, ElementRef, Input, inject } from "@angular/core";
import { COLOR_BLACK, THEME_DEFAULT_PROPERTIES } from "../constants";
import { ThemeService } from "../services/theme.service";
import { CommonObject } from "../utils/Utility";

@Component({
    selector: 'ev-page-theme',
    template: ''
})
export class EvPageThemeComponent {

    // colore della pagina
    private _color: string
    @Input() public set color(_color: string) {
        this._color = _color
        this.applyTheme()
    }
    public get color() {
        return this._color
    }

    // colore del testo
    private _textColor: string
    @Input() public set textColor(_textColor: string) {
        this._textColor = _textColor
        this.applyTheme()
    }
    public get textColor() {
        return this._textColor
    }

    // proprietà da applicare
    private _properties: CommonObject = THEME_DEFAULT_PROPERTIES
    public set properties(_properties: CommonObject) {
        this._properties = _properties
        this.applyTheme()
    }
    public get properties() {
        return this._properties
    }

    public themeService = inject(ThemeService)

    constructor(protected elementRef: ElementRef) {
        this.textColor = COLOR_BLACK
    }

    applyTheme() {
        if (!this.color) {
            return
        }
        this.themeService.applyTheme(this.elementRef, this.properties, this.color, this.textColor)
    }

    getInfo(): string {
        throw new Error("NIY!")
    }
}
