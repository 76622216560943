import { Component } from "@angular/core";
import { environment } from "../../../environments/environment";
import { SwUpdateService } from "../sw/sw-update.service";

@Component({
    selector: 'ev-debug-info',
    template: `

<div style="padding: 20px; display: flex; flex-direction: column;">
    <div>
        <h1>General info</h1>
    </div>
    <div>
        <div>Production: {{env.production}}</div>
    </div>
    <div>
        <div>Environment: {{env.name}}</div>
    </div>
    <mat-divider></mat-divider>
    <div>
        <h1>Mobile info</h1>
    </div>
    <div style="margin-top: 20px;" *ngFor="let line of swUpdateService.debugLines">
        <div [innerHtml]="line">
        </div>
        <mat-divider></mat-divider>
    </div>
</div>

    `
})
export class EvDebugInfoComponent {

    env = environment

    constructor(
        protected swUpdateService: SwUpdateService
    ) { }
}
