<div class="table-container">
    <div class="table-title" *ngIf="tableTitle">{{tableTitle}}</div>

    <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort (matSortChange)="sortData($event)">

        <ng-container matColumnDef="select" *ngIf="singleSelection || multipleSelection">
            <th mat-header-cell *matHeaderCellDef class="action-column"></th>
            <td mat-cell *matCellDef="let row" class="selection-cell">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? _selection.toggle(row) : null"
                    [checked]="_selection.isSelected(row)" *ngIf="showSelectionCheck(row)">
                </mat-checkbox>
            </td>
            <ng-template [ngIf]="footer">
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-template>
        </ng-container>




        <ng-container matColumnDef="{{column.field}}" *ngFor="let column of columnsToDisplay">
            <ng-template [ngIf]="column.type === 'text' ">
                <th mat-header-cell *matHeaderCellDef [mat-sort-header]="column.field"
                    [ngClass]="column.fill ? 'fill-column' : ''" [ngStyle]="applyHeaderStyle(column)"> {{column.title}}
                </th>
                <td mat-cell *matCellDef="let element" [ngStyle]="applyCellStyle(column, element)">
                    {{renderTextColumn(column, element)}}
                </td>
                <ng-template [ngIf]="footer">
                    <td mat-footer-cell *matFooterCellDef [ngStyle]="applyFooterCellStyle(column)"> {{renderFooter(column)}} </td>
                </ng-template>
            </ng-template>

            <ng-template [ngIf]="column.type === 'date' ">
                <th mat-header-cell *matHeaderCellDef [mat-sort-header]="column.field" ngClass="date-column">
                    {{column.title}} </th>
                <td mat-cell *matCellDef="let element" [ngStyle]="applyCellStyle(column, element)">
                    {{renderTextColumn(column, element)}}
                </td>
                <ng-template [ngIf]="footer">
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-template>
            </ng-template>

            <ng-template [ngIf]="column.type === 'datetime' ">
                <th mat-header-cell *matHeaderCellDef [mat-sort-header]="column.field" ngClass="datetime-column">
                    {{column.title}} </th>
                <td mat-cell *matCellDef="let element" [ngStyle]="applyCellStyle(column, element)">
                    {{renderTextColumn(column, element)}}
                </td>
                <ng-template [ngIf]="footer">
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-template>
            </ng-template>

            <ng-template [ngIf]="column.type === 'action'">
                <th mat-header-cell *matHeaderCellDef class="action-column"> {{column.title}} </th>
                <td mat-cell *matCellDef="let element" class="action-cell">
                    <ng-template [ngIf]="!cellHide(column, element)">
                        <mat-icon (click)="cellClick($event, column, element)"
                            style="cursor: pointer; vertical-align: middle;"
                            [ngStyle]="applyCellStyle(column, element)">
                            {{renderIcon(column, element)}}
                        </mat-icon>
                    </ng-template>
                </td>
                <ng-template [ngIf]="footer">
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-template>
            </ng-template>
        </ng-container>

        <ng-container matColumnDef="expand">
            <th mat-header-cell *matHeaderCellDef aria-label="row actions"
                [ngClass]="hideDetailTemplateIcon ? 'hidden-column' : 'action-column'">
                <ng-template [ngIf]="!hideDetailTemplateIcon">
                    &nbsp;
                </ng-template>
            </th>
            <td mat-cell *matCellDef="let element">
                <ng-template [ngIf]="!hideDetailTemplateIcon">
                    <button mat-icon-button aria-label="expand row"
                        (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
                        <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
                        <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
                    </button>
                </ng-template>
            </td>
            <ng-template [ngIf]="footer">
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-template>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="_realColumns.length">
                <div class="element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                    <ng-container [ngTemplateOutlet]="detailTemplate"
                        [ngTemplateOutletContext]="{ $implicit: element }">
                    </ng-container>
                </div>
            </td>
            <ng-template [ngIf]="footer">
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-template>
        </ng-container>


        <ng-template [ngIf]="showHeader">
            <tr mat-header-row *matHeaderRowDef="_realColumns"></tr>
        </ng-template>
        <tr mat-row *matRowDef="let element; columns: _realColumns;" class="element-row"
            [ngStyle]="{'background-color': element.__bgColor, 'color': element.__color}"
            [class.expanded-row]="expandedElement === element" (click)="expandClick($event, element)">
        </tr>
        <ng-template [ngIf]="footer">
            <tr mat-footer-row *matFooterRowDef="_realColumns"></tr>
        </ng-template>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
    </table>

    <mat-divider></mat-divider>
    <ev-paginator *ngIf="pagination" (changePage)="pageChanged($event)" #paginator></ev-paginator>
</div>