import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { LibModule } from '../lib/lib.module';
import { PortaleLayoutFooterComponent } from './layout/portale-layout-footer/portale-layout-footer.component';
import { PortaleLayoutHeaderComponent } from './layout/portale-layout-header/portale-layout-header.component';
import { AccessoVideoCallComponent } from './pages/accesso-video-call/accesso-video-call.component';
import { LoginPortaleComponent } from './pages/components/login-portale.component';
import { MenuPortaleComponent, MenuPortaleItemsComponent } from './pages/components/menu-portale.component';
import { ScaricaAppComponent } from './pages/components/scarica-app.component';
import { SectionTitleComponent } from './pages/components/section-title.component';
import { CookieComponent } from './pages/cookie/cookie.component';
import { DiconoDiNoiComponent } from './pages/dicono-di-noi/dicono-di-noi.component';
import { ForgotPasswordComponent } from './pages/impostazioni/forgot-password/forgot-password.component';
import { ForgotPinComponent } from './pages/impostazioni/forgot-pin/forgot-pin.component';
import { RecuperoPasswordComponent } from './pages/impostazioni/recupero-password/recupero-password.component';
import { LoginPageComponent } from './pages/login/login/login-page.component';
import { LogoutComponent } from './pages/login/logout/logout.component';
import { NewsComponent } from './pages/news/news.component';
import { NormativaPrivacyComponent } from './pages/normativa-privacy/normativa-privacy.component';
import { PrezzoComponent } from './pages/prezzo/prezzo.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { ScopriComponent } from './pages/scopri/scopri.component';
import { SicurezzaComponent } from './pages/sicurezza/sicurezza.component';
import { VantaggiComponent } from './pages/vantaggi/vantaggi.component';


@NgModule({
  declarations: [
    LoginPageComponent,
    LogoutComponent,
    ForgotPinComponent,
    ForgotPasswordComponent,
    RecuperoPasswordComponent,
    NewsComponent,
    PrivacyComponent,
    SicurezzaComponent,
    CookieComponent,
    AccessoVideoCallComponent,
    DiconoDiNoiComponent,
    VantaggiComponent,
    NormativaPrivacyComponent,
    PrezzoComponent,
    ScopriComponent,
    VantaggiComponent,
    PortaleLayoutHeaderComponent,
    PortaleLayoutFooterComponent,
    ScaricaAppComponent,
    LoginPortaleComponent,
    MenuPortaleComponent,
    LoginPageComponent,
    MenuPortaleItemsComponent,
    SectionTitleComponent
  ],
  imports: [
    CommonModule, FormsModule, RouterModule, BrowserModule, LibModule,
    MatSnackBarModule
  ],
  exports: [
    LoginPageComponent,
    LogoutComponent,
    ForgotPinComponent,
    ForgotPasswordComponent,
    RecuperoPasswordComponent,
    NewsComponent,
    PrivacyComponent,
    SicurezzaComponent,
    CookieComponent,
    AccessoVideoCallComponent,
  ],
})
export class PortaleModule { }
