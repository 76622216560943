<div style="margin-bottom:10px;">
  <button mat-button mat-raised-button color="primary" (click)="fileInput.click()" [disabled]="disabled">
    <ng-template [ngIf]="!multiUpload" [ngIfElse]="multiUploadButtonText">
      Seleziona un file
    </ng-template>
    <ng-template #multiUploadButtonText>
      Seleziona uno o più files
    </ng-template>
  </button>
  <form #form>
    <input style="visibility: hidden; display: none;" (change)="onFileSelected($event)" #fileInput type="file"
      [attr.multiple]="multiUpload">
  </form>
</div>

<ng-template [ngIf]="showFileName">
  <ng-template [ngIf]="!multiUpload" [ngIfElse]="multiUploadShowFileName">
    <div *ngIf="selectedFiles.length === 1" style="clear: both; font-size: 12px">
      <b>Nome file allegato:</b> {{getFileName(selectedFiles[0])}}
    </div>
  </ng-template>

  <ng-template #multiUploadShowFileName>
    <div *ngIf="getFileCount() > 0" style="clear: both; font-size: 12px">
      <b>Numero files allegati:</b> {{getFileCount()}}
    </div>
    <div *ngIf="showFileNameMultiUpload" style="clear: both; font-size: 12px">
      <div *ngFor="let file of selectedFiles; let i = index">
        <div style="font-style: italic;">{{i+1}} - {{file.fileName}}</div>
      </div>
    </div>
  </ng-template>
</ng-template>

<ng-template [ngIf]="showNotes">
  <div style="clear: both; font-size: 12px" class="text-muted">
    Attenzione: dimensione massima del file {{fileSizeString}}
  </div>
</ng-template>

<div style="display: flex; justify-content: space-between;">
  <ng-template [ngIf]="showActionLinks">
    <div *ngIf="selectedFiles?.length > 0 && downloadLinkLabel.length > 0">
      <a href="javascript:void(0)" (click)="download()">{{downloadLinkLabel}}</a>
    </div>
    <div *ngIf="selectedFiles?.length > 0 && clearLinkLabel.length > 0">
      <a href="javascript:void(0)" (click)="clear()" style="color:red">{{clearLinkLabel}}</a>
    </div>
  </ng-template>
</div>

<ng-template [ngIf]="showPreview">
  <div *ngIf="selectedFiles?.length === 1" class="logo-studio-preview-container">
    <img [src]="show()" />
  </div>
</ng-template>