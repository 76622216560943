import {ErrorHandler, Injectable} from '@angular/core';
import {LogService} from "./log.service"

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private logService: LogService) {
  }

  handleError(error: Error) {
   this.logService.messageError('Errore', error.message, error);
  }

}
