<div class="footer-container">
    <div style="font-weight: bold;">© 2023 {{applicationService.getApplicationName()}}</div>

    <div>
        <a href="javascript:void(0)" target="_blank" [routerLink]="['/iprivacy']">
            Informativa sul trattamento dei dati personali
        </a>
    </div>

    <div *ngIf="!isNextComunica">
        <a href="javascript:void(0)" target="_blank" [routerLink]="['/isicurezza']">
            Termini e condizioni
        </a>
    </div>
    <div *ngIf="isNextComunica">
        <a href="assets/documents/termini-nc.pdf" target="_blank">
            Termini e condizioni
        </a>
    </div>

    <div>
        <a href="javascript:void(0)" target="_blank" [routerLink]="['/icookie']">
            Informativa cookie
        </a>
    </div>

</div>