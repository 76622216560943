<div classForScreen="container" classSmallScreen="container-fluid">
  <div class='row'
    style="text-align: center; padding-top: 20px !important; font-family: 'Roboto', sans-serif; font-weight: normal; font-size: 100%;">
    <div class='col-12-md form-container'>

      <div class="alert alert-danger" role="alert" *ngIf="this.errorMessage" [innerHTML]="this.errorMessage">
      </div>

      <div class="alert alert-info" role="alert" *ngIf="this.successMessage" [innerHTML]="this.successMessage">
      </div>

      <p>ATTENZIONE! Si ricorda che l'utilizzo delle credenziali da parte di persone non autorizzate costituisce
        <strong style="font-weight: bold !important">illecito penalmente perseguibile.</strong>
      </p>

      <br>
      <p>
        Procedi come indicato di seguito:
      </p>
      <br>


      <p>
        Fase 1: inserisci in tuo codice fiscale
      </p>

      <div class="form-group">
        <input type="text" name="cf" required="required" [(ngModel)]="codiceFiscale" class="form-control"
          placeholder="Codice fiscale" value="">
      </div>


      <br><br>
      <p>
        Fase 2: clicca sulla casella di sicurezza sottostante
      </p>
      <div class="form-group" style="text-align: center;">
        <ev-captcha style="display: flex; justify-content: center;"></ev-captcha>
      </div>


      <br><br>
      <p>
        Fase 3: clicca sul pulsante. Verranno inviate alla tua email le istruzioni per il recupero della password.
      </p>
      <div class="form-group submit-container">
        <input type="submit" name="submit" (click)="onSubmitForgotPassword()" id="submitbutton" class="btn btn-primary"
          value="Recupera password" [disabled]="!valid">
      </div>

    </div>

    <div>
      <br />
      <a routerLink="/" >Torna indietro</a>
    </div>
  </div>
</div>