<div class="profile">
    <div class="profile-img-container">
        <img [src]="immagineProfilo" class="profile-img" />
        <button mat-raised-button color="primary" (click)="goToChangeImage()">Cambia immagine</button>
    </div>
    <div>
        <h3>{{nomeUtente}}</h3>
    </div>
    <div class="cf">
        <h4>CF: {{codiceFiscale}}</h4>
    </div>
    <div>
        Medico: {{nomeMedico}}
    </div>
    <div *ngIf="isPagamentiVisibile">
        Scadenza abbonamento: {{dataScadenzaAbbonamento}}
    </div>
    <div>
        Applicativo: {{applicationName}}
    </div>
</div>
