import { Component, ElementRef, Input, ViewChild, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { PROVINCE } from "../constants";

@Component({
    selector: 'ev-citta',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => EvProvinciaComponent),
        }
    ],
    template: `
<mat-form-field [style.width]="width">
    <mat-label>{{label}}</mat-label>
    <mat-select [(value)]="value" [disabled]="disabled" (selectionChange)="changed($event.value)" (openedChange)="doFocus($event)">
        <input #filterInput type="text" (keyup)="filter()" placeholder="Filtra" style="padding: 10px; width: 100%" [(ngModel)]="filterValue">
        <mat-option *ngFor="let prov of province_k" [value]="prov">
            {{province[prov]}}
        </mat-option>
    </mat-select>
</mat-form-field>
    `
})
export class EvProvinciaComponent implements ControlValueAccessor {
    @Input() label = 'Città'
    @Input() disabled = false
    @Input() width = '100%'

    @Input() set value(val) {
        if (val) {
            this._value = val
            this.province_k = [val]
            this.province = { [val]: PROVINCE[val] }
            this.changed(val)
        }
    }
    get value() {
        return this._value
    }
    _value = ''
    filterValue = ''

    @ViewChild('filterInput') filterInput: ElementRef<HTMLInputElement>

    province: any = PROVINCE
    province_k = Object.keys(PROVINCE)

    onChange: any
    onTouched: any

    doFocus(open) {
        if (open) {
            this.filterInput.nativeElement.focus()
        }
    }

    filter() {
        this.province = {}
        this.province_k = []

        Object.entries(PROVINCE).map(([key, value]) => {
            if (value.toLowerCase().indexOf(this.filterValue.toLowerCase()) > -1) {
                this.province[key] = value
                this.province_k.push(key)
            }
        })

        if (this.province_k.length === 0) {
            this.province = PROVINCE
            this.province_k = Object.keys(PROVINCE)
        }
    }

    changed(value) {
        if (this.onTouched) {
            this.onTouched(value)
        }
        if (this.onChange) {
            this.onChange(value)
        }
    }

    writeValue(value: any): void {
        this.value = value
    }
    registerOnChange(fn: any): void {
        this.onChange = fn
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled
    }
}