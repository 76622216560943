<ev-page pageTitle="News" funzionalita="homepage" [hideCategorie]="hideCategorie">

  <div>
    <mat-accordion>
      <mat-expansion-panel *ngFor="let news of newsList">
        <mat-expansion-panel-header>

          <mat-panel-title>
            News del {{news.data_inserimento}}
          </mat-panel-title>
          
        </mat-expansion-panel-header>
        <p [innerHTML]="news.testo"></p>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

</ev-page>