import { AfterViewInit, Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { EvButtonToggle } from './lib/components/ev-button-toggle.component';
import { URL_YT_NEXTCOMUNICA } from './lib/constants';
import { ApplicationService } from './lib/services/application.service';
import { DialogMaterialService } from './lib/services/dialog-material.service';
import { DisplayService } from './lib/services/display.service';
import { DocumentsService } from './lib/services/documents.service';
import { FunzionalitaService } from './lib/services/funzionalita.service';
import { ToolbarAction, ToolbarService } from './lib/services/toolbar.service';
import { UtenteService } from './lib/services/utente.service';
import { SwUpdateService } from './lib/sw/sw-update.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnDestroy {
  title = 'salute-con-te-client'

  showAppName: boolean = false
  showAppLogo: boolean = true
  
  URL_YT_NEXTCOMUNICA = URL_YT_NEXTCOMUNICA

  static instance
  loginSub: Subscription
  toolbarHideSub: Subscription
  datiUtentePresentiSub: Subscription
  isPagamentiVisibile = false
  hideToolbar = false
  funzionalitaLoadedSub: Subscription
  toolbarActionSub: Subscription
  currentToolbarAction: ToolbarAction

  @ViewChild('swDataRef') infoTemplateRef: TemplateRef<any>


  buttons: Partial<EvButtonToggle>[] = [
    {
      value: 'home',
      url: '/homepage',
      icon: 'home'
    },
    {
      value: 'calendario',
      url: '/calendario',
      icon: 'event'
    },
    {
      value: 'ricette',
      url: '/terapiaContinuativa',
      icon: 'description'
    },
    {
      value: 'comunicazioni',
      url: '/comunicazioni/comunicazioni',
      icon: 'chat'
    }
  ]

  constructor(
    protected router: Router,
    protected swUpdateService: SwUpdateService,
    protected funzionalitaService: FunzionalitaService,
    public utenteService: UtenteService,
    public applicationService: ApplicationService,
    public documentsService: DocumentsService,
    protected toolbarService: ToolbarService,
    private dialogMaterialService: DialogMaterialService,
    public displayService: DisplayService) {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {

      this.funzionalitaLoadedSub = this.funzionalitaService.funzionalitaLoaded$.subscribe(() => {
        if (!this.funzionalitaService.isFunzionalitaAbilitata('Appuntamenti')) {
          this.buttons = this.buttons.filter(b => b.icon !== 'event')
        }
      })

      this.datiUtentePresentiSub = this.utenteService.datiUtentePresenti.subscribe(value => {
        if (value) {
          this.isPagamentiVisibile = this.utenteService.isPagamentiVisibile()
        }
      })

      this.toolbarHideSub = this.toolbarService.hideToolbar.subscribe(value => {
        setTimeout(() => {
          this.hideToolbar = value
        }, 0)
      })

      this.toolbarActionSub = this.toolbarService.showAction.subscribe(action => {
        setTimeout(() => {
          this.currentToolbarAction = action
        }, 0)
      })
    }, 0);
  }

  showSwData() {
    this.dialogMaterialService.open(this.infoTemplateRef)
  }

  toolbarActionClick(action: ToolbarAction) {
    if (action.action) {
      action.action(action, action.data)
    }
  }

  showManualAndCloseMessage(homeMessage) {
    homeMessage.close()
    this.applicationService.richiediAssistenzaInterno()
  }

  ngOnDestroy(): void {
    this.funzionalitaLoadedSub?.unsubscribe()
    this.loginSub?.unsubscribe()
    this.toolbarActionSub?.unsubscribe()
    this.datiUtentePresentiSub?.unsubscribe()
  }
}
